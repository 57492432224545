import {StatusCodes} from 'http-status-codes';
import {Navigate} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../components';
import {useUserContext} from '../../context';
import {useQuery} from '../../hooks';
import {Routes} from '../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../util';

function ConfirmState(props) {
    const {
        children,
        registrationIsConfirmed,
        requiredRegistrationState,
    } = props;

    if (requiredRegistrationState) {
        // We need user to have confirmed registration
        if (registrationIsConfirmed) {
            // User has completed registration, so everything is ok and we can show children
            return children;
        }
        // User has not completed registration, so send them to the registration flow
        return (
            <Navigate
                replace={true}
                to={Routes.SIGN_UP_ABOUT_YOU}
            />
        );
    }
    // We need user to have not confirmed registration
    if (registrationIsConfirmed) {
        // User has completed registration, so they should not be able to access the children, so send them back
        // to their home
        return (
            <Navigate
                replace={true}
                to={Routes.HOME}
            />
        );
    }
    // User has not completed registration, so send them to the registration flow they are fine to access
    // children
    return children;
}

function ConfirmStateFromApi(props) {
    const {
        data: {registration: {confirmed = false} = {}} = {},
        error,
        loading,
    } = useQuery(Endpoint.SUMMARY);

    if (loading) {
        return (
            <div className={'guard'}>
                <Loading key={'loading'} />
            </div>
        );
    }

    if (error?.status === StatusCodes.FORBIDDEN) {
        // User has not consented and verified, so the summary is returning 403. In this case we know they are not confirmed yet
        return (
            <ConfirmState
                {...props}
                registrationIsConfirmed={false}
            />
        );
    }

    if (error) {
        return (
            <div className={'guard'}>
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    return (
        <ConfirmState
            {...props}
            registrationIsConfirmed={confirmed}
        />
    );
}

export function RequireCompletedRegistrationState(props) {
    const {isAuthenticated} = useUserContext();

    if (isAuthenticated) {
        return <ConfirmStateFromApi {...props} />;
    }
    // Not authenticated, so confirmed = false
    return (
        <ConfirmState
            {...props}
            registrationIsConfirmed={false}
        />
    );
}
