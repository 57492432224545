import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useAmplitude} from '../../../context';
import {
    useEligibility,
    useTitle,
} from '../../../hooks';

export function CheckEligibility() {
    const amplitude = useAmplitude();
    const {getEligibility} = useEligibility();
    const intl = useIntl();
    useTitle(intl.formatMessage({id: `signUp.eligibility.documentTitle`}));

    useEffect(() => {
        amplitude.logEligibilityVisit();
    }, [amplitude]);

    return (
        <>
            <h2 data-test-id={'sign-up-eligibility-title'}>
                <FormattedMessage id={'signUp.eligibility.title'} />
            </h2>
            <p>
                <FormattedMessage id={'signUp.eligibility.criteria'} />
            </p>
            <ul className={'no-item-margin'}>
                <li>
                    <FormattedMessage id={'signUp.eligibility.criteria.one'} />
                </li>
                <li>
                    <FormattedMessage id={'signUp.eligibility.criteria.two'} />
                </li>
            </ul>
            <h4 className={'less-margin'}>
                <FormattedMessage id={'signUp.eligibility.availability'} />
            </h4>
            <p className={'no-margin'}>
                <FormattedMessage
                    id={'signUp.eligibility.states'}
                    values={{
                        count: (chunk) => (
                            <span data-test-id={'state-count'}>
                                {getEligibility()?.eligibleRegions?.length ?? chunk}
                            </span>
                        ),
                    }}
                />
            </p>
            <p>
                <FormattedMessage id={'signUp.eligibility.liveIn'} />
            </p>
            <ul
                className={'no-item-margin list-of-states'}
                data-test-id={'list-of-states'}
            >
                {getEligibility()
                    ?.eligibleRegions.map((stateCode) => ({
                        stateCode: stateCode,
                        stateName: intl.formatMessage({id: `common.state.${stateCode}`}),
                    }))
                    .sort((
                        {stateName: leftStateName},
                        {stateName: rightStateName},
                    ) => (leftStateName < rightStateName ? -1 : leftStateName > rightStateName ? 1 : 0))
                    .map(({stateCode, stateName}) => (
                        <li
                            data-test-id={'state'}
                            key={stateCode}
                        >
                            {stateName}
                        </li>
                    ))}
            </ul>
            <p className={'description'}>
                <FormattedMessage id={'signUp.eligibility.dontLiveIn'} />
            </p>
        </>
    );
}
