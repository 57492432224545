import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link} from 'react-router-dom';
import {Checkmark} from '../../components';
import {Routes} from '../../Routes';

export function SecureContent() {
    const intl = useIntl();

    return (
        <div className={'secure mid-navy-gradient'}>
            <h4>
                <img
                    alt={intl.formatMessage({id: 'landing.bullets.locked.alt'})}
                    role={'presentation'}
                    src={'/images/icon-locked_vector_v1_1KB.svg'}
                />
                <span>
                    <FormattedMessage id={'landing.secure.title'} />
                </span>
            </h4>
            <ul>
                <li className={'is-heavy multiline'}>
                    <Checkmark />
                    <div>
                        <FormattedMessage id={'landing.secure.pointOne'} />
                        <br />
                        <span className={'is-caption is-light'}>
                            <FormattedMessage id={'landing.secure.pointOne.extra'} />
                        </span>
                    </div>
                </li>
                <li className={'is-heavy'}>
                    <Checkmark />
                    <FormattedMessage id={'landing.secure.pointTwo'} />
                </li>
                <li className={'is-heavy multiline'}>
                    <Checkmark />
                    <FormattedMessage id={'landing.secure.pointThree'} />
                </li>
            </ul>
            <Link
                className={'link button'}
                data-test-id={'trust-and-privacy-button'}
                title={intl.formatMessage({id: 'landing.secure.button.title'})}
                to={Routes.TRUST_AND_PRIVACY}
            >
                <FormattedMessage id={'landing.secure.button.text'} />
            </Link>
        </div>
    );
}
