import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
} from '../../../../../../components';

export function HighBloodPressureRiskFactors(props) {
    const {currentStep, totalSteps} = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`risk-factors-HT`}
            title={intl.formatMessage({id: `reports.trait.actionPlan.actionCard.discussRiskFactors.title`})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.description'}
                    values={{trait: intl.formatMessage({id: `common.trait.HT.text`})}}
                />
            </p>
            <ul>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.overweight'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.diet'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.activity'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.tooMuchAlcohol'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.smoking'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.sleep'}
                    />
                </li>
            </ul>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/bloodpressure/risk_factors.htm'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.link.alternative'}
                    values={{trait: intl.formatMessage({id: `common.trait.HT.text`})}}
                />
            </LinkWithAttribution>
        </ActionCard>
    );
}
