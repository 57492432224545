import classNames from 'classnames';
import {useName} from '../../hooks';

export function Name(props) {
    const {className, dataTestId} = props;

    const {name} = useName();

    const nameClasses = classNames('name', 'is-caption', className);

    return (
        <span
            className={nameClasses}
            data-test-id={dataTestId ?? 'linear-flow-navbar-name'}
        >
            {name}
        </span>
    );
}
