import classNames from 'classnames';
import {
    useMemo,
    useState,
} from 'react';
import {Outlet} from 'react-router-dom';
import {useSanitisedPathname} from '../../hooks';
import {Routes} from '../../Routes';
import {PrimaryFooter} from './PrimaryFooter';
import {PrimaryNavbar} from './PrimaryNavbar';

const MARKETING_ROUTES = [
    Routes.LANDING,
    Routes.SEE_HOW_IT_WORKS,
    Routes.OUR_SCIENCE,
    Routes.TRUST_AND_PRIVACY,
    Routes.ABOUT_US,
];

export function PrimaryLayout() {
    const sanitisedPathname = useSanitisedPathname();
    const [mainClassName, setMainClassName] = useState('');
    const [signUpInNavbar, setSignUpInNavbar] = useState(false);

    const mainClasses = useMemo(
        () => classNames('primary-layout', sanitisedPathname, mainClassName),
        [mainClassName, sanitisedPathname],
    );

    return (
        <>
            <PrimaryNavbar
                homeLink={true}
                marketingRoutes={MARKETING_ROUTES}
                requiresSignUp={signUpInNavbar}
            />
            <main className={mainClasses}>
                <Outlet
                    context={{
                        setMainClassName,
                        setSignUpInNavbar,
                    }}
                />
            </main>
            <PrimaryFooter marketingRoutes={MARKETING_ROUTES} />
        </>
    );
}
