import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
} from '../../../../../../components';

export function ProstateCancerScreenOptions(props) {
    const {currentStep, totalSteps} = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`screen-options-PC`}
            title={intl.formatMessage({id: `reports.trait.actionPlan.actionCard.discussScreeningOptions.title`})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.prostateCancer.description.one'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.prostateCancer.description.two'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/cancer/prostate/basic_info/benefits-harms.htm'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.prostateCancer.link'}
                />
            </LinkWithAttribution>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.prostateCancer.description.three'}
                />
            </p>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.PSA.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.PSA.description'}
                />
            </p>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.DRE.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.DRE.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.ACS`})}
                url={'https://www.cancer.org/cancer/prostate-cancer/detection-diagnosis-staging/tests.html'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.PC.screen.link'}
                />
            </LinkWithAttribution>
        </ActionCard>
    );
}
