import {
    Outlet,
    useOutletContext,
} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../../components';
import {useQuery} from '../../../hooks';
import {
    Endpoint,
    extractErrorMessage,
} from '../../../util';

export function TestReorderContext() {
    const outletContext = useOutletContext();
    const {
        data: currentUserProfile = {},
        error: queryError,
        loading: queryLoading,
    } = useQuery(Endpoint.PROFILE);

    if (queryLoading) {
        return <Loading />;
    }

    if (queryError) {
        return <FetchError reason={extractErrorMessage(queryError)} />;
    }

    return (
        <Outlet
            context={{
                ...outletContext,
                currentUserProfile,
            }}
        />
    );
}
