import classNames from 'classnames';
import {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
} from 'react';
import {useIntl} from 'react-intl';
import {action} from '../../util';
import {Overlay} from './Overlay';

const DEFAULT_DURATION = 5000;
const DEFAULT_NUM_STEPS = 4;
const DEFAULT_OVERLAY_DELAY_MULTIPLIER = 0.15;
const DEFAULT_STOP_TRANSITION_MULTIPLIER = 0.25;

const SET_OVERLAY_RESULT = 'SET_OVERLAY_RESULT';
const TOGGLE_IS_PLAYING = 'TOGGLE_IS_PLAYING';
const UPDATE_IS_TRANSITIONING = 'UPDATE_IS_TRANSITIONING';
const UPDATE_PREFERENCE = 'UPDATE_PREFERENCE';
const UPDATE_STEP = 'UPDATE_STEP';

const init = (steps) => ({
    isPlaying: false,
    isTransitioning: false,
    prefersReducedMotion: true,
    step: 0,
    steps: steps,
});

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_OVERLAY_RESULT:
            return {
                ...state,
                overlayResult: payload,
            };

        case TOGGLE_IS_PLAYING:
            return {
                ...state,
                isPlaying: !state.isPlaying,
            };

        case UPDATE_IS_TRANSITIONING:
            return {
                ...state,
                isTransitioning: payload,
            };

        case UPDATE_PREFERENCE:
            return {
                ...state,
                prefersReducedMotion: payload,
            };

        case UPDATE_STEP:
            return {
                ...state,
                step: state.step + 1 === state.steps ? 0 : state.step + 1,
            };

        default:
            return state;
    }
};

export function MarketingEqualizer(props) {
    const {
        className,
        config = [],
        dataTestId,
        duration = DEFAULT_DURATION,
        overlayDelayMultiplier = DEFAULT_OVERLAY_DELAY_MULTIPLIER,
        steps = DEFAULT_NUM_STEPS,
        stopTransitionMultiplier = DEFAULT_STOP_TRANSITION_MULTIPLIER,
    } = props;

    const intl = useIntl();
    const [{isPlaying, isTransitioning, prefersReducedMotion, overlayResult, step}, dispatch] = useReducer(
        reducer,
        steps,
        init,
    );

    const shouldAnimate = useMemo(() => {
        let animate = !prefersReducedMotion;
        if (prefersReducedMotion && isPlaying) {
            animate = true;
        }

        return animate;
    }, [isPlaying, prefersReducedMotion]);

    const mediaQueryEventHandler = () => {
        const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
        dispatch(action(UPDATE_PREFERENCE, !mediaQuery || mediaQuery.matches));
    };

    useEffect(() => {
        // Update the state now we know what the values of prefers-reduced-motion is
        const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
        dispatch(action(UPDATE_PREFERENCE, !mediaQuery || mediaQuery.matches));

        // Add an event listener to handle any changes, which are probably never likely to happen, but you never know...
        mediaQuery.addEventListener('change', mediaQueryEventHandler);

        return () => {
            mediaQuery.removeEventListener('change', mediaQueryEventHandler);
        };
    }, []);

    useEffect(() => {
        if (shouldAnimate) {
            const interval = setInterval(() => {
                dispatch(action(UPDATE_STEP));
            }, duration);

            return () => {
                clearInterval(interval);
            };
        }

        return () => {
            // Consistent returns
        };
    }, [duration, shouldAnimate]);

    useEffect(() => {
        if (shouldAnimate) {
            dispatch(action(UPDATE_IS_TRANSITIONING, true));

            setTimeout(() => {
                dispatch(action(SET_OVERLAY_RESULT, config[step]?.overlay));
            }, duration * overlayDelayMultiplier);

            setTimeout(() => {
                dispatch(action(UPDATE_IS_TRANSITIONING, false));
            }, duration * stopTransitionMultiplier);
        }
    }, [config, duration, shouldAnimate, overlayDelayMultiplier, step, stopTransitionMultiplier]);

    const onButtonClickHandler = useCallback(() => {
        dispatch(action(TOGGLE_IS_PLAYING));
    }, []);

    const containerClasses = classNames('marketing-equalizer', `step-${step}`, className);

    return (
        <div
            className={containerClasses}
            data-test-id={dataTestId ?? 'marketing-equalizer'}
        >
            {prefersReducedMotion && (
                <button
                    className={'play-pause'}
                    onClick={onButtonClickHandler}
                    type={'button'}
                >
                    {isPlaying
                        ? (
                            <img
                                alt={intl.formatMessage({id: 'landing.equalizer.button.pause'})}
                                src={'/images/icon-pause-filled.svg'}
                            />
                        )
                        : (
                            <img
                                alt={intl.formatMessage({id: 'landing.equalizer.button.play'})}
                                src={'/images/icon-play-filled.svg'}
                            />
                        )}
                </button>
            )}

            <Overlay
                isTransitioning={isTransitioning}
                {...overlayResult}
            />

            <div className={'images'}>
                {config.map(({img}, index) => (
                    <div
                        className={`image image-${index}`}
                        key={img}
                        style={{backgroundImage: img}}
                    />
                ))}
            </div>
        </div>
    );
}
