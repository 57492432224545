import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import {
    LinearFlowLayout,
    MinimalLayout,
} from '../../../layouts';
import {NotFound} from '../../not-found';
import {
    getKitActivationCode,
    KitActivationContext,
} from '../components';
import {EnterTheCode} from '../enter-the-code';
import {PackTheTube} from '../pack-the-tube';
import {PeelAndSeal} from '../peel-and-seal';
import {SampleCollected} from '../sample-collected';
import {SpitAndClose} from '../spit-and-close';
import {SwapAndShake} from '../swap-and-shake';
import {UnpackTheContents} from '../unpack-the-contents';

const hasEnteredKitCode = () => {
    const kitCode = getKitActivationCode();
    return Boolean(kitCode);
};

const flowConfig = {
    labelId: 'kit.activation.progress.label',
    steps: [
        {
            displayStep: '1',
            route: '/kit-activation/unpack-the-contents',
        },
        {
            displayStep: '2',
            route: '/kit-activation/enter-the-code',
        },
        {
            displayStep: '3',
            prerequisites: async function* checks() {
                yield hasEnteredKitCode();
            },
            route: '/kit-activation/spit-and-close',
        },
        {
            displayStep: '4',
            prerequisites: async function* checks() {
                yield hasEnteredKitCode();
            },
            route: '/kit-activation/swap-and-shake',
        },
        {
            displayStep: '5',
            prerequisites: async function* checks() {
                yield hasEnteredKitCode();
            },
            route: '/kit-activation/pack-the-tube',
        },
        {
            displayStep: '6',
            prerequisites: async function* checks() {
                yield hasEnteredKitCode();
            },
            route: '/kit-activation/peel-and-seal',
        },
        {
            displayStep: '7',
            prerequisites: async function* checks() {
                yield hasEnteredKitCode();
            },
            route: '/kit-activation/sample-collected',
        },
    ],
    totalStepsDisplay: '7',
};

export function KitActivationController() {
    return (
        <Routes>
            <Route
                element={
                    <LinearFlowLayout
                        className={'kit-activation'}
                        flowConfig={flowConfig}
                    />
                }
            >
                <Route element={<KitActivationContext />}>
                    <Route
                        element={
                            <Navigate
                                replace={true}
                                to={'unpack-the-contents'}
                            />
                        }
                        index={true}
                    />
                    <Route
                        element={<UnpackTheContents />}
                        path={'unpack-the-contents'}
                    />
                    <Route
                        element={<EnterTheCode />}
                        path={'enter-the-code'}
                    />
                    <Route
                        element={<SpitAndClose />}
                        path={'spit-and-close'}
                    />
                    <Route
                        element={<SwapAndShake />}
                        path={'swap-and-shake'}
                    />
                    <Route
                        element={<PackTheTube />}
                        path={'pack-the-tube'}
                    />
                    <Route
                        element={<PeelAndSeal />}
                        path={'peel-and-seal'}
                    />
                    <Route
                        element={<SampleCollected />}
                        path={'sample-collected'}
                    />
                </Route>
            </Route>
            <Route element={<MinimalLayout />}>
                <Route
                    element={<NotFound />}
                    path={'*'}
                />
            </Route>
        </Routes>
    );
}
