import classNames from 'classnames';
import {
    HomeLink,
    HowItWorksLink,
    ScienceLink,
    TrustAndPrivacyLink,
} from '../navigation-links';

export function FooterMarketingLinks() {
    const linkClasses = classNames('is-heavy', 'is-caption');

    return (
        <>
            <HomeLink
                className={linkClasses}
                dataTestId={'footer-home-link'}
            />
            <HowItWorksLink
                className={linkClasses}
                dataTestId={'footer-how-it-works-link'}
            />
            <ScienceLink
                className={linkClasses}
                dataTestId={'footer-science-link'}
            />
            <TrustAndPrivacyLink
                className={linkClasses}
                dataTestId={'footer-trust-and-privacy-link'}
            />
        </>
    );
}
