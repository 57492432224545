export class Routes {
    static ABOUT_US = '/about-us';

    static ACTIVATE = '/activate';

    static ACCOUNT = '/account';

    static ACCOUNT_LAB_REPORT = '/account/lab-report';

    static ACCOUNT_PROFILE = '/account/profile';

    static ACCOUNT_SETTINGS = '/account/security';

    static ACCOUNT_TESTS = '/account/tests';

    static CONTACT_SUPPORT = '/contact-support';

    static HELP_CENTER = 'https://help.insightsgenomics.com/';

    static HOME = '/home';

    static KIT_ACTIVATION = '/kit-activation';

    static KIT_ACTIVATION_ENTER_THE_CODE = '/kit-activation/enter-the-code';

    static LANDING = '/';

    static OVERVIEW = '/overview';

    static OUR_SCIENCE = '/science';

    static PRIVACY_POLICY = '/privacy-policy';

    static RE_ENTER_ACCESS_CODE = '/re-enter-access-code';

    static REORDER_KIT_CHECK_YOUR_DETAILS = '/test-reorder/check-your-details';

    static RESULTS = '/results';

    static REPORTS_CALCULATE_RISK = `/results/how-we-calculate-risk`;

    static SIGN_UP = '/sign-up';

    static SIGN_UP_INVITE_CODE = '/sign-up/invite-code';

    static SIGN_UP_CHECK_ELIGIBILITY = '/sign-up/check-eligibility';

    static SIGN_UP_THINGS_YOU_SHOULD_KNOW = '/sign-up/things-you-should-know';

    static SIGN_UP_ACCOUNT_SETUP = '/sign-up/account-setup';

    static SIGN_UP_CONSENT = '/sign-up/consent';

    static SIGN_UP_VERIFY_EMAIL = '/sign-up/verify-email';

    static SIGN_UP_ABOUT_YOU = '/sign-up/about-you';

    static SIGN_UP_NAME = '/sign-up/name';

    static SIGN_UP_DATE_OF_BIRTH = '/sign-up/date-of-birth';

    static SIGN_UP_EDIT_REPORTED_SEX = '/sign-up/edit/reported-sex';

    static SIGN_UP_REPORTED_SEX = '/sign-up/reported-sex';

    static SIGN_UP_EDIT_PNTS_SEX = '/sign-up/edit/prefer-not-to-say-sex';

    static SIGN_UP_PNTS_SEX = '/sign-up/prefer-not-to-say-sex';

    static SIGN_UP_EDIT_ETHNICITY = '/sign-up/edit/ethnicity';

    static SIGN_UP_ETHNICITY = '/sign-up/ethnicity';

    static SIGN_UP_EDIT_PNTS_ETHNICITY = '/sign-up/edit/prefer-not-to-say-ethnicity';

    static SIGN_UP_PNTS_ETHNICITY = '/sign-up/prefer-not-to-say-ethnicity';

    static SIGN_UP_EDIT_IDENTIFIED_ETHNICITY = '/sign-up/edit/identified-ethnicity';

    static SIGN_UP_IDENTIFIED_ETHNICITY = '/sign-up/identified-ethnicity';

    static SIGN_UP_PHONE_NUMBER = '/sign-up/phone-number';

    static SIGN_UP_HOME_ADDRESS = '/sign-up/home-address';

    static SIGN_UP_SHIPPING_DETAILS = '/sign-up/shipping-details';

    static SIGN_UP_SHIPPING_ADDRESS = '/sign-up/shipping-address';

    static SIGN_UP_SUMMARY = '/sign-up/summary';

    static SIGN_UP_YOUR_REPORTS = '/sign-up/your-reports';

    static SEE_HOW_IT_WORKS = '/how-it-works';

    static STEADYMD_DATA_POLICY = '/steadymd-patient-agreement';

    static TERMS_AND_CONDITIONS = '/terms-and-conditions';

    static TEST_REORDER = '/test-reorder';

    static TEST_REORDER_CHECK_YOUR_DETAILS = '/test-reorder/check-your-details';

    static TEST_REORDER_YOUR_REPORTS = '/test-reorder/your-reports';

    static TEST_REORDER_EDIT_NAME = '/test-reorder/edit/name';

    static TEST_REORDER_EDIT_DATE_OF_BIRTH = '/test-reorder/edit/date-of-birth';

    static TEST_REORDER_EDIT_REPORTED_SEX = '/test-reorder/edit/reported-sex';

    static TEST_REORDER_EDIT_PNTS_SEX = '/test-reorder/edit/prefer-not-to-say-sex';

    static TEST_REORDER_EDIT_ETHNICITY = '/test-reorder/edit/ethnicity';

    static TEST_REORDER_EDIT_PNTS_ETHNICITY = '/test-reorder/edit/prefer-not-to-say-ethnicity';

    static TEST_REORDER_EDIT_IDENTIFIED_ETHNICITY = '/test-reorder/edit/identified-ethnicity';

    static TEST_REORDER_EDIT_PHONE_NUMBER = '/test-reorder/phone-number';

    static TEST_REORDER_EDIT_HOME_ADDRESS = '/test-reorder/home-address';

    static TEST_REORDER_EDIT_SHIPPING_ADDRESS = '/test-reorder/shipping-address';

    static TRUST_AND_PRIVACY = '/trust-and-privacy';

    static TARGET_WEBSITE_PRIVACY = 'website-privacy';

    static LOGOUT = '/logout';
}
