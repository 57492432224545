import {
    Link,
    MoreInfo,
} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {GridContainer} from '../../../components';
import {Routes} from '../../../Routes';

export function KnowMore() {
    const intl = useIntl();

    return (
        <GridContainer
            containerClassName={'know-more'}
            gridClassName={'full-width'}
        >
            <div className={'content'}>
                <h2>
                    <FormattedMessage id={'reports.how-we-calculate-risk.know-more.title'} />
                </h2>
                <div className={'questions'}>
                    <MoreInfo
                        content={
                            <>
                                <p>
                                    <FormattedMessage
                                        id={'reports.how-we-calculate-risk.know-more.more-info.one.answer.one'}
                                    />
                                </p>
                                <p>
                                    <FormattedMessage
                                        id={'reports.how-we-calculate-risk.know-more.more-info.one.answer.two'}
                                    />
                                </p>
                                <p>
                                    <FormattedMessage
                                        id={'reports.how-we-calculate-risk.know-more.more-info.one.answer.three'}
                                    />
                                </p>
                            </>
                        }
                        dataTestId={'more-info-family'}
                        title={intl.formatMessage({
                            id: 'reports.how-we-calculate-risk.know-more.more-info.one.question',
                        })}
                    />
                    <MoreInfo
                        content={
                            <>
                                <p>
                                    <FormattedMessage
                                        id={'reports.how-we-calculate-risk.know-more.more-info.two.answer.one'}
                                    />
                                </p>
                                <ul className={'no-margin-top less-margin-bottom'}>
                                    <li>
                                        <FormattedMessage
                                            id={'reports.how-we-calculate-risk.know-more.more-info.two.answer.two'}
                                        />
                                    </li>
                                    <li className={'no-bottom-margin'}>
                                        <FormattedMessage
                                            id={'reports.how-we-calculate-risk.know-more.more-info.two.answer.three'}
                                        />
                                    </li>
                                </ul>
                                <p>
                                    <FormattedMessage
                                        id={'reports.how-we-calculate-risk.know-more.more-info.two.answer.four'}
                                    />
                                </p>
                            </>
                        }
                        dataTestId={'more-info-risk-window'}
                        title={intl.formatMessage({
                            id: 'reports.how-we-calculate-risk.know-more.more-info.two.question',
                        })}
                    />
                    <MoreInfo
                        content={
                            <>
                                <p>
                                    <FormattedMessage
                                        id={'reports.how-we-calculate-risk.know-more.more-info.three.answer.one'}
                                    />
                                </p>
                                <p>
                                    <FormattedMessage
                                        id={'reports.how-we-calculate-risk.know-more.more-info.three.answer.two'}
                                    />
                                </p>
                                <p>
                                    <FormattedMessage
                                        id={'reports.how-we-calculate-risk.know-more.more-info.three.answer.three'}
                                    />
                                </p>
                            </>
                        }
                        dataTestId={'more-info-ancestries'}
                        title={intl.formatMessage({
                            id: 'reports.how-we-calculate-risk.know-more.more-info.three.question',
                        })}
                    />
                </div>
                <Link
                    className={'button'}
                    rel={'noreferrer'}
                    target={'_blank'}
                    url={Routes.HELP_CENTER}
                >
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.know-more.find-more-answers.button'}
                    />
                </Link>
                <div className={'ask-a-doctor'}>
                    <h4>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.know-more.ask-specialist.title'}
                        />
                    </h4>
                    <p className={'no-margin'}>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.know-more.ask-specialist.description'}
                        />
                    </p>
                </div>
            </div>
        </GridContainer>
    );
}
