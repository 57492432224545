import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
    SupplementalBox,
} from '../../../../../../components';
import {RiskLevel} from '../../../../../../util';

export function CardiovascularDiseaseScreenOptions(props) {
    const {
        currentStep,
        totalSteps,
        riskLevel,
        riskWindow,
    } = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`screen-options-CVD_US-${riskLevel}`}
            title={intl.formatMessage({
                id: `reports.trait.actionPlan.actionCard.discussScreeningOptions.title.${riskLevel}`,
            })}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={`reports.trait.actionPlan.actionCard.discussScreeningOptions.description.CVD.${riskWindow}`}
                />
            </p>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.cholesterol.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.cholesterol.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/cholesterol/cholesterol_screening.htm'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.cholesterol.link'}
                />
            </LinkWithAttribution>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.diabetes.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.diabetes.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/diabetes/basics/getting-tested.html'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.diabetes.link'}
                />
            </LinkWithAttribution>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressure.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressure.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.AHA`})}
                url={'https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressure.link'}
                />
            </LinkWithAttribution>
            {riskLevel === RiskLevel.HIGHER && (
                <>
                    <p className={'heading is-heavy'}>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.coronaryArtery.heading'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.coronaryArtery.description'}
                        />
                    </p>
                    <LinkWithAttribution
                        attribution={intl.formatMessage({id: `common.link.attribution.NHLBI`})}
                        url={'https://www.nhlbi.nih.gov/health/heart-tests'}
                    >
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.coronaryArtery.link'}
                        />
                    </LinkWithAttribution>
                    <SupplementalBox
                        title={intl.formatMessage({
                            id: 'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.heading',
                        })}
                    >
                        <p>
                            <FormattedMessage
                                id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.CVD_US.description'}
                            />
                        </p>
                        <p className={'heading is-heavy'}>
                            <FormattedMessage
                                id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.statins.heading'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.statins.description'}
                            />
                        </p>
                        <LinkWithAttribution
                            attribution={intl.formatMessage({id: `common.link.attribution.AHA`})}
                            url={'https://www.heart.org/en/health-topics/heart-attack/treatment-of-a-heart-attack/cardiac-medications'}
                        >
                            <FormattedMessage
                                id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.statins.link'}
                            />
                        </LinkWithAttribution>
                    </SupplementalBox>
                </>
            )}
        </ActionCard>
    );
}
