import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useAmplitude} from '../../../context';
import {useTitle} from '../../../hooks';

export function AboutYou() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    useTitle(intl.formatMessage({id: `signUp.aboutYou.documentTitle`}));

    useEffect(() => {
        amplitude.logAboutYouVisited();
    }, [amplitude]);

    return (
        <>
            <h2 data-test-id={'sign-up-about-you-title'}>
                <FormattedMessage id={'signUp.aboutYou.title'} />
            </h2>
            <p>
                <FormattedMessage id={'signUp.aboutYou.description'} />
            </p>
        </>
    );
}
