import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Checkmark,
    SixFiveGridContainer,
} from '../../components';

export function DiscoverContent() {
    const intl = useIntl();

    return (
        <SixFiveGridContainer className={'discover'}>
            <div className={'five'}>
                <h2>
                    <FormattedMessage id={'landing.discover.title'} />
                </h2>
                <ul>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'landing.discover.cancer'} />
                    </li>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'common.trait.CVD_US.title'} />
                    </li>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'common.trait.T2D.title'} />
                    </li>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'common.trait.AF.title'} />
                    </li>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'common.trait.HCH.title'} />
                    </li>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'common.trait.HT.title'} />
                    </li>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'common.trait.FNDXAT_N1P0.title'} />
                    </li>
                </ul>
                <p className={'is-light'}>
                    <FormattedMessage id={'landing.discover.cancerResult'} />
                </p>
            </div>
            <img
                alt={intl.formatMessage({id: 'landing.image.results.alt'})}
                className={'six image'}
                src={'/images/result-mockup_1040x1200_v1_75KB.webp'}
            />
        </SixFiveGridContainer>
    );
}
