import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {
    PageContent,
    TitleContainer,
    TwoThirdsContainer,
} from '../../components';
import {useAmplitude} from '../../context';
import {ControlDataContent} from './ControlDataContent';
import {DataUseContent} from './DataUseContent';
import {DeidentifiedDataContent} from './DeidentifiedDataContent';
import {PersonalDataContent} from './PersonalDataContent';
import {ProtectDataContent} from './ProtectDataContent';

export function TrustAndPrivacy() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {setMainClassName} = useOutletContext();

    useEffect(() => {
        amplitude.logTrustAndPrivacyVisit();
        setMainClassName('wavy-line-background');

        return () => {
            setMainClassName(null);
        };
    }, [amplitude]);

    return (
        <>
            <PageContent className={'page-title variant-dark'}>
                <h1 data-test-id={'trust-and-privacy-page-main-title'}>
                    <FormattedMessage id={'trust-and-privacy.hero.title'} />
                </h1>
                <p>
                    <FormattedMessage id={'trust-and-privacy.hero.description'} />
                </p>
            </PageContent>
            <PageContent className={'variant-dark'}>
                <h2
                    className={'offset-title'}
                    data-test-id={'personal-data-title'}
                >
                    <FormattedMessage id={'trust-and-privacy.personal-data.title'} />
                </h2>
                <TwoThirdsContainer
                    ContentComponent={<PersonalDataContent />}
                    TitleComponent={
                        <span>
                            <FormattedMessage id={'trust-and-privacy.personal-data.description'} />
                        </span>
                    }
                />
            </PageContent>
            <PageContent className={'variant-dark mid-navy-gradient'}>
                <TwoThirdsContainer
                    ContentComponent={<ControlDataContent />}
                    TitleComponent={
                        <TitleContainer
                            large={true}
                            title={intl.formatMessage({id: 'trust-and-privacy.control-data.title'})}
                        />
                    }
                />
            </PageContent>
            <PageContent className={'variant-dark'}>
                <TwoThirdsContainer
                    ContentComponent={<DataUseContent />}
                    TitleComponent={
                        <TitleContainer
                            large={true}
                            title={intl.formatMessage({id: 'trust-and-privacy.data-create-reports.title'})}
                        />
                    }
                />
                <TwoThirdsContainer
                    ContentComponent={<DeidentifiedDataContent />}
                    TitleComponent={
                        <TitleContainer
                            large={true}
                            title={intl.formatMessage({id: 'trust-and-privacy.de-identified-data.title'})}
                        />
                    }
                />
                <TwoThirdsContainer
                    ContentComponent={<ProtectDataContent />}
                    TitleComponent={
                        <TitleContainer
                            large={true}
                            title={intl.formatMessage({id: 'trust-and-privacy.protect-data.title'})}
                        />
                    }
                />
            </PageContent>
        </>
    );
}
