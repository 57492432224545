import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
} from '../../../../../../components';

export function AtrialFibrillationRiskFactors(props) {
    const {currentStep, totalSteps} = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`risk-factors-AF`}
            title={intl.formatMessage({id: `reports.trait.actionPlan.actionCard.discussRiskFactors.title`})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.description'}
                    values={{trait: intl.formatMessage({id: `common.trait.AF.text`})}}
                />
            </p>
            <ul>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.overweight'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.tooMuchAlcohol'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.smoking'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.activity'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.bloodPressure'}
                    />
                </li>
            </ul>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.NHLBI'})}
                url={'https://www.nhlbi.nih.gov/health/atrial-fibrillation/causes'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.link'}
                    values={{trait: intl.formatMessage({id: `common.trait.AF.title`})}}
                />
            </LinkWithAttribution>
        </ActionCard>
    );
}
