import classNames from 'classnames';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Routes} from '../../../../Routes';

export function HomeLink(props) {
    const {className, dataTestId} = props;

    const intl = useIntl();

    const linkClasses = classNames('link', className);

    return (
        <NavLink
            className={linkClasses}
            data-test-id={dataTestId ?? 'navigation-home-link'}
            end={true}
            title={intl.formatMessage({id: 'navigation.link.home.title'})}
            to={Routes.LANDING}
        >
            <span>
                <FormattedMessage id={'navigation.link.home.text'} />
            </span>
        </NavLink>
    );
}
