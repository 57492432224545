import classNames from 'classnames';
import {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';

export function PerformanceGraph(props) {
    const {
        className,
        dataTestId,
        performanceData,
        scale,
        versus,
    } = props;

    const processedPerformanceData = useMemo(
        () =>
            performanceData.map(({name, performance}) => ({
                name: name,
                performance: performance,
                widthThem: ((performance.them - scale.min) / (scale.max - scale.min)) * 100,
                widthUs: ((performance.us - scale.min) / (scale.max - scale.min)) * 100,
            })),
        [performanceData, scale],
    );

    const chartClasses = classNames('performance-graph', className);

    return (
        <div
            className={chartClasses}
            data-test-id={dataTestId}
        >
            <div className={'title'}>
                <div className={'us'} />
                <div className={'versus is-heavy'}>
                    <FormattedMessage id={'common.performanceGraph.versus'} />
                </div>
                <div className={'them is-heavy'}>{versus}</div>
            </div>
            {processedPerformanceData.map((trait) => (
                <div
                    className={'trait'}
                    key={trait.name}
                >
                    <div className={'name is-caption is-heavy'}>{trait.name}</div>
                    <div
                        className={'datum us'}
                        style={{width: `${trait.widthUs}%`}}
                    />
                    <div
                        className={'datum them'}
                        style={{width: `${trait.widthThem}%`}}
                    />
                </div>
            ))}
            <div className={'rule-with-arrow'}>
                <div className={'rule'} />
                <div className={'arrow'} />
            </div>
            <div className={'scale'}>
                <div className={'is-caption is-second'}>{scale.min}</div>
                <div className={'is-caption is-second'}>
                    <FormattedMessage id={'common.performanceGraph.scale.units'} />
                </div>
                <div className={'is-caption is-second'}>{scale.max}</div>
            </div>
            <div className={'caption is-caption is-second'}>
                <FormattedMessage id={'common.performanceGraph.text'} />
            </div>
        </div>
    );
}
