import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
    SupplementalBox,
} from '../../../../../../components';
import {
    RiskLevel,
    Trait,
} from '../../../../../../util';

export function Type2DiabetesScreenOptions(props) {
    const {
        currentStep,
        riskLevel,
        totalSteps,
    } = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`screen-options-T2D-${riskLevel}`}
            title={intl.formatMessage({
                id: `reports.trait.actionPlan.actionCard.discussScreeningOptions.title.${riskLevel}`,
            })}
            totalSteps={totalSteps}
        >
            {riskLevel === RiskLevel.HIGHER && (
                <p>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.type2Diabetes.description.one'}
                    />
                </p>
            )}
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.type2Diabetes.description.two'}
                />
            </p>
            {riskLevel === RiskLevel.HIGHER && (
                <p>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.type2Diabetes.description.three'}
                    />
                </p>
            )}
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.type2Diabetes.description.four'}
                />
            </p>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.A1C.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.A1C.description'}
                />
            </p>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.fasting.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.fasting.description'}
                />
            </p>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.glucose.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.glucose.description'}
                />
            </p>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.random.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.random.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/diabetes/basics/getting-tested.html'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.diabetes.link'}
                />
            </LinkWithAttribution>
            {riskLevel === RiskLevel.HIGHER && (
                <SupplementalBox
                    title={intl.formatMessage({
                        id: 'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.heading',
                    })}
                >
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.noneRecommended'}
                            values={{trait: intl.formatMessage({id: `common.trait.${Trait.T2D.code}.text`})}}
                        />
                    </p>
                </SupplementalBox>
            )}
        </ActionCard>
    );
}
