import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {ActivateKitLink} from '../../components';

export function GiveSampleContent() {
    const intl = useIntl();

    return (
        <>
            <p>
                <FormattedMessage id={'how-it-works.use-test-kit.description'} />
            </p>
            <p>
                <ActivateKitLink
                    dataTestId={'activate-kit-link'}
                    location={'hiw-give-sample'}
                />
            </p>
            <img
                alt={intl.formatMessage({id: 'how-it-works.use-test-kit.image.one.alt'})}
                role={'presentation'}
                src={'/images/how-it-works-kit-w-instructions_1408x860_v1_67KB.webp'}
            />
        </>
    );
}
