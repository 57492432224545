import {Link} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Routes} from '../../Routes';

export function WhatHappensNext(props) {
    const {name, summary: {physicianReviewStatus} = {}} = props;

    const intl = useIntl();

    return (
        <>
            <div className={'left'}>
                <header>
                    <div
                        className={'is-caption name'}
                        data-test-id={'users-shortened-name'}
                    >
                        {name}
                    </div>
                    <h2
                        className={'page-title'}
                        data-test-id={'home-what-happens-next-title'}
                    >
                        <FormattedMessage
                            id={physicianReviewStatus === 'REORDER_PLACED'
                                ? 'home.whatHappensNext.reorder.title'
                                : 'home.whatHappensNext.title'}
                        />
                    </h2>
                </header>
                <p>
                    <FormattedMessage id={'home.whatHappensNext.description.one'} />
                </p>
                <p>
                    <FormattedMessage id={'home.whatHappensNext.description.two'} />
                </p>
                <p className={'no-margin-bottom'}>
                    <FormattedMessage
                        id={'home.whatHappensNext.description.three'}
                        values={{
                            link: (chunk) => (
                                <Link
                                    className={'alternate'}
                                    href={Routes.HELP_CENTER}
                                    rel={'noreferrer'}
                                    target={'_blank'}
                                >
                                    {chunk}
                                </Link>
                            ),
                        }}
                    />
                </p>
            </div>
            <div className={'right'}>
                <img
                    alt={intl.formatMessage({id: 'common.testKitBox.alt'})}
                    src={'/images/health-insights-test-kit-box_520x600.jpg'}
                />
            </div>
        </>
    );
}
