import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function GeneticRiskContent() {
    const intl = useIntl();

    return (
        <>
            <p>
                <FormattedMessage id={'science.calculateRisk.geneticRisk.contentOne'} />
            </p>
            <img
                alt={intl.formatMessage({id: 'science.calculateRisk.geneticRisk.img.alt'})}
                role={'presentation'}
                src={'/images/crossroads-people_1408x722_v1_108KB.webp'}
            />
            <p>
                <FormattedMessage id={'science.calculateRisk.geneticRisk.contentTwo'} />
            </p>
            <p>
                <FormattedMessage id={'science.calculateRisk.geneticRisk.contentThree'} />
            </p>
        </>
    );
}
