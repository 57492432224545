import {useEffect} from 'react';
import {Loading} from '../../../components';
import {
    useAmplitude,
    useUserContext,
} from '../../../context';
import {useLinearFlowContext} from '../../../layouts';
import {Routes} from '../../../Routes';

export function AccountSetup() {
    const amplitude = useAmplitude();
    const {isAuthenticated, signupToAuth0} = useUserContext();
    const {nextStep} = useLinearFlowContext();

    useEffect(() => {
        amplitude.logAccountVisit();

        if (isAuthenticated) {
            nextStep();
        }
        else {
            signupToAuth0(Routes.SIGN_UP_CONSENT);
        }
    }, [amplitude, isAuthenticated]);

    return <Loading key={'account-setup'} />;
}
