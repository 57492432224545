import {Link} from '@genomicsplc/denim-components';
import {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {
    Divider,
    UsefulLinks,
} from '../../components';
import {useUserContext} from '../../context';
import {Routes} from '../../Routes';

export function NotFound() {
    const {loginToAuth0} = useUserContext();

    const onClickHandler = useCallback(
        (event) => {
            event.preventDefault();
            loginToAuth0(Routes.HOME);
        },
        [loginToAuth0],
    );

    return (
        <div className={'grid fifty-fifty center-content not-found'}>
            <div className={'left'}>
                <h1 data-test-id={'not-found-title'}>
                    <FormattedMessage id={'notFound.title'} />
                </h1>
                <p>
                    <FormattedMessage
                        id={'notFound.description'}
                        values={{link: (chunks) => <Link onClick={onClickHandler}>{chunks}</Link>}}
                    />
                </p>

                <Divider />

                <UsefulLinks />
            </div>
            <img
                alt={''}
                className={'right'}
                role={'presentation'}
                src={'/images/square-and-circle.svg'}
            />
        </div>
    );
}
