import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useUserContext} from '../../../context';
import {
    useEligibility,
    useLazyQuery,
} from '../../../hooks';
import {Routes} from '../../../Routes';
import {
    Endpoint,
    Ethnicities,
} from '../../../util';

export function useSignUpFlow() {
    const {getEligibility} = useEligibility();
    const {user: {emailVerified} = {}} = useUserContext();
    const intl = useIntl();
    const [getConsent] = useLazyQuery(Endpoint.CONSENT);
    const [getProfile] = useLazyQuery(Endpoint.PROFILE);

    const hasEnteredInviteCode = useCallback(() => {
        const {inviteCode} = getEligibility() ?? {};
        return Boolean(inviteCode);
    }, [getEligibility]);

    const userHasConsented = useCallback(async () => {
        const {consent} = await getConsent();
        return Boolean(consent);
    }, [getConsent]);

    const userHasVerifiedEmail = useCallback(() => emailVerified, [emailVerified]);

    const userHasProfileWith = async (properties, values = []) => {
        const profile = await getProfile();
        let result = properties.every((property) => Boolean(profile[property]));

        if (result) {
            result = values.every((func) => func(profile));
        }

        return result;
    };

    const preferNotToSaySex = (profile) => {
        const {reportedSex = ''} = profile;
        return reportedSex === 'PREFER_NOT_TO_SAY';
    };

    const preferNotToSayEthnicity = (profile) => {
        const {ethnicities = []} = profile;
        return ethnicities.length === 1 && ethnicities[0] === Ethnicities.US_PNTS;
    };

    const multipleEthnicities = (profile) => {
        const {ethnicities = []} = profile;
        return ethnicities.length >= 2;
    };

    const ethnicityValid = (profile) => {
        const {ethnicityIdentifyMost, ethnicities = []} = profile;
        if (ethnicities.length === 1) {
            return true;
        }
        else if (ethnicities.length > 1 && Boolean(ethnicityIdentifyMost)) {
            return true;
        }

        return false;
    };

    const differentShippingAddress = (profile) => {
        const {
            firstName,
            homeAddress,
            lastName,
            shippingAddress,
        } = profile;

        // Has the user selected different address from the shipping details page...?
        const differentShippingAddressSelected = sessionStorage.getItem('differentShippingAddress') ?? false;
        if (differentShippingAddressSelected) {
            return true;
        }

        // Check to see if the shipping address has previously been set, which would mean some fields are non-null...
        const hasShippingAddress = Object.entries(shippingAddress ?? {}).some(([_, value]) => Boolean(value));
        if (hasShippingAddress) {
            const formattedHomeAddress = intl.formatMessage(
                {id: 'signUp.selectAddress.homeAddress.description'},
                {
                    firstName,
                    lastName,
                    ...homeAddress,
                },
            );

            const formattedShippingAddress = intl.formatMessage(
                {id: 'signUp.selectAddress.shippingAddress.description'},
                {...shippingAddress},
            );

            return formattedHomeAddress !== formattedShippingAddress;
        }

        // If the shipping address hasn't been filled out, it can't be the same...
        return true;
    };

    return {
        labelId: 'signUp.progress.label',
        steps: [
            {route: Routes.SIGN_UP_INVITE_CODE},
            {
                prerequisites: async function* checks() {
                    yield hasEnteredInviteCode();
                },
                route: Routes.SIGN_UP_CHECK_ELIGIBILITY,
            },
            {
                prerequisites: async function* checks() {
                    yield hasEnteredInviteCode();
                },
                route: Routes.SIGN_UP_THINGS_YOU_SHOULD_KNOW,
            },
            {
                prerequisites: async function* checks() {
                    yield hasEnteredInviteCode();
                },
                route: Routes.SIGN_UP_ACCOUNT_SETUP,
            },
            {route: Routes.SIGN_UP_CONSENT},
            {
                prerequisites: async function* checks() {
                    yield userHasConsented();
                },
                route: Routes.SIGN_UP_VERIFY_EMAIL,
            },
            {
                prerequisites: async function* checks() {
                    yield userHasConsented();
                    yield userHasVerifiedEmail();
                },
                route: Routes.SIGN_UP_ABOUT_YOU,
            },
            {
                prerequisites: async function* checks() {
                    yield userHasConsented();
                    yield userHasVerifiedEmail();
                },
                route: Routes.SIGN_UP_NAME,
            },
            {
                prerequisites: async function* checks() {
                    yield userHasConsented();
                    yield userHasVerifiedEmail();
                    yield userHasProfileWith(['firstName', 'lastName']);
                },
                route: Routes.SIGN_UP_DATE_OF_BIRTH,
            },
            {
                optional: [
                    {
                        prerequisites: async function* checks() {
                            yield userHasConsented();
                            yield userHasVerifiedEmail();
                            yield userHasProfileWith(
                                ['firstName', 'lastName', 'dateOfBirth', 'reportedSex'],
                                [preferNotToSaySex],
                            );
                        },
                        route: Routes.SIGN_UP_PNTS_SEX,
                    },
                ],
                prerequisites: async function* checks() {
                    yield userHasConsented();
                    yield userHasVerifiedEmail();
                    yield userHasProfileWith(['firstName', 'lastName', 'dateOfBirth']);
                },
                route: Routes.SIGN_UP_REPORTED_SEX,
            },
            {
                optional: [
                    {
                        prerequisites: async function* checks() {
                            yield userHasConsented();
                            yield userHasVerifiedEmail();
                            yield userHasProfileWith(
                                ['firstName', 'lastName', 'dateOfBirth', 'reportedSex', 'ethnicities'],
                                [preferNotToSayEthnicity],
                            );
                        },
                        route: Routes.SIGN_UP_PNTS_ETHNICITY,
                    },
                    {
                        prerequisites: async function* checks() {
                            yield userHasConsented();
                            yield userHasVerifiedEmail();
                            yield userHasProfileWith(
                                ['firstName', 'lastName', 'dateOfBirth', 'reportedSex', 'ethnicities'],
                                [multipleEthnicities],
                            );
                        },
                        route: Routes.SIGN_UP_IDENTIFIED_ETHNICITY,
                    },
                ],
                prerequisites: async function* checks() {
                    yield userHasConsented();
                    yield userHasVerifiedEmail();
                    yield userHasProfileWith(['firstName', 'lastName', 'dateOfBirth', 'reportedSex']);
                },
                route: Routes.SIGN_UP_ETHNICITY,
            },
            {
                prerequisites: async function* checks() {
                    yield userHasConsented();
                    yield userHasVerifiedEmail();
                    yield userHasProfileWith(
                        ['firstName', 'lastName', 'dateOfBirth', 'reportedSex', 'ethnicities'],
                        [ethnicityValid],
                    );
                },
                route: Routes.SIGN_UP_PHONE_NUMBER,
            },
            {
                prerequisites: async function* checks() {
                    yield userHasConsented();
                    yield userHasVerifiedEmail();
                    yield userHasProfileWith(
                        [
                            'firstName',
                            'lastName',
                            'dateOfBirth',
                            'reportedSex',
                            'ethnicities',
                            'phoneNumber',
                        ],
                        [ethnicityValid],
                    );
                },
                route: Routes.SIGN_UP_HOME_ADDRESS,
            },
            {
                optional: [
                    {
                        prerequisites: async function* checks() {
                            yield userHasConsented();
                            yield userHasVerifiedEmail();
                            yield userHasProfileWith(
                                [
                                    'firstName',
                                    'lastName',
                                    'dateOfBirth',
                                    'reportedSex',
                                    'ethnicities',
                                    'phoneNumber',
                                    'homeAddress',
                                ],
                                [ethnicityValid, differentShippingAddress],
                            );
                        },
                        route: Routes.SIGN_UP_SHIPPING_ADDRESS,
                    },
                ],
                prerequisites: async function* checks() {
                    yield userHasConsented();
                    yield userHasVerifiedEmail();
                    yield userHasProfileWith(
                        [
                            'firstName',
                            'lastName',
                            'dateOfBirth',
                            'reportedSex',
                            'ethnicities',
                            'phoneNumber',
                            'homeAddress',
                        ],
                        [ethnicityValid],
                    );
                },
                route: Routes.SIGN_UP_SHIPPING_DETAILS,
            },
            {
                prerequisites: async function* checks() {
                    yield userHasConsented();
                    yield userHasVerifiedEmail();
                    yield userHasProfileWith(
                        [
                            'firstName',
                            'lastName',
                            'dateOfBirth',
                            'reportedSex',
                            'ethnicities',
                            'phoneNumber',
                            'homeAddress',
                            'shippingAddress',
                        ],
                        [ethnicityValid],
                    );
                },
                route: Routes.SIGN_UP_SUMMARY,
            },
            {
                prerequisites: async function* checks() {
                    yield userHasConsented();
                    yield userHasVerifiedEmail();
                    yield userHasProfileWith(
                        [
                            'firstName',
                            'lastName',
                            'dateOfBirth',
                            'reportedSex',
                            'ethnicities',
                            'phoneNumber',
                            'homeAddress',
                            'shippingAddress',
                        ],
                        [ethnicityValid],
                    );
                },
                route: Routes.SIGN_UP_YOUR_REPORTS,
            },
        ],
    };
}
