import {FormattedMessage} from 'react-intl';
import {SignUpButton} from './SignUpButton';

export function SignUpMiddle() {
    return (
        <div className={'sign-up-middle'}>
            <h4>
                <FormattedMessage id={'landing.signUpPrompt.title'} />
            </h4>
            <SignUpButton
                dataTestId={'sign-up-first-prompt'}
                location={'first-prompt'}
            />
        </div>
    );
}
