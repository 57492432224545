import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActivateKitLink,
    MarketingEqualizer,
} from '../../components';
import {
    useFeatureFlags,
    useUserContext,
} from '../../context';
import {GotoAccountButton} from './GotoAccountButton';
import {HeroSignUpButton} from './HeroSignUpButton';

export function HeroContent() {
    const {isAuthenticated} = useUserContext();
    const {headerCarousel = false} = useFeatureFlags();
    const intl = useIntl();

    return (
        <>
            <div className={'hero-content variant-dark'}>
                <h1 data-test-id={'landing-page-main-title'}>
                    <FormattedMessage id={'landing.hero.title'} />
                </h1>
                <p>
                    <FormattedMessage id={'landing.hero.description'} />
                </p>
                {isAuthenticated ? <GotoAccountButton dataTestId={'hero-goto-account-button'} /> : <HeroSignUpButton />}
                <p className={'is-caption'}>
                    <ActivateKitLink
                        dataTestId={'hero-activate-link'}
                        location={'landing-hero'}
                    />
                </p>
            </div>
            {headerCarousel
                ? (
                    <MarketingEqualizer
                        config={[
                            {
                                img: 'url("/images/carousel-swimmer_1040x1040_v1_39KB.webp")',
                                overlay: {
                                    diseaseList: intl.formatMessage({id: 'landing.equalizer.swimmer.diseaseList'}),
                                    increasedCount: 2,
                                    name: intl.formatMessage({id: 'landing.equalizer.swimmer.name'}),
                                    typicalCount: 5,
                                },
                            },
                            {
                                img: 'url("/images/carousel-family_1040x1040_v1_75KB.webp")',
                                overlay: {
                                    diseaseList: intl.formatMessage({id: 'landing.equalizer.family.diseaseList'}),
                                    increasedCount: 1,
                                    name: intl.formatMessage({id: 'landing.equalizer.family.name'}),
                                    typicalCount: 6,
                                },
                            },
                            {
                                img: 'url("/images/carousel-cycling_1040x1040_v1_72KB.webp")',
                                overlay: {
                                    diseaseList: intl.formatMessage({id: 'landing.equalizer.cycling.diseaseList'}),
                                    increasedCount: 1,
                                    name: intl.formatMessage({id: 'landing.equalizer.cycling.name'}),
                                    typicalCount: 6,
                                },
                            },
                            {
                                img: 'url("/images/carousel-hiker_1040x1040_v1_29KB.webp")',
                                overlay: {
                                    diseaseList: intl.formatMessage({id: 'landing.equalizer.hiker.diseaseList'}),
                                    increasedCount: 2,
                                    name: intl.formatMessage({id: 'landing.equalizer.hiker.name'}),
                                    typicalCount: 5,
                                },
                            },
                        ]}
                    />
                )
                : (
                    <video
                        autoPlay={true}
                        className={'animated-image'}
                        loop={true}
                        muted={true}
                        role={'presentation'}
                    >
                        <source
                            src={'/images/animated-hero_25-07-2023_solid.webm'}
                            type={'video/webm'}
                        />
                        <source
                            src={'animated-hero_24fps_30-08-2023.mp4'}
                            type={'video/mp4'}
                        />
                        <img
                            alt={''}
                            src={'/images/animated-hero_24fps_30-08-2023.gif'}
                        />
                    </video>
                )}
        </>
    );
}
