import {
    Breakpoint,
    Navbar,
    useBreakpoints,
    Variant,
} from '@genomicsplc/denim-components';
import classNames from 'classnames';
import {
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Link,
    Outlet,
    useLocation,
} from 'react-router-dom';
import {GridContainer} from '../../components';
import {useUserContext} from '../../context';
import {useSanitisedPathname} from '../../hooks';
import {Routes} from '../../Routes';
import {LinearFlowNavbarDesktopContent} from './LinearFlowNavbarDesktopContent';
import {LinearFlowNavbarMobileContent} from './LinearFlowNavbarMobileContent';
import {LinearFlowProgressIndicator} from './LinearFlowProgressIndicator';
import {LinearFlowProvider} from './LinearFlowProvider';
import {Name} from './Name';

export function LinearFlowLayout(props) {
    const {
        className,
        dataTestId,
        flowConfig,
        logoutLink = false,
    } = props;

    const {isAuthenticated} = useUserContext();
    const currentBreakpoint = useBreakpoints();
    const intl = useIntl();
    const {pathname} = useLocation();
    const sanitisedPathname = useSanitisedPathname();
    const [containerClassName, setContainerClassName] = useState('');

    useEffect(() => {
        // Attempt to unfocus the continue button between pages.
        document.activeElement?.blur();
    }, [pathname]);

    const displayProgressIndicator = useMemo(
        () => [Breakpoint.TABLET_LANDSCAPE, Breakpoint.DESKTOP].includes(currentBreakpoint),
        [currentBreakpoint],
    );

    const containerClasses = classNames('linear-flow-container', containerClassName);
    const mainClasses = classNames(className, sanitisedPathname);

    return (
        <>
            <div className={containerClasses}>
                <LinearFlowProvider flowConfig={flowConfig}>
                    <Navbar
                        desktopContent={
                            <LinearFlowNavbarDesktopContent
                                farRightComponent={isAuthenticated ? <Name /> : null}
                            />
                        }
                        mobileContent={
                            <LinearFlowNavbarMobileContent
                                farRightComponent={isAuthenticated ? <Name /> : null}
                            />
                        }
                    />
                    <main
                        className={mainClasses}
                        data-test-id={dataTestId}
                    >
                        <div className={'grid linear-flow'}>
                            {displayProgressIndicator && <LinearFlowProgressIndicator />}
                            <div
                                className={'content'}
                                key={pathname}
                            >
                                <Outlet context={{setContainerClassName}} />
                            </div>
                        </div>
                    </main>
                </LinearFlowProvider>
            </div>
            {logoutLink && isAuthenticated && (
                <GridContainer
                    containerClassName={'linear-flow-logout-link'}
                    variant={Variant.DARK}
                >
                    <Link
                        className={'link logout-link alternate is-caption'}
                        data-test-id={'sign-up-logout-link'}
                        title={intl.formatMessage({id: 'common.logOut.title'})}
                        to={Routes.LOGOUT}
                    >
                        <span>
                            <FormattedMessage id={'common.logOut.text'} />
                        </span>
                    </Link>
                </GridContainer>
            )}
        </>
    );
}
