import {
    Card,
    Variant,
} from '@genomicsplc/denim-components';
import {FormattedMessage} from 'react-intl';

export function TalkToDoctorOption(props) {
    const {
        children,
        currentStep,
        title,
        totalSteps,
    } = props;

    return (
        <Card
            className={'talk-to-doctor-option'}
            variant={Variant.DARK}
        >
            <div>
                <div className={'step is-caption is-heavy'}>
                    <FormattedMessage
                        id={'common.optionOfTotal.text'}
                        values={{
                            currentStep,
                            totalSteps,
                        }}
                    />
                </div>
                <h4>{title}</h4>
            </div>
            {children}
        </Card>
    );
}
