import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
} from '../../../../../../components';

export function ProstateCancerRiskFactors(props) {
    const {currentStep, totalSteps} = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`risk-factors-PC`}
            title={intl.formatMessage({id: `reports.trait.actionPlan.actionCard.discussRiskFactors.title`})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.description.PC.one'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.description.PC.two'}
                />
            </p>
            <ul>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.smoking'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.diet'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.overweight'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.activity'}
                    />
                </li>
            </ul>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.ACS`})}
                url={'https://www.cancer.org/cancer/prostate-cancer/causes-risks-prevention/risk-factors.html'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.link'}
                    values={{trait: intl.formatMessage({id: `common.trait.PC.title`})}}
                />
            </LinkWithAttribution>
        </ActionCard>
    );
}
