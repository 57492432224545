import {useAuth0} from '@auth0/auth0-react';
import {StatusCodes} from 'http-status-codes';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import {useLocation} from 'react-router-dom';
import {
    apiFetch,
    getUserInfo,
    login as apiLogin,
    LoginResult,
    logout as apiLogout,
} from '../auth/apiAuthActions';
import {
    ErrorNotice,
    Loading,
} from '../components';
import {useHubSpot} from '../hooks';
import {ErrorLayout} from '../layouts';
import {devConsole} from '../util';
import {
    useAmplitude,
    useConfig,
} from './ConfigContext';

const UserContext = createContext({});

// Export Enum-like data objects for consumers
export const Auth0CallbackActions = Object.freeze({
    // These are not symbols because we want to compare them to strings
    Login: 'login',
    Register: 'signup',
});

export function useUserContext() {
    const context = useContext(UserContext);

    if (typeof context === 'undefined') {
        throw new Error(`Auth context must be used within a "AuthProvider"`);
    }

    return context;
}

// ::NOTE:: This is the only place in the app that should reference the useAuth0() hook
export function UserProvider({children}) {
    const amplitude = useAmplitude();
    const {
        loginWithRedirect,
        logout: logoutAuth0,
        getIdTokenClaims,
        isLoading,
        isAuthenticated,
    } = useAuth0();
    const {pathname} = useLocation();
    const {backend} = useConfig();
    const {clear} = useHubSpot();

    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState();
    const [error, setError] = useState(null);

    // Needed when we reload the page or make an API request and discover the backend session has expired, but we still
    // have an Auth0 session. Returns undefined in success or throws an exception in any other case.
    const refreshBackendSession = useCallback(
        async () => {
            // Does the user have an Auth0 session?
            if (!isAuthenticated) {
                devConsole('getOrLoginUser: User is not authenticated with Auth0');
                throw new Error('User is not authenticated with Auth0');
            }
            // Refresh our token in case it has expired
            devConsole('refreshBackendSession: Attempting to get Auth0 ID token claims');
            const claims = await getIdTokenClaims();
            const idToken = claims?.__raw;
            if (!idToken) {
                devConsole('refreshBackendSession: Unable to retrieve ID token for user');
                throw new Error('Unable to retrieve ID token for user');
            }

            devConsole('refreshBackendSession: Attempting backend API login');
            const loginResult = await apiLogin(idToken, backend);
            if (loginResult === LoginResult.Success) {
                return;
            }

            // Rare case we ever get here. This is either an Auth0 user without a backend account (in which case
            // they should be on the "re-enter code" page), or a user who was logged-in, but just deactivated.
            // At this point, we don't want to do anything, and allow the calling function to decide what to do.
            devConsole('refreshBackendSession: Backend API login was rejected');
            throw new Error('Backend API login was rejected');
        },
        [isAuthenticated, getIdTokenClaims, backend],
    );

    // Async function to do the login process.
    // Returns a UserAccount class if the user was logged in, null if not logged in, or a BackendLoginRejected class if we
    // have login credentials, but the backend rejects them.
    // Throws an error if something went wrong.
    const getOrLoginUser = useCallback(
        async () => {
            // Wait for Auth0 to be ready, otherwise we make some unnecessary backend requests when the user is logged out
            if (isLoading) {
                devConsole(
                    'getOrLoginUser: Auth0 is still loading. Waiting until we get a signal it is ready.',
                );
                return new Promise(() => {
                    // do nothing
                });
            }

            // Make the request for user details
            const userInfo = await getUserInfo(backend);
            if (userInfo !== null) {
                return userInfo;
            }

            devConsole('getOrLoginUser: Could not retrieve user account from the backend');
            // If users backend session has expired, or this is the first page load after logging into Auth0, we should check if
            // the user is logged into Auth0 and try to log in to the backend again
            if (!isAuthenticated) {
                devConsole('getOrLoginUser: User is not authenticated with Auth0');
                // return an unauthenticated user state, as they are not logged in
                return null;
            }
            // They are logged in to Auth0, so try to refresh their session by logging into the backend
            await refreshBackendSession();

            // Now the backend is logged in, we can try to get the user data again
            const newUserInfo = await getUserInfo(backend);
            if (newUserInfo === null) {
                // At this point a null result is an error, as we should have a user logged in
                devConsole(
                    'getOrLoginUser: User is not authenticated in the backend when they should be',
                );
                throw new Error(
                    'User is not authenticated in the backend when they should be',
                );
            }
            devConsole('getOrLoginUser: Got user info after backend login');
            return newUserInfo;
        },
        [isLoading, isAuthenticated, refreshBackendSession, backend],
    );

    /**
     * Main useEffect handler for this component. Wraps the getOrLogin user to set the React user object and loading state.
     * Also handles any other triggers for login (e.g. amplitude).
     */
    const getAccount = useCallback(async () => {
        devConsole('getAccount: Getting account');
        try {
            const account = await getOrLoginUser();
            if (account === null) {
                devConsole('getAccount: User not authenticated');

                // NOTE: If this is set to null, then object destructuring of the user object with defaults, no longer
                // works, as null is a valid value. I.e. {user: {emailAddress} = {}} doesn't work for a null user, but
                // does work if the user is undefined.
                // eslint-disable-next-line no-undefined
                setUser(undefined);
            }
            else {
                setUser(account);
                // Update amplitude with the user and customer details
                amplitude.setUserId(account.id);
                amplitude.setCustomerId(account.customerId);
            }
        }
        catch (err) {
            devConsole('getAccount: Error getting user account');
            setError(err);
        }
        finally {
            setLoaded(true);
        }
    }, [amplitude, getOrLoginUser]);

    /**
     * Rest of the application can use this to trigger a signup with Auth0 (e.g. via button click etc.)
     * @param {string} redirectPath - local react path to return to once signup has returned to this site and been
     * handled. Should include leading backslash - e.g. /home
     */
    const signupToAuth0 = (redirectPath) => {
        devConsole('Signing up to Auth0 with redirectPath: ', redirectPath);
        return loginWithRedirect({
            appState: {
                returnTo: redirectPath,
                action: Auth0CallbackActions.Register,
            },
            authorizationParams: {
                /* eslint-disable-next-line camelcase */
                screen_hint: 'signup',
            },
        });
    };

    /**
     * Rest of the application can use this to trigger a login with Auth0 (e.g. via button click etc.)
     * @param {string} redirectPath - local react path to return to once login has returned to this site and been
     * handled. Should include leading backslash - e.g. /home
     */
    const loginToAuth0 = (redirectPath) => {
        devConsole('Logging in to Auth0 with redirectPath: ', redirectPath);
        return loginWithRedirect({
            appState: {
                returnTo: redirectPath,
                action: Auth0CallbackActions.Login,
            },
            authorizationParams: {
                /* eslint-disable-next-line camelcase */
                screen_hint: 'login',
            },
        });
    };

    /**
     * Logout out of the application (both Auth0 and backend)
     * @param {string} returnPath - local react path to return to once logout has completed. Should include leading
     * backslash - e.g. /home. Optional value which defaults to the site root if not specified.
     */
    const logout = useCallback(
        async () => {
            devConsole('Logging out of backend');
            await apiLogout(backend);
            // Clear hubspot cookies in case the device is used by multiple people
            clear();
            devConsole('Logging out of Auth0');
            await logoutAuth0({
                logoutParams: {
                    returnTo: window.location.origin,
                },
            });
        },
        [backend, logoutAuth0],
    );

    /**
     * Wrapper for all fetch calls to the backend API. If the request returns a 401, the backend sessions may have timed out, so
     * attempts to silently re-authenticate, and if it can't, then redirects to the login.
     * triggers a logout if the
     * response is unauthorized. This may happen if sessions are invalidated on the backend.
     * @param {string} resource - API url to call. e.g. /v1/me/account
     * @param {string} options - Standard fetch options object. Is merged with default options for content type and
     * CSRF token.
     */
    const authenticatedFetch = useCallback(
        async (resource, options) => {
            const response = await apiFetch(backend, resource, options);

            if (response.status !== StatusCodes.UNAUTHORIZED) {
                // Either a success or some other error we don't handle here, so return it for caller to deal with
                return response;
            }

            // We must be handling an unauthorized response, so try to refresh their session by logging into the backend
            devConsole('authenticatedFetch:  Initial request returned 401, so trying to refresh backend session');
            try {
                await refreshBackendSession();
            }
            catch (e) {
                // The backend refresh failed, probably due to invalid auth0 credentials, so they need to login again.
                await loginToAuth0(pathname);
                return null;
            }

            // Try the original call again, now we should have a new session
            devConsole('authenticatedFetch: Attempting original request again.');
            const newResponse = await apiFetch(backend, resource, options);

            if (newResponse.status === StatusCodes.UNAUTHORIZED) {
                // User has Auth0 creds, but they are rejected by backend. We should only be here in very rare cases.
                // e.g. users account was deactivated while they were using the site.
                // In this case we can't do anything apart from asking them to login again. In future we could log them
                // out of Auth0 and show a message page to let them know their account credentials are no longer valid.
                await loginToAuth0(pathname);
            }
            return newResponse;
        },
        [loginToAuth0, pathname, refreshBackendSession],
    );

    useEffect(() => {
        // Get the account when the component is first rendered and when the function ref changes.
        getAccount();
    }, [getAccount]);

    if (loaded) {
        const isAuthenticated = Boolean(user);

        return (
            <UserContext.Provider
                value={{
                    authenticatedFetch,
                    isAuthenticated,
                    loginToAuth0,
                    logout,
                    signupToAuth0,
                    user,
                }}
            >
                {children}
            </UserContext.Provider>
        );
    }

    if (error) {
        return (
            <ErrorLayout mainClassName={'error-container'}>
                <ErrorNotice />
            </ErrorLayout>
        );
    }

    return (
        <div className={'flex-column full-height'}>
            <Loading key={'loading'} />
        </div>
    );
}
