import {
    Link,
    Variant,
} from '@genomicsplc/denim-components';
import classNames from 'classnames';
import {useCallback} from 'react';
import {
    useAmplitude,
    useConfig,
} from '../../context';
import {Endpoint} from '../../util';

export function ReportDownloadButton(props) {
    const {
        children,
        className,
        dataTestId,
    } = props;

    const amplitude = useAmplitude();
    const {backend} = useConfig();

    const onClickHandler = useCallback(() => {
        amplitude.logResultsDownloaded();
    }, [amplitude]);

    const buttonClasses = classNames('icon after document--download button', className);

    return (
        <Link
            className={buttonClasses}
            dataTestId={dataTestId}
            onClick={onClickHandler}
            rel={'noreferrer'}
            target={'_blank'}
            url={`${backend}/${Endpoint.REPORT}`}
            variant={Variant.SECONDARY}
        >
            {children}
        </Link>
    );
}
