import {FormattedMessage} from 'react-intl';
import {ExampleBellCurve} from '../../components';

export function YourRiskContent() {
    return (
        <>
            <p>
                <FormattedMessage id={'science.calculateRisk.yourRisk.content'} />
            </p>
            <ExampleBellCurve />
        </>
    );
}
