import {
    Button,
    Callout,
    MoreInfo,
} from '@genomicsplc/denim-components';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {ContactSupport} from '../../../components';
import {useUserContext} from '../../../context';
import {
    useMutation,
    useTitle,
} from '../../../hooks';
import {useLinearFlowContext} from '../../../layouts';
import {Endpoint} from '../../../util';

const DISPLAY_RESEND_DELAY = 3000;
const DISPLAY_RESEND_ERROR = 'RESEND_ERROR';
const DISPLAY_RESEND_SUCCESS = 'RESEND_SUCCESS';

export function VerifyEmail() {
    const {user: {emailAddress, emailVerified} = {}} = useUserContext();
    const intl = useIntl();
    const {nextStep} = useLinearFlowContext();
    const [sendVerificationEmail, {loading, error}] = useMutation(Endpoint.SEND_VERIFICATION_EMAIL);
    const [displayResendStatus, setDisplayResendStatus] = useState(false);
    useTitle(intl.formatMessage({id: 'signUp.verifyEmail.documentTitle'}));

    useEffect(() => {
        if (emailVerified) {
            // NOTE: React throws a warning about calling navigate() in a useEffect, but not when the component is first
            // rendered. So we have to do this stupid hack to chuck the navigation to the back of the event queue...
            setTimeout(() => nextStep(), 0);
        }
    }, [emailVerified]);

    useEffect(() => {
        if (error) {
            setDisplayResendStatus(DISPLAY_RESEND_ERROR);
        }
    }, [error]);

    const onClickHandler = useCallback(async () => {
        // Clear any messages that are being displayed, i.e. a previous error
        setDisplayResendStatus(null);

        // Attempt to resend the verification email
        await sendVerificationEmail();

        // Assume it's worked and try and display a success box
        setDisplayResendStatus(DISPLAY_RESEND_SUCCESS);
        setTimeout(() => {
            setDisplayResendStatus(null);
        }, DISPLAY_RESEND_DELAY);
    }, []);

    return (
        <>
            <h2 data-test-id={'sign-up-verify-email-title'}>
                <FormattedMessage id={'signUp.verifyEmail.title'} />
            </h2>
            <p>
                <FormattedMessage
                    id={'signUp.verifyEmail.paragraph.one'}
                    values={{email: () => <span className={'is-heavy'}>{emailAddress}</span>}}
                />
            </p>
            <p>
                <FormattedMessage id={'signUp.verifyEmail.paragraph.two'} />
            </p>
            <MoreInfo
                content={
                    <>
                        <ul className={'no-margin-top less-margin-bottom'}>
                            <li>
                                <FormattedMessage id={'signUp.verifyEmail.moreInfo.point.one'} />
                            </li>
                            <li>
                                <FormattedMessage id={'signUp.verifyEmail.moreInfo.point.two'} />
                            </li>
                        </ul>
                        <Button
                            dataTestId={'resend-email-button'}
                            isLoading={loading}
                            onClick={onClickHandler}
                            title={intl.formatMessage({id: 'signUp.verifyEmail.moreInfo.button.title'})}
                        >
                            <FormattedMessage id={'signUp.verifyEmail.moreInfo.button.text'} />
                        </Button>
                        {displayResendStatus && (
                            <Callout
                                className={displayResendStatus === DISPLAY_RESEND_ERROR
                                    ? 'variant-danger'
                                    : null}
                                dataTestId={'resend-email-status'}
                                icon={displayResendStatus === DISPLAY_RESEND_ERROR ? 'error' : null}
                            >
                                <FormattedMessage
                                    id={`signUp.verifyEmail.status.${displayResendStatus}`}
                                    values={{cs: (chunk) => <ContactSupport text={chunk} />}}
                                />
                            </Callout>
                        )}
                        <p className={'is-heavy half-margin'}>
                            <FormattedMessage id={'signUp.verifyEmail.moreInfo.wrongEmail'} />
                        </p>
                        <p className={'half-margin'}>
                            <FormattedMessage
                                id={'signUp.verifyEmail.moreInfo.contactSupport'}
                                values={{cs: (chunk) => <ContactSupport text={chunk} />}}
                            />
                        </p>
                    </>
                }
                title={intl.formatMessage({id: 'signUp.verifyEmail.moreInfo.title'})}
            />
        </>
    );
}
