import {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {useAmplitude} from '../../../context';
import {ReportsHeader} from '../../results/components';
import {AnalyseDna} from './AnalyseDna';
import {YourReports} from './YourReports';

export function ResultsOverview(props) {
    const {name} = props;

    const amplitude = useAmplitude();
    const {setMainClassName} = useOutletContext();

    useEffect(() => {
        amplitude.logResultsHomeVisited();
        setMainClassName('results');

        return () => {
            setMainClassName(null);
        };
    }, [amplitude]);

    return (
        <>
            <div className={'grid'}>
                <ReportsHeader divider={true}>
                    <h1
                        className={'no-margin'}
                        data-test-id={'reports-overview-title'}
                    >
                        <FormattedMessage
                            id={'reports.overview.title'}
                            values={{name: () => <span data-test-id={'users-shortened-name'}>{name}</span>}}
                        />
                    </h1>
                </ReportsHeader>
                <YourReports />
            </div>
            <AnalyseDna />
        </>
    );
}
