import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCardImage,
    GraphicalActionCard,
    LinkWithAttribution,
} from '../../../../../components';

export function Smoke(props) {
    const {
        currentStep,
        totalSteps,
        traitCode,
    } = props;

    const intl = useIntl();

    return (
        <GraphicalActionCard
            currentStep={currentStep}
            dataTestId={`smoke-${traitCode}`}
            headerImage={ActionCardImage.SMOKE}
            title={intl.formatMessage({id: 'reports.trait.lifestyleActions.smoke.title'})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={`reports.trait.lifestyleActions.smoke.description.one.${traitCode}`}
                />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.smoke.description.two'} />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'reports.trait.lifestyleActions.smoke.link.attribution'})}
                url={'https://smokefree.gov/'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.smoke.link'} />
            </LinkWithAttribution>
        </GraphicalActionCard>
    );
}
