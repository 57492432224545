import {
    Link,
    MoreInfo,
} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {PerformanceGraph} from '../../components';

export function OurPerformanceContent() {
    const intl = useIntl();

    return (
        <>
            <p>
                <FormattedMessage id={'science.ourPerformance.description'} />
            </p>
            <PerformanceGraph
                performanceData={[
                    {
                        name: intl.formatMessage({id: 'common.trait.AF.title'}),
                        performance: {
                            them: 0.62,
                            us: 0.65,
                        },
                    },
                    {
                        name: intl.formatMessage({id: 'common.trait.T2D.title'}),
                        performance: {
                            them: 0.65,
                            us: 0.71,
                        },
                    },
                ]}
                scale={{
                    max: 0.75,
                    min: 0.5,
                }}
                versus={intl.formatMessage({id: 'common.performanceGraph.23andMe'})}
            />
            <PerformanceGraph
                performanceData={[
                    {
                        name: intl.formatMessage({id: 'common.trait.AF.title'}),
                        performance: {
                            them: 0.57,
                            us: 0.65,
                        },
                    },
                    {
                        name: intl.formatMessage({id: 'common.trait.BC.title'}),
                        performance: {
                            them: 0.63,
                            us: 0.67,
                        },
                    },
                ]}
                scale={{
                    max: 0.75,
                    min: 0.5,
                }}
                versus={intl.formatMessage({id: 'common.performanceGraph.colorHealth'})}
            />
            <MoreInfo
                content={
                    <>
                        <p className={'half-margin'}>
                            <FormattedMessage id={'common.chartData.moreInfo.content'} />
                        </p>
                        <p className={'half-margin'}>
                            <FormattedMessage
                                id={'common.chartData.moreInfo.reference.one'}
                                values={{
                                    link: (chunks) => (
                                        <Link
                                            className={'alternate'}
                                            rel={'noreferrer'}
                                            target={'_blank'}
                                            url={'/documents/genomicsplc-HI-validation-paper-2023.pdf'}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                        <p className={'half-margin'}>
                            <FormattedMessage
                                id={'common.chartData.moreInfo.reference.two'}
                                values={{
                                    link1: (chunks) => (
                                        <Link
                                            className={'alternate'}
                                            rel={'noreferrer'}
                                            target={'_blank'}
                                            url={'https://permalinks.23andme.com/pdf/23_19-Type2Diabetes_March2019.pdf'}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                    link2: (chunks) => (
                                        <Link
                                            className={'alternate'}
                                            rel={'noreferrer'}
                                            target={'_blank'}
                                            url={'https://permalinks.23andme.com/pdf/23_21-PRSMethodologyAppendix_May2020.pdf'}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                        <p className={'half-margin'}>
                            <FormattedMessage
                                id={'common.chartData.moreInfo.reference.three'}
                                values={{
                                    link: (chunks) => (
                                        <Link
                                            className={'alternate'}
                                            rel={'noreferrer'}
                                            target={'_blank'}
                                            url={'https://www.color.com/wp-content/uploads/2020/04/2019_Homburger_et_al_Genome_Medicine.pdf'}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                    </>
                }
                dataTestId={'more-info-prs-performance'}
                title={intl.formatMessage({id: 'common.chartData.moreInfo.title'})}
            />
        </>
    );
}
