import {datadogRum} from '@datadog/browser-rum';
import {
    useEffect,
    useLayoutEffect,
} from 'react';
import {
    Route,
    Routes,
    useLocation,
} from 'react-router-dom';
import {useConfig} from './context';
import {
    BaseLegalLayout,
    MinimalLayout,
    PrimaryLayout,
} from './layouts';
import {
    AboutUs,
    AccessCodeReEntry,
    AccountController,
    Activate,
    Home,
    HowItWorks,
    KitActivationController,
    Landing,
    Logout,
    NotFound,
    Overview,
    PrivacyPolicy,
    ProtectedRoute,
    ResultsController,
    Science,
    SignUpController,
    SteadyMDPolicyPage,
    SystemError,
    TermsConditions,
    TestReorderController,
    TrustAndPrivacy,
} from './pages';
import {Routes as InternalRoutes} from './Routes';

export function App() {
    const {
        datadog,
        datadogApplicationId,
        datadogClientToken,
        datadogEnv,
        datadogService,
        datadogSite,
    } = useConfig();
    const location = useLocation();

    useEffect(() => {
        if (datadog) {
            datadogRum.init({
                allowedTracingUrls: [
                    (url) => url.startsWith(`${window.location.protocol}//${window.location.host}`),
                ],
                applicationId: datadogApplicationId,
                clientToken: datadogClientToken,
                defaultPrivacyLevel: 'mask',
                env: datadogEnv,
                service: datadogService,
                sessionSampleRate: 100,
                site: datadogSite,
                trackLongTasks: true,
                trackResources: true,
                trackUserInteractions: true,
                // Specify a version number to identify the deployed version of your application in Datadog
                // version: config.version,
            });
            datadogRum.startSessionReplayRecording();
        }
    }, [location]);

    useLayoutEffect(() => {
        // When someone comes in via a link, they probably want to start at the top of the page, not halfway down...
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route
                element={<BaseLegalLayout />}
                path={InternalRoutes.LANDING}
            >
                <Route element={<PrimaryLayout />}>
                    <Route
                        element={<Landing />}
                        index={true}
                    />
                    <Route
                        element={<Science />}
                        path={InternalRoutes.OUR_SCIENCE}
                    />
                    <Route
                        element={<TrustAndPrivacy />}
                        path={InternalRoutes.TRUST_AND_PRIVACY}
                    />
                    <Route
                        element={<HowItWorks />}
                        path={InternalRoutes.SEE_HOW_IT_WORKS}
                    />
                    <Route
                        element={<AboutUs />}
                        path={InternalRoutes.ABOUT_US}
                    />
                    <Route
                        element={
                            <ProtectedRoute
                                authenticated={true}
                                component={Home}
                                registered={true}
                            />
                        }
                        path={InternalRoutes.HOME}
                    />
                    <Route
                        element={
                            <ProtectedRoute
                                authenticated={true}
                                component={AccountController}
                                registered={true}
                            />
                        }
                        path={`${InternalRoutes.ACCOUNT}/*`}
                    />
                    <Route
                        element={<PrivacyPolicy />}
                        path={InternalRoutes.PRIVACY_POLICY}
                    />
                    <Route
                        element={<SteadyMDPolicyPage />}
                        path={InternalRoutes.STEADYMD_DATA_POLICY}
                    />
                    <Route
                        element={<TermsConditions />}
                        path={InternalRoutes.TERMS_AND_CONDITIONS}
                    />
                    <Route
                        element={<Overview />}
                        path={InternalRoutes.OVERVIEW}
                    />
                    <Route
                        element={
                            <ProtectedRoute
                                authenticated={true}
                                component={ResultsController}
                                report={true}
                            />
                        }
                        path={`${InternalRoutes.RESULTS}/*`}
                    />
                </Route>
                <Route
                    element={<SignUpController />}
                    path={`${InternalRoutes.SIGN_UP}/*`}
                />
                <Route
                    element={<TestReorderController />}
                    path={`${InternalRoutes.TEST_REORDER}/*`}
                />
                <Route
                    element={
                        <ProtectedRoute
                            authenticated={true}
                            component={KitActivationController}
                            registered={true}
                        />
                    }
                    path={`${InternalRoutes.KIT_ACTIVATION}/*`}
                />
                <Route element={<MinimalLayout />}>
                    <Route
                        element={<AccessCodeReEntry />}
                        path={InternalRoutes.RE_ENTER_ACCESS_CODE}
                    />
                    <Route
                        element={<Activate />}
                        path={InternalRoutes.ACTIVATE}
                    />
                    <Route
                        element={<SystemError />}
                        path={InternalRoutes.CONTACT_SUPPORT}
                    />
                    <Route
                        element={<NotFound />}
                        path={'*'}
                    />
                </Route>
            </Route>
            <Route
                element={<Logout />}
                path={InternalRoutes.LOGOUT}
            />
        </Routes>
    );
}
