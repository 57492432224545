import {
    Button,
    Variant,
} from '@genomicsplc/denim-components';
import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {useAmplitude} from '../../../../context';
import {Routes} from '../../../../Routes';

export function SignUpButton(props) {
    const {location} = props;

    const amplitude = useAmplitude();
    const intl = useIntl();
    const navigate = useNavigate();

    const onSignUpClickHandler = useCallback(() => {
        amplitude.logSignUpClicked(location ?? 'mobile-navbar');
        navigate(Routes.SIGN_UP);
    }, [amplitude, location, navigate]);

    return (
        <Button
            dataTestId={'navigation-sign-up'}
            onClick={onSignUpClickHandler}
            title={intl.formatMessage({id: 'navbar.signupButton.title'})}
            variant={Variant.PRIMARY}
        >
            <FormattedMessage id={'navbar.signupButton.text'} />
        </Button>
    );
}
