import classNames from 'classnames';
import {
    useCallback,
    useMemo,
} from 'react';
import {
    Outlet,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {BackLink} from '../../../components';
import {Routes} from '../../../Routes';

export function AccountMobileLayout() {
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const displayBackLink = useMemo(() => pathname !== Routes.ACCOUNT, [pathname]);

    const onClickHandler = useCallback(() => {
        navigate(Routes.ACCOUNT);
    }, [navigate]);

    const containerClasses = classNames('account-layout', 'mobile', 'grid', {'no-padding': displayBackLink});

    return (
        <div className={containerClasses}>
            <div className={'content'}>
                {displayBackLink && <BackLink onClick={onClickHandler} />}
                <Outlet />
            </div>
        </div>
    );
}
