import {
    Card,
    MoreInfo,
    Variant,
} from '@genomicsplc/denim-components';
import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {
    DoesNotTellYou,
    ExampleBellCurve,
    ExplanatoryStep,
} from '../../../components';
import {useAmplitude} from '../../../context';
import {HowWeCalculateRiskTitle} from './HowWeCalculateRiskTitle';
import {KnowMore} from './KnowMore';

export function HowWeCalculateRisk() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {setMainClassName} = useOutletContext();

    useEffect(() => {
        amplitude.logResultsHowVisited();
        setMainClassName('wavy-line-background');

        return () => {
            setMainClassName(null);
        };
    }, [amplitude]);

    return (
        <>
            <HowWeCalculateRiskTitle />
            <ExplanatoryStep
                stepNumber={1}
                stepTitle={intl.formatMessage({id: 'reports.how-we-calculate-risk.extract-dna.title'})}
            >
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.extract-dna.description.one'}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.extract-dna.description.two'}
                    />
                </p>
                <img
                    alt={intl.formatMessage({id: 'reports.how-we-calculate-risk.extract-dna.image.alt'})}
                    role={'presentation'}
                    src={'/images/hwcr-lab-image.jpg'}
                />
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.extract-dna.description.three'}
                    />
                </p>
                <MoreInfo
                    content={
                        <p>
                            <FormattedMessage
                                id={'reports.how-we-calculate-risk.extract-dna.more-info.answer'}
                            />
                        </p>
                    }
                    dataTestId={'more-info-genetic-variant'}
                    title={intl.formatMessage({id: 'reports.how-we-calculate-risk.extract-dna.more-info.question'})}
                />
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.extract-dna.description.four'}
                    />
                </p>
            </ExplanatoryStep>
            <ExplanatoryStep
                stepNumber={2}
                stepTitle={intl.formatMessage({id: 'reports.how-we-calculate-risk.calculate-risk.title'})}
            >
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.calculate-risk.description.one'}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.calculate-risk.description.two'}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.calculate-risk.description.three'}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.calculate-risk.description.four'}
                    />
                </p>
                <Card
                    className={'supplemental'}
                    variant={Variant.DARK}
                >
                    <h4 className={'no-margin'}>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.calculate-risk.supplemental.title'}
                        />
                    </h4>
                    <p>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.calculate-risk.supplemental.description.one'}
                            values={{italic: (chunks) => <em>{chunks}</em>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.calculate-risk.supplemental.description.two'}
                        />
                    </p>
                </Card>
            </ExplanatoryStep>
            <ExplanatoryStep
                stepNumber={3}
                stepTitle={intl.formatMessage({id: 'reports.how-we-calculate-risk.combine-genetic-risk.title'})}
            >
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.combine-genetic-risk.description.one'}
                    />
                </p>
                <ul>
                    <li>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.combine-genetic-risk.item.one'}
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.combine-genetic-risk.item.two'}
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.combine-genetic-risk.item.three'}
                        />
                    </li>
                </ul>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.combine-genetic-risk.description.two'}
                    />
                </p>
                <ul>
                    <li>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.combine-genetic-risk.item.four'}
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.combine-genetic-risk.item.five'}
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id={'reports.how-we-calculate-risk.combine-genetic-risk.item.six'}
                        />
                    </li>
                </ul>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.combine-genetic-risk.description.three'}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.combine-genetic-risk.description.four'}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.combine-genetic-risk.description.five'}
                    />
                </p>
                <MoreInfo
                    content={
                        <>
                            <p>
                                <FormattedMessage
                                    id={'reports.how-we-calculate-risk.combine-genetic-risk.more-info.answer.one'}
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id={'reports.how-we-calculate-risk.combine-genetic-risk.more-info.answer.two'}
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id={'reports.how-we-calculate-risk.combine-genetic-risk.more-info.answer.three'}
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id={'reports.how-we-calculate-risk.combine-genetic-risk.more-info.answer.four'}
                                />
                            </p>
                        </>
                    }
                    dataTestId={'more-info-age-sex-race'}
                    title={intl.formatMessage({
                        id: 'reports.how-we-calculate-risk.combine-genetic-risk.more-info.question',
                    })}
                />
            </ExplanatoryStep>
            <ExplanatoryStep
                className={'extra-bottom-padding'}
                stepNumber={4}
                stepTitle={intl.formatMessage({id: 'reports.how-we-calculate-risk.compare-risk.title'})}
            >
                <ExampleBellCurve />
                <h4>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.compare-risk.yourRisk.subtitle'}
                    />
                </h4>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.compare-risk.yourRisk.description'}
                    />
                </p>
                <h4>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.compare-risk.typicalOrIncreasedRisk.subtitle'}
                    />
                </h4>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.compare-risk.typicalOrIncreasedRisk.description.one'}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.compare-risk.typicalOrIncreasedRisk.description.two'}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.compare-risk.typicalOrIncreasedRisk.description.three'}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id={'reports.how-we-calculate-risk.compare-risk.typicalOrIncreasedRisk.description.four'}
                    />
                </p>
            </ExplanatoryStep>
            <DoesNotTellYou />
            <KnowMore />
        </>
    );
}
