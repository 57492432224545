import {useLocation} from 'react-router-dom';
import {useUserContext} from '../../context';

export function RequireAuthentication(props) {
    const {children} = props;
    const {pathname} = useLocation();

    const {isAuthenticated, loginToAuth0} = useUserContext();

    if (!isAuthenticated) {
        loginToAuth0(pathname);
        return null;
    }

    return children;
}
