import {useEffect} from 'react';
import {
    useAmplitude,
    useUserContext,
} from '../../context';
import {ActivateWhileAuthenticated} from './ActivateWhileAuthenticated';
import {ActivateWhileUnauthenticated} from './ActivateWhileUnauthenticated';

export function Activate() {
    const amplitude = useAmplitude();
    const {isAuthenticated} = useUserContext();

    useEffect(() => {
        amplitude.logActivationVisit();
    }, [amplitude]);

    const ActivateComponent = isAuthenticated
        ? ActivateWhileAuthenticated
        : ActivateWhileUnauthenticated;

    return <ActivateComponent />;
}
