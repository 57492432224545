import {
    Button,
    Variant,
} from '@genomicsplc/denim-components';
import classNames from 'classnames';
import {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {Logotype} from '../../../components';
import {
    useAmplitude,
    useUserContext,
} from '../../../context';
import {Routes} from '../../../Routes';
import {
    AccountButton,
    NavbarMarketingLinks,
} from '../common';

export function DesktopNavbarContent(props) {
    const {
        className,
        dataTestId,
        displaySignUp = false,
        homeLink = false,
        marketingRoutes = [],
    } = props;

    const amplitude = useAmplitude();
    const {isAuthenticated, loginToAuth0} = useUserContext();
    const intl = useIntl();
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const displayPoweredByLogotype = useMemo(() => marketingRoutes.includes(pathname), [pathname]);

    const displaySignUpButton = useMemo(() => {
        if (displaySignUp) {
            return true;
        }

        if (isAuthenticated) {
            return false;
        }

        return pathname !== Routes.LANDING;
    }, [displaySignUp, isAuthenticated, pathname]);

    const onLoginClickHandler = useCallback(() => {
        loginToAuth0(Routes.HOME);
    }, [loginToAuth0]);

    const onSignUpClickHandler = useCallback(() => {
        amplitude.logSignUpClicked('navbar');
        navigate(Routes.SIGN_UP);
    }, [amplitude, navigate]);

    const menuClasses = classNames('main-menu', 'inline', 'no-decoration', 'no-margin', className, {
        'sign-up': displaySignUpButton,
    });

    return (
        <>
            <Logotype
                dataTestId={'health-insights-logotype-navigation'}
                homeLink={homeLink}
                poweredByLogotype={displayPoweredByLogotype}
            />
            <ul
                className={menuClasses}
                data-test-id={dataTestId}
            >
                <NavbarMarketingLinks compact={true} />
                <li>
                    {isAuthenticated ? <AccountButton /> : (
                        <Button
                            dataTestId={'navigation-login'}
                            onClick={onLoginClickHandler}
                            title={intl.formatMessage({id: 'navbar.loginButton.title'})}
                        >
                            <FormattedMessage id={'navbar.loginButton.text'} />
                        </Button>
                    )}
                </li>
                {displaySignUpButton && (
                    <li className={'half-margin'}>
                        <Button
                            dataTestId={'navigation-sign-up'}
                            onClick={onSignUpClickHandler}
                            title={intl.formatMessage({id: 'navbar.signupButton.title'})}
                            variant={Variant.PRIMARY}
                        >
                            <FormattedMessage id={'navbar.signupButton.text'} />
                        </Button>
                    </li>
                )}
            </ul>
        </>
    );
}
