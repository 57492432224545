import {PageContent} from './PageContent';

export function ActivateWhileUnauthenticated() {
    return (
        <PageContent
            buttonDataTestId={'login-button'}
            buttonKey={'kit.activate.loggedOut.button.text'}
            buttonTitleKey={'kit.activate.loggedOut.button.title'}
            titleDataTestId={'activate-while-unauthenticated'}
            titleKey={'kit.activate.loggedOut.title'}
        />
    );
}
