import {
    Button,
    ErrorSummary,
    FormGroup,
    Link,
    TextInput,
    Variant,
} from '@genomicsplc/denim-components';
import {StatusCodes} from 'http-status-codes';
import {
    useCallback,
    useRef,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {ContactSupport} from '../../../components';
import {useAmplitude} from '../../../context';
import {
    useEligibility,
    useLazyQuery,
    useTitle,
} from '../../../hooks';
import {useLinearFlowContext} from '../../../layouts';
import {Routes} from '../../../Routes';
import {
    devConsole,
    Endpoint,
} from '../../../util';

// This is used to try and tease apart whatever format they've chosen to enter their code with, we can try and
// reconstruct it into the format they were sent and the backend accepts...
const ACCESS_CODE_PATTERN = '\\s*[A-Za-z0-9]{4}\\s*-?\\s*[A-Za-z0-9]{4}\\s*';
const ACCESS_CODE_REGEX = /^(?<first>[A-Za-z0-9]{4})\s*-?\s*(?<second>[A-Za-z0-9]{4})$/im;

export function InviteCode() {
    const {getEligibility, setEligibility} = useEligibility();
    const amplitude = useAmplitude();
    const intl = useIntl();
    const [validateInviteCode, {error, loading}] = useLazyQuery(Endpoint.INVITATIONS_AVAILABLE);
    const {nextStep} = useLinearFlowContext();
    const navigate = useNavigate();
    const inviteCodeRef = useRef();
    useTitle(intl.formatMessage({id: `signUp.inviteCode.documentTitle`}));

    const onLoginClickHandler = useCallback((event) => {
        event.preventDefault();
        navigate(Routes.HOME);
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            const formValid = event.target.checkValidity();
            if (formValid) {
                // Get the value from the text input and strip any spaces...
                const accessCode = inviteCodeRef.current.value;
                const accessCodeWithNoSpaces = accessCode.replaceAll(/\s/gi, '');

                const result = accessCodeWithNoSpaces.match(ACCESS_CODE_REGEX);
                if (result) {
                    const {groups: {first, second} = {}} = result ?? {};
                    const correctlyFormattedAccessCode = `${first.toLocaleUpperCase()}-${second.toLocaleUpperCase()}`;

                    try {
                        // Validate the invite code and store it...
                        const response = await validateInviteCode({
                            endpoint: `${Endpoint.INVITATIONS_AVAILABLE}/${correctlyFormattedAccessCode}`,
                        });

                        setEligibility({
                            eligibleRegions: response.eligibleRegions,
                            inviteCode: correctlyFormattedAccessCode,
                        });
                        amplitude.logValidAccessCodeEntered();

                        // Continue to the next step...
                        nextStep();
                    }
                    catch (err) {
                        amplitude.logInvalidAccessCodeEntered(accessCode);
                        devConsole(err);
                    }
                }
                else {
                    amplitude.logInvalidAccessCodeEntered(accessCode);
                }
            }
        },
        [amplitude, nextStep, setEligibility],
    );

    return (
        <form
            noValidate={true}
            onSubmit={onSubmitHandler}
        >
            <h2 data-test-id={'sign-up-invite-code-title'}>
                <FormattedMessage id={'signUp.inviteCode.title'} />
            </h2>
            <p className={'description'}>
                <FormattedMessage id={'signUp.inviteCode.description'} />
            </p>
            {error && (
                <ErrorSummary
                    dataTestId={'sign-up-error-summary'}
                    errors={[
                        error?.status === StatusCodes.NOT_FOUND
                            ? intl.formatMessage(
                                {id: 'signUp.inviteCode.error.description'},
                                {cs: (chunk) => <ContactSupport text={chunk} />},
                            )
                            : intl.formatMessage(
                                {id: 'fetchError.information'},
                                {cs: (chunk) => <ContactSupport text={chunk} />},
                            ),
                    ]}
                    title={error?.status === StatusCodes.NOT_FOUND
                        ? intl.formatMessage({id: 'signUp.inviteCode.error.title'})
                        : intl.formatMessage({id: 'fetchError.title'})}
                />
            )}
            <FormGroup
                dataTestId={'sign-up-invite-code-form-group'}
                label={intl.formatMessage({id: 'signUp.inviteCode.label'})}
                labelFor={'inviteCode'}
            >
                <TextInput
                    dataTestId={'sign-up-invite-code-input'}
                    errorMessages={{
                        patternMismatch: intl.formatMessage({id: 'signUp.inviteCode.patternMismatch'}),
                        valueMissing: intl.formatMessage({id: 'signUp.inviteCode.valueMissing'}),
                    }}
                    id={'invite-code'}
                    ignoreOnChangeInvalidity={true}
                    isLoading={loading}
                    name={'inviteCode'}
                    pattern={ACCESS_CODE_PATTERN}
                    ref={inviteCodeRef}
                    required={true}
                    type={'text'}
                    value={getEligibility()?.inviteCode ?? ''}
                />
            </FormGroup>
            <Button
                className={'no-margin icon arrow--right after'}
                dataTestId={'sign-up-page-continue'}
                submit={true}
                title={intl.formatMessage({id: 'common.button.continue.title'})}
                variant={Variant.PRIMARY}
            >
                <FormattedMessage id={'common.button.continue'} />
            </Button>
            <p className={'variant-dark'}>
                <FormattedMessage
                    id={'signUp.inviteCode.existingAccount'}
                    values={{
                        login: (chunk) => (
                            <Link
                                className={'alternate'}
                                onClick={onLoginClickHandler}
                                url={'#'}
                            >
                                {chunk}
                            </Link>
                        ),
                    }}
                />
            </p>
        </form>
    );
}
