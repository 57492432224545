import {CardiovascularDiseaseRiskFactors} from './CardiovascularDiseaseRiskFactors';
import {CardiovascularDiseaseScreenOptions} from './CardiovascularDiseaseScreenOptions';

export function CardiovascularDiseaseMedicalActions(props) {
    const {
        riskLevel,
        riskWindow,
        totalSteps,
    } = props;

    return (
        <>
            <CardiovascularDiseaseRiskFactors
                currentStep={1}
                riskWindow={riskWindow}
                totalSteps={totalSteps}
            />
            <CardiovascularDiseaseScreenOptions
                currentStep={2}
                riskLevel={riskLevel}
                riskWindow={riskWindow}
                totalSteps={totalSteps}
            />
        </>
    );
}
