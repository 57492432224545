/* eslint-disable no-magic-numbers */
import {useMemo} from 'react';

export function PeopleVisualisation(props) {
    const {integratedRiskScore, riskLevel} = props;
    const roundedIntegratedRiskScore = Math.round(integratedRiskScore * 100);

    const imageProps = useMemo(
        () => (riskLevel === 'AVERAGE'
            ? {
                stopColor1: '#7C26DF',
                stopColor2: '#0F5FFF',
            }
            : {
                stopColor1: '#FF5301',
                stopColor2: '#FC4183',
            }),
        [riskLevel],
    );

    const calculateOpacity = (number) => (number <= roundedIntegratedRiskScore ? '1' : '0.1');

    return (
        <figure className={'people visualisation'}>
            <svg
                fill={'none'}
                viewBox={'0 0 400 180'}
                xmlns={'http://www.w3.org/2000/svg'}
            >
                <g opacity={calculateOpacity(19)}>
                    <path
                        d={'M6.23615 25.875C8.10011 25.875 9.61121 23.2262 9.61121 21.3668C9.61121 19.5074 8.10017 18 6.23621 18C4.37225 18 2.86121 19.5074 2.86121 21.3668C2.86121 23.2262 4.37219 25.875 6.23615 25.875Z'}
                        fill={'url(#paint0)'}
                    />
                    <path
                        d={'M0 32.625C0 29.3113 2.68629 26.625 6 26.625C9.31371 26.625 12 29.3113 12 32.625V37.425C12 38.0877 11.4627 38.625 10.8 38.625H9.24634C9.2843 38.7555 9.30059 38.8949 9.29115 39.0389L8.6048 49.5035C8.56343 50.1344 8.03963 50.625 7.40738 50.625H4.59262C3.96037 50.625 3.43658 50.1344 3.3952 49.5035L2.70886 39.0389C2.69941 38.8949 2.7157 38.7555 2.75367 38.625H1.2C0.537259 38.625 0 38.0877 0 37.425V32.625Z'}
                        fill={'url(#paint1)'}
                    />
                </g>
                <g opacity={calculateOpacity(71)}>
                    <path
                        d={'M6.23615 62.875C8.10011 62.875 9.61121 60.2262 9.61121 58.3668C9.61121 56.5074 8.10017 55 6.23621 55C4.37225 55 2.86121 56.5074 2.86121 58.3668C2.86121 60.2262 4.37219 62.875 6.23615 62.875Z'}
                        fill={'url(#paint2)'}
                    />
                    <path
                        d={'M0 69.625C0 66.3113 2.68629 63.625 6 63.625C9.31371 63.625 12 66.3113 12 69.625V74.425C12 75.0877 11.4627 75.625 10.8 75.625H9.24634C9.2843 75.7555 9.30059 75.8949 9.29115 76.0389L8.6048 86.5035C8.56343 87.1344 8.03963 87.625 7.40738 87.625H4.59262C3.96037 87.625 3.43658 87.1344 3.3952 86.5035L2.70886 76.0389C2.69941 75.8949 2.7157 75.7555 2.75367 75.625H1.2C0.537259 75.625 0 75.0877 0 74.425V69.625Z'}
                        fill={'url(#paint3)'}
                    />
                </g>
                <g opacity={calculateOpacity(15)}>
                    <path
                        d={'M6.23615 99.875C8.10011 99.875 9.61121 97.2262 9.61121 95.3668C9.61121 93.5074 8.10017 92 6.23621 92C4.37225 92 2.86121 93.5074 2.86121 95.3668C2.86121 97.2262 4.37219 99.875 6.23615 99.875Z'}
                        fill={'url(#paint4)'}
                    />
                    <path
                        d={'M0 106.625C0 103.311 2.68629 100.625 6 100.625C9.31371 100.625 12 103.311 12 106.625V111.425C12 112.088 11.4627 112.625 10.8 112.625H9.24634C9.2843 112.756 9.30059 112.895 9.29115 113.039L8.6048 123.503C8.56343 124.134 8.03963 124.625 7.40738 124.625H4.59262C3.96037 124.625 3.43658 124.134 3.3952 123.503L2.70886 113.039C2.69941 112.895 2.7157 112.756 2.75367 112.625H1.2C0.537259 112.625 0 112.088 0 111.425V106.625Z'}
                        fill={'url(#paint5)'}
                    />
                </g>
                <g opacity={calculateOpacity(40)}>
                    <path
                        d={'M6.23615 136.875C8.10011 136.875 9.61121 134.226 9.61121 132.367C9.61121 130.507 8.10017 129 6.23621 129C4.37225 129 2.86121 130.507 2.86121 132.367C2.86121 134.226 4.37219 136.875 6.23615 136.875Z'}
                        fill={'url(#paint6)'}
                    />
                    <path
                        d={'M0 143.625C0 140.311 2.68629 137.625 6 137.625C9.31371 137.625 12 140.311 12 143.625V148.425C12 149.088 11.4627 149.625 10.8 149.625H9.24634C9.2843 149.756 9.30059 149.895 9.29115 150.039L8.6048 160.503C8.56343 161.134 8.03963 161.625 7.40738 161.625H4.59262C3.96037 161.625 3.43658 161.134 3.3952 160.503L2.70886 150.039C2.69941 149.895 2.7157 149.756 2.75367 149.625H1.2C0.537259 149.625 0 149.088 0 148.425V143.625Z'}
                        fill={'url(#paint7)'}
                    />
                </g>
                <g opacity={calculateOpacity(45)}>
                    <path
                        d={'M22.4027 33.875C24.2666 33.875 25.7777 31.2262 25.7777 29.3668C25.7777 27.5074 24.2667 26 22.4027 26C20.5387 26 19.0277 27.5074 19.0277 29.3668C19.0277 31.2262 20.5387 33.875 22.4027 33.875Z'}
                        fill={'url(#paint8)'}
                    />
                    <path
                        d={'M16.1665 40.625C16.1665 37.3113 18.8528 34.625 22.1665 34.625C25.4802 34.625 28.1665 37.3113 28.1665 40.625V45.425C28.1665 46.0877 27.6292 46.625 26.9665 46.625H25.4128C25.4508 46.7555 25.4671 46.8949 25.4576 47.0389L24.7713 57.5035C24.7299 58.1344 24.2061 58.625 23.5739 58.625H20.7591C20.1269 58.625 19.6031 58.1344 19.5617 57.5035L18.8754 47.0389C18.8659 46.8949 18.8822 46.7555 18.9202 46.625H17.3665C16.7038 46.625 16.1665 46.0877 16.1665 45.425V40.625Z'}
                        fill={'url(#paint9)'}
                    />
                </g>
                <g opacity={calculateOpacity(72)}>
                    <path
                        d={'M22.4027 70.875C24.2666 70.875 25.7777 68.2262 25.7777 66.3668C25.7777 64.5074 24.2667 63 22.4027 63C20.5387 63 19.0277 64.5074 19.0277 66.3668C19.0277 68.2262 20.5387 70.875 22.4027 70.875Z'}
                        fill={'url(#paint10)'}
                    />
                    <path
                        d={'M16.1665 77.625C16.1665 74.3113 18.8528 71.625 22.1665 71.625C25.4802 71.625 28.1665 74.3113 28.1665 77.625V82.425C28.1665 83.0877 27.6292 83.625 26.9665 83.625H25.4128C25.4508 83.7555 25.4671 83.8949 25.4576 84.0389L24.7713 94.5035C24.7299 95.1344 24.2061 95.625 23.5739 95.625H20.7591C20.1269 95.625 19.6031 95.1344 19.5617 94.5035L18.8754 84.0389C18.8659 83.8949 18.8822 83.7555 18.9202 83.625H17.3665C16.7038 83.625 16.1665 83.0877 16.1665 82.425V77.625Z'}
                        fill={'url(#paint11)'}
                    />
                </g>
                <g opacity={calculateOpacity(66)}>
                    <path
                        d={'M22.4027 107.875C24.2666 107.875 25.7777 105.226 25.7777 103.367C25.7777 101.507 24.2667 100 22.4027 100C20.5387 100 19.0277 101.507 19.0277 103.367C19.0277 105.226 20.5387 107.875 22.4027 107.875Z'}
                        fill={'url(#paint12)'}
                    />
                    <path
                        d={'M16.1665 114.625C16.1665 111.311 18.8528 108.625 22.1665 108.625C25.4802 108.625 28.1665 111.311 28.1665 114.625V119.425C28.1665 120.088 27.6292 120.625 26.9665 120.625H25.4128C25.4508 120.756 25.4671 120.895 25.4576 121.039L24.7713 131.503C24.7299 132.134 24.2061 132.625 23.5739 132.625H20.7591C20.1269 132.625 19.6031 132.134 19.5617 131.503L18.8754 121.039C18.8659 120.895 18.8822 120.756 18.9202 120.625H17.3665C16.7038 120.625 16.1665 120.088 16.1665 119.425V114.625Z'}
                        fill={'url(#paint13)'}
                    />
                </g>
                <g opacity={calculateOpacity(23)}>
                    <path
                        d={'M22.4027 144.875C24.2666 144.875 25.7777 142.226 25.7777 140.367C25.7777 138.507 24.2667 137 22.4027 137C20.5387 137 19.0277 138.507 19.0277 140.367C19.0277 142.226 20.5387 144.875 22.4027 144.875Z'}
                        fill={'url(#paint14)'}
                    />
                    <path
                        d={'M16.1665 151.625C16.1665 148.311 18.8528 145.625 22.1665 145.625C25.4802 145.625 28.1665 148.311 28.1665 151.625V156.425C28.1665 157.088 27.6292 157.625 26.9665 157.625H25.4128C25.4508 157.756 25.4671 157.895 25.4576 158.039L24.7713 168.503C24.7299 169.134 24.2061 169.625 23.5739 169.625H20.7591C20.1269 169.625 19.6031 169.134 19.5617 168.503L18.8754 158.039C18.8659 157.895 18.8822 157.756 18.9202 157.625H17.3665C16.7038 157.625 16.1665 157.088 16.1665 156.425V151.625Z'}
                        fill={'url(#paint15)'}
                    />
                </g>
                <g opacity={calculateOpacity(61)}>
                    <path
                        d={'M38.5696 25.875C40.4336 25.875 41.9447 23.2262 41.9447 21.3668C41.9447 19.5074 40.4337 18 38.5697 18C36.7057 18 35.1947 19.5074 35.1947 21.3668C35.1947 23.2262 36.7057 25.875 38.5696 25.875Z'}
                        fill={'url(#paint16)'}
                    />
                    <path
                        d={'M32.3335 32.625C32.3335 29.3113 35.0198 26.625 38.3335 26.625C41.6472 26.625 44.3335 29.3113 44.3335 32.625V37.425C44.3335 38.0877 43.7962 38.625 43.1335 38.625H41.5798C41.6178 38.7555 41.6341 38.8949 41.6246 39.0389L40.9383 49.5035C40.8969 50.1344 40.3731 50.625 39.7409 50.625H36.9261C36.2939 50.625 35.7701 50.1344 35.7287 49.5035L35.0424 39.0389C35.0329 38.8949 35.0492 38.7555 35.0872 38.625H33.5335C32.8708 38.625 32.3335 38.0877 32.3335 37.425V32.625Z'}
                        fill={'url(#paint17)'}
                    />
                </g>
                <g opacity={calculateOpacity(6)}>
                    <path
                        d={'M38.5696 62.875C40.4336 62.875 41.9447 60.2262 41.9447 58.3668C41.9447 56.5074 40.4337 55 38.5697 55C36.7057 55 35.1947 56.5074 35.1947 58.3668C35.1947 60.2262 36.7057 62.875 38.5696 62.875Z'}
                        fill={'url(#paint18)'}
                    />
                    <path
                        d={'M32.3335 69.625C32.3335 66.3113 35.0198 63.625 38.3335 63.625C41.6472 63.625 44.3335 66.3113 44.3335 69.625V74.425C44.3335 75.0877 43.7962 75.625 43.1335 75.625H41.5798C41.6178 75.7555 41.6341 75.8949 41.6246 76.0389L40.9383 86.5035C40.8969 87.1344 40.3731 87.625 39.7409 87.625H36.9261C36.2939 87.625 35.7701 87.1344 35.7287 86.5035L35.0424 76.0389C35.0329 75.8949 35.0492 75.7555 35.0872 75.625H33.5335C32.8708 75.625 32.3335 75.0877 32.3335 74.425V69.625Z'}
                        fill={'url(#paint19)'}
                    />
                </g>
                <g opacity={calculateOpacity(53)}>
                    <path
                        d={'M38.5696 99.875C40.4336 99.875 41.9447 97.2262 41.9447 95.3668C41.9447 93.5074 40.4337 92 38.5697 92C36.7057 92 35.1947 93.5074 35.1947 95.3668C35.1947 97.2262 36.7057 99.875 38.5696 99.875Z'}
                        fill={'url(#paint20)'}
                    />
                    <path
                        d={'M32.3335 106.625C32.3335 103.311 35.0198 100.625 38.3335 100.625C41.6472 100.625 44.3335 103.311 44.3335 106.625V111.425C44.3335 112.088 43.7962 112.625 43.1335 112.625H41.5798C41.6178 112.756 41.6341 112.895 41.6246 113.039L40.9383 123.503C40.8969 124.134 40.3731 124.625 39.7409 124.625H36.9261C36.2939 124.625 35.7701 124.134 35.7287 123.503L35.0424 113.039C35.0329 112.895 35.0492 112.756 35.0872 112.625H33.5335C32.8708 112.625 32.3335 112.088 32.3335 111.425V106.625Z'}
                        fill={'url(#paint21)'}
                    />
                </g>
                <g opacity={calculateOpacity(76)}>
                    <path
                        d={'M38.5696 136.875C40.4336 136.875 41.9447 134.226 41.9447 132.367C41.9447 130.507 40.4337 129 38.5697 129C36.7057 129 35.1947 130.507 35.1947 132.367C35.1947 134.226 36.7057 136.875 38.5696 136.875Z'}
                        fill={'url(#paint22)'}
                    />
                    <path
                        d={'M32.3335 143.625C32.3335 140.311 35.0198 137.625 38.3335 137.625C41.6472 137.625 44.3335 140.311 44.3335 143.625V148.425C44.3335 149.088 43.7962 149.625 43.1335 149.625H41.5798C41.6178 149.756 41.6341 149.895 41.6246 150.039L40.9383 160.503C40.8969 161.134 40.3731 161.625 39.7409 161.625H36.9261C36.2939 161.625 35.7701 161.134 35.7287 160.503L35.0424 150.039C35.0329 149.895 35.0492 149.756 35.0872 149.625H33.5335C32.8708 149.625 32.3335 149.088 32.3335 148.425V143.625Z'}
                        fill={'url(#paint23)'}
                    />
                </g>
                <g opacity={calculateOpacity(89)}>
                    <path
                        d={'M54.7362 33.875C56.6001 33.875 58.1112 31.2262 58.1112 29.3668C58.1112 27.5074 56.6002 26 54.7362 26C52.8722 26 51.3612 27.5074 51.3612 29.3668C51.3612 31.2262 52.8722 33.875 54.7362 33.875Z'}
                        fill={'url(#paint24)'}
                    />
                    <path
                        d={'M48.5 40.625C48.5 37.3113 51.1863 34.625 54.5 34.625C57.8137 34.625 60.5 37.3113 60.5 40.625V45.425C60.5 46.0877 59.9627 46.625 59.3 46.625H57.7463C57.7843 46.7555 57.8006 46.8949 57.7911 47.0389L57.1048 57.5035C57.0634 58.1344 56.5396 58.625 55.9074 58.625H53.0926C52.4604 58.625 51.9366 58.1344 51.8952 57.5035L51.2089 47.0389C51.1994 46.8949 51.2157 46.7555 51.2537 46.625H49.7C49.0373 46.625 48.5 46.0877 48.5 45.425V40.625Z'}
                        fill={'url(#paint25)'}
                    />
                </g>
                <g opacity={calculateOpacity(29)}>
                    <path
                        d={'M54.7362 70.875C56.6001 70.875 58.1112 68.2262 58.1112 66.3668C58.1112 64.5074 56.6002 63 54.7362 63C52.8722 63 51.3612 64.5074 51.3612 66.3668C51.3612 68.2262 52.8722 70.875 54.7362 70.875Z'}
                        fill={'url(#paint26)'}
                    />
                    <path
                        d={'M48.5 77.625C48.5 74.3113 51.1863 71.625 54.5 71.625C57.8137 71.625 60.5 74.3113 60.5 77.625V82.425C60.5 83.0877 59.9627 83.625 59.3 83.625H57.7463C57.7843 83.7555 57.8006 83.8949 57.7911 84.0389L57.1048 94.5035C57.0634 95.1344 56.5396 95.625 55.9074 95.625H53.0926C52.4604 95.625 51.9366 95.1344 51.8952 94.5035L51.2089 84.0389C51.1994 83.8949 51.2157 83.7555 51.2537 83.625H49.7C49.0373 83.625 48.5 83.0877 48.5 82.425V77.625Z'}
                        fill={'url(#paint27)'}
                    />
                </g>
                <g opacity={calculateOpacity(60)}>
                    <path
                        d={'M54.7362 107.875C56.6001 107.875 58.1112 105.226 58.1112 103.367C58.1112 101.507 56.6002 100 54.7362 100C52.8722 100 51.3612 101.507 51.3612 103.367C51.3612 105.226 52.8722 107.875 54.7362 107.875Z'}
                        fill={'url(#paint28)'}
                    />
                    <path
                        d={'M48.5 114.625C48.5 111.311 51.1863 108.625 54.5 108.625C57.8137 108.625 60.5 111.311 60.5 114.625V119.425C60.5 120.088 59.9627 120.625 59.3 120.625H57.7463C57.7843 120.756 57.8006 120.895 57.7911 121.039L57.1048 131.503C57.0634 132.134 56.5396 132.625 55.9074 132.625H53.0926C52.4604 132.625 51.9366 132.134 51.8952 131.503L51.2089 121.039C51.1994 120.895 51.2157 120.756 51.2537 120.625H49.7C49.0373 120.625 48.5 120.088 48.5 119.425V114.625Z'}
                        fill={'url(#paint29)'}
                    />
                </g>
                <g opacity={calculateOpacity(81)}>
                    <path
                        d={'M54.7362 144.875C56.6001 144.875 58.1112 142.226 58.1112 140.367C58.1112 138.507 56.6002 137 54.7362 137C52.8722 137 51.3612 138.507 51.3612 140.367C51.3612 142.226 52.8722 144.875 54.7362 144.875Z'}
                        fill={'url(#paint30)'}
                    />
                    <path
                        d={'M48.5 151.625C48.5 148.311 51.1863 145.625 54.5 145.625C57.8137 145.625 60.5 148.311 60.5 151.625V156.425C60.5 157.088 59.9627 157.625 59.3 157.625H57.7463C57.7843 157.756 57.8006 157.895 57.7911 158.039L57.1048 168.503C57.0634 169.134 56.5396 169.625 55.9074 169.625H53.0926C52.4604 169.625 51.9366 169.134 51.8952 168.503L51.2089 158.039C51.1994 157.895 51.2157 157.756 51.2537 157.625H49.7C49.0373 157.625 48.5 157.088 48.5 156.425V151.625Z'}
                        fill={'url(#paint31)'}
                    />
                </g>
                <g opacity={calculateOpacity(13)}>
                    <path
                        d={'M70.9027 25.875C72.7666 25.875 74.2777 23.2262 74.2777 21.3668C74.2777 19.5074 72.7667 18 70.9027 18C69.0387 18 67.5277 19.5074 67.5277 21.3668C67.5277 23.2262 69.0387 25.875 70.9027 25.875Z'}
                        fill={'url(#paint32)'}
                    />
                    <path
                        d={'M64.6665 32.625C64.6665 29.3113 67.3528 26.625 70.6665 26.625C73.9802 26.625 76.6665 29.3113 76.6665 32.625V37.425C76.6665 38.0877 76.1292 38.625 75.4665 38.625H73.9128C73.9508 38.7555 73.9671 38.8949 73.9576 39.0389L73.2713 49.5035C73.2299 50.1344 72.7061 50.625 72.0739 50.625H69.2591C68.6269 50.625 68.1031 50.1344 68.0617 49.5035L67.3754 39.0389C67.3659 38.8949 67.3822 38.7555 67.4202 38.625H65.8665C65.2038 38.625 64.6665 38.0877 64.6665 37.425V32.625Z'}
                        fill={'url(#paint33)'}
                    />
                </g>
                <g opacity={calculateOpacity(95)}>
                    <path
                        d={'M70.9027 62.875C72.7666 62.875 74.2777 60.2262 74.2777 58.3668C74.2777 56.5074 72.7667 55 70.9027 55C69.0387 55 67.5277 56.5074 67.5277 58.3668C67.5277 60.2262 69.0387 62.875 70.9027 62.875Z'}
                        fill={'url(#paint34)'}
                    />
                    <path
                        d={'M64.6665 69.625C64.6665 66.3113 67.3528 63.625 70.6665 63.625C73.9802 63.625 76.6665 66.3113 76.6665 69.625V74.425C76.6665 75.0877 76.1292 75.625 75.4665 75.625H73.9128C73.9508 75.7555 73.9671 75.8949 73.9576 76.0389L73.2713 86.5035C73.2299 87.1344 72.7061 87.625 72.0739 87.625H69.2591C68.6269 87.625 68.1031 87.1344 68.0617 86.5035L67.3754 76.0389C67.3659 75.8949 67.3822 75.7555 67.4202 75.625H65.8665C65.2038 75.625 64.6665 75.0877 64.6665 74.425V69.625Z'}
                        fill={'url(#paint35)'}
                    />
                </g>
                <g opacity={calculateOpacity(38)}>
                    <path
                        d={'M70.9027 99.875C72.7666 99.875 74.2777 97.2262 74.2777 95.3668C74.2777 93.5074 72.7667 92 70.9027 92C69.0387 92 67.5277 93.5074 67.5277 95.3668C67.5277 97.2262 69.0387 99.875 70.9027 99.875Z'}
                        fill={'url(#paint36)'}
                    />
                    <path
                        d={'M64.6665 106.625C64.6665 103.311 67.3528 100.625 70.6665 100.625C73.9802 100.625 76.6665 103.311 76.6665 106.625V111.425C76.6665 112.088 76.1292 112.625 75.4665 112.625H73.9128C73.9508 112.756 73.9671 112.895 73.9576 113.039L73.2713 123.503C73.2299 124.134 72.7061 124.625 72.0739 124.625H69.2591C68.6269 124.625 68.1031 124.134 68.0617 123.503L67.3754 113.039C67.3659 112.895 67.3822 112.756 67.4202 112.625H65.8665C65.2038 112.625 64.6665 112.088 64.6665 111.425V106.625Z'}
                        fill={'url(#paint37)'}
                    />
                </g>
                <g opacity={calculateOpacity(10)}>
                    <path
                        d={'M70.9027 136.875C72.7666 136.875 74.2777 134.226 74.2777 132.367C74.2777 130.507 72.7667 129 70.9027 129C69.0387 129 67.5277 130.507 67.5277 132.367C67.5277 134.226 69.0387 136.875 70.9027 136.875Z'}
                        fill={'url(#paint38)'}
                    />
                    <path
                        d={'M64.6665 143.625C64.6665 140.311 67.3528 137.625 70.6665 137.625C73.9802 137.625 76.6665 140.311 76.6665 143.625V148.425C76.6665 149.088 76.1292 149.625 75.4665 149.625H73.9128C73.9508 149.756 73.9671 149.895 73.9576 150.039L73.2713 160.503C73.2299 161.134 72.7061 161.625 72.0739 161.625H69.2591C68.6269 161.625 68.1031 161.134 68.0617 160.503L67.3754 150.039C67.3659 149.895 67.3822 149.756 67.4202 149.625H65.8665C65.2038 149.625 64.6665 149.088 64.6665 148.425V143.625Z'}
                        fill={'url(#paint39)'}
                    />
                </g>
                <g opacity={calculateOpacity(39)}>
                    <path
                        d={'M87.0696 33.875C88.9336 33.875 90.4447 31.2262 90.4447 29.3668C90.4447 27.5074 88.9337 26 87.0697 26C85.2057 26 83.6947 27.5074 83.6947 29.3668C83.6947 31.2262 85.2057 33.875 87.0696 33.875Z'}
                        fill={'url(#paint40)'}
                    />
                    <path
                        d={'M80.8335 40.625C80.8335 37.3113 83.5198 34.625 86.8335 34.625C90.1472 34.625 92.8335 37.3113 92.8335 40.625V45.425C92.8335 46.0877 92.2962 46.625 91.6335 46.625H90.0798C90.1178 46.7555 90.1341 46.8949 90.1246 47.0389L89.4383 57.5035C89.3969 58.1344 88.8731 58.625 88.2409 58.625H85.4261C84.7939 58.625 84.2701 58.1344 84.2287 57.5035L83.5424 47.0389C83.5329 46.8949 83.5492 46.7555 83.5872 46.625H82.0335C81.3708 46.625 80.8335 46.0877 80.8335 45.425V40.625Z'}
                        fill={'url(#paint41)'}
                    />
                </g>
                <g opacity={calculateOpacity(78)}>
                    <path
                        d={'M87.0696 70.875C88.9336 70.875 90.4447 68.2262 90.4447 66.3668C90.4447 64.5074 88.9337 63 87.0697 63C85.2057 63 83.6947 64.5074 83.6947 66.3668C83.6947 68.2262 85.2057 70.875 87.0696 70.875Z'}
                        fill={'url(#paint42)'}
                    />
                    <path
                        d={'M80.8335 77.625C80.8335 74.3113 83.5198 71.625 86.8335 71.625C90.1472 71.625 92.8335 74.3113 92.8335 77.625V82.425C92.8335 83.0877 92.2962 83.625 91.6335 83.625H90.0798C90.1178 83.7555 90.1341 83.8949 90.1246 84.0389L89.4383 94.5035C89.3969 95.1344 88.8731 95.625 88.2409 95.625H85.4261C84.7939 95.625 84.2701 95.1344 84.2287 94.5035L83.5424 84.0389C83.5329 83.8949 83.5492 83.7555 83.5872 83.625H82.0335C81.3708 83.625 80.8335 83.0877 80.8335 82.425V77.625Z'}
                        fill={'url(#paint43)'}
                    />
                </g>
                <g opacity={calculateOpacity(43)}>
                    <path
                        d={'M87.0696 107.875C88.9336 107.875 90.4447 105.226 90.4447 103.367C90.4447 101.507 88.9337 100 87.0697 100C85.2057 100 83.6947 101.507 83.6947 103.367C83.6947 105.226 85.2057 107.875 87.0696 107.875Z'}
                        fill={'url(#paint44)'}
                    />
                    <path
                        d={'M80.8335 114.625C80.8335 111.311 83.5198 108.625 86.8335 108.625C90.1472 108.625 92.8335 111.311 92.8335 114.625V119.425C92.8335 120.088 92.2962 120.625 91.6335 120.625H90.0798C90.1178 120.756 90.1341 120.895 90.1246 121.039L89.4383 131.503C89.3969 132.134 88.8731 132.625 88.2409 132.625H85.4261C84.7939 132.625 84.2701 132.134 84.2287 131.503L83.5424 121.039C83.5329 120.895 83.5492 120.756 83.5872 120.625H82.0335C81.3708 120.625 80.8335 120.088 80.8335 119.425V114.625Z'}
                        fill={'url(#paint45)'}
                    />
                </g>
                <g opacity={calculateOpacity(91)}>
                    <path
                        d={'M87.0696 144.875C88.9336 144.875 90.4447 142.226 90.4447 140.367C90.4447 138.507 88.9337 137 87.0697 137C85.2057 137 83.6947 138.507 83.6947 140.367C83.6947 142.226 85.2057 144.875 87.0696 144.875Z'}
                        fill={'url(#paint46)'}
                    />
                    <path
                        d={'M80.8335 151.625C80.8335 148.311 83.5198 145.625 86.8335 145.625C90.1472 145.625 92.8335 148.311 92.8335 151.625V156.425C92.8335 157.088 92.2962 157.625 91.6335 157.625H90.0798C90.1178 157.756 90.1341 157.895 90.1246 158.039L89.4383 168.503C89.3969 169.134 88.8731 169.625 88.2409 169.625H85.4261C84.7939 169.625 84.2701 169.134 84.2287 168.503L83.5424 158.039C83.5329 157.895 83.5492 157.756 83.5872 157.625H82.0335C81.3708 157.625 80.8335 157.088 80.8335 156.425V151.625Z'}
                        fill={'url(#paint47)'}
                    />
                </g>
                <g opacity={calculateOpacity(86)}>
                    <path
                        d={'M103.236 25.875C105.1 25.875 106.611 23.2262 106.611 21.3668C106.611 19.5074 105.1 18 103.236 18C101.372 18 99.8612 19.5074 99.8612 21.3668C99.8612 23.2262 101.372 25.875 103.236 25.875Z'}
                        fill={'url(#paint48)'}
                    />
                    <path
                        d={'M97 32.625C97 29.3113 99.6863 26.625 103 26.625C106.314 26.625 109 29.3113 109 32.625V37.425C109 38.0877 108.463 38.625 107.8 38.625H106.246C106.284 38.7555 106.301 38.8949 106.291 39.0389L105.605 49.5035C105.563 50.1344 105.04 50.625 104.407 50.625H101.593C100.96 50.625 100.437 50.1344 100.395 49.5035L99.7089 39.0389C99.6994 38.8949 99.7157 38.7555 99.7537 38.625H98.2C97.5373 38.625 97 38.0877 97 37.425V32.625Z'}
                        fill={'url(#paint49)'}
                    />
                </g>
                <g opacity={calculateOpacity(52)}>
                    <path
                        d={'M103.236 62.875C105.1 62.875 106.611 60.2262 106.611 58.3668C106.611 56.5074 105.1 55 103.236 55C101.372 55 99.8612 56.5074 99.8612 58.3668C99.8612 60.2262 101.372 62.875 103.236 62.875Z'}
                        fill={'url(#paint50)'}
                    />
                    <path
                        d={'M97 69.625C97 66.3113 99.6863 63.625 103 63.625C106.314 63.625 109 66.3113 109 69.625V74.425C109 75.0877 108.463 75.625 107.8 75.625H106.246C106.284 75.7555 106.301 75.8949 106.291 76.0389L105.605 86.5035C105.563 87.1344 105.04 87.625 104.407 87.625H101.593C100.96 87.625 100.437 87.1344 100.395 86.5035L99.7089 76.0389C99.6994 75.8949 99.7157 75.7555 99.7537 75.625H98.2C97.5373 75.625 97 75.0877 97 74.425V69.625Z'}
                        fill={'url(#paint51)'}
                    />
                </g>
                <g opacity={calculateOpacity(1)}>
                    <path
                        d={'M103.236 99.875C105.1 99.875 106.611 97.2262 106.611 95.3668C106.611 93.5074 105.1 92 103.236 92C101.372 92 99.8612 93.5074 99.8612 95.3668C99.8612 97.2262 101.372 99.875 103.236 99.875Z'}
                        fill={'url(#paint52)'}
                    />
                    <path
                        d={'M97 106.625C97 103.311 99.6863 100.625 103 100.625C106.314 100.625 109 103.311 109 106.625V111.425C109 112.088 108.463 112.625 107.8 112.625H106.246C106.284 112.756 106.301 112.895 106.291 113.039L105.605 123.503C105.563 124.134 105.04 124.625 104.407 124.625H101.593C100.96 124.625 100.437 124.134 100.395 123.503L99.7089 113.039C99.6994 112.895 99.7157 112.756 99.7537 112.625H98.2C97.5373 112.625 97 112.088 97 111.425V106.625Z'}
                        fill={'url(#paint53)'}
                    />
                </g>
                <g opacity={calculateOpacity(59)}>
                    <path
                        d={'M103.236 136.875C105.1 136.875 106.611 134.226 106.611 132.367C106.611 130.507 105.1 129 103.236 129C101.372 129 99.8612 130.507 99.8612 132.367C99.8612 134.226 101.372 136.875 103.236 136.875Z'}
                        fill={'url(#paint54)'}
                    />
                    <path
                        d={'M97 143.625C97 140.311 99.6863 137.625 103 137.625C106.314 137.625 109 140.311 109 143.625V148.425C109 149.088 108.463 149.625 107.8 149.625H106.246C106.284 149.756 106.301 149.895 106.291 150.039L105.605 160.503C105.563 161.134 105.04 161.625 104.407 161.625H101.593C100.96 161.625 100.437 161.134 100.395 160.503L99.7089 150.039C99.6994 149.895 99.7157 149.756 99.7537 149.625H98.2C97.5373 149.625 97 149.088 97 148.425V143.625Z'}
                        fill={'url(#paint55)'}
                    />
                </g>
                <g opacity={calculateOpacity(80)}>
                    <path
                        d={'M119.403 33.875C121.267 33.875 122.778 31.2262 122.778 29.3668C122.778 27.5074 121.267 26 119.403 26C117.539 26 116.028 27.5074 116.028 29.3668C116.028 31.2262 117.539 33.875 119.403 33.875Z'}
                        fill={'url(#paint56)'}
                    />
                    <path
                        d={'M113.167 40.625C113.167 37.3113 115.853 34.625 119.167 34.625C122.48 34.625 125.167 37.3113 125.167 40.625V45.425C125.167 46.0877 124.629 46.625 123.967 46.625H122.413C122.451 46.7555 122.467 46.8949 122.458 47.0389L121.771 57.5035C121.73 58.1344 121.206 58.625 120.574 58.625H117.759C117.127 58.625 116.603 58.1344 116.562 57.5035L115.875 47.0389C115.866 46.8949 115.882 46.7555 115.92 46.625H114.367C113.704 46.625 113.167 46.0877 113.167 45.425V40.625Z'}
                        fill={'url(#paint57)'}
                    />
                </g>
                <g opacity={calculateOpacity(18)}>
                    <path
                        d={'M119.403 70.875C121.267 70.875 122.778 68.2262 122.778 66.3668C122.778 64.5074 121.267 63 119.403 63C117.539 63 116.028 64.5074 116.028 66.3668C116.028 68.2262 117.539 70.875 119.403 70.875Z'}
                        fill={'url(#paint58)'}
                    />
                    <path
                        d={'M113.167 77.625C113.167 74.3113 115.853 71.625 119.167 71.625C122.48 71.625 125.167 74.3113 125.167 77.625V82.425C125.167 83.0877 124.629 83.625 123.967 83.625H122.413C122.451 83.7555 122.467 83.8949 122.458 84.0389L121.771 94.5035C121.73 95.1344 121.206 95.625 120.574 95.625H117.759C117.127 95.625 116.603 95.1344 116.562 94.5035L115.875 84.0389C115.866 83.8949 115.882 83.7555 115.92 83.625H114.367C113.704 83.625 113.167 83.0877 113.167 82.425V77.625Z'}
                        fill={'url(#paint59)'}
                    />
                </g>
                <g opacity={calculateOpacity(98)}>
                    <path
                        d={'M119.403 107.875C121.267 107.875 122.778 105.226 122.778 103.367C122.778 101.507 121.267 100 119.403 100C117.539 100 116.028 101.507 116.028 103.367C116.028 105.226 117.539 107.875 119.403 107.875Z'}
                        fill={'url(#paint60)'}
                    />
                    <path
                        d={'M113.167 114.625C113.167 111.311 115.853 108.625 119.167 108.625C122.48 108.625 125.167 111.311 125.167 114.625V119.425C125.167 120.088 124.629 120.625 123.967 120.625H122.413C122.451 120.756 122.467 120.895 122.458 121.039L121.771 131.503C121.73 132.134 121.206 132.625 120.574 132.625H117.759C117.127 132.625 116.603 132.134 116.562 131.503L115.875 121.039C115.866 120.895 115.882 120.756 115.92 120.625H114.367C113.704 120.625 113.167 120.088 113.167 119.425V114.625Z'}
                        fill={'url(#paint61)'}
                    />
                </g>
                <g opacity={calculateOpacity(24)}>
                    <path
                        d={'M119.403 144.875C121.267 144.875 122.778 142.226 122.778 140.367C122.778 138.507 121.267 137 119.403 137C117.539 137 116.028 138.507 116.028 140.367C116.028 142.226 117.539 144.875 119.403 144.875Z'}
                        fill={'url(#paint62)'}
                    />
                    <path
                        d={'M113.167 151.625C113.167 148.311 115.853 145.625 119.167 145.625C122.48 145.625 125.167 148.311 125.167 151.625V156.425C125.167 157.088 124.629 157.625 123.967 157.625H122.413C122.451 157.756 122.467 157.895 122.458 158.039L121.771 168.503C121.73 169.134 121.206 169.625 120.574 169.625H117.759C117.127 169.625 116.603 169.134 116.562 168.503L115.875 158.039C115.866 157.895 115.882 157.756 115.92 157.625H114.367C113.704 157.625 113.167 157.088 113.167 156.425V151.625Z'}
                        fill={'url(#paint63)'}
                    />
                </g>
                <g opacity={calculateOpacity(35)}>
                    <path
                        d={'M135.57 25.875C137.434 25.875 138.945 23.2262 138.945 21.3668C138.945 19.5074 137.434 18 135.57 18C133.706 18 132.195 19.5074 132.195 21.3668C132.195 23.2262 133.706 25.875 135.57 25.875Z'}
                        fill={'url(#paint64)'}
                    />
                    <path
                        d={'M129.333 32.625C129.333 29.3113 132.02 26.625 135.333 26.625C138.647 26.625 141.333 29.3113 141.333 32.625V37.425C141.333 38.0877 140.796 38.625 140.133 38.625H138.58C138.618 38.7555 138.634 38.8949 138.625 39.0389L137.938 49.5035C137.897 50.1344 137.373 50.625 136.741 50.625H133.926C133.294 50.625 132.77 50.1344 132.729 49.5035L132.042 39.0389C132.033 38.8949 132.049 38.7555 132.087 38.625H130.533C129.871 38.625 129.333 38.0877 129.333 37.425V32.625Z'}
                        fill={'url(#paint65)'}
                    />
                </g>
                <g opacity={calculateOpacity(58)}>
                    <path
                        d={'M135.57 62.875C137.434 62.875 138.945 60.2262 138.945 58.3668C138.945 56.5074 137.434 55 135.57 55C133.706 55 132.195 56.5074 132.195 58.3668C132.195 60.2262 133.706 62.875 135.57 62.875Z'}
                        fill={'url(#paint66)'}
                    />
                    <path
                        d={'M129.333 69.625C129.333 66.3113 132.02 63.625 135.333 63.625C138.647 63.625 141.333 66.3113 141.333 69.625V74.425C141.333 75.0877 140.796 75.625 140.133 75.625H138.58C138.618 75.7555 138.634 75.8949 138.625 76.0389L137.938 86.5035C137.897 87.1344 137.373 87.625 136.741 87.625H133.926C133.294 87.625 132.77 87.1344 132.729 86.5035L132.042 76.0389C132.033 75.8949 132.049 75.7555 132.087 75.625H130.533C129.871 75.625 129.333 75.0877 129.333 74.425V69.625Z'}
                        fill={'url(#paint67)'}
                    />
                </g>
                <g opacity={calculateOpacity(55)}>
                    <path
                        d={'M135.57 99.875C137.434 99.875 138.945 97.2262 138.945 95.3668C138.945 93.5074 137.434 92 135.57 92C133.706 92 132.195 93.5074 132.195 95.3668C132.195 97.2262 133.706 99.875 135.57 99.875Z'}
                        fill={'url(#paint68)'}
                    />
                    <path
                        d={'M129.333 106.625C129.333 103.311 132.02 100.625 135.333 100.625C138.647 100.625 141.333 103.311 141.333 106.625V111.425C141.333 112.088 140.796 112.625 140.133 112.625H138.58C138.618 112.756 138.634 112.895 138.625 113.039L137.938 123.503C137.897 124.134 137.373 124.625 136.741 124.625H133.926C133.294 124.625 132.77 124.134 132.729 123.503L132.042 113.039C132.033 112.895 132.049 112.756 132.087 112.625H130.533C129.871 112.625 129.333 112.088 129.333 111.425V106.625Z'}
                        fill={'url(#paint69)'}
                    />
                </g>
                <g opacity={calculateOpacity(90)}>
                    <path
                        d={'M135.57 136.875C137.434 136.875 138.945 134.226 138.945 132.367C138.945 130.507 137.434 129 135.57 129C133.706 129 132.195 130.507 132.195 132.367C132.195 134.226 133.706 136.875 135.57 136.875Z'}
                        fill={'url(#paint70)'}
                    />
                    <path
                        d={'M129.333 143.625C129.333 140.311 132.02 137.625 135.333 137.625C138.647 137.625 141.333 140.311 141.333 143.625V148.425C141.333 149.088 140.796 149.625 140.133 149.625H138.58C138.618 149.756 138.634 149.895 138.625 150.039L137.938 160.503C137.897 161.134 137.373 161.625 136.741 161.625H133.926C133.294 161.625 132.77 161.134 132.729 160.503L132.042 150.039C132.033 149.895 132.049 149.756 132.087 149.625H130.533C129.871 149.625 129.333 149.088 129.333 148.425V143.625Z'}
                        fill={'url(#paint71)'}
                    />
                </g>
                <g opacity={calculateOpacity(3)}>
                    <path
                        d={'M151.736 33.875C153.6 33.875 155.111 31.2262 155.111 29.3668C155.111 27.5074 153.6 26 151.736 26C149.872 26 148.361 27.5074 148.361 29.3668C148.361 31.2262 149.872 33.875 151.736 33.875Z'}
                        fill={'url(#paint72)'}
                    />
                    <path
                        d={'M145.5 40.625C145.5 37.3113 148.186 34.625 151.5 34.625C154.814 34.625 157.5 37.3113 157.5 40.625V45.425C157.5 46.0877 156.963 46.625 156.3 46.625H154.746C154.784 46.7555 154.801 46.8949 154.791 47.0389L154.105 57.5035C154.063 58.1344 153.54 58.625 152.907 58.625H150.093C149.46 58.625 148.937 58.1344 148.895 57.5035L148.209 47.0389C148.199 46.8949 148.216 46.7555 148.254 46.625H146.7C146.037 46.625 145.5 46.0877 145.5 45.425V40.625Z'}
                        fill={'url(#paint73)'}
                    />
                </g>
                <g opacity={calculateOpacity(84)}>
                    <path
                        d={'M151.736 70.875C153.6 70.875 155.111 68.2262 155.111 66.3668C155.111 64.5074 153.6 63 151.736 63C149.872 63 148.361 64.5074 148.361 66.3668C148.361 68.2262 149.872 70.875 151.736 70.875Z'}
                        fill={'url(#paint74)'}
                    />
                    <path
                        d={'M145.5 77.625C145.5 74.3113 148.186 71.625 151.5 71.625C154.814 71.625 157.5 74.3113 157.5 77.625V82.425C157.5 83.0877 156.963 83.625 156.3 83.625H154.746C154.784 83.7555 154.801 83.8949 154.791 84.0389L154.105 94.5035C154.063 95.1344 153.54 95.625 152.907 95.625H150.093C149.46 95.625 148.937 95.1344 148.895 94.5035L148.209 84.0389C148.199 83.8949 148.216 83.7555 148.254 83.625H146.7C146.037 83.625 145.5 83.0877 145.5 82.425V77.625Z'}
                        fill={'url(#paint75)'}
                    />
                </g>
                <g opacity={calculateOpacity(17)}>
                    <path
                        d={'M151.736 107.875C153.6 107.875 155.111 105.226 155.111 103.367C155.111 101.507 153.6 100 151.736 100C149.872 100 148.361 101.507 148.361 103.367C148.361 105.226 149.872 107.875 151.736 107.875Z'}
                        fill={'url(#paint76)'}
                    />
                    <path
                        d={'M145.5 114.625C145.5 111.311 148.186 108.625 151.5 108.625C154.814 108.625 157.5 111.311 157.5 114.625V119.425C157.5 120.088 156.963 120.625 156.3 120.625H154.746C154.784 120.756 154.801 120.895 154.791 121.039L154.105 131.503C154.063 132.134 153.54 132.625 152.907 132.625H150.093C149.46 132.625 148.937 132.134 148.895 131.503L148.209 121.039C148.199 120.895 148.216 120.756 148.254 120.625H146.7C146.037 120.625 145.5 120.088 145.5 119.425V114.625Z'}
                        fill={'url(#paint77)'}
                    />
                </g>
                <g opacity={calculateOpacity(49)}>
                    <path
                        d={'M151.736 144.875C153.6 144.875 155.111 142.226 155.111 140.367C155.111 138.507 153.6 137 151.736 137C149.872 137 148.361 138.507 148.361 140.367C148.361 142.226 149.872 144.875 151.736 144.875Z'}
                        fill={'url(#paint78)'}
                    />
                    <path
                        d={'M145.5 151.625C145.5 148.311 148.186 145.625 151.5 145.625C154.814 145.625 157.5 148.311 157.5 151.625V156.425C157.5 157.088 156.963 157.625 156.3 157.625H154.746C154.784 157.756 154.801 157.895 154.791 158.039L154.105 168.503C154.063 169.134 153.54 169.625 152.907 169.625H150.093C149.46 169.625 148.937 169.134 148.895 168.503L148.209 158.039C148.199 157.895 148.216 157.756 148.254 157.625H146.7C146.037 157.625 145.5 157.088 145.5 156.425V151.625Z'}
                        fill={'url(#paint79)'}
                    />
                </g>
                <g opacity={calculateOpacity(63)}>
                    <path
                        d={'M167.903 25.875C169.767 25.875 171.278 23.2262 171.278 21.3668C171.278 19.5074 169.767 18 167.903 18C166.039 18 164.528 19.5074 164.528 21.3668C164.528 23.2262 166.039 25.875 167.903 25.875Z'}
                        fill={'url(#paint80)'}
                    />
                    <path
                        d={'M161.667 32.625C161.667 29.3113 164.353 26.625 167.667 26.625C170.98 26.625 173.667 29.3113 173.667 32.625V37.425C173.667 38.0877 173.129 38.625 172.467 38.625H170.913C170.951 38.7555 170.967 38.8949 170.958 39.0389L170.271 49.5035C170.23 50.1344 169.706 50.625 169.074 50.625H166.259C165.627 50.625 165.103 50.1344 165.062 49.5035L164.375 39.0389C164.366 38.8949 164.382 38.7555 164.42 38.625H162.867C162.204 38.625 161.667 38.0877 161.667 37.425V32.625Z'}
                        fill={'url(#paint81)'}
                    />
                </g>
                <g opacity={calculateOpacity(26)}>
                    <path
                        d={'M167.903 62.875C169.767 62.875 171.278 60.2262 171.278 58.3668C171.278 56.5074 169.767 55 167.903 55C166.039 55 164.528 56.5074 164.528 58.3668C164.528 60.2262 166.039 62.875 167.903 62.875Z'}
                        fill={'url(#paint82)'}
                    />
                    <path
                        d={'M161.667 69.625C161.667 66.3113 164.353 63.625 167.667 63.625C170.98 63.625 173.667 66.3113 173.667 69.625V74.425C173.667 75.0877 173.129 75.625 172.467 75.625H170.913C170.951 75.7555 170.967 75.8949 170.958 76.0389L170.271 86.5035C170.23 87.1344 169.706 87.625 169.074 87.625H166.259C165.627 87.625 165.103 87.1344 165.062 86.5035L164.375 76.0389C164.366 75.8949 164.382 75.7555 164.42 75.625H162.867C162.204 75.625 161.667 75.0877 161.667 74.425V69.625Z'}
                        fill={'url(#paint83)'}
                    />
                </g>
                <g opacity={calculateOpacity(79)}>
                    <path
                        d={'M167.903 99.875C169.767 99.875 171.278 97.2262 171.278 95.3668C171.278 93.5074 169.767 92 167.903 92C166.039 92 164.528 93.5074 164.528 95.3668C164.528 97.2262 166.039 99.875 167.903 99.875Z'}
                        fill={'url(#paint84)'}
                    />
                    <path
                        d={'M161.667 106.625C161.667 103.311 164.353 100.625 167.667 100.625C170.98 100.625 173.667 103.311 173.667 106.625V111.425C173.667 112.088 173.129 112.625 172.467 112.625H170.913C170.951 112.756 170.967 112.895 170.958 113.039L170.271 123.503C170.23 124.134 169.706 124.625 169.074 124.625H166.259C165.627 124.625 165.103 124.134 165.062 123.503L164.375 113.039C164.366 112.895 164.382 112.756 164.42 112.625H162.867C162.204 112.625 161.667 112.088 161.667 111.425V106.625Z'}
                        fill={'url(#paint85)'}
                    />
                </g>
                <g opacity={calculateOpacity(9)}>
                    <path
                        d={'M167.903 136.875C169.767 136.875 171.278 134.226 171.278 132.367C171.278 130.507 169.767 129 167.903 129C166.039 129 164.528 130.507 164.528 132.367C164.528 134.226 166.039 136.875 167.903 136.875Z'}
                        fill={'url(#paint86)'}
                    />
                    <path
                        d={'M161.667 143.625C161.667 140.311 164.353 137.625 167.667 137.625C170.98 137.625 173.667 140.311 173.667 143.625V148.425C173.667 149.088 173.129 149.625 172.467 149.625H170.913C170.951 149.756 170.967 149.895 170.958 150.039L170.271 160.503C170.23 161.134 169.706 161.625 169.074 161.625H166.259C165.627 161.625 165.103 161.134 165.062 160.503L164.375 150.039C164.366 149.895 164.382 149.756 164.42 149.625H162.867C162.204 149.625 161.667 149.088 161.667 148.425V143.625Z'}
                        fill={'url(#paint87)'}
                    />
                </g>
                <g opacity={calculateOpacity(44)}>
                    <path
                        d={'M184.07 33.875C185.934 33.875 187.445 31.2262 187.445 29.3668C187.445 27.5074 185.934 26 184.07 26C182.206 26 180.695 27.5074 180.695 29.3668C180.695 31.2262 182.206 33.875 184.07 33.875Z'}
                        fill={'url(#paint88)'}
                    />
                    <path
                        d={'M177.833 40.625C177.833 37.3113 180.52 34.625 183.833 34.625C187.147 34.625 189.833 37.3113 189.833 40.625V45.425C189.833 46.0877 189.296 46.625 188.633 46.625H187.08C187.118 46.7555 187.134 46.8949 187.125 47.0389L186.438 57.5035C186.397 58.1344 185.873 58.625 185.241 58.625H182.426C181.794 58.625 181.27 58.1344 181.229 57.5035L180.542 47.0389C180.533 46.8949 180.549 46.7555 180.587 46.625H179.033C178.371 46.625 177.833 46.0877 177.833 45.425V40.625Z'}
                        fill={'url(#paint89)'}
                    />
                </g>
                <g opacity={calculateOpacity(74)}>
                    <path
                        d={'M184.07 70.875C185.934 70.875 187.445 68.2262 187.445 66.3668C187.445 64.5074 185.934 63 184.07 63C182.206 63 180.695 64.5074 180.695 66.3668C180.695 68.2262 182.206 70.875 184.07 70.875Z'}
                        fill={'url(#paint90)'}
                    />
                    <path
                        d={'M177.833 77.625C177.833 74.3113 180.52 71.625 183.833 71.625C187.147 71.625 189.833 74.3113 189.833 77.625V82.425C189.833 83.0877 189.296 83.625 188.633 83.625H187.08C187.118 83.7555 187.134 83.8949 187.125 84.0389L186.438 94.5035C186.397 95.1344 185.873 95.625 185.241 95.625H182.426C181.794 95.625 181.27 95.1344 181.229 94.5035L180.542 84.0389C180.533 83.8949 180.549 83.7555 180.587 83.625H179.033C178.371 83.625 177.833 83.0877 177.833 82.425V77.625Z'}
                        fill={'url(#paint91)'}
                    />
                </g>
                <g opacity={calculateOpacity(41)}>
                    <path
                        d={'M184.07 107.875C185.934 107.875 187.445 105.226 187.445 103.367C187.445 101.507 185.934 100 184.07 100C182.206 100 180.695 101.507 180.695 103.367C180.695 105.226 182.206 107.875 184.07 107.875Z'}
                        fill={'url(#paint92)'}
                    />
                    <path
                        d={'M177.833 114.625C177.833 111.311 180.52 108.625 183.833 108.625C187.147 108.625 189.833 111.311 189.833 114.625V119.425C189.833 120.088 189.296 120.625 188.633 120.625H187.08C187.118 120.756 187.134 120.895 187.125 121.039L186.438 131.503C186.397 132.134 185.873 132.625 185.241 132.625H182.426C181.794 132.625 181.27 132.134 181.229 131.503L180.542 121.039C180.533 120.895 180.549 120.756 180.587 120.625H179.033C178.371 120.625 177.833 120.088 177.833 119.425V114.625Z'}
                        fill={'url(#paint93)'}
                    />
                </g>
                <g opacity={calculateOpacity(88)}>
                    <path
                        d={'M184.07 144.875C185.934 144.875 187.445 142.226 187.445 140.367C187.445 138.507 185.934 137 184.07 137C182.206 137 180.695 138.507 180.695 140.367C180.695 142.226 182.206 144.875 184.07 144.875Z'}
                        fill={'url(#paint94)'}
                    />
                    <path
                        d={'M177.833 151.625C177.833 148.311 180.52 145.625 183.833 145.625C187.147 145.625 189.833 148.311 189.833 151.625V156.425C189.833 157.088 189.296 157.625 188.633 157.625H187.08C187.118 157.756 187.134 157.895 187.125 158.039L186.438 168.503C186.397 169.134 185.873 169.625 185.241 169.625H182.426C181.794 169.625 181.27 169.134 181.229 168.503L180.542 158.039C180.533 157.895 180.549 157.756 180.587 157.625H179.033C178.371 157.625 177.833 157.088 177.833 156.425V151.625Z'}
                        fill={'url(#paint95)'}
                    />
                </g>
                <g opacity={calculateOpacity(73)}>
                    <path
                        d={'M200.236 25.875C202.1 25.875 203.611 23.2262 203.611 21.3668C203.611 19.5074 202.1 18 200.236 18C198.372 18 196.861 19.5074 196.861 21.3668C196.861 23.2262 198.372 25.875 200.236 25.875Z'}
                        fill={'url(#paint96)'}
                    />
                    <path
                        d={'M194 32.625C194 29.3113 196.686 26.625 200 26.625C203.314 26.625 206 29.3113 206 32.625V37.425C206 38.0877 205.463 38.625 204.8 38.625H203.246C203.284 38.7555 203.301 38.8949 203.291 39.0389L202.605 49.5035C202.563 50.1344 202.04 50.625 201.407 50.625H198.593C197.96 50.625 197.437 50.1344 197.395 49.5035L196.709 39.0389C196.699 38.8949 196.716 38.7555 196.754 38.625H195.2C194.537 38.625 194 38.0877 194 37.425V32.625Z'}
                        fill={'url(#paint97)'}
                    />
                </g>
                <g opacity={calculateOpacity(12)}>
                    <path
                        d={'M200.236 62.875C202.1 62.875 203.611 60.2262 203.611 58.3668C203.611 56.5074 202.1 55 200.236 55C198.372 55 196.861 56.5074 196.861 58.3668C196.861 60.2262 198.372 62.875 200.236 62.875Z'}
                        fill={'url(#paint98)'}
                    />
                    <path
                        d={'M194 69.625C194 66.3113 196.686 63.625 200 63.625C203.314 63.625 206 66.3113 206 69.625V74.425C206 75.0877 205.463 75.625 204.8 75.625H203.246C203.284 75.7555 203.301 75.8949 203.291 76.0389L202.605 86.5035C202.563 87.1344 202.04 87.625 201.407 87.625H198.593C197.96 87.625 197.437 87.1344 197.395 86.5035L196.709 76.0389C196.699 75.8949 196.716 75.7555 196.754 75.625H195.2C194.537 75.625 194 75.0877 194 74.425V69.625Z'}
                        fill={'url(#paint99)'}
                    />
                </g>
                <g opacity={calculateOpacity(46)}>
                    <path
                        d={'M200.236 99.875C202.1 99.875 203.611 97.2262 203.611 95.3668C203.611 93.5074 202.1 92 200.236 92C198.372 92 196.861 93.5074 196.861 95.3668C196.861 97.2262 198.372 99.875 200.236 99.875Z'}
                        fill={'url(#paint100)'}
                    />
                    <path
                        d={'M194 106.625C194 103.311 196.686 100.625 200 100.625C203.314 100.625 206 103.311 206 106.625V111.425C206 112.088 205.463 112.625 204.8 112.625H203.246C203.284 112.756 203.301 112.895 203.291 113.039L202.605 123.503C202.563 124.134 202.04 124.625 201.407 124.625H198.593C197.96 124.625 197.437 124.134 197.395 123.503L196.709 113.039C196.699 112.895 196.716 112.756 196.754 112.625H195.2C194.537 112.625 194 112.088 194 111.425V106.625Z'}
                        fill={'url(#paint101)'}
                    />
                </g>
                <g opacity={calculateOpacity(31)}>
                    <path
                        d={'M200.236 136.875C202.1 136.875 203.611 134.226 203.611 132.367C203.611 130.507 202.1 129 200.236 129C198.372 129 196.861 130.507 196.861 132.367C196.861 134.226 198.372 136.875 200.236 136.875Z'}
                        fill={'url(#paint102)'}
                    />
                    <path
                        d={'M194 143.625C194 140.311 196.686 137.625 200 137.625C203.314 137.625 206 140.311 206 143.625V148.425C206 149.088 205.463 149.625 204.8 149.625H203.246C203.284 149.756 203.301 149.895 203.291 150.039L202.605 160.503C202.563 161.134 202.04 161.625 201.407 161.625H198.593C197.96 161.625 197.437 161.134 197.395 160.503L196.709 150.039C196.699 149.895 196.716 149.756 196.754 149.625H195.2C194.537 149.625 194 149.088 194 148.425V143.625Z'}
                        fill={'url(#paint103)'}
                    />
                </g>
                <g opacity={calculateOpacity(28)}>
                    <path
                        d={'M216.403 33.875C218.267 33.875 219.778 31.2262 219.778 29.3668C219.778 27.5074 218.267 26 216.403 26C214.539 26 213.028 27.5074 213.028 29.3668C213.028 31.2262 214.539 33.875 216.403 33.875Z'}
                        fill={'url(#paint104)'}
                    />
                    <path
                        d={'M210.167 40.625C210.167 37.3113 212.853 34.625 216.167 34.625C219.48 34.625 222.167 37.3113 222.167 40.625V45.425C222.167 46.0877 221.629 46.625 220.967 46.625H219.413C219.451 46.7555 219.467 46.8949 219.458 47.0389L218.771 57.5035C218.73 58.1344 218.206 58.625 217.574 58.625H214.759C214.127 58.625 213.603 58.1344 213.562 57.5035L212.875 47.0389C212.866 46.8949 212.882 46.7555 212.92 46.625H211.367C210.704 46.625 210.167 46.0877 210.167 45.425V40.625Z'}
                        fill={'url(#paint105)'}
                    />
                </g>
                <g opacity={calculateOpacity(100)}>
                    <path
                        d={'M216.403 70.875C218.267 70.875 219.778 68.2262 219.778 66.3668C219.778 64.5074 218.267 63 216.403 63C214.539 63 213.028 64.5074 213.028 66.3668C213.028 68.2262 214.539 70.875 216.403 70.875Z'}
                        fill={'url(#paint106)'}
                    />
                    <path
                        d={'M210.167 77.625C210.167 74.3113 212.853 71.625 216.167 71.625C219.48 71.625 222.167 74.3113 222.167 77.625V82.425C222.167 83.0877 221.629 83.625 220.967 83.625H219.413C219.451 83.7555 219.467 83.8949 219.458 84.0389L218.771 94.5035C218.73 95.1344 218.206 95.625 217.574 95.625H214.759C214.127 95.625 213.603 95.1344 213.562 94.5035L212.875 84.0389C212.866 83.8949 212.882 83.7555 212.92 83.625H211.367C210.704 83.625 210.167 83.0877 210.167 82.425V77.625Z'}
                        fill={'url(#paint107)'}
                    />
                </g>
                <g opacity={calculateOpacity(5)}>
                    <path
                        d={'M216.403 107.875C218.267 107.875 219.778 105.226 219.778 103.367C219.778 101.507 218.267 100 216.403 100C214.539 100 213.028 101.507 213.028 103.367C213.028 105.226 214.539 107.875 216.403 107.875Z'}
                        fill={'url(#paint108)'}
                    />
                    <path
                        d={'M210.167 114.625C210.167 111.311 212.853 108.625 216.167 108.625C219.48 108.625 222.167 111.311 222.167 114.625V119.425C222.167 120.088 221.629 120.625 220.967 120.625H219.413C219.451 120.756 219.467 120.895 219.458 121.039L218.771 131.503C218.73 132.134 218.206 132.625 217.574 132.625H214.759C214.127 132.625 213.603 132.134 213.562 131.503L212.875 121.039C212.866 120.895 212.882 120.756 212.92 120.625H211.367C210.704 120.625 210.167 120.088 210.167 119.425V114.625Z'}
                        fill={'url(#paint109)'}
                    />
                </g>
                <g opacity={calculateOpacity(70)}>
                    <path
                        d={'M216.403 144.875C218.267 144.875 219.778 142.226 219.778 140.367C219.778 138.507 218.267 137 216.403 137C214.539 137 213.028 138.507 213.028 140.367C213.028 142.226 214.539 144.875 216.403 144.875Z'}
                        fill={'url(#paint110)'}
                    />
                    <path
                        d={'M210.167 151.625C210.167 148.311 212.853 145.625 216.167 145.625C219.48 145.625 222.167 148.311 222.167 151.625V156.425C222.167 157.088 221.629 157.625 220.967 157.625H219.413C219.451 157.756 219.467 157.895 219.458 158.039L218.771 168.503C218.73 169.134 218.206 169.625 217.574 169.625H214.759C214.127 169.625 213.603 169.134 213.562 168.503L212.875 158.039C212.866 157.895 212.882 157.756 212.92 157.625H211.367C210.704 157.625 210.167 157.088 210.167 156.425V151.625Z'}
                        fill={'url(#paint111)'}
                    />
                </g>
                <g opacity={calculateOpacity(94)}>
                    <path
                        d={'M232.57 25.875C234.434 25.875 235.945 23.2262 235.945 21.3668C235.945 19.5074 234.434 18 232.57 18C230.706 18 229.195 19.5074 229.195 21.3668C229.195 23.2262 230.706 25.875 232.57 25.875Z'}
                        fill={'url(#paint112)'}
                    />
                    <path
                        d={'M226.333 32.625C226.333 29.3113 229.02 26.625 232.333 26.625C235.647 26.625 238.333 29.3113 238.333 32.625V37.425C238.333 38.0877 237.796 38.625 237.133 38.625H235.58C235.618 38.7555 235.634 38.8949 235.625 39.0389L234.938 49.5035C234.897 50.1344 234.373 50.625 233.741 50.625H230.926C230.294 50.625 229.77 50.1344 229.729 49.5035L229.042 39.0389C229.033 38.8949 229.049 38.7555 229.087 38.625H227.533C226.871 38.625 226.333 38.0877 226.333 37.425V32.625Z'}
                        fill={'url(#paint113)'}
                    />
                </g>
                <g opacity={calculateOpacity(33)}>
                    <path
                        d={'M232.57 62.875C234.434 62.875 235.945 60.2262 235.945 58.3668C235.945 56.5074 234.434 55 232.57 55C230.706 55 229.195 56.5074 229.195 58.3668C229.195 60.2262 230.706 62.875 232.57 62.875Z'}
                        fill={'url(#paint114)'}
                    />
                    <path
                        d={'M226.333 69.625C226.333 66.3113 229.02 63.625 232.333 63.625C235.647 63.625 238.333 66.3113 238.333 69.625V74.425C238.333 75.0877 237.796 75.625 237.133 75.625H235.58C235.618 75.7555 235.634 75.8949 235.625 76.0389L234.938 86.5035C234.897 87.1344 234.373 87.625 233.741 87.625H230.926C230.294 87.625 229.77 87.1344 229.729 86.5035L229.042 76.0389C229.033 75.8949 229.049 75.7555 229.087 75.625H227.533C226.871 75.625 226.333 75.0877 226.333 74.425V69.625Z'}
                        fill={'url(#paint115)'}
                    />
                </g>
                <g opacity={calculateOpacity(83)}>
                    <path
                        d={'M232.57 99.875C234.434 99.875 235.945 97.2262 235.945 95.3668C235.945 93.5074 234.434 92 232.57 92C230.706 92 229.195 93.5074 229.195 95.3668C229.195 97.2262 230.706 99.875 232.57 99.875Z'}
                        fill={'url(#paint116)'}
                    />
                    <path
                        d={'M226.333 106.625C226.333 103.311 229.02 100.625 232.333 100.625C235.647 100.625 238.333 103.311 238.333 106.625V111.425C238.333 112.088 237.796 112.625 237.133 112.625H235.58C235.618 112.756 235.634 112.895 235.625 113.039L234.938 123.503C234.897 124.134 234.373 124.625 233.741 124.625H230.926C230.294 124.625 229.77 124.134 229.729 123.503L229.042 113.039C229.033 112.895 229.049 112.756 229.087 112.625H227.533C226.871 112.625 226.333 112.088 226.333 111.425V106.625Z'}
                        fill={'url(#paint117)'}
                    />
                </g>
                <g opacity={calculateOpacity(34)}>
                    <path
                        d={'M232.57 136.875C234.434 136.875 235.945 134.226 235.945 132.367C235.945 130.507 234.434 129 232.57 129C230.706 129 229.195 130.507 229.195 132.367C229.195 134.226 230.706 136.875 232.57 136.875Z'}
                        fill={'url(#paint118)'}
                    />
                    <path
                        d={'M226.333 143.625C226.333 140.311 229.02 137.625 232.333 137.625C235.647 137.625 238.333 140.311 238.333 143.625V148.425C238.333 149.088 237.796 149.625 237.133 149.625H235.58C235.618 149.756 235.634 149.895 235.625 150.039L234.938 160.503C234.897 161.134 234.373 161.625 233.741 161.625H230.926C230.294 161.625 229.77 161.134 229.729 160.503L229.042 150.039C229.033 149.895 229.049 149.756 229.087 149.625H227.533C226.871 149.625 226.333 149.088 226.333 148.425V143.625Z'}
                        fill={'url(#paint119)'}
                    />
                </g>
                <g opacity={calculateOpacity(11)}>
                    <path
                        d={'M248.736 33.875C250.6 33.875 252.111 31.2262 252.111 29.3668C252.111 27.5074 250.6 26 248.736 26C246.872 26 245.361 27.5074 245.361 29.3668C245.361 31.2262 246.872 33.875 248.736 33.875Z'}
                        fill={'url(#paint120)'}
                    />
                    <path
                        d={'M242.5 40.625C242.5 37.3113 245.186 34.625 248.5 34.625C251.814 34.625 254.5 37.3113 254.5 40.625V45.425C254.5 46.0877 253.963 46.625 253.3 46.625H251.746C251.784 46.7555 251.801 46.8949 251.791 47.0389L251.105 57.5035C251.063 58.1344 250.54 58.625 249.907 58.625H247.093C246.46 58.625 245.937 58.1344 245.895 57.5035L245.209 47.0389C245.199 46.8949 245.216 46.7555 245.254 46.625H243.7C243.037 46.625 242.5 46.0877 242.5 45.425V40.625Z'}
                        fill={'url(#paint121)'}
                    />
                </g>
                <g opacity={calculateOpacity(50)}>
                    <path
                        d={'M248.736 70.875C250.6 70.875 252.111 68.2262 252.111 66.3668C252.111 64.5074 250.6 63 248.736 63C246.872 63 245.361 64.5074 245.361 66.3668C245.361 68.2262 246.872 70.875 248.736 70.875Z'}
                        fill={'url(#paint122)'}
                    />
                    <path
                        d={'M242.5 77.625C242.5 74.3113 245.186 71.625 248.5 71.625C251.814 71.625 254.5 74.3113 254.5 77.625V82.425C254.5 83.0877 253.963 83.625 253.3 83.625H251.746C251.784 83.7555 251.801 83.8949 251.791 84.0389L251.105 94.5035C251.063 95.1344 250.54 95.625 249.907 95.625H247.093C246.46 95.625 245.937 95.1344 245.895 94.5035L245.209 84.0389C245.199 83.8949 245.216 83.7555 245.254 83.625H243.7C243.037 83.625 242.5 83.0877 242.5 82.425V77.625Z'}
                        fill={'url(#paint123)'}
                    />
                </g>
                <g opacity={calculateOpacity(62)}>
                    <path
                        d={'M248.736 107.875C250.6 107.875 252.111 105.226 252.111 103.367C252.111 101.507 250.6 100 248.736 100C246.872 100 245.361 101.507 245.361 103.367C245.361 105.226 246.872 107.875 248.736 107.875Z'}
                        fill={'url(#paint124)'}
                    />
                    <path
                        d={'M242.5 114.625C242.5 111.311 245.186 108.625 248.5 108.625C251.814 108.625 254.5 111.311 254.5 114.625V119.425C254.5 120.088 253.963 120.625 253.3 120.625H251.746C251.784 120.756 251.801 120.895 251.791 121.039L251.105 131.503C251.063 132.134 250.54 132.625 249.907 132.625H247.093C246.46 132.625 245.937 132.134 245.895 131.503L245.209 121.039C245.199 120.895 245.216 120.756 245.254 120.625H243.7C243.037 120.625 242.5 120.088 242.5 119.425V114.625Z'}
                        fill={'url(#paint125)'}
                    />
                </g>
                <g opacity={calculateOpacity(16)}>
                    <path
                        d={'M248.736 144.875C250.6 144.875 252.111 142.226 252.111 140.367C252.111 138.507 250.6 137 248.736 137C246.872 137 245.361 138.507 245.361 140.367C245.361 142.226 246.872 144.875 248.736 144.875Z'}
                        fill={'url(#paint126)'}
                    />
                    <path
                        d={'M242.5 151.625C242.5 148.311 245.186 145.625 248.5 145.625C251.814 145.625 254.5 148.311 254.5 151.625V156.425C254.5 157.088 253.963 157.625 253.3 157.625H251.746C251.784 157.756 251.801 157.895 251.791 158.039L251.105 168.503C251.063 169.134 250.54 169.625 249.907 169.625H247.093C246.46 169.625 245.937 169.134 245.895 168.503L245.209 158.039C245.199 157.895 245.216 157.756 245.254 157.625H243.7C243.037 157.625 242.5 157.088 242.5 156.425V151.625Z'}
                        fill={'url(#paint127)'}
                    />
                </g>
                <g opacity={calculateOpacity(87)}>
                    <path
                        d={'M264.903 25.875C266.767 25.875 268.278 23.2262 268.278 21.3668C268.278 19.5074 266.767 18 264.903 18C263.039 18 261.528 19.5074 261.528 21.3668C261.528 23.2262 263.039 25.875 264.903 25.875Z'}
                        fill={'url(#paint128)'}
                    />
                    <path
                        d={'M258.667 32.625C258.667 29.3113 261.353 26.625 264.667 26.625C267.98 26.625 270.667 29.3113 270.667 32.625V37.425C270.667 38.0877 270.129 38.625 269.467 38.625H267.913C267.951 38.7555 267.967 38.8949 267.958 39.0389L267.271 49.5035C267.23 50.1344 266.706 50.625 266.074 50.625H263.259C262.627 50.625 262.103 50.1344 262.062 49.5035L261.375 39.0389C261.366 38.8949 261.382 38.7555 261.42 38.625H259.867C259.204 38.625 258.667 38.0877 258.667 37.425V32.625Z'}
                        fill={'url(#paint129)'}
                    />
                </g>
                <g opacity={calculateOpacity(57)}>
                    <path
                        d={'M264.903 62.875C266.767 62.875 268.278 60.2262 268.278 58.3668C268.278 56.5074 266.767 55 264.903 55C263.039 55 261.528 56.5074 261.528 58.3668C261.528 60.2262 263.039 62.875 264.903 62.875Z'}
                        fill={'url(#paint130)'}
                    />
                    <path
                        d={'M258.667 69.625C258.667 66.3113 261.353 63.625 264.667 63.625C267.98 63.625 270.667 66.3113 270.667 69.625V74.425C270.667 75.0877 270.129 75.625 269.467 75.625H267.913C267.951 75.7555 267.967 75.8949 267.958 76.0389L267.271 86.5035C267.23 87.1344 266.706 87.625 266.074 87.625H263.259C262.627 87.625 262.103 87.1344 262.062 86.5035L261.375 76.0389C261.366 75.8949 261.382 75.7555 261.42 75.625H259.867C259.204 75.625 258.667 75.0877 258.667 74.425V69.625Z'}
                        fill={'url(#paint131)'}
                    />
                </g>
                <g opacity={calculateOpacity(20)}>
                    <path
                        d={'M264.903 99.875C266.767 99.875 268.278 97.2262 268.278 95.3668C268.278 93.5074 266.767 92 264.903 92C263.039 92 261.528 93.5074 261.528 95.3668C261.528 97.2262 263.039 99.875 264.903 99.875Z'}
                        fill={'url(#paint132)'}
                    />
                    <path
                        d={'M258.667 106.625C258.667 103.311 261.353 100.625 264.667 100.625C267.98 100.625 270.667 103.311 270.667 106.625V111.425C270.667 112.088 270.129 112.625 269.467 112.625H267.913C267.951 112.756 267.967 112.895 267.958 113.039L267.271 123.503C267.23 124.134 266.706 124.625 266.074 124.625H263.259C262.627 124.625 262.103 124.134 262.062 123.503L261.375 113.039C261.366 112.895 261.382 112.756 261.42 112.625H259.867C259.204 112.625 258.667 112.088 258.667 111.425V106.625Z'}
                        fill={'url(#paint133)'}
                    />
                </g>
                <g opacity={calculateOpacity(99)}>
                    <path
                        d={'M264.903 136.875C266.767 136.875 268.278 134.226 268.278 132.367C268.278 130.507 266.767 129 264.903 129C263.039 129 261.528 130.507 261.528 132.367C261.528 134.226 263.039 136.875 264.903 136.875Z'}
                        fill={'url(#paint134)'}
                    />
                    <path
                        d={'M258.667 143.625C258.667 140.311 261.353 137.625 264.667 137.625C267.98 137.625 270.667 140.311 270.667 143.625V148.425C270.667 149.088 270.129 149.625 269.467 149.625H267.913C267.951 149.756 267.967 149.895 267.958 150.039L267.271 160.503C267.23 161.134 266.706 161.625 266.074 161.625H263.259C262.627 161.625 262.103 161.134 262.062 160.503L261.375 150.039C261.366 149.895 261.382 149.756 261.42 149.625H259.867C259.204 149.625 258.667 149.088 258.667 148.425V143.625Z'}
                        fill={'url(#paint135)'}
                    />
                </g>
                <g opacity={calculateOpacity(48)}>
                    <path
                        d={'M281.07 33.875C282.934 33.875 284.445 31.2262 284.445 29.3668C284.445 27.5074 282.934 26 281.07 26C279.206 26 277.695 27.5074 277.695 29.3668C277.695 31.2262 279.206 33.875 281.07 33.875Z'}
                        fill={'url(#paint136)'}
                    />
                    <path
                        d={'M274.833 40.625C274.833 37.3113 277.52 34.625 280.833 34.625C284.147 34.625 286.833 37.3113 286.833 40.625V45.425C286.833 46.0877 286.296 46.625 285.633 46.625H284.08C284.118 46.7555 284.134 46.8949 284.125 47.0389L283.438 57.5035C283.397 58.1344 282.873 58.625 282.241 58.625H279.426C278.794 58.625 278.27 58.1344 278.229 57.5035L277.542 47.0389C277.533 46.8949 277.549 46.7555 277.587 46.625H276.033C275.371 46.625 274.833 46.0877 274.833 45.425V40.625Z'}
                        fill={'url(#paint137)'}
                    />
                </g>
                <g opacity={calculateOpacity(2)}>
                    <path
                        d={'M281.07 70.875C282.934 70.875 284.445 68.2262 284.445 66.3668C284.445 64.5074 282.934 63 281.07 63C279.206 63 277.695 64.5074 277.695 66.3668C277.695 68.2262 279.206 70.875 281.07 70.875Z'}
                        fill={'url(#paint138)'}
                    />
                    <path
                        d={'M274.833 77.625C274.833 74.3113 277.52 71.625 280.833 71.625C284.147 71.625 286.833 74.3113 286.833 77.625V82.425C286.833 83.0877 286.296 83.625 285.633 83.625H284.08C284.118 83.7555 284.134 83.8949 284.125 84.0389L283.438 94.5035C283.397 95.1344 282.873 95.625 282.241 95.625H279.426C278.794 95.625 278.27 95.1344 278.229 94.5035L277.542 84.0389C277.533 83.8949 277.549 83.7555 277.587 83.625H276.033C275.371 83.625 274.833 83.0877 274.833 82.425V77.625Z'}
                        fill={'url(#paint139)'}
                    />
                </g>
                <g opacity={calculateOpacity(67)}>
                    <path
                        d={'M281.07 107.875C282.934 107.875 284.445 105.226 284.445 103.367C284.445 101.507 282.934 100 281.07 100C279.206 100 277.695 101.507 277.695 103.367C277.695 105.226 279.206 107.875 281.07 107.875Z'}
                        fill={'url(#paint140)'}
                    />
                    <path
                        d={'M274.833 114.625C274.833 111.311 277.52 108.625 280.833 108.625C284.147 108.625 286.833 111.311 286.833 114.625V119.425C286.833 120.088 286.296 120.625 285.633 120.625H284.08C284.118 120.756 284.134 120.895 284.125 121.039L283.438 131.503C283.397 132.134 282.873 132.625 282.241 132.625H279.426C278.794 132.625 278.27 132.134 278.229 131.503L277.542 121.039C277.533 120.895 277.549 120.756 277.587 120.625H276.033C275.371 120.625 274.833 120.088 274.833 119.425V114.625Z'}
                        fill={'url(#paint141)'}
                    />
                </g>
                <g opacity={calculateOpacity(42)}>
                    <path
                        d={'M281.07 144.875C282.934 144.875 284.445 142.226 284.445 140.367C284.445 138.507 282.934 137 281.07 137C279.206 137 277.695 138.507 277.695 140.367C277.695 142.226 279.206 144.875 281.07 144.875Z'}
                        fill={'url(#paint142)'}
                    />
                    <path
                        d={'M274.833 151.625C274.833 148.311 277.52 145.625 280.833 145.625C284.147 145.625 286.833 148.311 286.833 151.625V156.425C286.833 157.088 286.296 157.625 285.633 157.625H284.08C284.118 157.756 284.134 157.895 284.125 158.039L283.438 168.503C283.397 169.134 282.873 169.625 282.241 169.625H279.426C278.794 169.625 278.27 169.134 278.229 168.503L277.542 158.039C277.533 157.895 277.549 157.756 277.587 157.625H276.033C275.371 157.625 274.833 157.088 274.833 156.425V151.625Z'}
                        fill={'url(#paint143)'}
                    />
                </g>
                <g opacity={calculateOpacity(22)}>
                    <path
                        d={'M297.236 25.875C299.1 25.875 300.611 23.2262 300.611 21.3668C300.611 19.5074 299.1 18 297.236 18C295.372 18 293.861 19.5074 293.861 21.3668C293.861 23.2262 295.372 25.875 297.236 25.875Z'}
                        fill={'url(#paint144)'}
                    />
                    <path
                        d={'M291 32.625C291 29.3113 293.686 26.625 297 26.625C300.314 26.625 303 29.3113 303 32.625V37.425C303 38.0877 302.463 38.625 301.8 38.625H300.246C300.284 38.7555 300.301 38.8949 300.291 39.0389L299.605 49.5035C299.563 50.1344 299.04 50.625 298.407 50.625H295.593C294.96 50.625 294.437 50.1344 294.395 49.5035L293.709 39.0389C293.699 38.8949 293.716 38.7555 293.754 38.625H292.2C291.537 38.625 291 38.0877 291 37.425V32.625Z'}
                        fill={'url(#paint145)'}
                    />
                </g>
                <g opacity={calculateOpacity(36)}>
                    <path
                        d={'M297.236 62.875C299.1 62.875 300.611 60.2262 300.611 58.3668C300.611 56.5074 299.1 55 297.236 55C295.372 55 293.861 56.5074 293.861 58.3668C293.861 60.2262 295.372 62.875 297.236 62.875Z'}
                        fill={'url(#paint146)'}
                    />
                    <path
                        d={'M291 69.625C291 66.3113 293.686 63.625 297 63.625C300.314 63.625 303 66.3113 303 69.625V74.425C303 75.0877 302.463 75.625 301.8 75.625H300.246C300.284 75.7555 300.301 75.8949 300.291 76.0389L299.605 86.5035C299.563 87.1344 299.04 87.625 298.407 87.625H295.593C294.96 87.625 294.437 87.1344 294.395 86.5035L293.709 76.0389C293.699 75.8949 293.716 75.7555 293.754 75.625H292.2C291.537 75.625 291 75.0877 291 74.425V69.625Z'}
                        fill={'url(#paint147)'}
                    />
                </g>
                <g opacity={calculateOpacity(85)}>
                    <path
                        d={'M297.236 99.875C299.1 99.875 300.611 97.2262 300.611 95.3668C300.611 93.5074 299.1 92 297.236 92C295.372 92 293.861 93.5074 293.861 95.3668C293.861 97.2262 295.372 99.875 297.236 99.875Z'}
                        fill={'url(#paint148)'}
                    />
                    <path
                        d={'M291 106.625C291 103.311 293.686 100.625 297 100.625C300.314 100.625 303 103.311 303 106.625V111.425C303 112.088 302.463 112.625 301.8 112.625H300.246C300.284 112.756 300.301 112.895 300.291 113.039L299.605 123.503C299.563 124.134 299.04 124.625 298.407 124.625H295.593C294.96 124.625 294.437 124.134 294.395 123.503L293.709 113.039C293.699 112.895 293.716 112.756 293.754 112.625H292.2C291.537 112.625 291 112.088 291 111.425V106.625Z'}
                        fill={'url(#paint149)'}
                    />
                </g>
                <g opacity={calculateOpacity(54)}>
                    <path
                        d={'M297.236 136.875C299.1 136.875 300.611 134.226 300.611 132.367C300.611 130.507 299.1 129 297.236 129C295.372 129 293.861 130.507 293.861 132.367C293.861 134.226 295.372 136.875 297.236 136.875Z'}
                        fill={'url(#paint150)'}
                    />
                    <path
                        d={'M291 143.625C291 140.311 293.686 137.625 297 137.625C300.314 137.625 303 140.311 303 143.625V148.425C303 149.088 302.463 149.625 301.8 149.625H300.246C300.284 149.756 300.301 149.895 300.291 150.039L299.605 160.503C299.563 161.134 299.04 161.625 298.407 161.625H295.593C294.96 161.625 294.437 161.134 294.395 160.503L293.709 150.039C293.699 149.895 293.716 149.756 293.754 149.625H292.2C291.537 149.625 291 149.088 291 148.425V143.625Z'}
                        fill={'url(#paint151)'}
                    />
                </g>
                <g opacity={calculateOpacity(69)}>
                    <path
                        d={'M313.403 33.875C315.267 33.875 316.778 31.2262 316.778 29.3668C316.778 27.5074 315.267 26 313.403 26C311.539 26 310.028 27.5074 310.028 29.3668C310.028 31.2262 311.539 33.875 313.403 33.875Z'}
                        fill={'url(#paint152)'}
                    />
                    <path
                        d={'M307.167 40.625C307.167 37.3113 309.853 34.625 313.167 34.625C316.48 34.625 319.167 37.3113 319.167 40.625V45.425C319.167 46.0877 318.629 46.625 317.967 46.625H316.413C316.451 46.7555 316.467 46.8949 316.458 47.0389L315.771 57.5035C315.73 58.1344 315.206 58.625 314.574 58.625H311.759C311.127 58.625 310.603 58.1344 310.562 57.5035L309.875 47.0389C309.866 46.8949 309.882 46.7555 309.92 46.625H308.367C307.704 46.625 307.167 46.0877 307.167 45.425V40.625Z'}
                        fill={'url(#paint153)'}
                    />
                </g>
                <g opacity={calculateOpacity(96)}>
                    <path
                        d={'M313.403 70.875C315.267 70.875 316.778 68.2262 316.778 66.3668C316.778 64.5074 315.267 63 313.403 63C311.539 63 310.028 64.5074 310.028 66.3668C310.028 68.2262 311.539 70.875 313.403 70.875Z'}
                        fill={'url(#paint154)'}
                    />
                    <path
                        d={'M307.167 77.625C307.167 74.3113 309.853 71.625 313.167 71.625C316.48 71.625 319.167 74.3113 319.167 77.625V82.425C319.167 83.0877 318.629 83.625 317.967 83.625H316.413C316.451 83.7555 316.467 83.8949 316.458 84.0389L315.771 94.5035C315.73 95.1344 315.206 95.625 314.574 95.625H311.759C311.127 95.625 310.603 95.1344 310.562 94.5035L309.875 84.0389C309.866 83.8949 309.882 83.7555 309.92 83.625H308.367C307.704 83.625 307.167 83.0877 307.167 82.425V77.625Z'}
                        fill={'url(#paint155)'}
                    />
                </g>
                <g opacity={calculateOpacity(8)}>
                    <path
                        d={'M313.403 107.875C315.267 107.875 316.778 105.226 316.778 103.367C316.778 101.507 315.267 100 313.403 100C311.539 100 310.028 101.507 310.028 103.367C310.028 105.226 311.539 107.875 313.403 107.875Z'}
                        fill={'url(#paint156)'}
                    />
                    <path
                        d={'M307.167 114.625C307.167 111.311 309.853 108.625 313.167 108.625C316.48 108.625 319.167 111.311 319.167 114.625V119.425C319.167 120.088 318.629 120.625 317.967 120.625H316.413C316.451 120.756 316.467 120.895 316.458 121.039L315.771 131.503C315.73 132.134 315.206 132.625 314.574 132.625H311.759C311.127 132.625 310.603 132.134 310.562 131.503L309.875 121.039C309.866 120.895 309.882 120.756 309.92 120.625H308.367C307.704 120.625 307.167 120.088 307.167 119.425V114.625Z'}
                        fill={'url(#paint157)'}
                    />
                </g>
                <g opacity={calculateOpacity(75)}>
                    <path
                        d={'M313.403 144.875C315.267 144.875 316.778 142.226 316.778 140.367C316.778 138.507 315.267 137 313.403 137C311.539 137 310.028 138.507 310.028 140.367C310.028 142.226 311.539 144.875 313.403 144.875Z'}
                        fill={'url(#paint158)'}
                    />
                    <path
                        d={'M307.167 151.625C307.167 148.311 309.853 145.625 313.167 145.625C316.48 145.625 319.167 148.311 319.167 151.625V156.425C319.167 157.088 318.629 157.625 317.967 157.625H316.413C316.451 157.756 316.467 157.895 316.458 158.039L315.771 168.503C315.73 169.134 315.206 169.625 314.574 169.625H311.759C311.127 169.625 310.603 169.134 310.562 168.503L309.875 158.039C309.866 157.895 309.882 157.756 309.92 157.625H308.367C307.704 157.625 307.167 157.088 307.167 156.425V151.625Z'}
                        fill={'url(#paint159)'}
                    />
                </g>
                <g opacity={calculateOpacity(97)}>
                    <path
                        d={'M329.57 25.875C331.434 25.875 332.945 23.2262 332.945 21.3668C332.945 19.5074 331.434 18 329.57 18C327.706 18 326.195 19.5074 326.195 21.3668C326.195 23.2262 327.706 25.875 329.57 25.875Z'}
                        fill={'url(#paint160)'}
                    />
                    <path
                        d={'M323.333 32.625C323.333 29.3113 326.02 26.625 329.333 26.625C332.647 26.625 335.333 29.3113 335.333 32.625V37.425C335.333 38.0877 334.796 38.625 334.133 38.625H332.58C332.618 38.7555 332.634 38.8949 332.625 39.0389L331.938 49.5035C331.897 50.1344 331.373 50.625 330.741 50.625H327.926C327.294 50.625 326.77 50.1344 326.729 49.5035L326.042 39.0389C326.033 38.8949 326.049 38.7555 326.087 38.625H324.533C323.871 38.625 323.333 38.0877 323.333 37.425V32.625Z'}
                        fill={'url(#paint161)'}
                    />
                </g>
                <g opacity={calculateOpacity(25)}>
                    <path
                        d={'M329.57 62.875C331.434 62.875 332.945 60.2262 332.945 58.3668C332.945 56.5074 331.434 55 329.57 55C327.706 55 326.195 56.5074 326.195 58.3668C326.195 60.2262 327.706 62.875 329.57 62.875Z'}
                        fill={'url(#paint162)'}
                    />
                    <path
                        d={'M323.333 69.625C323.333 66.3113 326.02 63.625 329.333 63.625C332.647 63.625 335.333 66.3113 335.333 69.625V74.425C335.333 75.0877 334.796 75.625 334.133 75.625H332.58C332.618 75.7555 332.634 75.8949 332.625 76.0389L331.938 86.5035C331.897 87.1344 331.373 87.625 330.741 87.625H327.926C327.294 87.625 326.77 87.1344 326.729 86.5035L326.042 76.0389C326.033 75.8949 326.049 75.7555 326.087 75.625H324.533C323.871 75.625 323.333 75.0877 323.333 74.425V69.625Z'}
                        fill={'url(#paint163)'}
                    />
                </g>
                <g opacity={calculateOpacity(93)}>
                    <path
                        d={'M329.57 99.875C331.434 99.875 332.945 97.2262 332.945 95.3668C332.945 93.5074 331.434 92 329.57 92C327.706 92 326.195 93.5074 326.195 95.3668C326.195 97.2262 327.706 99.875 329.57 99.875Z'}
                        fill={'url(#paint164)'}
                    />
                    <path
                        d={'M323.333 106.625C323.333 103.311 326.02 100.625 329.333 100.625C332.647 100.625 335.333 103.311 335.333 106.625V111.425C335.333 112.088 334.796 112.625 334.133 112.625H332.58C332.618 112.756 332.634 112.895 332.625 113.039L331.938 123.503C331.897 124.134 331.373 124.625 330.741 124.625H327.926C327.294 124.625 326.77 124.134 326.729 123.503L326.042 113.039C326.033 112.895 326.049 112.756 326.087 112.625H324.533C323.871 112.625 323.333 112.088 323.333 111.425V106.625Z'}
                        fill={'url(#paint165)'}
                    />
                </g>
                <g opacity={calculateOpacity(21)}>
                    <path
                        d={'M329.57 136.875C331.434 136.875 332.945 134.226 332.945 132.367C332.945 130.507 331.434 129 329.57 129C327.706 129 326.195 130.507 326.195 132.367C326.195 134.226 327.706 136.875 329.57 136.875Z'}
                        fill={'url(#paint166)'}
                    />
                    <path
                        d={'M323.333 143.625C323.333 140.311 326.02 137.625 329.333 137.625C332.647 137.625 335.333 140.311 335.333 143.625V148.425C335.333 149.088 334.796 149.625 334.133 149.625H332.58C332.618 149.756 332.634 149.895 332.625 150.039L331.938 160.503C331.897 161.134 331.373 161.625 330.741 161.625H327.926C327.294 161.625 326.77 161.134 326.729 160.503L326.042 150.039C326.033 149.895 326.049 149.756 326.087 149.625H324.533C323.871 149.625 323.333 149.088 323.333 148.425V143.625Z'}
                        fill={'url(#paint167)'}
                    />
                </g>
                <g opacity={calculateOpacity(7)}>
                    <path
                        d={'M345.736 33.875C347.6 33.875 349.111 31.2262 349.111 29.3668C349.111 27.5074 347.6 26 345.736 26C343.872 26 342.361 27.5074 342.361 29.3668C342.361 31.2262 343.872 33.875 345.736 33.875Z'}
                        fill={'url(#paint168)'}
                    />
                    <path
                        d={'M339.5 40.625C339.5 37.3113 342.186 34.625 345.5 34.625C348.814 34.625 351.5 37.3113 351.5 40.625V45.425C351.5 46.0877 350.963 46.625 350.3 46.625H348.746C348.784 46.7555 348.801 46.8949 348.791 47.0389L348.105 57.5035C348.063 58.1344 347.54 58.625 346.907 58.625H344.093C343.46 58.625 342.937 58.1344 342.895 57.5035L342.209 47.0389C342.199 46.8949 342.216 46.7555 342.254 46.625H340.7C340.037 46.625 339.5 46.0877 339.5 45.425V40.625Z'}
                        fill={'url(#paint169)'}
                    />
                </g>
                <g opacity={calculateOpacity(51)}>
                    <path
                        d={'M345.736 70.875C347.6 70.875 349.111 68.2262 349.111 66.3668C349.111 64.5074 347.6 63 345.736 63C343.872 63 342.361 64.5074 342.361 66.3668C342.361 68.2262 343.872 70.875 345.736 70.875Z'}
                        fill={'url(#paint170)'}
                    />
                    <path
                        d={'M339.5 77.625C339.5 74.3113 342.186 71.625 345.5 71.625C348.814 71.625 351.5 74.3113 351.5 77.625V82.425C351.5 83.0877 350.963 83.625 350.3 83.625H348.746C348.784 83.7555 348.801 83.8949 348.791 84.0389L348.105 94.5035C348.063 95.1344 347.54 95.625 346.907 95.625H344.093C343.46 95.625 342.937 95.1344 342.895 94.5035L342.209 84.0389C342.199 83.8949 342.216 83.7555 342.254 83.625H340.7C340.037 83.625 339.5 83.0877 339.5 82.425V77.625Z'}
                        fill={'url(#paint171)'}
                    />
                </g>
                <g opacity={calculateOpacity(47)}>
                    <path
                        d={'M345.736 107.875C347.6 107.875 349.111 105.226 349.111 103.367C349.111 101.507 347.6 100 345.736 100C343.872 100 342.361 101.507 342.361 103.367C342.361 105.226 343.872 107.875 345.736 107.875Z'}
                        fill={'url(#paint172)'}
                    />
                    <path
                        d={'M339.5 114.625C339.5 111.311 342.186 108.625 345.5 108.625C348.814 108.625 351.5 111.311 351.5 114.625V119.425C351.5 120.088 350.963 120.625 350.3 120.625H348.746C348.784 120.756 348.801 120.895 348.791 121.039L348.105 131.503C348.063 132.134 347.54 132.625 346.907 132.625H344.093C343.46 132.625 342.937 132.134 342.895 131.503L342.209 121.039C342.199 120.895 342.216 120.756 342.254 120.625H340.7C340.037 120.625 339.5 120.088 339.5 119.425V114.625Z'}
                        fill={'url(#paint173)'}
                    />
                </g>
                <g opacity={calculateOpacity(65)}>
                    <path
                        d={'M345.736 144.875C347.6 144.875 349.111 142.226 349.111 140.367C349.111 138.507 347.6 137 345.736 137C343.872 137 342.361 138.507 342.361 140.367C342.361 142.226 343.872 144.875 345.736 144.875Z'}
                        fill={'url(#paint174)'}
                    />
                    <path
                        d={'M339.5 151.625C339.5 148.311 342.186 145.625 345.5 145.625C348.814 145.625 351.5 148.311 351.5 151.625V156.425C351.5 157.088 350.963 157.625 350.3 157.625H348.746C348.784 157.756 348.801 157.895 348.791 158.039L348.105 168.503C348.063 169.134 347.54 169.625 346.907 169.625H344.093C343.46 169.625 342.937 169.134 342.895 168.503L342.209 158.039C342.199 157.895 342.216 157.756 342.254 157.625H340.7C340.037 157.625 339.5 157.088 339.5 156.425V151.625Z'}
                        fill={'url(#paint175)'}
                    />
                </g>
                <g opacity={calculateOpacity(92)}>
                    <path
                        d={'M361.903 25.875C363.767 25.875 365.278 23.2262 365.278 21.3668C365.278 19.5074 363.767 18 361.903 18C360.039 18 358.528 19.5074 358.528 21.3668C358.528 23.2262 360.039 25.875 361.903 25.875Z'}
                        fill={'url(#paint176)'}
                    />
                    <path
                        d={'M355.667 32.625C355.667 29.3113 358.353 26.625 361.667 26.625C364.98 26.625 367.667 29.3113 367.667 32.625V37.425C367.667 38.0877 367.129 38.625 366.467 38.625H364.913C364.951 38.7555 364.967 38.8949 364.958 39.0389L364.271 49.5035C364.23 50.1344 363.706 50.625 363.074 50.625H360.259C359.627 50.625 359.103 50.1344 359.062 49.5035L358.375 39.0389C358.366 38.8949 358.382 38.7555 358.42 38.625H356.867C356.204 38.625 355.667 38.0877 355.667 37.425V32.625Z'}
                        fill={'url(#paint177)'}
                    />
                </g>
                <g opacity={calculateOpacity(77)}>
                    <path
                        d={'M361.903 62.875C363.767 62.875 365.278 60.2262 365.278 58.3668C365.278 56.5074 363.767 55 361.903 55C360.039 55 358.528 56.5074 358.528 58.3668C358.528 60.2262 360.039 62.875 361.903 62.875Z'}
                        fill={'url(#paint178)'}
                    />
                    <path
                        d={'M355.667 69.625C355.667 66.3113 358.353 63.625 361.667 63.625C364.98 63.625 367.667 66.3113 367.667 69.625V74.425C367.667 75.0877 367.129 75.625 366.467 75.625H364.913C364.951 75.7555 364.967 75.8949 364.958 76.0389L364.271 86.5035C364.23 87.1344 363.706 87.625 363.074 87.625H360.259C359.627 87.625 359.103 87.1344 359.062 86.5035L358.375 76.0389C358.366 75.8949 358.382 75.7555 358.42 75.625H356.867C356.204 75.625 355.667 75.0877 355.667 74.425V69.625Z'}
                        fill={'url(#paint179)'}
                    />
                </g>
                <g opacity={calculateOpacity(37)}>
                    <path
                        d={'M361.903 99.875C363.767 99.875 365.278 97.2262 365.278 95.3668C365.278 93.5074 363.767 92 361.903 92C360.039 92 358.528 93.5074 358.528 95.3668C358.528 97.2262 360.039 99.875 361.903 99.875Z'}
                        fill={'url(#paint180)'}
                    />
                    <path
                        d={'M355.667 106.625C355.667 103.311 358.353 100.625 361.667 100.625C364.98 100.625 367.667 103.311 367.667 106.625V111.425C367.667 112.088 367.129 112.625 366.467 112.625H364.913C364.951 112.756 364.967 112.895 364.958 113.039L364.271 123.503C364.23 124.134 363.706 124.625 363.074 124.625H360.259C359.627 124.625 359.103 124.134 359.062 123.503L358.375 113.039C358.366 112.895 358.382 112.756 358.42 112.625H356.867C356.204 112.625 355.667 112.088 355.667 111.425V106.625Z'}
                        fill={'url(#paint181)'}
                    />
                </g>
                <g opacity={calculateOpacity(4)}>
                    <path
                        d={'M361.903 136.875C363.767 136.875 365.278 134.226 365.278 132.367C365.278 130.507 363.767 129 361.903 129C360.039 129 358.528 130.507 358.528 132.367C358.528 134.226 360.039 136.875 361.903 136.875Z'}
                        fill={'url(#paint182)'}
                    />
                    <path
                        d={'M355.667 143.625C355.667 140.311 358.353 137.625 361.667 137.625C364.98 137.625 367.667 140.311 367.667 143.625V148.425C367.667 149.088 367.129 149.625 366.467 149.625H364.913C364.951 149.756 364.967 149.895 364.958 150.039L364.271 160.503C364.23 161.134 363.706 161.625 363.074 161.625H360.259C359.627 161.625 359.103 161.134 359.062 160.503L358.375 150.039C358.366 149.895 358.382 149.756 358.42 149.625H356.867C356.204 149.625 355.667 149.088 355.667 148.425V143.625Z'}
                        fill={'url(#paint183)'}
                    />
                </g>
                <g opacity={calculateOpacity(56)}>
                    <path
                        d={'M378.07 33.875C379.934 33.875 381.445 31.2262 381.445 29.3668C381.445 27.5074 379.934 26 378.07 26C376.206 26 374.695 27.5074 374.695 29.3668C374.695 31.2262 376.206 33.875 378.07 33.875Z'}
                        fill={'url(#paint184)'}
                    />
                    <path
                        d={'M371.833 40.625C371.833 37.3113 374.52 34.625 377.833 34.625C381.147 34.625 383.833 37.3113 383.833 40.625V45.425C383.833 46.0877 383.296 46.625 382.633 46.625H381.08C381.118 46.7555 381.134 46.8949 381.125 47.0389L380.438 57.5035C380.397 58.1344 379.873 58.625 379.241 58.625H376.426C375.794 58.625 375.27 58.1344 375.229 57.5035L374.542 47.0389C374.533 46.8949 374.549 46.7555 374.587 46.625H373.033C372.371 46.625 371.833 46.0877 371.833 45.425V40.625Z'}
                        fill={'url(#paint185)'}
                    />
                </g>
                <g opacity={calculateOpacity(14)}>
                    <path
                        d={'M378.07 70.875C379.934 70.875 381.445 68.2262 381.445 66.3668C381.445 64.5074 379.934 63 378.07 63C376.206 63 374.695 64.5074 374.695 66.3668C374.695 68.2262 376.206 70.875 378.07 70.875Z'}
                        fill={'url(#paint186)'}
                    />
                    <path
                        d={'M371.833 77.625C371.833 74.3113 374.52 71.625 377.833 71.625C381.147 71.625 383.833 74.3113 383.833 77.625V82.425C383.833 83.0877 383.296 83.625 382.633 83.625H381.08C381.118 83.7555 381.134 83.8949 381.125 84.0389L380.438 94.5035C380.397 95.1344 379.873 95.625 379.241 95.625H376.426C375.794 95.625 375.27 95.1344 375.229 94.5035L374.542 84.0389C374.533 83.8949 374.549 83.7555 374.587 83.625H373.033C372.371 83.625 371.833 83.0877 371.833 82.425V77.625Z'}
                        fill={'url(#paint187)'}
                    />
                </g>
                <g opacity={calculateOpacity(32)}>
                    <path
                        d={'M378.07 107.875C379.934 107.875 381.445 105.226 381.445 103.367C381.445 101.507 379.934 100 378.07 100C376.206 100 374.695 101.507 374.695 103.367C374.695 105.226 376.206 107.875 378.07 107.875Z'}
                        fill={'url(#paint188)'}
                    />
                    <path
                        d={'M371.833 114.625C371.833 111.311 374.52 108.625 377.833 108.625C381.147 108.625 383.833 111.311 383.833 114.625V119.425C383.833 120.088 383.296 120.625 382.633 120.625H381.08C381.118 120.756 381.134 120.895 381.125 121.039L380.438 131.503C380.397 132.134 379.873 132.625 379.241 132.625H376.426C375.794 132.625 375.27 132.134 375.229 131.503L374.542 121.039C374.533 120.895 374.549 120.756 374.587 120.625H373.033C372.371 120.625 371.833 120.088 371.833 119.425V114.625Z'}
                        fill={'url(#paint189)'}
                    />
                </g>
                <g opacity={calculateOpacity(82)}>
                    <path
                        d={'M378.07 144.875C379.934 144.875 381.445 142.226 381.445 140.367C381.445 138.507 379.934 137 378.07 137C376.206 137 374.695 138.507 374.695 140.367C374.695 142.226 376.206 144.875 378.07 144.875Z'}
                        fill={'url(#paint190)'}
                    />
                    <path
                        d={'M371.833 151.625C371.833 148.311 374.52 145.625 377.833 145.625C381.147 145.625 383.833 148.311 383.833 151.625V156.425C383.833 157.088 383.296 157.625 382.633 157.625H381.08C381.118 157.756 381.134 157.895 381.125 158.039L380.438 168.503C380.397 169.134 379.873 169.625 379.241 169.625H376.426C375.794 169.625 375.27 169.134 375.229 168.503L374.542 158.039C374.533 157.895 374.549 157.756 374.587 157.625H373.033C372.371 157.625 371.833 157.088 371.833 156.425V151.625Z'}
                        fill={'url(#paint191)'}
                    />
                </g>
                <g opacity={calculateOpacity(30)}>
                    <path
                        d={'M394.236 25.875C396.1 25.875 397.611 23.2262 397.611 21.3668C397.611 19.5074 396.1 18 394.236 18C392.372 18 390.861 19.5074 390.861 21.3668C390.861 23.2262 392.372 25.875 394.236 25.875Z'}
                        fill={'url(#paint192)'}
                    />
                    <path
                        d={'M388 32.625C388 29.3113 390.686 26.625 394 26.625C397.314 26.625 400 29.3113 400 32.625V37.425C400 38.0877 399.463 38.625 398.8 38.625H397.246C397.284 38.7555 397.301 38.8949 397.291 39.0389L396.605 49.5035C396.563 50.1344 396.04 50.625 395.407 50.625H392.593C391.96 50.625 391.437 50.1344 391.395 49.5035L390.709 39.0389C390.699 38.8949 390.716 38.7555 390.754 38.625H389.2C388.537 38.625 388 38.0877 388 37.425V32.625Z'}
                        fill={'url(#paint193)'}
                    />
                </g>
                <g opacity={calculateOpacity(68)}>
                    <path
                        d={'M394.236 62.875C396.1 62.875 397.611 60.2262 397.611 58.3668C397.611 56.5074 396.1 55 394.236 55C392.372 55 390.861 56.5074 390.861 58.3668C390.861 60.2262 392.372 62.875 394.236 62.875Z'}
                        fill={'url(#paint194)'}
                    />
                    <path
                        d={'M388 69.625C388 66.3113 390.686 63.625 394 63.625C397.314 63.625 400 66.3113 400 69.625V74.425C400 75.0877 399.463 75.625 398.8 75.625H397.246C397.284 75.7555 397.301 75.8949 397.291 76.0389L396.605 86.5035C396.563 87.1344 396.04 87.625 395.407 87.625H392.593C391.96 87.625 391.437 87.1344 391.395 86.5035L390.709 76.0389C390.699 75.8949 390.716 75.7555 390.754 75.625H389.2C388.537 75.625 388 75.0877 388 74.425V69.625Z'}
                        fill={'url(#paint195)'}
                    />
                </g>
                <g opacity={calculateOpacity(64)}>
                    <path
                        d={'M394.236 99.875C396.1 99.875 397.611 97.2262 397.611 95.3668C397.611 93.5074 396.1 92 394.236 92C392.372 92 390.861 93.5074 390.861 95.3668C390.861 97.2262 392.372 99.875 394.236 99.875Z'}
                        fill={'url(#paint196)'}
                    />
                    <path
                        d={'M388 106.625C388 103.311 390.686 100.625 394 100.625C397.314 100.625 400 103.311 400 106.625V111.425C400 112.088 399.463 112.625 398.8 112.625H397.246C397.284 112.756 397.301 112.895 397.291 113.039L396.605 123.503C396.563 124.134 396.04 124.625 395.407 124.625H392.593C391.96 124.625 391.437 124.134 391.395 123.503L390.709 113.039C390.699 112.895 390.716 112.756 390.754 112.625H389.2C388.537 112.625 388 112.088 388 111.425V106.625Z'}
                        fill={'url(#paint197)'}
                    />
                </g>
                <g opacity={calculateOpacity(27)}>
                    <path
                        d={'M394.236 136.875C396.1 136.875 397.611 134.226 397.611 132.367C397.611 130.507 396.1 129 394.236 129C392.372 129 390.861 130.507 390.861 132.367C390.861 134.226 392.372 136.875 394.236 136.875Z'}
                        fill={'url(#paint198)'}
                    />
                    <path
                        d={'M388 143.625C388 140.311 390.686 137.625 394 137.625C397.314 137.625 400 140.311 400 143.625V148.425C400 149.088 399.463 149.625 398.8 149.625H397.246C397.284 149.756 397.301 149.895 397.291 150.039L396.605 160.503C396.563 161.134 396.04 161.625 395.407 161.625H392.593C391.96 161.625 391.437 161.134 391.395 160.503L390.709 150.039C390.699 149.895 390.716 149.756 390.754 149.625H389.2C388.537 149.625 388 149.088 388 148.425V143.625Z'}
                        fill={'url(#paint199)'}
                    />
                </g>
                <defs>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint0'}
                        x1={'-0.899999'}
                        x2={'36.7759'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint1'}
                        x1={'-0.899999'}
                        x2={'36.7759'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint2'}
                        x1={'-0.899999'}
                        x2={'36.7759'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint3'}
                        x1={'-0.899999'}
                        x2={'36.7759'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint4'}
                        x1={'-0.899999'}
                        x2={'36.7759'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint5'}
                        x1={'-0.899999'}
                        x2={'36.7759'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint6'}
                        x1={'-0.899999'}
                        x2={'36.7759'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint7'}
                        x1={'-0.899999'}
                        x2={'36.7759'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint8'}
                        x1={'15.2665'}
                        x2={'52.9424'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint9'}
                        x1={'15.2665'}
                        x2={'52.9424'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint10'}
                        x1={'15.2665'}
                        x2={'52.9424'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint11'}
                        x1={'15.2665'}
                        x2={'52.9424'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint12'}
                        x1={'15.2665'}
                        x2={'52.9424'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint13'}
                        x1={'15.2665'}
                        x2={'52.9424'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint14'}
                        x1={'15.2665'}
                        x2={'52.9424'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint15'}
                        x1={'15.2665'}
                        x2={'52.9424'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint16'}
                        x1={'31.4335'}
                        x2={'69.1094'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint17'}
                        x1={'31.4335'}
                        x2={'69.1094'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint18'}
                        x1={'31.4335'}
                        x2={'69.1094'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint19'}
                        x1={'31.4335'}
                        x2={'69.1094'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint20'}
                        x1={'31.4335'}
                        x2={'69.1094'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint21'}
                        x1={'31.4335'}
                        x2={'69.1094'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint22'}
                        x1={'31.4335'}
                        x2={'69.1094'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint23'}
                        x1={'31.4335'}
                        x2={'69.1094'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint24'}
                        x1={'47.6'}
                        x2={'85.2759'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint25'}
                        x1={'47.6'}
                        x2={'85.2759'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint26'}
                        x1={'47.6'}
                        x2={'85.2759'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint27'}
                        x1={'47.6'}
                        x2={'85.2759'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint28'}
                        x1={'47.6'}
                        x2={'85.2759'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint29'}
                        x1={'47.6'}
                        x2={'85.2759'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint30'}
                        x1={'47.6'}
                        x2={'85.2759'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint31'}
                        x1={'47.6'}
                        x2={'85.2759'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint32'}
                        x1={'63.7665'}
                        x2={'101.442'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint33'}
                        x1={'63.7665'}
                        x2={'101.442'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint34'}
                        x1={'63.7665'}
                        x2={'101.442'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint35'}
                        x1={'63.7665'}
                        x2={'101.442'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint36'}
                        x1={'63.7665'}
                        x2={'101.442'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint37'}
                        x1={'63.7665'}
                        x2={'101.442'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint38'}
                        x1={'63.7665'}
                        x2={'101.442'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint39'}
                        x1={'63.7665'}
                        x2={'101.442'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint40'}
                        x1={'79.9335'}
                        x2={'117.609'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint41'}
                        x1={'79.9335'}
                        x2={'117.609'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint42'}
                        x1={'79.9335'}
                        x2={'117.609'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint43'}
                        x1={'79.9335'}
                        x2={'117.609'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint44'}
                        x1={'79.9335'}
                        x2={'117.609'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint45'}
                        x1={'79.9335'}
                        x2={'117.609'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint46'}
                        x1={'79.9335'}
                        x2={'117.609'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint47'}
                        x1={'79.9335'}
                        x2={'117.609'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint48'}
                        x1={'96.1'}
                        x2={'133.776'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint49'}
                        x1={'96.1'}
                        x2={'133.776'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint50'}
                        x1={'96.1'}
                        x2={'133.776'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint51'}
                        x1={'96.1'}
                        x2={'133.776'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint52'}
                        x1={'96.1'}
                        x2={'133.776'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint53'}
                        x1={'96.1'}
                        x2={'133.776'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint54'}
                        x1={'96.1'}
                        x2={'133.776'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint55'}
                        x1={'96.1'}
                        x2={'133.776'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint56'}
                        x1={'112.267'}
                        x2={'149.942'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint57'}
                        x1={'112.267'}
                        x2={'149.942'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint58'}
                        x1={'112.267'}
                        x2={'149.942'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint59'}
                        x1={'112.267'}
                        x2={'149.942'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint60'}
                        x1={'112.267'}
                        x2={'149.942'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint61'}
                        x1={'112.267'}
                        x2={'149.942'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint62'}
                        x1={'112.267'}
                        x2={'149.942'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint63'}
                        x1={'112.267'}
                        x2={'149.942'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint64'}
                        x1={'128.433'}
                        x2={'166.109'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint65'}
                        x1={'128.433'}
                        x2={'166.109'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint66'}
                        x1={'128.433'}
                        x2={'166.109'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint67'}
                        x1={'128.433'}
                        x2={'166.109'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint68'}
                        x1={'128.433'}
                        x2={'166.109'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint69'}
                        x1={'128.433'}
                        x2={'166.109'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint70'}
                        x1={'128.433'}
                        x2={'166.109'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint71'}
                        x1={'128.433'}
                        x2={'166.109'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint72'}
                        x1={'144.6'}
                        x2={'182.276'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint73'}
                        x1={'144.6'}
                        x2={'182.276'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint74'}
                        x1={'144.6'}
                        x2={'182.276'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint75'}
                        x1={'144.6'}
                        x2={'182.276'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint76'}
                        x1={'144.6'}
                        x2={'182.276'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint77'}
                        x1={'144.6'}
                        x2={'182.276'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint78'}
                        x1={'144.6'}
                        x2={'182.276'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint79'}
                        x1={'144.6'}
                        x2={'182.276'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint80'}
                        x1={'160.767'}
                        x2={'198.442'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint81'}
                        x1={'160.767'}
                        x2={'198.442'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint82'}
                        x1={'160.767'}
                        x2={'198.442'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint83'}
                        x1={'160.767'}
                        x2={'198.442'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint84'}
                        x1={'160.767'}
                        x2={'198.442'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint85'}
                        x1={'160.767'}
                        x2={'198.442'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint86'}
                        x1={'160.767'}
                        x2={'198.442'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint87'}
                        x1={'160.767'}
                        x2={'198.442'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint88'}
                        x1={'176.933'}
                        x2={'214.609'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint89'}
                        x1={'176.933'}
                        x2={'214.609'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint90'}
                        x1={'176.933'}
                        x2={'214.609'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint91'}
                        x1={'176.933'}
                        x2={'214.609'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint92'}
                        x1={'176.933'}
                        x2={'214.609'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint93'}
                        x1={'176.933'}
                        x2={'214.609'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint94'}
                        x1={'176.933'}
                        x2={'214.609'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint95'}
                        x1={'176.933'}
                        x2={'214.609'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint96'}
                        x1={'193.1'}
                        x2={'230.776'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint97'}
                        x1={'193.1'}
                        x2={'230.776'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint98'}
                        x1={'193.1'}
                        x2={'230.776'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint99'}
                        x1={'193.1'}
                        x2={'230.776'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint100'}
                        x1={'193.1'}
                        x2={'230.776'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint101'}
                        x1={'193.1'}
                        x2={'230.776'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint102'}
                        x1={'193.1'}
                        x2={'230.776'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint103'}
                        x1={'193.1'}
                        x2={'230.776'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint104'}
                        x1={'209.267'}
                        x2={'246.942'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint105'}
                        x1={'209.267'}
                        x2={'246.942'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint106'}
                        x1={'209.267'}
                        x2={'246.942'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint107'}
                        x1={'209.267'}
                        x2={'246.942'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint108'}
                        x1={'209.267'}
                        x2={'246.942'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint109'}
                        x1={'209.267'}
                        x2={'246.942'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint110'}
                        x1={'209.267'}
                        x2={'246.942'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint111'}
                        x1={'209.267'}
                        x2={'246.942'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint112'}
                        x1={'225.433'}
                        x2={'263.109'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint113'}
                        x1={'225.433'}
                        x2={'263.109'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint114'}
                        x1={'225.433'}
                        x2={'263.109'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint115'}
                        x1={'225.433'}
                        x2={'263.109'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint116'}
                        x1={'225.433'}
                        x2={'263.109'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint117'}
                        x1={'225.433'}
                        x2={'263.109'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint118'}
                        x1={'225.433'}
                        x2={'263.109'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint119'}
                        x1={'225.433'}
                        x2={'263.109'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint120'}
                        x1={'241.6'}
                        x2={'279.276'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint121'}
                        x1={'241.6'}
                        x2={'279.276'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint122'}
                        x1={'241.6'}
                        x2={'279.276'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint123'}
                        x1={'241.6'}
                        x2={'279.276'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint124'}
                        x1={'241.6'}
                        x2={'279.276'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint125'}
                        x1={'241.6'}
                        x2={'279.276'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint126'}
                        x1={'241.6'}
                        x2={'279.276'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint127'}
                        x1={'241.6'}
                        x2={'279.276'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint128'}
                        x1={'257.767'}
                        x2={'295.442'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint129'}
                        x1={'257.767'}
                        x2={'295.442'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint130'}
                        x1={'257.767'}
                        x2={'295.442'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint131'}
                        x1={'257.767'}
                        x2={'295.442'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint132'}
                        x1={'257.767'}
                        x2={'295.442'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint133'}
                        x1={'257.767'}
                        x2={'295.442'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint134'}
                        x1={'257.767'}
                        x2={'295.442'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint135'}
                        x1={'257.767'}
                        x2={'295.442'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint136'}
                        x1={'273.933'}
                        x2={'311.609'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint137'}
                        x1={'273.933'}
                        x2={'311.609'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint138'}
                        x1={'273.933'}
                        x2={'311.609'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint139'}
                        x1={'273.933'}
                        x2={'311.609'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint140'}
                        x1={'273.933'}
                        x2={'311.609'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint141'}
                        x1={'273.933'}
                        x2={'311.609'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint142'}
                        x1={'273.933'}
                        x2={'311.609'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint143'}
                        x1={'273.933'}
                        x2={'311.609'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint144'}
                        x1={'290.1'}
                        x2={'327.776'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint145'}
                        x1={'290.1'}
                        x2={'327.776'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint146'}
                        x1={'290.1'}
                        x2={'327.776'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint147'}
                        x1={'290.1'}
                        x2={'327.776'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint148'}
                        x1={'290.1'}
                        x2={'327.776'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint149'}
                        x1={'290.1'}
                        x2={'327.776'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint150'}
                        x1={'290.1'}
                        x2={'327.776'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint151'}
                        x1={'290.1'}
                        x2={'327.776'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint152'}
                        x1={'306.267'}
                        x2={'343.942'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint153'}
                        x1={'306.267'}
                        x2={'343.942'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint154'}
                        x1={'306.267'}
                        x2={'343.942'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint155'}
                        x1={'306.267'}
                        x2={'343.942'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint156'}
                        x1={'306.267'}
                        x2={'343.942'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint157'}
                        x1={'306.267'}
                        x2={'343.942'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint158'}
                        x1={'306.267'}
                        x2={'343.942'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint159'}
                        x1={'306.267'}
                        x2={'343.942'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint160'}
                        x1={'322.433'}
                        x2={'360.109'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint161'}
                        x1={'322.433'}
                        x2={'360.109'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint162'}
                        x1={'322.433'}
                        x2={'360.109'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint163'}
                        x1={'322.433'}
                        x2={'360.109'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint164'}
                        x1={'322.433'}
                        x2={'360.109'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint165'}
                        x1={'322.433'}
                        x2={'360.109'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint166'}
                        x1={'322.433'}
                        x2={'360.109'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint167'}
                        x1={'322.433'}
                        x2={'360.109'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint168'}
                        x1={'338.6'}
                        x2={'376.276'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint169'}
                        x1={'338.6'}
                        x2={'376.276'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint170'}
                        x1={'338.6'}
                        x2={'376.276'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint171'}
                        x1={'338.6'}
                        x2={'376.276'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint172'}
                        x1={'338.6'}
                        x2={'376.276'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint173'}
                        x1={'338.6'}
                        x2={'376.276'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint174'}
                        x1={'338.6'}
                        x2={'376.276'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint175'}
                        x1={'338.6'}
                        x2={'376.276'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint176'}
                        x1={'354.767'}
                        x2={'392.442'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint177'}
                        x1={'354.767'}
                        x2={'392.442'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint178'}
                        x1={'354.767'}
                        x2={'392.442'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint179'}
                        x1={'354.767'}
                        x2={'392.442'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint180'}
                        x1={'354.767'}
                        x2={'392.442'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint181'}
                        x1={'354.767'}
                        x2={'392.442'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint182'}
                        x1={'354.767'}
                        x2={'392.442'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint183'}
                        x1={'354.767'}
                        x2={'392.442'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint184'}
                        x1={'370.933'}
                        x2={'408.609'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint185'}
                        x1={'370.933'}
                        x2={'408.609'}
                        y1={'109.194'}
                        y2={'99.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint186'}
                        x1={'370.933'}
                        x2={'408.609'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint187'}
                        x1={'370.933'}
                        x2={'408.609'}
                        y1={'146.194'}
                        y2={'136.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint188'}
                        x1={'370.933'}
                        x2={'408.609'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint189'}
                        x1={'370.933'}
                        x2={'408.609'}
                        y1={'183.194'}
                        y2={'173.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint190'}
                        x1={'370.933'}
                        x2={'408.609'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint191'}
                        x1={'370.933'}
                        x2={'408.609'}
                        y1={'220.194'}
                        y2={'210.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint192'}
                        x1={'387.1'}
                        x2={'424.776'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint193'}
                        x1={'387.1'}
                        x2={'424.776'}
                        y1={'101.194'}
                        y2={'91.6834'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint194'}
                        x1={'387.1'}
                        x2={'424.776'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint195'}
                        x1={'387.1'}
                        x2={'424.776'}
                        y1={'138.194'}
                        y2={'128.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint196'}
                        x1={'387.1'}
                        x2={'424.776'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint197'}
                        x1={'387.1'}
                        x2={'424.776'}
                        y1={'175.194'}
                        y2={'165.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint198'}
                        x1={'387.1'}
                        x2={'424.776'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                    <linearGradient
                        gradientUnits={'userSpaceOnUse'}
                        id={'paint199'}
                        x1={'387.1'}
                        x2={'424.776'}
                        y1={'212.194'}
                        y2={'202.683'}
                    >
                        <stop stopColor={imageProps.stopColor1} />
                        <stop
                            offset={'1'}
                            stopColor={imageProps.stopColor2}
                        />
                    </linearGradient>
                </defs>
            </svg>
        </figure>
    );
}
