import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
} from '../../../../../../components';
import {RiskLevel} from '../../../../../../util';

export function BreastCancerScreenOptions(props) {
    const {
        currentStep,
        totalSteps,
        riskLevel,
    } = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`screen-options-BC-${riskLevel}`}
            title={intl.formatMessage({id: `reports.trait.actionPlan.actionCard.discussScreeningOptions.title`})}
            totalSteps={totalSteps}
        >
            {riskLevel !== RiskLevel.HIGHER && (
                <>
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.screeningAge.description.one'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.screeningAge.description.two'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.screeningAge.description.three'}
                        />
                    </p>
                </>
            )}
            {riskLevel === RiskLevel.HIGHER && (
                <>
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.discuss'}
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.discuss.item.one'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.discuss.item.two'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.discuss.item.three'}
                            />
                        </li>
                    </ul>
                </>
            )}
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.mammogram.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.mammogram.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/cancer/breast/basic_info/screening.htm'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.BC.screen.link'}
                />
            </LinkWithAttribution>
        </ActionCard>
    );
}
