import classNames from 'classnames';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Checkmark} from '../checkmark';
import {
    PageContent,
    TitleContainer,
    TwoThirdsContainer,
} from '../page-content';

export function DoesNotTellYou(props) {
    const {className, dataTestId} = props;

    const intl = useIntl();

    const contentClasses = classNames(
        'variant-dark',
        'mid-navy-gradient',
        'does-not-tell-you',
        className,
    );

    return (
        <PageContent
            className={contentClasses}
            dataTestId={dataTestId}
        >
            <TwoThirdsContainer
                ContentComponent={
                    <ul className={'no-decoration no-margin'}>
                        <li className={'with-checkmark is-heavy'}>
                            <Checkmark />
                            <FormattedMessage id={'common.doesNotTellYou.pointOne'} />
                        </li>
                        <li className={'with-checkmark is-heavy'}>
                            <Checkmark />
                            <FormattedMessage id={'common.doesNotTellYou.pointTwo'} />
                        </li>
                        <li className={'with-checkmark is-heavy'}>
                            <Checkmark />
                            <FormattedMessage id={'common.doesNotTellYou.pointThree'} />
                        </li>
                        <li className={'with-checkmark is-heavy'}>
                            <Checkmark />
                            <FormattedMessage id={'common.doesNotTellYou.pointFour'} />
                        </li>
                    </ul>
                }
                TitleComponent={<TitleContainer title={intl.formatMessage({id: 'common.doesNotTellYou.title'})} />}
            />
        </PageContent>
    );
}
