import {
    Button,
    MoreInfo,
} from '@genomicsplc/denim-components';
import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function DoctorsMessage(props) {
    const {dataTestId} = props;

    const intl = useIntl();

    const onCopyTextClickHandler = useCallback(() => {
        navigator.clipboard.writeText(
            intl.formatMessage({id: 'doctorsMessage.copyText'}, {br: () => '\n'}),
        );
    }, []);

    return (
        <MoreInfo
            className={'doctors-message'}
            content={
                <>
                    <p className={'description'}>
                        <FormattedMessage id={'doctorsMessage.description'} />
                    </p>
                    <div className={'copy-text'}>
                        <p className={'no-margin'}>
                            <FormattedMessage
                                id={'doctorsMessage.copyText'}
                                values={{br: () => <br />}}
                            />
                        </p>
                        <Button
                            className={'icon after copy'}
                            dataTestId={'doctor-message-copy-button'}
                            onClick={onCopyTextClickHandler}
                        >
                            <FormattedMessage id={'doctorsMessage.button'} />
                        </Button>
                    </div>
                </>
            }
            dataTestId={dataTestId ?? 'more-info-doctor-message'}
            title={intl.formatMessage({id: 'doctorsMessage.title'})}
        />
    );
}
