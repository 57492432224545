import classNames from 'classnames';

export function ReportsHeader(props) {
    const {children, className} = props;

    const reportsHeaderClasses = classNames('reports-header', 'variant-dark', className);

    return (
        <section className={reportsHeaderClasses}>
            <div className={'header-content'}>{children}</div>
            <div className={'eye-of-genomics'} />
        </section>
    );
}
