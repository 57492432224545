import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {
    NumberedTitleContainer,
    PageContent,
    TwoThirdsContainer,
} from '../../components';
import {useAmplitude} from '../../context';
import {DiscussWithDoctorContent} from './DiscussWithDoctorContent';
import {GiveSampleContent} from './GiveSampleContent';
import {ReadDNAContent} from './ReadDNAContent';
import {ReviewResultsContent} from './ReviewResultsContent';
import {SignUpContent} from './SignUpContent';

export function HowItWorks() {
    const amplitude = useAmplitude();
    const {setMainClassName} = useOutletContext();
    const intl = useIntl();

    useEffect(() => {
        amplitude.logHowItWorksVisit();
        setMainClassName('wavy-line-background');

        return () => {
            setMainClassName(null);
        };
    }, [amplitude]);

    return (
        <>
            <PageContent className={'page-title variant-dark'}>
                <h1 data-test-id={'how-it-works-page-main-title'}>
                    <FormattedMessage id={'how-it-works.hero.title'} />
                </h1>
                <p>
                    <FormattedMessage id={'how-it-works.hero.description'} />
                </p>
            </PageContent>
            <PageContent className={'variant-dark calculate-risk'}>
                <TwoThirdsContainer
                    ContentComponent={<SignUpContent />}
                    TitleComponent={
                        <NumberedTitleContainer
                            large={true}
                            number={1}
                            title={intl.formatMessage({id: 'how-it-works.sign-up.title'})}
                        />
                    }
                />
                <TwoThirdsContainer
                    ContentComponent={<GiveSampleContent />}
                    TitleComponent={
                        <NumberedTitleContainer
                            large={true}
                            number={2}
                            title={intl.formatMessage({id: 'how-it-works.use-test-kit.title'})}
                        />
                    }
                />
                <TwoThirdsContainer
                    ContentComponent={
                        <p>
                            <FormattedMessage id={'how-it-works.test-kit-mail.description'} />
                        </p>
                    }
                    TitleComponent={
                        <NumberedTitleContainer
                            large={true}
                            number={3}
                            title={intl.formatMessage({id: 'how-it-works.test-kit-mail.title'})}
                        />
                    }
                />
                <TwoThirdsContainer
                    ContentComponent={<ReadDNAContent />}
                    TitleComponent={
                        <NumberedTitleContainer
                            large={true}
                            number={4}
                            title={intl.formatMessage({id: 'how-it-works.test-sample.title'})}
                        />
                    }
                />
                <TwoThirdsContainer
                    ContentComponent={<ReviewResultsContent />}
                    TitleComponent={
                        <NumberedTitleContainer
                            large={true}
                            number={5}
                            title={intl.formatMessage({id: 'how-it-works.get-reports.title'})}
                        />
                    }
                />
                <TwoThirdsContainer
                    ContentComponent={<DiscussWithDoctorContent />}
                    TitleComponent={
                        <NumberedTitleContainer
                            large={true}
                            number={6}
                            title={intl.formatMessage({id: 'how-it-works.discuss-doctor.title'})}
                        />
                    }
                />
            </PageContent>
        </>
    );
}
