import {MoreInfo} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function ReadYourDNAContent() {
    const intl = useIntl();

    return (
        <>
            <p>
                <FormattedMessage id={'science.calculateRisk.readYourDNA.content'} />
            </p>
            <MoreInfo
                content={intl.formatMessage({id: 'science.calculateRisk.readYourDNA.clia.content'})}
                dataTestId={'more-info-clia-certification'}
                title={intl.formatMessage({id: 'science.calculateRisk.readYourDNA.clia.title'})}
            />
            <MoreInfo
                content={intl.formatMessage({id: 'science.calculateRisk.readYourDNA.variants.content'})}
                dataTestId={'more-info-genetic-variants'}
                title={intl.formatMessage({id: 'science.calculateRisk.readYourDNA.variants.title'})}
            />
        </>
    );
}
