import {useMemo} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {SidebarNavItem} from '../../../components';
import {useUserContext} from '../../../context';
import {useQuery} from '../../../hooks';
import {Routes} from '../../../Routes';
import {Endpoint} from '../../../util';

export function AccountSidebar() {
    const {user} = useUserContext();
    const intl = useIntl();
    const {pathname} = useLocation();
    const {data} = useQuery(Endpoint.SUMMARY);

    const hasReport = useMemo(() => {
        const {resultStatus = 'NONE'} = data ?? {};
        return resultStatus === 'READY';
    }, [data]);

    return (
        <>
            <div className={'user-details is-eyebrow is-small is-uppercase'}>
                <span data-test-id={'account-sidebar-title'}>
                    <FormattedMessage id={'account.sidebar.title'} />
                </span>
                <br />
                <span className={'is-dark'}>{user.emailAddress}</span>
            </div>
            <ul className={'no-margin no-decoration'}>
                <li>
                    <SidebarNavItem
                        dataTestId={'account-sidebar-nav-profile'}
                        icon={'/images/icon-profile.svg'}
                        isActive={pathname === Routes.ACCOUNT_PROFILE}
                        linkTo={'profile'}
                        title={intl.formatMessage({id: 'account.profile.title'})}
                    />
                </li>
                <li>
                    <SidebarNavItem
                        dataTestId={'account-sidebar-nav-tests'}
                        icon={'/images/icon-test-order.svg'}
                        isActive={pathname === Routes.ACCOUNT_TESTS}
                        linkTo={'tests'}
                        title={intl.formatMessage({id: 'account.tests.title'})}
                    />
                </li>
                {hasReport && (
                    <li>
                        <SidebarNavItem
                            dataTestId={'account-sidebar-nav-lab-report'}
                            icon={'/images/icon-lab-report.svg'}
                            isActive={pathname === Routes.ACCOUNT_LAB_REPORT}
                            linkTo={'lab-report'}
                            title={intl.formatMessage({id: 'account.labReport.title'})}
                        />
                    </li>
                )}
                <li>
                    <SidebarNavItem
                        dataTestId={'account-sidebar-nav-settings'}
                        icon={'/images/icon-account.svg'}
                        isActive={pathname === Routes.ACCOUNT_SETTINGS}
                        linkTo={'security'}
                        title={intl.formatMessage({id: 'account.security.title'})}
                    />
                </li>
            </ul>
        </>
    );
}
