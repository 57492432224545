import {
    Button,
    Variant,
} from '@genomicsplc/denim-components';
import {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Outlet,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {
    BackLink,
    FetchError,
    Loading,
} from '../../../components';
import {useAmplitude} from '../../../context';
import {
    useMutation,
    useQuery,
} from '../../../hooks';
import {useLinearFlowContext} from '../../../layouts';
import {
    devConsole,
    Endpoint,
    extractErrorMessage,
} from '../../../util';

export function ProfilePage() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {nextStep, previousStep} = useLinearFlowContext();
    const {pathname, state} = useLocation();
    const [updateCurrentUserProfile, {error: errorResponse, loading: mutationLoading}] = useMutation(
        Endpoint.PROFILE,
    );
    const navigate = useNavigate();
    const {
        data: currentUserProfile = {},
        error: queryError,
        loading: queryLoading,
    } = useQuery(Endpoint.PROFILE);
    const [persistFields, setPersistFields] = useState(null);

    const userIneligible = useMemo(() => Boolean(state?.userIneligible), [state]);

    const onBackClickHandler = useCallback(() => {
        // Go to the previous step, unless the user is ineligible, in which case, cgo back one in the history...
        if (userIneligible) {
            navigate(-1, {state: {returningFromIneligiblePage: true}});
        }
        else {
            previousStep();
        }
    }, [navigate, previousStep, userIneligible]);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            const formValid = event.target.checkValidity();
            if (formValid) {
                // If there are fields to persist...
                if (persistFields) {
                    try {
                        // Protected against idiocy...
                        const fields = persistFields();
                        if (!fields) {
                            devConsole(
                                'A persistFields function was set, but it returned no fields to persist',
                            );
                            return;
                        }

                        await updateCurrentUserProfile({body: fields});
                        amplitude.logProfileQuestionAnswered(pathname, fields);
                    }
                    catch (err) {
                        return;
                    }
                }

                nextStep();
            }
        },
        [nextStep, persistFields],
    );

    if (queryLoading) {
        return <Loading />;
    }

    if (queryError) {
        return <FetchError reason={extractErrorMessage(queryError)} />;
    }

    return (
        <>
            <BackLink
                className={'less-margin'}
                onClick={onBackClickHandler}
            />
            <form
                noValidate={true}
                onSubmit={onSubmitHandler}
            >
                <Outlet
                    context={{
                        currentUserProfile,
                        errorResponse,
                        setPersistFields,
                    }}
                />
                {!userIneligible && (
                    <Button
                        className={'icon arrow--right after'}
                        dataTestId={'sign-up-page-continue'}
                        isLoading={mutationLoading}
                        submit={true}
                        title={intl.formatMessage({id: 'common.button.continue.title'})}
                        variant={Variant.PRIMARY}
                    >
                        <FormattedMessage id={'common.button.continue'} />
                    </Button>
                )}
            </form>
        </>
    );
}
