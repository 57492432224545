import {
    FormGroup,
    TextInput,
} from '@genomicsplc/denim-components';
import {
    useEffect,
    useRef,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {FetchValidationError} from '../../../fetch-validation-error';

const FIRST_NAME_MAX_LENGTH = 100;

export function Name() {
    const intl = useIntl();
    const {
        currentUserProfile: {firstName = '', lastName = ''} = {},
        errorResponse,
        setPersistFields,
    } = useOutletContext();
    const firstNameRef = useRef();
    const lastNameRef = useRef();

    useEffect(() => {
        setPersistFields(() => () => ({
            firstName: firstNameRef.current?.value,
            lastName: lastNameRef.current?.value,
        }));

        return () => {
            setPersistFields(null);
        };
    }, []);

    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-name-title'}
            >
                <FormattedMessage id={'signUp.name.title'} />
            </h2>
            <FetchValidationError errorResponse={errorResponse} />
            <div className={'text-input-width-container'}>
                <FormGroup
                    dataTestId={'sign-up-first-name-form-group'}
                    label={intl.formatMessage({id: 'signUp.name.firstName.label'})}
                    labelFor={'firstName'}
                >
                    <TextInput
                        dataTestId={'sign-up-first-name-input'}
                        errorMessages={{
                            tooLong: intl.formatMessage({id: 'signUp.name.firstName.tooLong'}),
                            valueMissing: intl.formatMessage({id: 'signUp.name.firstName.valueMissing'}),
                        }}
                        id={'first-name'}
                        maxLength={FIRST_NAME_MAX_LENGTH}
                        name={'firstName'}
                        ref={firstNameRef}
                        required={true}
                        type={'text'}
                        value={firstName ?? ''}
                    />
                </FormGroup>
                <FormGroup
                    dataTestId={'sign-up-last-name-form-group'}
                    label={intl.formatMessage({id: 'signUp.name.lastName.label'})}
                    labelFor={'lastName'}
                >
                    <TextInput
                        dataTestId={'sign-up-last-name-input'}
                        errorMessages={{valueMissing: intl.formatMessage({id: 'signUp.name.lastName.valueMissing'})}}
                        id={'last-name'}
                        name={'lastName'}
                        ref={lastNameRef}
                        required={true}
                        type={'text'}
                        value={lastName ?? ''}
                    />
                </FormGroup>
            </div>
        </>
    );
}
