import {FormattedMessage} from 'react-intl';
import {ContactSupport} from '../../../contact-support';

export function StateNotEligible() {
    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-home-address-state-not-eligible-title'}
            >
                <FormattedMessage id={'fetchValidationError.title.ADDRESS_STATE_NOT_SUPPORTED'} />
            </h2>
            <p>
                <FormattedMessage
                    id={'fetchValidationError.paragraph.one.ADDRESS_STATE_NOT_SUPPORTED'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'fetchValidationError.paragraph.two.ADDRESS_STATE_NOT_SUPPORTED'}
                    values={{cs: (chunk) => <ContactSupport text={chunk} />}}
                />
            </p>
        </>
    );
}
