import {useMemo} from 'react';
import {
    Route,
    Routes,
} from 'react-router-dom';
import {
    DateOfBirth,
    Ethnicity,
    EthnicityOptionalQuestionInterceptor,
    HomeAddress,
    IdentifiedEthnicity,
    Name,
    PhoneNumber,
    PreferNotToSayEthnicity,
    PreferNotToSaySex,
    ReportedSex,
    ReportedSexOptionalQuestionInterceptor,
    ShippingAddress,
} from '../../../components';
import {
    LinearFlowLayout,
    MinimalLayout,
} from '../../../layouts';
import {Routes as InternalRoutes} from '../../../Routes';
import {
    RequireAuthentication,
    RequireTestReorderInvite,
} from '../../auth';
import {NotFound} from '../../not-found';
import {CheckYourDetails} from '../check-your-details';
import {TestReorderContext} from '../context';
import {OrderNewTest} from '../order-new-test';
import {UpdateProfilePage} from '../update-profile-page';
import {YourReports} from '../your-reports';

export function TestReorderController() {
    const flowConfig = useMemo(() => ({
        labelId: 'testReorder.progress.label',
        steps: [
            {route: InternalRoutes.TEST_REORDER_CHECK_YOUR_DETAILS},
            {route: InternalRoutes.TEST_REORDER_YOUR_REPORTS},
        ],
    }), []);

    return (
        <Routes>
            <Route
                element={
                    <RequireAuthentication>
                        <RequireTestReorderInvite>
                            <LinearFlowLayout
                                className={'test-reorder'}
                                flowConfig={flowConfig}
                                logoutLink={true}
                            />
                        </RequireTestReorderInvite>
                    </RequireAuthentication>
                }
            >
                <Route element={<TestReorderContext />}>
                    <Route
                        element={<CheckYourDetails />}
                        path={'check-your-details'}
                    />
                    <Route
                        element={<YourReports />}
                        path={'your-reports'}
                    />
                    <Route
                        element={<UpdateProfilePage />}
                        path={'edit'}
                    >
                        <Route
                            element={<Name />}
                            path={'name'}
                        />
                        <Route
                            element={<DateOfBirth />}
                            path={'date-of-birth'}
                        />
                        <Route
                            element={
                                <ReportedSexOptionalQuestionInterceptor
                                    routes={{
                                        reportedSex: InternalRoutes.TEST_REORDER_EDIT_REPORTED_SEX,
                                        pntsSex: InternalRoutes.TEST_REORDER_EDIT_PNTS_SEX,
                                    }}
                                />
                            }
                        >
                            <Route
                                element={<ReportedSex />}
                                path={'reported-sex'}
                            />
                            <Route
                                element={<PreferNotToSaySex />}
                                path={'prefer-not-to-say-sex'}
                            />
                        </Route>
                        <Route
                            element={
                                <EthnicityOptionalQuestionInterceptor
                                    routes={{
                                        ethnicity: InternalRoutes.TEST_REORDER_EDIT_ETHNICITY,
                                        pntsEthnicity: InternalRoutes.TEST_REORDER_EDIT_PNTS_ETHNICITY,
                                        identifiedEthnicity: InternalRoutes.TEST_REORDER_EDIT_IDENTIFIED_ETHNICITY,
                                    }}
                                />
                            }
                        >
                            <Route
                                element={<Ethnicity />}
                                path={'ethnicity'}
                            />
                            <Route
                                element={<IdentifiedEthnicity />}
                                path={'identified-ethnicity'}
                            />
                            <Route
                                element={<PreferNotToSayEthnicity />}
                                path={'prefer-not-to-say-ethnicity'}
                            />
                        </Route>
                        <Route
                            element={<PhoneNumber />}
                            path={'phone-number'}
                        />
                        <Route
                            element={<HomeAddress />}
                            path={'home-address'}
                        />
                        <Route
                            element={<ShippingAddress />}
                            path={'shipping-address'}
                        />
                    </Route>
                </Route>
            </Route>
            <Route element={<MinimalLayout />}>
                <Route
                    element={<OrderNewTest />}
                    index={true}
                />
                <Route
                    element={<NotFound />}
                    path={'*'}
                />
            </Route>
        </Routes>
    );
}
