import {Callout} from '@genomicsplc/denim-components';
import {FormattedMessage} from 'react-intl';
import {
    FetchError,
    Loading,
} from '../../../components';
import {extractErrorMessage} from '../../../util';
import {
    ExcludedReports,
    ReportLink,
    useReportList,
} from '../../results/components';

export function YourReports() {
    const {
        excludedReports,
        error,
        loading,
        orderedReports,
        reportCount,
    } = useReportList();

    if (loading) {
        return (
            <div className={'center-within'}>
                <Loading key={'loading'} />
            </div>
        );
    }

    if (error) {
        return (
            <div
                className={'center-within'}
                data-test-id={'results-overview-traits-error'}
            >
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    return (
        <section className={'your-reports layout two-thirds'}>
            <div className={'sidebar'}>
                <h2 data-test-id={'reports-overview-your-reports'}>
                    <FormattedMessage id={'reports.overview.yourResults'} />
                </h2>
                <span className={'count is-caption is-heavy'}>
                    <FormattedMessage
                        id={'reports.overview.yourResults.count'}
                        values={{reportCount}}
                    />
                </span>
            </div>
            <div className={'content'}>
                {orderedReports.map((report) => (
                    <ReportLink
                        key={report.trait}
                        {...report}
                    />
                ))}
                {excludedReports !== ExcludedReports.NEITHER_CANCERS && (
                    <Callout
                        dataTestId={`excluded-${excludedReports}`}
                        isHeavy={false}
                    >
                        <span className={'is-heavy'}>
                            <FormattedMessage
                                id={`reports.overview.yourResults.excluded.${excludedReports}.title`}
                            />
                        </span>
                        <br />
                        <FormattedMessage
                            id={`reports.overview.yourResults.excluded.${excludedReports}.text`}
                        />
                    </Callout>
                )}
            </div>
        </section>
    );
}
