import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCardImage,
    GraphicalActionCard,
    LinkWithAttribution,
} from '../../../../../../components';

export function ProstateCancerDiet(props) {
    const {currentStep, totalSteps} = props;

    const intl = useIntl();

    return (
        <GraphicalActionCard
            currentStep={currentStep}
            dataTestId={'diet-PC'}
            headerImage={ActionCardImage.DIET}
            title={intl.formatMessage({id: 'reports.trait.lifestyleActions.diet.title'})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.PC.one'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.PC.one'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.PC.two'} />
                </li>
            </ul>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.PC.two'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.one'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.one'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.two'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.three'} />
                </li>
            </ul>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.two'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.four'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.five'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.six'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.seven'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.eight'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.nine'} />
                </li>
            </ul>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.three'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.ten'} />
                </li>
            </ul>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.ACS'})}
                url={'https://www.cancer.org/healthy/eat-healthy-get-active/eat-healthy/add-fruits-and-veggies-to-your-diet.html'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.link.tips'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.AND'})}
                url={'https://www.eatright.org/health/diseases-and-conditions/cancer/prostate-cancer-prevention-with-proper-nutrition'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.link.prostateCancer'} />
            </LinkWithAttribution>
        </GraphicalActionCard>
    );
}
