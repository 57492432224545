import classNames from 'classnames';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

export function SidebarNavItem(props) {
    const {
        dataTestId,
        description,
        icon,
        isActive = false,
        linkTo,
        title,
    } = props;

    const navigate = useNavigate();

    const onClickHandler = useCallback(() => {
        navigate(linkTo);
    }, [linkTo]);

    const sidebarNavItemClasses = classNames('sidebar-nav-item', {
        clickable: linkTo,
        'is-active': isActive,
    });

    return (
        <div
            className={sidebarNavItemClasses}
            data-test-id={dataTestId}
            onClick={onClickHandler}
        >
            {icon && (
                <img
                    alt={''}
                    className={'icon'}
                    role={'presentation'}
                    src={icon}
                />
            )}
            <h3 className={'title'}>
                <span>{title}</span>
                <span className={'icon after chevron--right'} />
            </h3>
            <span className={'description is-caption is-heavy'}>{description}</span>
        </div>
    );
}
