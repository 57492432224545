import {Callout} from '@genomicsplc/denim-components';
import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {BackLink} from '../../../components';
import {useAmplitude} from '../../../context';
import {useLinearFlowContext} from '../../../layouts';
import {
    ContinueButton,
    Instruction,
} from '../components';

export function PeelAndSeal() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {onBackLinkClickHandler} = useLinearFlowContext();

    useEffect(() => {
        amplitude.logActivationPeelVisit();
    }, [amplitude]);

    return (
        <>
            <BackLink onClick={onBackLinkClickHandler} />
            <h2 data-test-id={'kit-activation-step-six-title'}>
                <FormattedMessage id={'kit.activation.stepSix.title'} />
            </h2>
            <Instruction
                image={'/images/kit-activation-six-one.svg'}
                text={intl.formatMessage({id: 'kit.activation.stepSix.instruction.one'})}
            />
            <Instruction
                className={'more-margin'}
                image={'/images/kit-activation-six-two.svg'}
                text={intl.formatMessage({id: 'kit.activation.stepSix.instruction.two'})}
            />
            <Callout>
                <FormattedMessage id={'kit.activation.stepSix.callout'} />
            </Callout>
            <ContinueButton />
        </>
    );
}
