import {useMemo} from 'react';
import {useQuery} from '../../../../hooks';
import {
    Endpoint,
    Trait,
} from '../../../../util';
import {ExcludedReports} from '../utils';

const COMPARE_HIGHER = 2;
const COMPARE_AVERAGE = 1;
const riskLevelConverter = (riskLevel) => (riskLevel === 'HIGHER' ? COMPARE_HIGHER : COMPARE_AVERAGE);

export function useReportList() {
    const {
        data,
        error,
        loading,
    } = useQuery(Endpoint.RESULTS);

    const {reportCount, orderedReports} = useMemo(() => {
        // Doing this in a plain comparator function did work, no idea why, so using a map sort instead... 🤷
        const riskLevels = (data ?? []).map(({riskLevel, trait}, index) => ({
            index: index,
            riskLevel: riskLevelConverter(riskLevel),
            sortKey: Trait[trait].sortKey,
        }));
        riskLevels.sort((first, second) => {
            const primarySort = second.riskLevel - first.riskLevel;
            return primarySort === 0 ? first.sortKey.localeCompare(second.sortKey) : primarySort;
        });
        const sortedByRiskLevel = riskLevels.map(({index}) => data[index]);

        return {
            orderedReports: sortedByRiskLevel,
            reportCount: sortedByRiskLevel.length,
        };
    }, [data]);

    // This is in its own function, as Prettier was being a dick and failing and reformatting the file into nonsense...
    const excludedReports = useMemo(() => {
        // Work out which of the cancer reports they're missing...
        let excludedCancerReports = ExcludedReports.BOTH_CANCERS;
        orderedReports.forEach(({trait}) => {
            if (trait === Trait.BC.code) {
                excludedCancerReports = excludedCancerReports === ExcludedReports.BREAST_CANCER
                    ? ExcludedReports.NEITHER_CANCERS
                    : ExcludedReports.PROSTATE_CANCER;
            }
            else if (trait === Trait.PC.code) {
                excludedCancerReports = excludedCancerReports === ExcludedReports.PROSTATE_CANCER
                    ? ExcludedReports.NEITHER_CANCERS
                    : ExcludedReports.BREAST_CANCER;
            }
        });

        return excludedCancerReports;
    }, [orderedReports]);

    return {
        error,
        excludedReports,
        loading,
        orderedReports,
        reportCount,
    };
}
