import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {useLinearFlowContext} from './LinearFlowProvider';

export function LinearFlowProgressIndicator(props) {
    const {className} = props;

    const {
        currentStep,
        labelId,
        totalSteps,
    } = useLinearFlowContext();

    const containerClasses = classNames('linear-flow-progress-indicator', className);

    return (
        <div
            className={containerClasses}
            data-test-id={'linear-flow-progress-indicator'}
        >
            <span
                className={'label is-heavy'}
                data-test-id={'linear-flow-progress-indicator-label'}
            >
                <FormattedMessage id={labelId} />
            </span>
            <span
                className={'step is-caption'}
                data-test-id={'linear-flow-progress-indicator-steps'}
            >
                <FormattedMessage
                    id={'common.stepOfTotal.text'}
                    values={{
                        currentStep,
                        totalSteps,
                    }}
                />
            </span>
        </div>
    );
}
