import {useEffect} from 'react';
import {
    useAmplitude,
    useUserContext,
} from '../../../context';
import {WhileAuthenticated} from './WhileAuthenticated';
import {WhileUnauthenticated} from './WhileUnauthenticated';

export function OrderNewTest() {
    const amplitude = useAmplitude();
    const {isAuthenticated} = useUserContext();

    useEffect(() => {
        amplitude.logRequestNewTestVisit();
    }, [amplitude]);

    const OrderNewTestComponent = isAuthenticated
        ? WhileAuthenticated
        : WhileUnauthenticated;

    return <OrderNewTestComponent />;
}
