import classNames from 'classnames';
import {useMemo} from 'react';
import {
    useName,
    useQuery,
} from '../../../../hooks';
import {Endpoint} from '../../../../util';
import {
    AccountHomeLink,
    LabReportLink,
    LogoutLink,
    ProfileLink,
    SettingsLink,
    TestOrderLink,
} from '../navigation-links';

export function AccountMenu(props) {
    const {
        className,
        compact,
        dataTestId,
    } = props;

    const {name} = useName();
    const {data} = useQuery(Endpoint.SUMMARY);

    const hasReport = useMemo(() => {
        const {resultStatus = 'NONE'} = data ?? {};
        return resultStatus === 'READY';
    }, [data]);

    const accountMenuClasses = classNames('account-menu', className);
    const linkClasses = classNames('is-heavy', 'no-decoration', {'is-caption': compact});

    return (
        <div
            className={accountMenuClasses}
            data-test-id={dataTestId ?? 'account-menu'}
        >
            <ul role={'menu'}>
                <li
                    className={'name is-caption is-heavy'}
                    data-test-id={'account-menu-name'}
                    role={'presentation'}
                >
                    {name}
                </li>
                <li role={'menuitem'}>
                    <AccountHomeLink
                        className={linkClasses}
                        dataTestId={'account-menu-account-home-link'}
                    />
                </li>
                <li role={'menuitem'}>
                    <ProfileLink className={linkClasses} />
                </li>
                <li role={'menuitem'}>
                    <TestOrderLink className={linkClasses} />
                </li>
                {hasReport && (
                    <li role={'menuitem'}>
                        <LabReportLink className={linkClasses} />
                    </li>
                )}
                <li role={'menuitem'}>
                    <SettingsLink className={linkClasses} />
                </li>
                <li role={'menuitem'}>
                    <LogoutLink className={linkClasses} />
                </li>
            </ul>
        </div>
    );
}
