export class ObservableCacheEntry {
    #data;
    #observers = [];

    constructor(data) {
        this.#data = data;
    }

    subscribe(observer) {
        if ('updated' in observer) {
            // Add the observer to the list...
            this.#observers.push(observer);

            // Return an unsubscribe function...
            return () => {
                this.#observers = this.#observers.filter(
                    (subscribedObserver) => subscribedObserver !== observer,
                );
            };
        }

        throw Error('observer must have an "updated" callback function');
    }

    #notify() {
        this.#observers.forEach((observer) => observer.updated(this));
    }

    get data() {
        return this.#data;
    }

    set data(data) {
        this.#data = data;
        this.#notify();
    }
}
