import {Link} from '@genomicsplc/denim-components';
import classNames from 'classnames';

export function LinkWithAttribution(props) {
    const {
        attribution,
        children,
        className,
        dataTestId,
        formatted = false,
        isAlternate = false,
        linkClassName,
        rel = 'noreferrer',
        target = '_blank',
        url,
    } = props;

    const linkWithAttributionClasses = classNames('link-with-attribution', className, {alternate: isAlternate});
    const attributionClasses = classNames('attribution', {'is-caption': !isAlternate});

    return (
        <div
            className={linkWithAttributionClasses}
            data-test-id={dataTestId}
        >
            {formatted ? children : (
                <Link
                    className={linkClassName}
                    rel={rel}
                    target={target}
                    url={url}
                >
                    {children}
                </Link>
            )}
            <div className={attributionClasses}>{attribution}</div>
        </div>
    );
}
