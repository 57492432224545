import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
    SupplementalBox,
} from '../../../../../../components';
import {
    RiskLevel,
    Trait,
} from '../../../../../../util';

export function HighBloodPressureScreenOptions(props) {
    const {
        currentStep,
        totalSteps,
        riskLevel,
    } = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`screen-options-HT-${riskLevel}`}
            title={intl.formatMessage({
                id: `reports.trait.actionPlan.actionCard.discussScreeningOptions.title.${riskLevel}`,
            })}
            totalSteps={totalSteps}
        >
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={riskLevel === RiskLevel.HIGHER
                        ? 'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.heading.alternative.HIGHER'
                        : 'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.heading.alternative'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.description.one'}
                />
            </p>
            {riskLevel !== RiskLevel.HIGHER && (
                <>
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.description.two'}
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.item.one'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.item.two'}
                            />
                        </li>
                    </ul>
                </>
            )}
            {riskLevel === RiskLevel.HIGHER && (
                <p>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.description.two.HIGHER'}
                    />
                </p>
            )}
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.description.three'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.description.four'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/bloodpressure/measure.htm'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.link.one'}
                />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.AHA`})}
                url={'https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.link.two'}
                />
            </LinkWithAttribution>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.otherTests.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.otherTests.HT.description'}
                />
            </p>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.cholesterol.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.cholesterol.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/cholesterol/cholesterol_screening.htm'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.cholesterol.link'}
                />
            </LinkWithAttribution>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.diabetes.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.diabetes.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/diabetes/basics/getting-tested.html'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.diabetes.link'}
                />
            </LinkWithAttribution>
            {riskLevel === RiskLevel.HIGHER && (
                <>
                    <p className={'heading is-heavy'}>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.kidney.heading'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.kidney.description'}
                        />
                    </p>
                    <LinkWithAttribution
                        attribution={intl.formatMessage({id: `common.link.attribution.NKF`})}
                        url={'https://www.kidney.org/atoz/content/kidneytests'}
                    >
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.kidney.link'}
                        />
                    </LinkWithAttribution>
                    <SupplementalBox
                        title={intl.formatMessage({
                            id: 'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.heading',
                        })}
                    >
                        <p>
                            <FormattedMessage
                                id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.noneRecommended'}
                                values={{trait: intl.formatMessage({id: `common.trait.${Trait.HT.code}.text`})}}
                            />
                        </p>
                    </SupplementalBox>
                </>
            )}
        </ActionCard>
    );
}
