import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {Routes} from '../../../../Routes';

export function SplitLogotype(props) {
    const {dataTestId} = props;

    const intl = useIntl();
    const navigate = useNavigate();

    const onClickHandler = useCallback(() => {
        navigate(Routes.LANDING);
    }, []);

    return (
        <img
            alt={intl.formatMessage({id: 'common.logotype.alt'})}
            data-test-id={dataTestId}
            onClick={onClickHandler}
            src={'/images/health-insights-split-logotype.svg'}
        />
    );
}
