import {FormattedMessage} from 'react-intl';
import {
    DoctorsMessage,
    ReportDownloadButton,
} from '../../../components';

export function LabReport() {
    return (
        <section className={'account-content lab-report'}>
            <header>
                <img
                    alt={''}
                    className={'icon'}
                    role={'presentation'}
                    src={'/images/icon-lab-report.svg'}
                />
                <h2 data-test-id={'account-lab-report-title'}>
                    <FormattedMessage id={'account.labReport.title'} />
                </h2>
            </header>
            <p>
                <FormattedMessage id={'account.labReport.description'} />
            </p>
            <DoctorsMessage />
            <ReportDownloadButton dataTestId={'download-button'}>
                <FormattedMessage id={'common.button.downloadReport'} />
            </ReportDownloadButton>
        </section>
    );
}
