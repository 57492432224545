import classNames from 'classnames';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useFormatPercent} from '../../../../hooks';
import {Trait} from '../../../../util';

export function LikelihoodHeadline(props) {
    const {
        firstName,
        highThreshold,
        integratedRiskScore,
        riskLevel,
        riskWindow,
        traitCode,
    } = props;

    const formatPercent = useFormatPercent();
    const intl = useIntl();
    const baselineFactorsMessageId = traitCode === Trait.PC.code ? 'PC' : 'other';

    const leadRiskLevelClasses = classNames('risk-gradient', 'text', {[riskLevel.toLocaleLowerCase()]: riskLevel});
    const fillerRiskLevelClasses = classNames('risk-gradient', 'text', 'is-heavy', {
        [riskLevel.toLocaleLowerCase()]: riskLevel,
    });

    return (
        <>
            <h2
                className={'lead alternative'}
                data-test-id={`likelihood-headline-lead-${traitCode}-${riskLevel}-${riskWindow}`}
            >
                <FormattedMessage
                    id={'reports.trait.likelihood.lead'}
                    values={{
                        firstName: firstName,
                        irs: formatPercent(integratedRiskScore, false),
                        riskLevel: (chunks) => <span className={leadRiskLevelClasses}>{chunks}</span>,
                        riskWindow: intl.formatMessage({id: `reports.trait.likelihood.window.${riskWindow}`}),
                        trait: intl.formatMessage({id: `common.trait.${traitCode}.text`}),
                    }}
                />
            </h2>
            <p
                className={'filler'}
                data-test-id={`likelihood-headline-filler-${traitCode}-${riskLevel}-${baselineFactorsMessageId}`}
            >
                <FormattedMessage
                    id={`reports.trait.likelihood.filler.${riskLevel}`}
                    values={{
                        baselineFactors: intl.formatMessage({
                            id: `reports.trait.likelihood.baselineFactors.${baselineFactorsMessageId}`,
                        }),
                        riskLevel: (chunks) => <span className={fillerRiskLevelClasses}>{chunks}</span>,
                        trait: intl.formatMessage({id: `common.trait.${traitCode}.text`}),
                    }}
                />
            </p>
            <p className={'explanation is-caption'}>
                <span className={'is-heavy'}>
                    <FormattedMessage id={`reports.trait.likelihood.peopleInRange.${riskLevel}`} />
                </span>
                <br />
                <span className={'typical-range'}>
                    <FormattedMessage
                        id={`reports.trait.likelihood.typicalRange.${riskLevel}`}
                        values={{
                            baselineFactors: intl.formatMessage({
                                id: `reports.trait.likelihood.baselineFactors.${baselineFactorsMessageId}`,
                            }),
                            highThreshold: formatPercent(highThreshold, false),
                        }}
                    />
                </span>
            </p>
        </>
    );
}
