import {OsteopeniaRiskFactors} from './OsteopeniaRiskFactors';
import {OsteopeniaScreenOptions} from './OsteopeniaScreenOptions';

export function OsteopeniaMedicalActions(props) {
    const {
        reportedSex,
        riskLevel,
        riskWindow,
        totalSteps,
    } = props;

    return (
        <>
            <OsteopeniaRiskFactors
                currentStep={1}
                reportedSex={reportedSex}
                riskWindow={riskWindow}
                totalSteps={totalSteps}
            />
            <OsteopeniaScreenOptions
                currentStep={2}
                reportedSex={reportedSex}
                riskLevel={riskLevel}
                totalSteps={totalSteps}
            />
        </>
    );
}
