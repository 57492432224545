import classNames from 'classnames';

export function SixFiveGridContainer(props) {
    const {
        children,
        className,
        dataTestId,
    } = props;

    const containerClasses = classNames('six-five-grid-container', className);

    return (
        <div
            className={containerClasses}
            data-test-id={dataTestId}
        >
            {children}
        </div>
    );
}
