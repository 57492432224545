import classNames from 'classnames';
import {
    useCallback,
    useMemo,
} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {useUserContext} from '../../context';
import {Routes} from '../../Routes';

export function Logotype(props) {
    const {
        className,
        dataTestId,
        homeLink = false,
        poweredByLogotype = false,
    } = props;

    const {isAuthenticated} = useUserContext();
    const intl = useIntl();
    const navigate = useNavigate();

    const onClickHandler = useCallback(() => {
        if (homeLink) {
            navigate(isAuthenticated ? Routes.HOME : Routes.LANDING);
        }
    }, [homeLink]);

    const wordmarkSrc = useMemo(
        () => (poweredByLogotype
            ? '/images/health-insights-powered-by-logotype.svg'
            : '/images/health-insights-logotype.svg'),
        [poweredByLogotype],
    );

    const logotypeClasses = classNames('logotype', className, {
        'home-link': homeLink,
        'powered-by': poweredByLogotype,
    });

    return (
        <img
            alt={intl.formatMessage({id: 'common.logotype.alt'})}
            className={logotypeClasses}
            data-test-id={dataTestId}
            onClick={onClickHandler}
            src={wordmarkSrc}
        />
    );
}
