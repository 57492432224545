import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

export function GenomicsGroup(props) {
    const {className, dataTestId} = props;

    const groupClasses = classNames('is-caption', className);

    return (
        <div
            className={groupClasses}
            data-test-id={dataTestId}
        >
            <FormattedMessage id={'common.genomicsGroup'} />
        </div>
    );
}
