import {FormattedMessage} from 'react-intl';

export function DiscussWithDoctorContent() {
    return (
        <>
            <p>
                <FormattedMessage id={'how-it-works.discuss-doctor.description'} />
            </p>
            <ul className={'no-margin'}>
                <li>
                    <FormattedMessage id={'how-it-works.discuss-doctor.list.one'} />
                </li>
                <li>
                    <FormattedMessage id={'how-it-works.discuss-doctor.list.two'} />
                </li>
            </ul>
        </>
    );
}
