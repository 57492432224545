import {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {useOutletContext} from 'react-router-dom';

export function PreferNotToSay() {
    const {setPersistFields} = useOutletContext();

    // This is not really required, but makes integrating it into the edit flow easier.
    useEffect(() => {
        setPersistFields(() => () => ({}));

        return () => {
            setPersistFields(null);
        };
    }, []);

    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-prefer-not-to-say-ethnicity-title'}
            >
                <FormattedMessage id={'signUp.ethnicity.pnts.title'} />
            </h2>
            <p>
                <FormattedMessage id={'signUp.ethnicity.pnts.paragraph.one'} />
            </p>
            <p>
                <FormattedMessage id={'signUp.ethnicity.pnts.paragraph.two'} />
            </p>
        </>
    );
}
