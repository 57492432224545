import {
    RadioButton,
    RadioButtonFormGroup,
} from '@genomicsplc/denim-components';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {FetchValidationError} from '../../../components';

const HOME_ADDRESS = 'HomeAddress';
const DIFFERENT_ADDRESS = 'DifferentAddress';

const addressTypeToSessionStorage = (addressType) => {
    if (addressType === DIFFERENT_ADDRESS) {
        sessionStorage.setItem('differentShippingAddress', 'true');
    }
    else {
        sessionStorage.removeItem('differentShippingAddress');
    }
};

export function ShippingDetails() {
    const intl = useIntl();
    const {
        currentUserProfile: {firstName, homeAddress, lastName, shippingAddress} = {},
        errorResponse,
        setPersistFields,
    } = useOutletContext();
    const [selectedAddress, setSelectedAddress] = useState(HOME_ADDRESS);

    useEffect(() => {
        // Have they previously selected a different address...?
        const differentShippingAddressSelected = sessionStorage.getItem('differentShippingAddress') ?? false;

        // If they haven't previously selected different address, then assume home address but try and work it out...
        let addressType = differentShippingAddressSelected ? DIFFERENT_ADDRESS : HOME_ADDRESS;
        if (addressType === HOME_ADDRESS) {
            // Check to see if the shipping address has previously been set, which would mean some fields are non-null...
            const hasShippingAddress = Object.entries(shippingAddress ?? {}).some(([_, value]) => Boolean(value));
            if (hasShippingAddress) {
                const formattedHomeAddress = intl.formatMessage(
                    {id: 'signUp.selectAddress.homeAddress.description'},
                    {
                        firstName,
                        lastName,
                        ...homeAddress,
                    },
                );

                const formattedShippingAddress = intl.formatMessage(
                    {id: 'signUp.selectAddress.shippingAddress.description'},
                    {...shippingAddress},
                );

                addressType = formattedHomeAddress === formattedShippingAddress
                    ? HOME_ADDRESS
                    : DIFFERENT_ADDRESS;
            }
        }

        setSelectedAddress(addressType);
        addressTypeToSessionStorage(addressType);
    }, [firstName, homeAddress, lastName, shippingAddress]);

    const onChangeHandler = useCallback((value) => {
        setSelectedAddress(value);
        addressTypeToSessionStorage(value);
    }, []);

    useEffect(() => {
        if (selectedAddress === HOME_ADDRESS) {
            setPersistFields(() => () => ({
                shippingAddress: {
                    recipient: `${firstName} ${lastName}`,
                    ...homeAddress,
                },
            }));
        }
        else {
            setPersistFields(null);
        }

        return () => {
            setPersistFields(null);
        };
    }, [selectedAddress]);

    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-select-address-title'}
            >
                <FormattedMessage id={'signUp.selectAddress.title'} />
            </h2>
            <FetchValidationError errorResponse={errorResponse} />
            <RadioButtonFormGroup
                dataTestId={'sign-up-select-address-form-group'}
                errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.requiredOption'})}}
                name={'addressToShipTo'}
                onChange={onChangeHandler}
                required={true}
                value={selectedAddress}
            >
                <RadioButton
                    dataTestId={'select-address-home'}
                    description={intl.formatMessage(
                        {id: 'signUp.selectAddress.homeAddress.description'},
                        {
                            firstName,
                            lastName,
                            ...homeAddress,
                        },
                    )}
                    id={'select-address-home'}
                    label={intl.formatMessage({id: 'signUp.selectAddress.homeAddress'})}
                    value={HOME_ADDRESS}
                />
                <RadioButton
                    dataTestId={'select-address-shipping'}
                    id={'select-address-shipping'}
                    label={intl.formatMessage({id: 'signUp.selectAddress.differentAddress'})}
                    value={DIFFERENT_ADDRESS}
                />
            </RadioButtonFormGroup>
        </>
    );
}
