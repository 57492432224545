import {Variant} from '@genomicsplc/denim-components';
import {useMemo} from 'react';
import {GridContainer} from '../../../../components';
import {Trait} from '../../../../util';
import {AtrialFibrillation} from './AtrialFibrillation';
import {BreastCancer} from './BreastCancer';
import {CardiovascularDisease} from './CardiovascularDisease';
import {HighBloodPressure} from './HighBloodPressure';
import {HighLDLCholesterol} from './HighLDLCholesterol';
import {Osteopenia} from './Osteopenia';
import {ProstateCancer} from './ProstateCancer';
import {Type2Diabetes} from './Type2Diabetes';

export function LearnMoreAbout(props) {
    const {traitCode} = props;

    const ContentComponent = useMemo(() => {
        switch (traitCode) {
            case Trait.AF.code:
                return AtrialFibrillation;

            case Trait.BC.code:
                return BreastCancer;

            case Trait.CVD_US.code:
                return CardiovascularDisease;

            case Trait.HT.code:
                return HighBloodPressure;

            case Trait.HCH.code:
                return HighLDLCholesterol;

            case Trait.FNDXAT_N1P0.code:
                return Osteopenia;

            case Trait.PC.code:
                return ProstateCancer;

            case Trait.T2D.code:
                return Type2Diabetes;

            default:
                return null;
        }
    }, [traitCode]);

    return (
        <GridContainer
            containerClassName={'learn-more-about'}
            containerDataTestId={`learn-more-about-${traitCode}`}
            gridClassName={'full-width'}
            variant={Variant.DARK}
        >
            <img
                alt={''}
                className={'background-image'}
                role={'presentation'}
                src={'/images/spiky-circle.svg'}
            />
            <div className={'content'}>
                <ContentComponent />
            </div>
        </GridContainer>
    );
}
