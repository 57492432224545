import deepEqual from 'deep-equal';
import {StatusCodes} from 'http-status-codes';
import {
    useEffect,
    useReducer,
} from 'react';
import {
    ObservableCacheEntry,
    useCacheContext,
} from '../cache';
import {useUserContext} from '../context';
import {
    action,
    NetworkPolicy,
} from '../util';

const SET_DATA = 'SET_DATA';
const SET_ERROR = 'SET_ERROR';
const SET_FETCHING = 'SET_FETCHING';

const initialState = {
    // eslint-disable-next-line no-undefined
    data: undefined, // Setting to undefined allows for destructuring
    error: null,
    loading: false,
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_DATA:
            return {
                ...initialState,
                data: payload,
            };

        case SET_ERROR:
            return {
                ...initialState,
                error: payload,
            };

        case SET_FETCHING:
            return {
                ...state,
                error: null,
                loading: true,
            };

        default:
            return state;
    }
};

async function performFetch(
    dispatch,
    authenticatedFetch,
    cacheHasKey,
    getCachedValue,
    setCacheValue,
    resource,
    networkPolicy = NetworkPolicy.CACHE_OR_NETWORK,
    cacheKey = resource,
    method = 'GET',
    body = null,
) {
    dispatch(action(SET_FETCHING, null));

    if ([NetworkPolicy.CACHE_OR_NETWORK, NetworkPolicy.CACHE_AND_NETWORK].includes(networkPolicy)) {
        if (cacheHasKey(cacheKey)) {
            const cachedValue = getCachedValue(cacheKey);
            dispatch(action(SET_DATA, cachedValue));

            if (networkPolicy === NetworkPolicy.CACHE_OR_NETWORK) {
                return cachedValue.data;
            }
        }
    }

    const requestBody = body ? JSON.stringify(body) : null;
    const response = await authenticatedFetch(resource, {
        body: requestBody,
        method: method,
    });

    if (!response.ok) {
        let errorData = '';
        try {
            errorData = await response.clone().json();
        }
        catch (err) {
            errorData = await response.text();
        }

        const errorObject = {
            errorData: errorData,
            status: response.status,
        };
        dispatch(action(SET_ERROR, errorObject));
        throw Error(`Error response ${response.status} received from fetch`, {cause: errorObject});
    }

    /* eslint-disable no-undefined */
    const responseData = response.status !== StatusCodes.NO_CONTENT
        ? response.headers.get('content-type')?.includes('application/json')
            ? await response.json()
            : await response.text()
        : undefined;
    /* eslint-enable no-undefined */

    if (
        networkPolicy !== NetworkPolicy.NO_CACHE
        && !deepEqual(responseData, getCachedValue(cacheKey))
    ) {
        let observableData = getCachedValue(cacheKey);
        if (observableData) {
            observableData.data = responseData;
        }
        else {
            observableData = new ObservableCacheEntry(responseData);
            setCacheValue(cacheKey, observableData);
        }

        dispatch(action(SET_DATA, observableData));
    }
    else {
        dispatch(action(SET_DATA, responseData));
    }

    return responseData;
}

export function useQuery(endpoint, options = {}) {
    const {
        cacheHasKey,
        getCachedValue,
        setCacheValue,
    } = useCacheContext();
    const {authenticatedFetch} = useUserContext();

    const [{data: queryResult, error, loading}, dispatch] = useReducer(reducer, {
        ...initialState,
        loading: true,
    });

    useEffect(() => {
        let unsubscribe;
        if (queryResult?.subscribe) {
            unsubscribe = queryResult.subscribe({
                updated: (updated) => {
                    dispatch(action(SET_DATA, updated));
                },
            });
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [queryResult]);

    const optionsWithDefaults = {
        cacheKey: endpoint,
        networkPolicy: NetworkPolicy.CACHE_OR_NETWORK,
        ...options,
    };
    const {cacheKey, networkPolicy} = optionsWithDefaults;

    useEffect(() => {
        performFetch(
            dispatch,
            authenticatedFetch,
            cacheHasKey,
            getCachedValue,
            setCacheValue,
            endpoint,
            networkPolicy,
            cacheKey,
        );
    }, []);

    return {
        data: queryResult?.data ?? queryResult,
        error: error,
        loading: loading,
    };
}

export function useLazyQuery(endpoint, options = {}) {
    const {
        cacheHasKey,
        getCachedValue,
        setCacheValue,
    } = useCacheContext();
    const {authenticatedFetch} = useUserContext();

    const [{data: queryResult, error, loading}, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        let unsubscribe;
        if (queryResult?.subscribe) {
            unsubscribe = queryResult.subscribe({
                updated: (updated) => {
                    dispatch(action(SET_DATA, updated));
                },
            });
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [queryResult]);

    const query = (queryOptions) => {
        const endpointOverride = queryOptions?.endpoint ?? endpoint;

        const mergedOptions = {
            cacheKey: endpointOverride,
            networkPolicy: NetworkPolicy.CACHE_OR_NETWORK,
            ...options,
            ...Object.entries(queryOptions ?? {}).filter(([key]) => key !== 'endpoint'),
        };
        const {networkPolicy, cacheKey} = mergedOptions;

        return performFetch(
            dispatch,
            authenticatedFetch,
            cacheHasKey,
            getCachedValue,
            setCacheValue,
            endpointOverride,
            networkPolicy,
            cacheKey,
        );
    };

    return [
        query,
        {
            data: queryResult?.data ?? queryResult,
            error: error,
            loading: loading,
        },
    ];
}

export function useMutation(endpoint, options = {}) {
    const {
        cacheHasKey,
        getCachedValue,
        setCacheValue,
    } = useCacheContext();
    const {authenticatedFetch} = useUserContext();

    const [{data: mutationResult, error, loading}, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        let unsubscribe;
        if (mutationResult?.subscribe) {
            unsubscribe = mutationResult.subscribe({
                updated: (updated) => {
                    dispatch(action(SET_DATA, updated));
                },
            });
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [mutationResult]);

    const mutate = (mutateOptions = {}) => {
        const mergedOptions = {
            cacheKey: endpoint,
            networkPolicy: NetworkPolicy.NETWORK_ONLY,
            ...options,
            endpoint: endpoint,
            ...mutateOptions,
        };
        const {
            body,
            networkPolicy,
            cacheKey,
        } = mergedOptions;

        if (![NetworkPolicy.NETWORK_ONLY, NetworkPolicy.NO_CACHE].includes(networkPolicy)) {
            throw Error("useMutation only supports networkPolicy's of 'network_only' and 'no_cache'");
        }

        return performFetch(
            dispatch,
            authenticatedFetch,
            cacheHasKey,
            getCachedValue,
            setCacheValue,
            endpoint,
            networkPolicy,
            cacheKey,
            'POST',
            body,
        );
    };

    return [
        mutate,
        {
            data: mutationResult?.data ?? mutationResult,
            error: error,
            loading: loading,
        },
    ];
}
