import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
    SupplementalBox,
} from '../../../../../../components';
import {
    RiskLevel,
    Trait,
} from '../../../../../../util';

export function OsteopeniaScreenOptions(props) {
    const {
        currentStep,
        reportedSex,
        riskLevel,
        totalSteps,
    } = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`screen-options-FNDXAT_N1P0-${riskLevel}`}
            title={intl.formatMessage({
                id: `reports.trait.actionPlan.actionCard.discussScreeningOptions.title.${riskLevel}`,
            })}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={`reports.trait.actionPlan.actionCard.discussScreeningOptions.osteopenia.description.one.${reportedSex}`}
                />
            </p>

            {reportedSex !== 'MALE' && (
                <ul>
                    <li>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.osteopenia.item.one'}
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id={`reports.trait.actionPlan.actionCard.discussScreeningOptions.osteopenia.item.two.${reportedSex}`}
                        />
                    </li>
                </ul>
            )}

            {reportedSex !== 'MALE' && (
                <FormattedMessage
                    id={`reports.trait.actionPlan.actionCard.discussScreeningOptions.osteopenia.description.two.${reportedSex}`}
                />
            )}

            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.boneDensityTest.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.boneDensityTest.description.one'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.boneDensityTest.description.two'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.boneDensityTest.description.three'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/nceh/radiation/dexa-scan.html'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.boneDensityTest.link'}
                />
            </LinkWithAttribution>
            {riskLevel === RiskLevel.HIGHER && (
                <SupplementalBox
                    title={intl.formatMessage({
                        id: 'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.heading',
                    })}
                >
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.noneRecommended'}
                            values={{trait: intl.formatMessage({id: `common.trait.${Trait.FNDXAT_N1P0.code}.text`})}}
                        />
                    </p>
                </SupplementalBox>
            )}
        </ActionCard>
    );
}
