export function TitleContainer(props) {
    const {
        className,
        dataTestId,
        title,
    } = props;

    return (
        <h2
            className={className}
            data-test-id={dataTestId}
        >
            {title}
        </h2>
    );
}
