import {useIntl} from 'react-intl';

export function useFormatPercent() {
    const intl = useIntl();

    const formatPercent = (number, scale = true) =>
        intl.formatNumber(scale ? number / 100 : number, {
            style: 'percent',
            unitDisplay: 'short',
        });

    return formatPercent;
}
