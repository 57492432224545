import {Navigate} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../components';
import {useQuery} from '../../hooks';
import {Routes} from '../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../util';

export function ReportGuard(props) {
    const {children, navigateTo} = props;

    const {
        data: {registration: {confirmed = false} = {}, resultStatus = 'NONE'} = {},
        error,
        loading,
    } = useQuery(Endpoint.SUMMARY);

    if (loading) {
        return (
            <div className={'guard'}>
                <Loading key={'loading'} />
            </div>
        );
    }

    if (error) {
        return (
            <div className={'guard'}>
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    if (!confirmed) {
        return <Navigate to={Routes.SIGN_UP_ABOUT_YOU} />;
    }
    else if (resultStatus === 'READY') {
        return children;
    }

    return <Navigate to={navigateTo ?? Routes.HOME} />;
}
