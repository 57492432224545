import {BreastCancerRiskFactors} from './BreastCancerRiskFactors';
import {BreastCancerScreenOptions} from './BreastCancerScreenOptions';

export function BreastCancerMedicalActions(props) {
    const {riskLevel, totalSteps} = props;

    return (
        <>
            <BreastCancerRiskFactors
                currentStep={1}
                totalSteps={totalSteps}
            />
            <BreastCancerScreenOptions
                currentStep={2}
                riskLevel={riskLevel}
                totalSteps={totalSteps}
            />
        </>
    );
}
