import {
    Button,
    Link,
    Variant,
} from '@genomicsplc/denim-components';
import deepEqual from 'deep-equal';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import Modal from 'react-modal';
import {
    Outlet,
    useLocation,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import {
    BackLink,
    Divider,
} from '../../../components';
import {useMutation} from '../../../hooks';
import {Routes} from '../../../Routes';
import {
    devConsole,
    Endpoint,
    TRANSIENT_PERSIST_FIELDS_KEY,
} from '../../../util';

export function UpdateProfilePage() {
    const intl = useIntl();
    const {pathname, state} = useLocation();
    const [updateCurrentUserProfile, {error: errorResponse, loading: mutationLoading}] = useMutation(
        Endpoint.PROFILE,
    );
    const navigate = useNavigate();
    const {currentUserProfile = {}, setContainerClassName} = useOutletContext();
    const [isConfirmExitModalOpen, setIsConfirmExitModalOpen] = useState(false);
    const [persistFields, setPersistFields] = useState(null);
    const [navigateToOptionalQuestion, setNavigateToOptionalQuestion] = useState(null);

    useEffect(() => {
        setContainerClassName('edit-profile');

        return () => {
            setContainerClassName(null);
        };
    }, [setContainerClassName]);

    // We still need to display the ineligible pages if someone edits their details into an invalid state
    const userIneligible = useMemo(() => Boolean(state?.userIneligible), [state]);

    // If there is a back route set, then we're on an optional question, so the back link should take you to the master
    // question, not the summary.
    const backRoute = useMemo(() => state?.backRoute, [state]);

    const navigateToSummary = useCallback(() => {
        // Go back to the summary...
        navigate(
            document.referrer.endsWith(Routes.REORDER_KIT_CHECK_YOUR_DETAILS)
                ? -1
                : Routes.REORDER_KIT_CHECK_YOUR_DETAILS,
        );
    }, []);

    const onBackLinkHandler = useCallback(
        (event) => {
            event.preventDefault();

            // If we're displaying an ineligible page (age out of range, ineligible State), then we know we can just go
            // back one in the history, we could've tried to wire up the backRoute stuff below, but we don't need to
            // navigate internally, just refresh the existing route...
            if (userIneligible) {
                navigate(-1);
            }
            // There should only be a backRoute if we're on an optional question, as fields aren't persisted until the
            // save button is clicked on the last of those, it should be safe to just go back without checking anything
            // is dirty...
            else if (backRoute) {
                navigate(backRoute);
            }
            else {
                // This is prototypey and just to get it working, we need to come up with a better holistic solution.
                let fieldsToPersist = JSON.parse(
                    sessionStorage.getItem(TRANSIENT_PERSIST_FIELDS_KEY) ?? '{}',
                );
                let persistKeys = Object.keys(fieldsToPersist);

                if (!persistKeys.length) {
                    // If there are no fields to persist from an optional question chain, then drop back to the "normal"
                    // way of doing things.
                    fieldsToPersist = persistFields ? persistFields() : {};
                    persistKeys = Object.keys(fieldsToPersist);
                }

                if (persistKeys.length) {
                    // Filter out any irrelevant fields from the profile...
                    const originalFieldValues = Object.entries(currentUserProfile).reduce(
                        (memo, [key, value]) => {
                            if (persistKeys.includes(key)) {
                                return {
                                    ...memo,
                                    [key]: value,
                                };
                            }

                            return memo;
                        },
                        {},
                    );

                    if (deepEqual(fieldsToPersist, originalFieldValues)) {
                        // The original values equal the current values, so just go back
                        navigateToSummary();
                    }
                    else {
                        // The original values have been modified, so ask the user if they are you sure they want to
                        // leave without saving
                        setIsConfirmExitModalOpen(true);
                    }
                }
                else {
                    // No fields to persist, so just go back...
                    navigateToSummary();
                }
            }
        },
        [currentUserProfile, persistFields, userIneligible],
    );

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            const formValid = event.target.checkValidity();
            if (formValid) {
                // If there are fields to persist...
                if (persistFields) {
                    try {
                        // Protect against idiocy...
                        const fields = persistFields();
                        if (fields) {
                            await updateCurrentUserProfile({body: fields});

                            // See the EthnicityOptionalQuestionInterceptor comments on why this now lives here.
                            sessionStorage.removeItem(TRANSIENT_PERSIST_FIELDS_KEY);
                        }
                        else {
                            devConsole(
                                'A persistFields function was set, but it returned no fields to persist',
                            );
                        }
                    }
                    catch (err) {
                        devConsole(err);
                        return;
                    }
                }

                // Go back to the summary, only if we don't need to visit an optional question...
                if (navigateToOptionalQuestion) {
                    setNavigateToOptionalQuestion(null);
                    navigate(navigateToOptionalQuestion, {state: {backRoute: pathname}});
                }
                else {
                    navigateToSummary();
                }
            }
        },
        [navigateToOptionalQuestion, persistFields],
    );

    const cancelEditingHandler = useCallback((event) => {
        event.preventDefault();
        navigateToSummary();
    }, []);

    const continueEditingHandler = useCallback(() => {
        setIsConfirmExitModalOpen(false);
    }, []);

    return (
        <>
            <BackLink
                className={'less-margin'}
                onClick={onBackLinkHandler}
            />
            <form
                noValidate={true}
                onSubmit={onSubmitHandler}
            >
                <Outlet
                    context={{
                        currentUserProfile,
                        errorResponse,
                        setNavigateToOptionalQuestion,
                        setPersistFields,
                    }}
                />
                {!userIneligible && (
                    <Button
                        className={'icon arrow--right after'}
                        dataTestId={'sign-up-page-continue'}
                        isLoading={mutationLoading}
                        submit={true}
                        title={intl.formatMessage({id: 'signUp.edit.saveAndContinue.title'})}
                        variant={Variant.PRIMARY}
                    >
                        <FormattedMessage id={'signUp.edit.saveAndContinue.text'} />
                    </Button>
                )}
            </form>
            <Modal
                className={'confirm-exit dialog'}
                contentLabel={intl.formatMessage({id: 'signUp.edit.editDialog.contentLabel'})}
                isOpen={isConfirmExitModalOpen}
                overlayClassName={'modal overlay'}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                shouldFocusAfterRender={true}
                testId={'confirm-exit-dialog'}
            >
                <div className={'question'}>
                    <h3 className={'text'}>
                        <FormattedMessage id={'signUp.edit.editDialog.question'} />
                    </h3>
                    <span
                        className={'icon after close'}
                        data-test-id={'close-dialog-icon'}
                        onClick={continueEditingHandler}
                    />
                </div>
                <Divider />
                <div className={'actions'}>
                    <Link
                        className={'cancel-action'}
                        dataTestId={'close-dialog-link'}
                        onClick={cancelEditingHandler}
                        url={'#'}
                    >
                        <FormattedMessage id={'signUp.edit.editDialog.cancel'} />
                    </Link>
                    <Button
                        className={'continue-action'}
                        dataTestId={'close-dialog-button'}
                        onClick={continueEditingHandler}
                        variant={Variant.PRIMARY}
                    >
                        <FormattedMessage id={'signUp.edit.editDialog.continue'} />
                    </Button>
                </div>
            </Modal>
        </>
    );
}
