import {
    Route,
    Routes,
} from 'react-router-dom';
import {MinimalLayout} from '../../layouts';
import {PathValidator} from '../auth';
import {NotFound} from '../not-found';
import {HowWeCalculateRisk} from './how-we-calculate-risk';
import {ResultsOverviewRedirect} from './ResultsOverviewRedirect';
import {TraitResult} from './trait-result';

export function ResultsController() {
    return (
        <Routes>
            <Route path={'/'}>
                <Route
                    element={<ResultsOverviewRedirect />}
                    path={'/'}
                />
                <Route
                    element={<HowWeCalculateRisk />}
                    path={'how-we-calculate-risk'}
                />
                <Route
                    element={
                        <PathValidator
                            allowedValues={[
                                'atrial-fibrillation',
                                'breast-cancer',
                                'cardiovascular-disease',
                                'hypertension',
                                'cholesterol',
                                'prostate-cancer',
                                'type-2-diabetes',
                                'osteopenia',
                            ]}
                            paramName={'trait'}
                        >
                            <TraitResult />
                        </PathValidator>
                    }
                    path={':trait'}
                />
            </Route>
            <Route element={<MinimalLayout />}>
                <Route
                    element={<NotFound />}
                    path={'*'}
                />
            </Route>
        </Routes>
    );
}
