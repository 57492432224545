import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {LinkWithAttribution} from '../../../../components';

export function Type2Diabetes() {
    const intl = useIntl();

    return (
        <>
            <div className={'heading is-caption is-heavy'}>
                <FormattedMessage id={'reports.trait.learnMoreAbout.common.heading'} />
            </div>
            <h4>
                <FormattedMessage id={'common.trait.T2D.title'} />
            </h4>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.T2D.paragraph.one'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.T2D.paragraph.two'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.T2D.paragraph.three'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.T2D.paragraph.four'} />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.CDCP'})}
                url={'https://www.cdc.gov/diabetes/basics/type2.html'}
            >
                <FormattedMessage id={'reports.trait.learnMoreAbout.T2D.link.one'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.AHA'})}
                url={'https://www.heart.org/en/healthy-living/healthy-lifestyle/lifes-essential-8'}
            >
                <FormattedMessage id={'reports.trait.learnMoreAbout.T2D.link.two'} />
            </LinkWithAttribution>
        </>
    );
}
