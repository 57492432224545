import {
    Link,
    MoreInfo,
} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {
    PerformanceGraph,
    SixFiveGridContainer,
} from '../../components';
import {Routes} from '../../Routes';

export function AccuracyContent() {
    const intl = useIntl();

    return (
        <SixFiveGridContainer className={'reversed accuracy'}>
            <div className={'five'}>
                <h4>
                    <FormattedMessage id={'landing.accuracy.title'} />
                </h4>
                <p>
                    <FormattedMessage id={'landing.accuracy.content'} />
                </p>
                <InternalLink
                    className={'link button'}
                    data-test-id={'discover-our-science-button'}
                    title={intl.formatMessage({id: 'landing.accuracy.button.title'})}
                    to={Routes.OUR_SCIENCE}
                >
                    <FormattedMessage id={'landing.accuracy.button.text'} />
                </InternalLink>
            </div>
            <div className={'six chart'}>
                <PerformanceGraph
                    performanceData={[
                        {
                            name: intl.formatMessage({id: 'common.trait.AF.title'}),
                            performance: {
                                them: 0.62,
                                us: 0.65,
                            },
                        },
                        {
                            name: intl.formatMessage({id: 'common.trait.T2D.title'}),
                            performance: {
                                them: 0.65,
                                us: 0.71,
                            },
                        },
                    ]}
                    scale={{
                        max: 0.75,
                        min: 0.5,
                    }}
                    versus={intl.formatMessage({id: 'common.performanceGraph.23andMe'})}
                />
                <div className={'is-caption is-second'}>
                    <MoreInfo
                        content={
                            <>
                                <p>
                                    <FormattedMessage
                                        id={'common.chartData.moreInfo.reference.one'}
                                        values={{
                                            link: (chunks) => (
                                                <Link
                                                    className={'alternate'}
                                                    rel={'noreferrer'}
                                                    target={'_blank'}
                                                    url={'/documents/genomicsplc-HI-validation-paper-2023.pdf'}
                                                >
                                                    {chunks}
                                                </Link>
                                            ),
                                        }}
                                    />
                                </p>
                                <p>
                                    <FormattedMessage
                                        id={'common.chartData.moreInfo.reference.two'}
                                        values={{
                                            link1: (chunks) => (
                                                <Link
                                                    className={'alternate'}
                                                    rel={'noreferrer'}
                                                    target={'_blank'}
                                                    url={'https://permalinks.23andme.com/pdf/23_19-Type2Diabetes_March2019.pdf'}
                                                >
                                                    {chunks}
                                                </Link>
                                            ),
                                            link2: (chunks) => (
                                                <Link
                                                    className={'alternate'}
                                                    rel={'noreferrer'}
                                                    target={'_blank'}
                                                    url={'https://permalinks.23andme.com/pdf/23_21-PRSMethodologyAppendix_May2020.pdf'}
                                                >
                                                    {chunks}
                                                </Link>
                                            ),
                                        }}
                                    />
                                </p>
                            </>
                        }
                        dataTestId={'more-info-chart-data'}
                        title={intl.formatMessage({id: 'common.chartData.moreInfo.title'})}
                    />
                </div>
            </div>
        </SixFiveGridContainer>
    );
}
