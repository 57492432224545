import {FormattedMessage} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {ProfileInformation} from '../../../components';

export function Summary() {
    const {currentUserProfile = {}} = useOutletContext();

    return (
        <>
            <h2 data-test-id={'sign-up-summary-title'}>
                <FormattedMessage id={'signUp.summary.title'} />
            </h2>
            <ProfileInformation
                allowEditing={true}
                {...currentUserProfile}
            />
        </>
    );
}
