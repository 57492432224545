import classNames from 'classnames';
import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link} from 'react-router-dom';
import {TRANSIENT_PERSIST_FIELDS_KEY} from '../../util';

export function QuestionAndAnswer(props) {
    const {
        answer,
        answerClassName,
        dataTestId,
        editRoute,
        question,
    } = props;

    const intl = useIntl();

    const onClickHandler = useCallback(() => {
        // Blank any transient state for optional questions...
        sessionStorage.removeItem(TRANSIENT_PERSIST_FIELDS_KEY);
    }, []);

    const classes = classNames('question-and-answer', 'variant-dark', {'is-editable': editRoute});
    const answerClasses = classNames('answer', 'is-heavy', answerClassName);

    return (
        <div
            className={classes}
            data-test-id={dataTestId}
        >
            <div className={'question is-caption is-heavy'}>{question}</div>
            {editRoute && (
                <Link
                    className={'link edit is-caption is-heavy alternate'}
                    data-test-id={`${dataTestId}-link`}
                    onClick={onClickHandler}
                    title={intl.formatMessage({id: 'common.edit.title'})}
                    to={editRoute}
                >
                    <span>
                        <FormattedMessage id={'common.edit.text'} />
                    </span>
                </Link>
            )}
            <div
                className={answerClasses}
                data-test-id={`${dataTestId}-answer`}
            >
                {answer}
            </div>
        </div>
    );
}
