import {Link} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
} from '../../../../../../components';

export function OsteopeniaRiskFactors(props) {
    const {
        currentStep,
        reportedSex,
        totalSteps,
    } = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`risk-factors-FNDXAT_N1P0-${reportedSex}`}
            title={intl.formatMessage({id: `reports.trait.actionPlan.actionCard.discussRiskFactors.title`})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.description'}
                    values={{trait: intl.formatMessage({id: `common.trait.FNDXAT_N1P0.text`})}}
                />
            </p>
            <ul>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.smoking'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.lowWeight'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.tooMuchAlcohol'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.activity'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.calcium'}
                    />
                </li>
                {reportedSex !== 'MALE' && (
                    <li>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussRiskFactors.menopause'}
                        />
                    </li>
                )}
            </ul>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.NIH`})}
                formatted={true}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.link.FNDXAT_N1P0'}
                    values={{
                        link: (chunks) => (
                            <Link
                                rel={'noreferrer'}
                                target={'_blank'}
                                url={`https://www.bones.nih.gov/health-info/bone/SGR/surgeon-generals-report#r`}
                            >
                                {chunks}
                            </Link>
                        ),
                    }}
                />
            </LinkWithAttribution>
        </ActionCard>
    );
}
