import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
    SupplementalBox,
} from '../../../../../../components';
import {
    RiskLevel,
    Trait,
} from '../../../../../../util';

export function AtrialFibrillationScreenOptions(props) {
    const {
        currentStep,
        totalSteps,
        riskLevel,
    } = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`screen-options-AF-${riskLevel}`}
            title={intl.formatMessage({
                id: `reports.trait.actionPlan.actionCard.discussScreeningOptions.title.${riskLevel}`,
            })}
            totalSteps={totalSteps}
        >
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.electrocardiogram.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.electrocardiogram.description.one'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.electrocardiogram.description.two'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.electrocardiogram.description.three'}
                />
            </p>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.smartWatch.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.smartWatch.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.NHLBI'})}
                url={'https://www.nhlbi.nih.gov/health/atrial-fibrillation/diagnosis'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.AF.diagnosis.link'}
                />
            </LinkWithAttribution>
            {riskLevel === RiskLevel.HIGHER && (
                <SupplementalBox
                    title={intl.formatMessage({
                        id: 'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.heading',
                    })}
                >
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.noneRecommended'}
                            values={{trait: intl.formatMessage({id: `common.trait.${Trait.AF.code}.text`})}}
                        />
                    </p>
                </SupplementalBox>
            )}
        </ActionCard>
    );
}
