import {PageContent} from './PageContent';

export function WhileUnauthenticated() {
    return (
        <PageContent
            buttonDataTestId={'login-button'}
            buttonKey={'testReorder.order.loggedOut.button.text'}
            buttonTitleKey={'testReorder.order.loggedOut.button.title'}
            titleDataTestId={'test-reorder-while-unauthenticated'}
            titleKey={'testReorder.order.loggedOut.title'}
        />
    );
}
