import classNames from 'classnames';
import {useIntl} from 'react-intl';

export function LogotypeIcon(props) {
    const {className, dataTestId} = props;

    const intl = useIntl();

    const logotypeClasses = classNames('logotype-icon', className);

    return (
        <img
            alt={intl.formatMessage({id: 'common.logotype.alt'})}
            className={logotypeClasses}
            data-test-id={dataTestId ?? 'health-insights-icon-navigation'}
            src={'/images/health-insights-icon.svg'}
        />
    );
}
