import {useEffect} from 'react';
import {useHubSpot} from '../hooks';
import {useUserContext} from './UserContext';

export function HubSpotLoader({children}) {
    const {
        clear,
        load,
        setUserEmail,
    } = useHubSpot();
    const {user} = useUserContext();

    useEffect(() => {
        load();
        clear();
        if (user?.emailVerified && user?.hsVerificationToken) {
            setUserEmail(user.emailAddress, user.hsVerificationToken);
        }
    }, [user]);

    return children;
}
