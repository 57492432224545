import {MoreInfo} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function LeadershipContent() {
    const intl = useIntl();

    return (
        <>
            <div className={'flex-column-to-row'}>
                <img
                    alt={intl.formatMessage({id: 'aboutUs.leadership.peter.alt'})}
                    src={'/images/headshot-peter-donnelly_800x800_v1_99KB.webp'}
                />
                <div>
                    <p>
                        <FormattedMessage
                            id={'aboutUs.leadership.peter'}
                            values={{bold: (chunk) => <span className={'is-heavy'}>{chunk}</span>}}
                        />
                    </p>
                    <MoreInfo
                        content={intl.formatMessage({id: 'aboutUs.leadership.peter.more.content'})}
                        dataTestId={'leadership-peter-more-info'}
                        title={intl.formatMessage({id: 'aboutUs.leadership.peter.more.title'})}
                    />
                </div>
            </div>
            <div className={'flex-column-to-row'}>
                <img
                    alt={intl.formatMessage({id: 'aboutUs.leadership.gil.alt'})}
                    src={'/images/headshot-gil-mcvean_800x800_v1_46KB.webp'}
                />
                <div>
                    <p>
                        <FormattedMessage
                            id={'aboutUs.leadership.gil'}
                            values={{bold: (chunk) => <span className={'is-heavy'}>{chunk}</span>}}
                        />
                    </p>
                    <MoreInfo
                        content={intl.formatMessage({id: 'aboutUs.leadership.gil.more.content'})}
                        dataTestId={'leadership-gil-more-info'}
                        title={intl.formatMessage({id: 'aboutUs.leadership.gil.more.title'})}
                    />
                </div>
            </div>
        </>
    );
}
