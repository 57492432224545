import {useParams} from 'react-router-dom';
import {NotFound} from '../not-found';

export function PathValidator({paramName, allowedValues, children}) {
    const params = useParams();
    const paramToCheck = params[paramName];

    if (allowedValues.includes(paramToCheck)) {
        return children;
    }

    return <NotFound />;
}
