import classNames from 'classnames';
import {
    AboutUsLink,
    HomeLink,
    HowItWorksLink,
    ScienceLink,
    TrustAndPrivacyLink,
} from '../navigation-links';

export function NavbarMarketingLinks(props) {
    const {compact} = props;

    const linkClasses = classNames('no-decoration', 'is-heavy', {'is-caption': compact});

    return (
        <>
            <li>
                <HomeLink className={linkClasses} />
            </li>
            <li>
                <HowItWorksLink className={linkClasses} />
            </li>
            <li>
                <ScienceLink className={linkClasses} />
            </li>
            <li>
                <TrustAndPrivacyLink className={linkClasses} />
            </li>
            <li>
                <AboutUsLink className={linkClasses} />
            </li>
        </>
    );
}
