/* eslint-disable no-magic-numbers */
import {
    Breakpoint,
    useBreakpoints,
} from '@genomicsplc/denim-components';
import classNames from 'classnames';
import jstat from 'jstat';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {useFormatPercent} from '../../../../hooks';

const imageSDWidth = 6; // SD stands for "Standard Deviation"
const imageSDCutoff = 2.45; // Used to specify min and max SD position of lollipop
const highThresholdPercentile = 80;

const calculateOffset = (width, prsPercentile) => {
    const prsPercentileAsProbability = prsPercentile / 100;
    const riskSD = jstat.normal.inv(prsPercentileAsProbability, 0, 1);
    const adjustedRiskSD = Math.min(Math.max(-imageSDCutoff, riskSD), imageSDCutoff);
    return (adjustedRiskSD / imageSDWidth) * width - 4;
};

// Used for the threshold percentages so they can be offset from absolute positioning
const calculateAbsoluteOffset = (offset, width) => offset + width * 0.44 + 4;
const calculateHeightOffset = (width, desktop) => (desktop ? -(width * 0.21 + 16) : -(width * 0.23 + 15));
const calculateFontSize = (width, desktop) => (desktop ? width * 0.035 : width * 0.04);

export function BellCurveVisualisation(props) {
    const {
        highThreshold,
        integratedRiskScore,
        riskLevel,
        prsPercentile,
    } = props;

    const formatPercent = useFormatPercent();
    const bellCurveContainerEl = useRef();
    const [width, setWidth] = useState(0);
    const offset = useMemo(() => calculateOffset(width, prsPercentile), [width]);
    const highThresholdOffset = useMemo(
        () => calculateOffset(width, highThresholdPercentile),
        [width],
    );
    const currentBreakpoint = useBreakpoints();
    const usingDesktopImage = [Breakpoint.TABLET_LANDSCAPE, Breakpoint.DESKTOP].includes(
        currentBreakpoint,
    );

    // This function sets the width of the figure
    const getFigureSize = useCallback(() => {
        setWidth(bellCurveContainerEl.current.offsetWidth);
    }, []);

    useEffect(() => {
        setWidth(bellCurveContainerEl.current.offsetWidth);
        window.addEventListener('resize', getFigureSize);

        return () => {
            window.removeEventListener('resize', getFigureSize);
        };
    }, []);

    const bellCurveClasses = classNames('bell-curve', 'visualisation', 'flex-column', [
        riskLevel.toLocaleLowerCase(),
    ]);
    const textClasses = classNames('text', 'is-heavy');
    const riskGradientTextClasses = classNames(textClasses, 'risk-gradient', [
        riskLevel.toLocaleLowerCase(),
    ]);
    const percentileTextClasses = classNames(textClasses, 'percentile');
    const sweetClasses = classNames('sweet', 'is-heavy', 'risk-gradient', [
        riskLevel.toLocaleLowerCase(),
    ]);
    const stickClasses = classNames('stick', 'risk-gradient', [riskLevel.toLocaleLowerCase()]);

    return (
        <figure
            className={bellCurveClasses}
            ref={bellCurveContainerEl}
        >
            <span
                className={riskGradientTextClasses}
                style={{transform: `translate(${offset}px)`}}
            >
                {'Your risk'}
            </span>
            <div
                className={sweetClasses}
                style={{transform: `translate(${offset}px)`}}
            >
                <svg viewBox={'0 0 64 64'}>
                    <text
                        textAnchor={'middle'}
                        x={'32'}
                        y={'43'}
                    >
                        {`${Math.round(integratedRiskScore * 100)}`}
                        <tspan fontSize={'18'}>{'%'}</tspan>
                    </text>
                </svg>
            </div>
            <div
                className={stickClasses}
                style={{transform: `translate(${offset}px)`}}
            />
            <div className={'percentiles'}>
                <div
                    className={'percentile-box'}
                    style={{
                        transform: `translate(${
                            calculateAbsoluteOffset(
                                highThresholdOffset,
                                width,
                            )
                        }px, ${calculateHeightOffset(width, usingDesktopImage)}px)`,
                        width: `${width * 0.1}px`,
                    }}
                >
                    <span
                        className={percentileTextClasses}
                        style={{fontSize: `${calculateFontSize(width, usingDesktopImage)}px`}}
                    >
                        {formatPercent(highThreshold, false)}
                    </span>
                </div>
            </div>
        </figure>
    );
}
