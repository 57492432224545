import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

export function useSanitisedPathname() {
    const {pathname} = useLocation();

    return useMemo(() => {
        let sanitised = pathname.substring(1);
        sanitised = sanitised.replace('/', '-');

        if (sanitised.endsWith('-')) {
            sanitised = sanitised.slice(0, -1);
        }

        return sanitised;
    }, [pathname]);
}
