import {Trait} from '../../../../../../util';
import {AtrialFibrillationWeight} from './AtrialFibrillationWeight';
import {BreastCancerWeight} from './BreastCancerWeight';
import {CardiovascularDiseaseWeight} from './CardiovascularDiseaseWeight';
import {HighBloodPressureWeight} from './HighBloodPressureWeight';
import {HighLDLCholesterolWeight} from './HighLDLCholesterolWeight';
import {OsteopeniaWeight} from './OsteopeniaWeight';
import {ProstateCancerWeight} from './ProstateCancerWeight';
import {Type2DiabetesWeight} from './Type2DiabetesWeight';

export function Weight(props) {
    const {
        currentStep,
        totalSteps,
        traitCode,
    } = props;

    let WeightComponent = null;
    switch (traitCode) {
        case Trait.AF.code:
            WeightComponent = AtrialFibrillationWeight;
            break;

        case Trait.BC.code:
            WeightComponent = BreastCancerWeight;
            break;

        case Trait.CVD_US.code:
            WeightComponent = CardiovascularDiseaseWeight;
            break;

        case Trait.HT.code:
            WeightComponent = HighBloodPressureWeight;
            break;

        case Trait.HCH.code:
            WeightComponent = HighLDLCholesterolWeight;
            break;

        case Trait.FNDXAT_N1P0.code:
            WeightComponent = OsteopeniaWeight;
            break;

        case Trait.PC.code:
            WeightComponent = ProstateCancerWeight;
            break;

        case Trait.T2D.code:
            WeightComponent = Type2DiabetesWeight;
            break;

        default:
            break;
    }

    return (
        <WeightComponent
            currentStep={currentStep}
            totalSteps={totalSteps}
            traitCode={traitCode}
        />
    );
}
