import {Button} from '@genomicsplc/denim-components';
import classNames from 'classnames';
import {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {LogotypeIcon} from '../../../components';
import {useQuery} from '../../../hooks';
import {Endpoint} from '../../../util';
import {
    AccountMenu,
    ActivateKitLink,
    CalculateRiskLink,
    HelpCenterLink,
    NavbarMarketingLinks,
    TrustAndPrivacyLink,
} from '../common';

export function MobileNavbarMenuContent(props) {
    const {onClose, marketingRoutes = []} = props;

    const intl = useIntl();
    const {pathname} = useLocation();
    const {data} = useQuery(Endpoint.SUMMARY);

    const displayMarketingLinks = useMemo(() => marketingRoutes.includes(pathname), [pathname]);

    const hasReport = useMemo(() => {
        const {resultStatus = 'NONE'} = data ?? {};
        return resultStatus === 'READY';
    }, [data]);

    const onCloseClickHandler = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    const linkClasses = classNames('no-decoration', 'is-heavy');

    return (
        <>
            <div className={'header'}>
                <LogotypeIcon />
                <Button
                    dataTestId={'mobile-nav-close'}
                    onClick={onCloseClickHandler}
                    title={intl.formatMessage({id: 'navbar.closeButton.title'})}
                >
                    <FormattedMessage id={'navbar.closeButton.text'} />
                </Button>
            </div>
            <div className={'content'}>
                <ul className={'main-menu no-decoration no-margin'}>
                    <AccountMenu />
                    {displayMarketingLinks ? <NavbarMarketingLinks compact={false} /> : (
                        <>
                            {hasReport && (
                                <li>
                                    <CalculateRiskLink className={linkClasses} />
                                </li>
                            )}
                            <li>
                                <TrustAndPrivacyLink className={linkClasses} />
                            </li>
                        </>
                    )}
                    {displayMarketingLinks && (
                        <li>
                            <ActivateKitLink
                                className={linkClasses}
                                location={'mobile-menu-drawer'}
                            />
                        </li>
                    )}
                    <li>
                        <HelpCenterLink className={linkClasses} />
                    </li>
                </ul>
            </div>
        </>
    );
}
