import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {
    GenomicsGroup,
    PageContent,
    TitleContainer,
    TwoThirdsContainer,
} from '../../components';
import {useAmplitude} from '../../context';
import {LeadershipContent} from './LeadershipContent';
import {ProjectsContent} from './ProjectsContent';
import {ScientistsContent} from './ScientistsContent';
import {StoryContent} from './StoryContent';

export function AboutUs() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {setMainClassName} = useOutletContext();

    useEffect(() => {
        amplitude.logAboutUsVisit();
        setMainClassName('wavy-line-background');

        return () => {
            setMainClassName(null);
        };
    }, [amplitude]);

    return (
        <>
            <PageContent className={'page-title variant-dark'}>
                <h1 data-test-id={'about-us-page-title'}>
                    <FormattedMessage id={'aboutUs.title.text'} />
                </h1>
                <p>
                    <FormattedMessage id={'aboutUs.title.content'} />
                </p>
            </PageContent>
            <PageContent className={'variant-dark story'}>
                <TwoThirdsContainer
                    ContentComponent={<StoryContent />}
                    TitleComponent={<TitleContainer title={intl.formatMessage({id: 'aboutUs.story.title'})} />}
                />
            </PageContent>
            <PageContent className={'variant-dark mid-navy-gradient projects'}>
                <TwoThirdsContainer
                    ContentComponent={<ProjectsContent />}
                    TitleComponent={<TitleContainer title={intl.formatMessage({id: 'aboutUs.projects.title'})} />}
                />
            </PageContent>
            <PageContent className={'variant-dark people'}>
                <TwoThirdsContainer
                    ContentComponent={<LeadershipContent />}
                    TitleComponent={<TitleContainer title={intl.formatMessage({id: 'aboutUs.leadership.title'})} />}
                />
                <TwoThirdsContainer
                    ContentComponent={<ScientistsContent />}
                    TitleComponent={<TitleContainer title={intl.formatMessage({id: 'aboutUs.scientists.title'})} />}
                />
            </PageContent>
            <PageContent className={'variant-dark genomics-group'}>
                <TwoThirdsContainer ContentComponent={<GenomicsGroup />} />
            </PageContent>
        </>
    );
}
