import classNames from 'classnames';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Routes} from '../../../../Routes';

export function LogoutLink(props) {
    const {className, dataTestId} = props;

    const intl = useIntl();

    const linkClasses = classNames('link', className);

    return (
        <NavLink
            className={linkClasses}
            data-test-id={dataTestId ?? 'account-menu-logout-link'}
            title={intl.formatMessage({id: 'common.logOut.title'})}
            to={Routes.LOGOUT}
        >
            <span>
                <FormattedMessage id={'common.logOut.text'} />
            </span>
        </NavLink>
    );
}
