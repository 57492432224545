import {
    createContext,
    useContext,
    useReducer,
} from 'react';

const SET_CACHE_VALUE = 'SET_CACHE_VALUE';

const init = () => ({});

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_CACHE_VALUE:
            return {
                ...state,
                [payload.key]: payload.value,
            };

        default:
            return state;
    }
};

const action = (type, payload) => ({
    payload,
    type,
});

const CacheContext = createContext({});

export function useCacheContext() {
    const context = useContext(CacheContext);

    if (typeof context === 'undefined') {
        throw new Error('Cache context must be used within a "CacheProvider"');
    }

    return context;
}

export function CacheProvider({children}) {
    const [cache, dispatch] = useReducer(reducer, null, init);

    const cacheHasKey = (key) => Object.keys(cache).includes(key);

    const getCachedValue = (key) => cache[key];

    const setCacheValue = (key, value) => {
        dispatch(
            action(SET_CACHE_VALUE, {
                key,
                value,
            }),
        );
    };

    const context = {
        cacheHasKey,
        getCachedValue,
        setCacheValue,
    };

    return <CacheContext.Provider value={context}>{children}</CacheContext.Provider>;
}
