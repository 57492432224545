import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {LinkWithAttribution} from '../../../../components';

export function ProstateCancer() {
    const intl = useIntl();

    return (
        <>
            <div className={'heading is-caption is-heavy'}>
                <FormattedMessage id={'reports.trait.learnMoreAbout.common.heading'} />
            </div>
            <h4>
                <FormattedMessage id={'common.trait.PC.title'} />
            </h4>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.PC.paragraph.one'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.PC.paragraph.two'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.PC.paragraph.three'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.PC.paragraph.four'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.PC.paragraph.five'} />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.ACS'})}
                url={'https://www.cancer.org/cancer/prostate-cancer.html'}
            >
                <FormattedMessage id={'reports.trait.learnMoreAbout.PC.link.one'} />
            </LinkWithAttribution>
        </>
    );
}
