import classNames from 'classnames';

export function Instruction(props) {
    const {
        className,
        image,
        text,
    } = props;

    const instructionClasses = classNames('instruction', className);

    return (
        <div className={instructionClasses}>
            <img
                alt={''}
                className={'image'}
                role={'presentation'}
                src={image}
            />
            <span className={'text'}>{text}</span>
        </div>
    );
}
