import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {GridContainer} from '../../../components';
import {Routes} from '../../../Routes';

export function AnalyseDna() {
    return (
        <GridContainer
            containerClassName={'analyse-dna'}
            gridClassName={'fifty-fifty'}
        >
            <div className={'left'}>
                <h4
                    className={'title'}
                    data-test-id={'reports-overview-analyse-dna'}
                >
                    <FormattedMessage id={'reports.overview.analyse-dna.title'} />
                </h4>
            </div>
            <div className={'right'}>
                <Link
                    className={'link button'}
                    data-test-id={'reports-overview-how-we-calculate-risk-button'}
                    to={Routes.REPORTS_CALCULATE_RISK}
                >
                    <FormattedMessage id={'reports.overview.analyse-dna.button'} />
                </Link>
            </div>
        </GridContainer>
    );
}
