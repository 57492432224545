import {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {useAmplitude} from '../../context';
import {Routes} from '../../Routes';

export function ActivateKitLink(props) {
    const {dataTestId, location} = props;

    const amplitude = useAmplitude();

    const onClickHandler = useCallback(() => {
        amplitude.logActivateKitLinkClicked(location);
    }, [amplitude, location]);

    return (
        <FormattedMessage
            id={'common.link.activateKit'}
            values={{
                link: (chunk) => (
                    <InternalLink
                        className={'link alternate'}
                        data-test-id={dataTestId}
                        onClick={onClickHandler}
                        to={Routes.ACTIVATE}
                    >
                        {chunk}
                    </InternalLink>
                ),
            }}
        />
    );
}
