import {Trait} from '../../../../../../util';
import {AtrialFibrillationDiet} from './AtrialFibrillationDiet';
import {BreastCancerDiet} from './BreastCancerDiet';
import {CardiovascularDiseaseDiet} from './CardiovascularDiseaseDiet';
import {HighBloodPressureDiet} from './HighBloodPressureDiet';
import {HighLDLCholesterolDiet} from './HighLDLCholesterolDiet';
import {OsteopeniaDiet} from './OsteopeniaDiet';
import {ProstateCancerDiet} from './ProstateCancerDiet';
import {Type2DiabetesDiet} from './Type2DiabetesDiet';

export function Diet(props) {
    const {
        currentStep,
        totalSteps,
        traitCode,
    } = props;

    let DietComponent = null;
    switch (traitCode) {
        case Trait.AF.code:
            DietComponent = AtrialFibrillationDiet;
            break;

        case Trait.BC.code:
            DietComponent = BreastCancerDiet;
            break;

        case Trait.CVD_US.code:
            DietComponent = CardiovascularDiseaseDiet;
            break;

        case Trait.HT.code:
            DietComponent = HighBloodPressureDiet;
            break;

        case Trait.HCH.code:
            DietComponent = HighLDLCholesterolDiet;
            break;

        case Trait.FNDXAT_N1P0.code:
            DietComponent = OsteopeniaDiet;
            break;

        case Trait.PC.code:
            DietComponent = ProstateCancerDiet;
            break;

        case Trait.T2D.code:
            DietComponent = Type2DiabetesDiet;
            break;

        default:
            break;
    }

    return (
        <DietComponent
            currentStep={currentStep}
            totalSteps={totalSteps}
        />
    );
}
