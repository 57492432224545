import {FormattedMessage} from 'react-intl';
import {
    FetchError,
    Loading,
    ProfileInformation,
} from '../../../components';
import {useQuery} from '../../../hooks';
import {
    Endpoint,
    extractErrorMessage,
} from '../../../util';

export function Profile() {
    const {
        data: currentUserProfile,
        error,
        loading,
    } = useQuery(Endpoint.PROFILE);

    return (
        <section className={'account-content account-and-security'}>
            <header>
                <img
                    alt={''}
                    className={'icon'}
                    role={'presentation'}
                    src={'/images/icon-profile.svg'}
                />
                <h2 data-test-id={'account-profile-title'}>
                    <FormattedMessage id={'account.profile.title'} />
                </h2>
            </header>
            {error ? <FetchError reason={extractErrorMessage(error)} /> : loading
                ? (
                    <div className={'center-within'}>
                        <Loading />
                    </div>
                )
                : <ProfileInformation {...currentUserProfile} />}
        </section>
    );
}
