import {
    useEffect,
    useMemo,
} from 'react';
import {FormattedMessage} from 'react-intl';
import {
    useOutletContext,
    useParams,
} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../../components';
import {useAmplitude} from '../../../context';
import {useQuery} from '../../../hooks';
import {
    Endpoint,
    extractErrorMessage,
    traitPathnameToCode,
} from '../../../util';
import {
    AccountHome,
    ReportsHeader,
} from '../components';
import {ActionPlan} from './action-plan';
import {KeepInMind} from './KeepInMind';
import {LearnMoreAbout} from './learn-more-about';
import {RiskLikelihood} from './risk-likelihood';

export function TraitResult() {
    const amplitude = useAmplitude();
    const {trait} = useParams();
    const traitCode = useMemo(() => traitPathnameToCode(trait), [trait]);
    const {setMainClassName} = useOutletContext();
    const {
        data: {firstName, reportedSex} = {},
        error: profileError,
        loading: profileLoading,
    } = useQuery(Endpoint.PROFILE);
    const {
        data: {integratedRiskScore, highThreshold, prsPercentile, riskLevel, riskWindow} = {},
        error: traitCodeError,
        loading: traitCodeLoading,
    } = useQuery(`${Endpoint.RESULTS}/${traitCode}`);

    useEffect(() => {
        amplitude.logTraitVisited(traitCode);
        setMainClassName('trait');

        return () => {
            setMainClassName(null);
        };
    }, [amplitude, traitCode]);

    if (profileLoading || traitCodeLoading) {
        return (
            <div className={'center-within no-margin'}>
                <Loading key={'loading'} />
            </div>
        );
    }

    if (profileError || traitCodeError) {
        return (
            <div
                className={'center-within no-margin'}
                data-test-id={`results-trait-${profileError ? 'profile' : 'results'}-error`}
            >
                <FetchError reason={extractErrorMessage(profileError ?? traitCodeError)} />
            </div>
        );
    }

    return (
        <>
            <div
                className={'grid'}
                data-test-id={`trait-result-${traitCode}`}
            >
                <ReportsHeader>
                    <AccountHome />
                    <h1
                        className={'trait-title'}
                        data-test-id={'reports-trait-title'}
                    >
                        <FormattedMessage id={`common.trait.${traitCode}.title`} />
                    </h1>
                    <p className={'trait-description no-margin-bottom'}>
                        <FormattedMessage
                            id={`reports.trait.${traitCode}.description`}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                </ReportsHeader>
                <RiskLikelihood
                    firstName={firstName}
                    highThreshold={highThreshold}
                    integratedRiskScore={integratedRiskScore}
                    prsPercentile={prsPercentile}
                    riskLevel={riskLevel}
                    riskWindow={riskWindow}
                    traitCode={traitCode}
                />
                <KeepInMind
                    riskLevel={riskLevel}
                    riskWindow={riskWindow}
                    traitCode={traitCode}
                />
                <ActionPlan
                    reportedSex={reportedSex}
                    riskLevel={riskLevel}
                    riskWindow={riskWindow}
                    traitCode={traitCode}
                />
            </div>
            <LearnMoreAbout traitCode={traitCode} />
        </>
    );
}
