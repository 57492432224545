import classNames from 'classnames';
import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {useAmplitude} from '../../../../context';
import {useSanitisedPathname} from '../../../../hooks';
import {Routes} from '../../../../Routes';

export function ActivateKitLink(props) {
    const {
        className,
        dataTestId,
        location,
    } = props;

    const amplitude = useAmplitude();
    const intl = useIntl();
    const pathname = useSanitisedPathname();

    const onClickHandler = useCallback(() => {
        amplitude.logActivateKitLinkClicked(location ?? `footer-${pathname}`);
    }, [amplitude, pathname]);

    const linkClasses = classNames('link', className);

    return (
        <NavLink
            className={linkClasses}
            data-test-id={dataTestId ?? 'navigation-activate-kit-link'}
            onClick={onClickHandler}
            title={intl.formatMessage({id: 'navigation.link.activateKit.title'})}
            to={Routes.ACTIVATE}
        >
            <span>
                <FormattedMessage id={'navigation.link.activateKit.text'} />
            </span>
        </NavLink>
    );
}
