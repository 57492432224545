import {
    Callout,
    Card,
    Variant,
} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Trait} from '../../../../util';
import {LifestyleActions} from './lifestyle-actions';
import {MedicalActions} from './medical-actions';
import {TalkToDoctor} from './TalkToDoctor';

export function ActionPlan(props) {
    const {
        reportedSex,
        riskLevel,
        riskWindow,
        traitCode,
    } = props;

    const intl = useIntl();

    const descriptionMessageId = riskLevel === 'HIGHER'
        ? `reports.trait.actionPlan.description.${riskLevel}.${
            [Trait.BC.code, Trait.PC.code].includes(traitCode) ? 'alternative' : 'other'
        }`
        : `reports.trait.actionPlan.description.${riskLevel}.${
            [Trait.AF.code, Trait.HCH.code, Trait.HT.code].includes(traitCode)
                ? traitCode
                : 'other'
        }`;

    const calloutMessage = traitCode === Trait.CVD_US.code ? 'CVD_US' : 'other';

    return (
        <Card
            className={'action-plan'}
            variant={Variant.DARK}
        >
            <section className={'grid'}>
                <h1 className={'heading'}>
                    <FormattedMessage id={'reports.trait.actionPlan.title'} />
                </h1>
                <p className={'description'}>
                    <FormattedMessage
                        id={descriptionMessageId}
                        values={{trait: intl.formatMessage({id: `common.trait.${traitCode}.text`})}}
                    />
                </p>
                <Callout>
                    <FormattedMessage
                        id={`reports.trait.actionPlan.callout.${calloutMessage}`}
                        values={{
                            br: () => <br />,
                            trait: intl.formatMessage({id: `common.trait.${traitCode}.text`}),
                        }}
                    />
                </Callout>
            </section>
            <MedicalActions
                reportedSex={reportedSex}
                riskLevel={riskLevel}
                riskWindow={riskWindow}
                traitCode={traitCode}
            />
            <LifestyleActions
                riskLevel={riskLevel}
                traitCode={traitCode}
            />
            <TalkToDoctor />
        </Card>
    );
}
