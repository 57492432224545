import {
    Button,
    Link,
} from '@genomicsplc/denim-components';
import {
    useCallback,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import Modal from 'react-modal';
import {useAmplitude} from '../../context';

export function BulletPointsContent() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onClickHandler = useCallback(
        (event) => {
            event.preventDefault();
            setIsModalOpen(true);
            amplitude.logLandingCostModalOpenedClicked();
        },
        [amplitude],
    );

    const onCloseHandler = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    return (
        <>
            <div className={'content'}>
                <div className={'point first'}>
                    <img
                        alt={intl.formatMessage({id: 'landing.bullets.cost.alt'})}
                        role={'presentation'}
                        src={'/images/icon-cost_vector_v1_2KB.svg'}
                    />
                    <div>
                        <h4>
                            <FormattedMessage id={'landing.bullets.cost.text'} />
                        </h4>
                        <p className={'is-caption is-heavy is-light'}>
                            <FormattedMessage
                                id={'landing.bullets.cost.more'}
                                values={{
                                    why: (chunk) => (
                                        <Link
                                            className={'alternate why-link'}
                                            onClick={onClickHandler}
                                            url={'#'}
                                        >
                                            {chunk}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                    </div>
                </div>
                <div className={'point second'}>
                    <img
                        alt={intl.formatMessage({id: 'landing.bullets.locked.alt'})}
                        role={'presentation'}
                        src={'/images/icon-locked_vector_v1_1KB.svg'}
                    />
                    <h4>
                        <FormattedMessage id={'landing.bullets.locked.text'} />
                    </h4>
                </div>
                <div className={'point third'}>
                    <img
                        alt={intl.formatMessage({id: 'landing.bullets.identify.alt'})}
                        role={'presentation'}
                        src={'/images/icon-identify_vector_v1_1KB.svg'}
                    />
                    <h4>
                        <FormattedMessage id={'landing.bullets.identify.text'} />
                    </h4>
                </div>
            </div>
            <Modal
                className={'dialog'}
                isOpen={isModalOpen}
                onRequestClose={onCloseHandler}
                overlayClassName={'modal overlay cost-to-you'}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                shouldFocusAfterRender={true}
                testId={'cost-clarification-modal'}
            >
                <div className={'content'}>
                    <h4 className={'text'}>
                        <FormattedMessage id={'landing.cost.title'} />
                    </h4>
                    <p>
                        <FormattedMessage id={'landing.cost.content'} />
                    </p>
                </div>
                <div className={'actions'}>
                    <Button
                        className={'close-action'}
                        dataTestId={'close-dialog-button'}
                        onClick={onCloseHandler}
                    >
                        <FormattedMessage id={'landing.cost.button'} />
                    </Button>
                </div>
            </Modal>
        </>
    );
}
