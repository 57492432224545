import {
    MoreInfo,
    RadioButton,
    RadioButtonFormGroup,
} from '@genomicsplc/denim-components';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {Ethnicities} from '../../../../util';
import {FetchValidationError} from '../../../fetch-validation-error';

export function IdentifiedEthnicity() {
    const intl = useIntl();
    const {
        currentUserProfile: {ethnicityIdentifyMost, ethnicities = []} = {},
        errorResponse,
        setPersistFields,
    } = useOutletContext();
    const [value, setValue] = useState(ethnicityIdentifyMost);

    useEffect(() => {
        setPersistFields(() => () => ({ethnicityIdentifyMost: value}));

        return () => {
            setPersistFields(null);
        };
    }, [value]);

    const onChangeHandler = useCallback((checkboxValues) => {
        setValue(checkboxValues);
    }, []);

    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-identified-ethnicity-title'}
            >
                <FormattedMessage id={'signUp.identifiedEthnicity.title'} />
            </h2>
            <MoreInfo
                content={
                    <>
                        <p className={'no-margin-top'}>
                            <FormattedMessage id={'signUp.identifiedEthnicity.moreInfo.one'} />
                        </p>
                        <p className={'no-margin-top'}>
                            <FormattedMessage id={'signUp.identifiedEthnicity.moreInfo.two'} />
                        </p>
                        <p className={'no-margin'}>
                            <FormattedMessage id={'signUp.identifiedEthnicity.moreInfo.three'} />
                        </p>
                    </>
                }
                title={intl.formatMessage({id: 'common.whyWeAskThisQuestion'})}
            />
            <FetchValidationError errorResponse={errorResponse} />
            <RadioButtonFormGroup
                dataTestId={'sign-up-identified-ethnicity-form-group'}
                errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.requiredOption'})}}
                name={'identifiedEthnicity'}
                onChange={onChangeHandler}
                required={true}
                value={ethnicityIdentifyMost ?? ''}
            >
                {[
                    <RadioButton
                        dataTestId={'ethnicity-black-or-african-american'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_BLACK}.description`,
                        })}
                        id={'ethnicity-black-or-african-american'}
                        key={Ethnicities.US_BLACK}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_BLACK}.text`})}
                        value={Ethnicities.US_BLACK}
                    />,
                    <RadioButton
                        dataTestId={'ethnicity-east-asian'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_EAS}.description`,
                        })}
                        id={'ethnicity-east-asian'}
                        key={Ethnicities.US_EAS}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_EAS}.text`})}
                        value={Ethnicities.US_EAS}
                    />,
                    <RadioButton
                        dataTestId={'ethnicity-native-american-or-alaskan-native'}
                        id={'ethnicity-native-american-or-alaskan-native'}
                        key={Ethnicities.US_AIAN}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_AIAN}.text`})}
                        value={Ethnicities.US_AIAN}
                    />,
                    <RadioButton
                        dataTestId={'ethnicity-native-hawaiian-or-pacific-islander'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_NHPI}.description`,
                        })}
                        id={'ethnicity-native-hawaiian-or-pacific-islander'}
                        key={Ethnicities.US_NHPI}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_NHPI}.text`})}
                        value={Ethnicities.US_NHPI}
                    />,
                    <RadioButton
                        dataTestId={'ethnicity-south-asian'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_SAS}.description`,
                        })}
                        id={'ethnicity-south-asian'}
                        key={Ethnicities.US_SAS}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_SAS}.text`})}
                        value={Ethnicities.US_SAS}
                    />,
                    <RadioButton
                        dataTestId={'ethnicity-south-east-asian'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_SEAS}.description`,
                        })}
                        id={'ethnicity-south-east-asian'}
                        key={Ethnicities.US_SEAS}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_SEAS}.text`})}
                        value={Ethnicities.US_SEAS}
                    />,
                    <RadioButton
                        dataTestId={'ethnicity-hispanic'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_HISP}.description`,
                        })}
                        id={'ethnicity-hispanic'}
                        key={Ethnicities.US_HISP}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_HISP}.text`})}
                        value={Ethnicities.US_HISP}
                    />,
                    <RadioButton
                        dataTestId={'ethnicity-white'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_WHITE}.description`,
                        })}
                        id={'ethnicity-white'}
                        key={Ethnicities.US_WHITE}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_WHITE}.text`})}
                        value={Ethnicities.US_WHITE}
                    />,
                    <RadioButton
                        dataTestId={'ethnicity-other'}
                        id={'ethnicity-other'}
                        key={Ethnicities.US_OTHER}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_OTHER}.text`})}
                        value={Ethnicities.US_OTHER}
                    />,
                ].filter((checkbox) => ethnicities.includes(checkbox.props.value))}
                <p>
                    <FormattedMessage id={'common.or'} />
                </p>
                <RadioButton
                    dataTestId={'ethnicity-equal'}
                    id={'ethnicity-equal'}
                    key={Ethnicities.US_EQUAL}
                    label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_EQUAL}.text`})}
                    value={Ethnicities.US_EQUAL}
                />
            </RadioButtonFormGroup>
        </>
    );
}
