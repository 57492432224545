import {
    Navigate,
    useLocation,
} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../components';
import {useQuery} from '../../hooks';
import {Routes} from '../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../util';

export function RegistrationGuard(props) {
    const {children} = props;

    const {pathname} = useLocation();
    const {
        data: {registration: {confirmed = false} = {}} = {},
        error,
        loading,
    } = useQuery(Endpoint.SUMMARY);

    if (loading) {
        return (
            <div className={'guard'}>
                <Loading key={'loading'} />
            </div>
        );
    }

    if (error) {
        return (
            <div className={'guard'}>
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    if (confirmed) {
        // If the user has completed registration, then they shouldn't be able to access any of the registration flow
        // pages, so send them back to their home page
        if (pathname.includes(Routes.SIGN_UP)) {
            return (
                <Navigate
                    replace={true}
                    to={Routes.HOME}
                />
            );
        }

        // Display whatever it is, as they've completed registration and aren't trying to re-access that flow
        return children;
    }

    // If they haven't completed registration, so if they're trying to access a registration flow page, then render it.
    // Otherwise, they're trying to access something outwith registration, so send them back to finish it. We send them
    // to the last page, then the prerequisites will cut in and direct them to the first unanswered question.
    return pathname.includes(Routes.SIGN_UP) ? children : (
        <Navigate
            replace={true}
            to={Routes.SIGN_UP_SUMMARY}
        />
    );
}
