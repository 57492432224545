import {Experiment} from '@amplitude/experiment-js-client';
import {devConsole} from '../util';

export class ExperimentClient {
    constructor(apiKey) {
        if (apiKey) {
            this.experiment = Experiment.initializeWithAmplitudeAnalytics(apiKey);
            devConsole('Amplitude ExperimentClient created');
        }
    }

    static load(apiKey) {
        return new ExperimentClient(apiKey);
    }

    async startExperiment() {
        if (this.experiment) {
            await this.experiment.start();
            devConsole('Amplitude Experiment started');
        }
    }

    isVariant(flag) {
        if (this.experiment) {
            const {value} = this.experiment.variant(flag);
            devConsole(`Amplitude Experiment: ${flag} is ${value === 'on'}`);
            return value === 'on';
        }

        return false;
    }
}
