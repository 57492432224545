import {StatusCodes} from 'http-status-codes';
import {
    devConsole,
    Endpoint,
} from '../util';

// File holds all the async promise functions related to contacting the backend APi to log in and out.
// To provide clear separation of responsibilities, these are async API wrappers only, and should not interface with
// any react components or functions.

export const LoginResult = Object.freeze({
    OtherError: Symbol('other-error'),
    Success: Symbol('success'),
    AccountDoesNotExist: Symbol('user-in-auth0-but-not-api'),
});

export const RegisterResult = Object.freeze({
    InvalidAccessCode: Symbol('invalid-access-code'),
    OtherError: Symbol('other-error'),
    Success: Symbol('success'),
});

// Class that represents a user account returned from backend API
class UserAccount {
    #id;
    #customerId;
    #emailAddress;
    #emailVerified;
    #hsVerificationToken;

    constructor(id, customerId, emailAddress, emailVerified, hsVerificationToken) {
        this.#id = id;
        this.#customerId = customerId;
        this.#emailAddress = emailAddress;
        this.#emailVerified = emailVerified;
        this.#hsVerificationToken = hsVerificationToken;
    }

    get id() {
        return this.#id;
    }

    get customerId() {
        return this.#customerId;
    }

    get emailAddress() {
        return this.#emailAddress;
    }

    get emailVerified() {
        return this.#emailVerified;
    }

    get hsVerificationToken() {
        return this.#hsVerificationToken;
    }
}

// Async function to get user info from backend API. Abstracted to its own function, so we can call it in multiple places.
// Returns a UserAccount class if the user was logged in, or null if not logged in. Throws an error if something went wrong.
export const getUserInfo = async (apiUrl) => {
    devConsole('getUserInfo: Making request for user info');
    const response = await fetch(`${apiUrl}/${Endpoint.ACCOUNT}`, {credentials: 'same-origin'});

    // if code is 401, the user is not authenticated, maybe the session associated with the cookie has expired, and we
    // can continue to try to log them in
    if (response.status === StatusCodes.UNAUTHORIZED) {
        devConsole('getUserInfo: User info request returned 401');
        return null;
    }

    if (!response.ok) {
        // something happened here we can't recover from, so return an error
        devConsole(
            'getUserInfo: User info request returned error we cant handle',
        );
        throw new Error('getUserInfo: User info request returned error we cant handle');
    }

    devConsole('getUserInfo: Got an OK response');
    // If we got a valid user return it
    const data = await response.json();
    const account = new UserAccount(
        data.id,
        data.customerId,
        data.emailAddress,
        data.emailVerified,
        data.hsVerificationToken,
    );
    devConsole('getUserInfo: Found account', account);
    return account;
};

/**
 * Called by application when it wants to register with Health Insights backend (usually after a return callback
 * from Auth0).
 * @param {string} accessCode - Users access code. This is passed through Auth0 and then to the API to register when
 * handling the callback
 * @param {string} idToken - Encrypted Oauth ID token to send to the backend as authentication while signing up
 * @param {string} apiRoot - Root URL of the API, including http:// prefix
 */
export const register = async (accessCode, idToken, apiRoot) => {
    if (!idToken) {
        return RegisterResult.OtherError;
    }
    let response = null;
    try {
        devConsole(`register: Making API request`);
        response = await fetch(`${apiRoot}/${Endpoint.REGISTER}`, {
            body: JSON.stringify({
                accessCode,
                idToken,
            }),
            credentials: 'same-origin',
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
        });
    }
    catch (err) {
        devConsole(`register: Request returned error`);
        return RegisterResult.OtherError;
    }
    if (response.ok) {
        return RegisterResult.Success;
    }

    if (response.status === StatusCodes.BAD_REQUEST) {
        devConsole(`register: attempt failed due to invalid access code`);
        return RegisterResult.InvalidAccessCode;
    }
    devConsole(`register: request failed: status code - ${response.status}`);
    return RegisterResult.OtherError;
};

/**
 * Called by application when it wants to log in to Health Insights backend (usually after a return callback
 * from Auth0).
 * @param {string} idToken - Encrypted Oauth ID token to send to the backend as authentication while signing up
 * @param {string} apiRoot - Root URL of the API, including http:// prefix
 */
export const login = async (idToken, apiRoot) => {
    if (!idToken) {
        throw new Error('login: idToken is required');
    }
    let response = null;

    devConsole(`login: Starting login request`);
    response = await fetch(`${apiRoot}/${Endpoint.LOGIN}`, {
        body: JSON.stringify({idToken}),
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json'},
        method: 'POST',
    });
    if (response.ok) {
        devConsole(`login: http response ok`);
        return LoginResult.Success;
    }

    // Login was not authenticated or some error occurred
    devConsole(`login: request failed: status code - ${response.status}`);

    if (response.status === StatusCodes.BAD_REQUEST) {
        // either of these codes means the user did not exist in the backend
        devConsole(`login: request returned "User in Auth0 but not in API"`);
        return LoginResult.AccountDoesNotExist;
    }

    throw new Error('login: unexpected error');
};

/**
 * Logout out of the application backend
 * @param {string} apiRoot - Root URL of the API, including http:// prefix
 */
export const logout = async (apiRoot) => {
    devConsole('logout: Starting backend logout request');
    await fetch(`${apiRoot}/${Endpoint.LOGOUT}`, {credentials: 'same-origin'});
};

const csrfCaptureGroup = /(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*(?<csrfToken>[^;]*).*$)|^.*$/;

/**
 * Wrapper for all fetch calls to the backend API. Adds the XSRF token to the headers.
 * @param {string} apiRoot - Root URL of the API, including http:// prefix
 * @param {string} resource - API url to call. e.g. /v1/me/account
 * @param {string} options - Standard fetch options object. Is merged with default options for content type and
 * CSRF token.
 */
export const apiFetch = async (apiRoot, resource, options) => {
    const {groups: {csrfToken} = {}} = csrfCaptureGroup.exec(document.cookie);
    const defaultOptions = {
        headers: {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        },
    };

    return await fetch(`${apiRoot}/${resource}`, {
        ...defaultOptions,
        ...options,
    });
};
