import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {BackLink} from '../../../../components';
import {Routes} from '../../../../Routes';

export function AccountHome() {
    const navigate = useNavigate();

    const onClickHandler = useCallback((event) => {
        event.preventDefault();

        navigate(document.referrer.endsWith(Routes.HOME) ? -1 : Routes.HOME);
    }, []);

    return (
        <BackLink
            className={'account-home'}
            onClick={onClickHandler}
            textId={'common.accountHome.text'}
            titleId={'common.accountHome.title'}
        />
    );
}
