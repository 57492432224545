import classNames from 'classnames';
import {Logotype} from '../../components';

export function LinearFlowNavbarDesktopContent(props) {
    const {
        className,
        dataTestId,
        farRightComponent,
    } = props;

    const containerClasses = classNames('linear-flow-navbar', 'desktop', className);

    return (
        <div
            className={containerClasses}
            data-test-id={dataTestId}
        >
            <Logotype />
            {farRightComponent}
        </div>
    );
}
