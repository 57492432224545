import {FormattedMessage} from 'react-intl';
import {SignUpButton} from './SignUpButton';

export function SignUpBottom() {
    return (
        <div className={'sign-up-bottom'}>
            <h4 className={'feedback'}>
                <FormattedMessage id={'landing.signUpPrompt.feedback'} />
            </h4>
            <span className={'reference is-caption'}>
                <FormattedMessage id={'landing.signUpPrompt.reference'} />
            </span>
            <SignUpButton
                dataTestId={'sign-up-second-prompt'}
                location={'second-prompt'}
            />
        </div>
    );
}
