import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {Routes} from '../../Routes';

export function ReadDNAContent() {
    return (
        <>
            <p>
                <FormattedMessage id={'how-it-works.test-sample.description'} />
            </p>
            <Link
                className={'link button'}
                data-test-id={'how-it-works-science-button'}
                to={Routes.OUR_SCIENCE}
            >
                <FormattedMessage id={'how-it-works.test-sample.button'} />
            </Link>
        </>
    );
}
