import {useEffect} from 'react';
import {useOutletContext} from 'react-router-dom';
import {PageContent} from '../../components';
import {useAmplitude} from '../../context';
import {AccuracyContent} from './AccuracyContent';
import {BulletPointsContent} from './BulletPointsContent';
import {DiscoverContent} from './DiscoverContent';
import {FaqContent} from './FaqContent';
import {HeroContent} from './HeroContent';
import {PeopleContent} from './PeopleContent';
import {SalivaTestContent} from './SalivaTestContent';
import {SecureContent} from './SecureContent';
import {SignUpBottom} from './SignUpBottom';
import {SignUpMiddle} from './SignUpMiddle';
import {TakeActionContent} from './TakeActionContent';

export function Landing() {
    const amplitude = useAmplitude();

    const {setMainClassName} = useOutletContext();

    useEffect(() => {
        amplitude.logLandingVisit();
        setMainClassName('landing-page');

        return () => {
            setMainClassName(null);
        };
    }, [amplitude]);

    return (
        <>
            <PageContent
                className={'page-title'}
                containerClassName={'grid hero'}
            >
                <HeroContent />
            </PageContent>
            <PageContent className={'no-vertical-padding bullets'}>
                <BulletPointsContent />
            </PageContent>
            <PageContent>
                <DiscoverContent />
                <TakeActionContent />
                <SalivaTestContent />
            </PageContent>
            <PageContent className={'variant-dark mid-navy-gradient'}>
                <SignUpMiddle />
            </PageContent>
            <PageContent className={'variant-dark'}>
                <AccuracyContent />
                <SecureContent />
                <PeopleContent />
                <FaqContent />
            </PageContent>
            <PageContent className={'variant-dark mid-navy-gradient'}>
                <SignUpBottom />
            </PageContent>
        </>
    );
}
