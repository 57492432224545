import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function ReviewResultsContent() {
    const intl = useIntl();

    return (
        <>
            <p>
                <FormattedMessage id={'how-it-works.get-reports.description.one'} />
            </p>
            <img
                alt={intl.formatMessage({id: 'how-it-works.get-reports.image.alt'})}
                role={'presentation'}
                src={'/images/how-it-works-results_1408x860_v1_66KB.webp'}
            />
            <p>
                <FormattedMessage id={'how-it-works.get-reports.description.two'} />
            </p>
        </>
    );
}
