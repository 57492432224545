import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCardImage,
    GraphicalActionCard,
    LinkWithAttribution,
} from '../../../../../components';

export function Sleep(props) {
    const {
        currentStep,
        totalSteps,
        traitCode,
    } = props;

    const intl = useIntl();

    return (
        <GraphicalActionCard
            currentStep={currentStep}
            dataTestId={`sleep-${traitCode}`}
            headerImage={ActionCardImage.SLEEP}
            title={intl.formatMessage({id: 'reports.trait.lifestyleActions.sleep.title'})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={`reports.trait.lifestyleActions.sleep.description.one.${traitCode}`}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.AHA'})}
                url={'https://www.heart.org/en/healthy-living/healthy-lifestyle/lifes-essential-8/how-to-get-healthy-sleep-fact-sheet'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.sleep.link'} />
            </LinkWithAttribution>
        </GraphicalActionCard>
    );
}
