import {FormattedMessage} from 'react-intl';
import {Checkmark} from '../../components';

export function DataUseContent() {
    return (
        <ul className={'no-decoration'}>
            <li className={'with-checkmark is-heavy'}>
                <Checkmark />
                <FormattedMessage id={'trust-and-privacy.data-create-reports.one'} />
            </li>
            <li className={'with-checkmark is-heavy'}>
                <Checkmark />
                <FormattedMessage id={'trust-and-privacy.data-create-reports.two'} />
            </li>
            <li className={'with-checkmark is-heavy'}>
                <Checkmark />
                <FormattedMessage id={'trust-and-privacy.data-create-reports.three'} />
            </li>
        </ul>
    );
}
