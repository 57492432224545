import classNames from 'classnames';

export function PageContent(props) {
    const {
        children,
        className,
        containerClassName,
        containerDataTestId,
        dataTestId,
    } = props;

    const contentClasses = classNames('page-content', className);
    const containerClasses = classNames('container', containerClassName);

    return (
        <div
            className={contentClasses}
            data-test-id={dataTestId}
        >
            <div
                className={containerClasses}
                data-test-id={containerDataTestId ?? `${dataTestId}-container`}
            >
                {children}
            </div>
        </div>
    );
}
