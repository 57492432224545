import {
    Link,
    Variant,
} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {
    BackLink,
    GridContainer,
} from '../../components';
import {
    useDefaultBackClickHandler,
    useTitle,
} from '../../hooks';
import {Routes} from '../../Routes';

export function TermsConditions() {
    const intl = useIntl();
    const defaultBackClickHandler = useDefaultBackClickHandler();

    const rtfFormat = () => ({
        bold: (chunks) => <strong>{chunks}</strong>,
        em: (chunks) => <em>{chunks}</em>,
        email: (chunks) => (
            <Link
                rel={'noreferrer'}
                target={'_blank'}
                url={intl.formatMessage({id: 'common.support.email'})}
            >
                {chunks}
            </Link>
        ),
        hipn: (chunks) => (
            <em>
                <InternalLink
                    className={'link'}
                    to={Routes.PRIVACY_POLICY}
                >
                    <span>{chunks}</span>
                </InternalLink>
            </em>
        ),
        wplink: (chunks) => <Link url={`#${Routes.TARGET_WEBSITE_PRIVACY}`}>{chunks}</Link>,
    });

    useTitle(intl.formatMessage({id: 'websiteTermsAndConditions.documentTitle'}));

    return (
        <section className={'static-content'}>
            <GridContainer containerClassName={'content-section'}>
                <div className={'page-title'}>
                    <BackLink onClick={defaultBackClickHandler} />
                    <h1 data-test-id={'terms-and-conditions-page-title'}>
                        <FormattedMessage id={'websiteTermsAndConditions.pageTitle.text'} />
                    </h1>
                </div>
            </GridContainer>
            <GridContainer
                className={'content-section'}
                variant={Variant.LIGHT}
            >
                <div className={'content-item about-terms'}>
                    <h2>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.aboutOurWebsiteTerms.title'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.aboutOurWebsiteTerms.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.aboutOurWebsiteTerms.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.aboutOurWebsiteTerms.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.aboutOurWebsiteTerms.paragraph.four'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item about-us'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.aboutUs.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.aboutUs.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item using-the-site'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.usingTheSite.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.usingTheSite.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.usingTheSite.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.usingTheSite.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item unnacceptable-use'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.unacceptableUse.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.unacceptableUse.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.unacceptableUse.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item registration-and-password-security'}>
                    <h2>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accountAndPasswordSecurity.title'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accountAndPasswordSecurity.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accountAndPasswordSecurity.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accountAndPasswordSecurity.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accountAndPasswordSecurity.paragraph.four'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>

                <div className={'content-item infringing-content'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.infringingContent.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.infringingContent.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.infringingContent.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item privacy-and-personal-information'}>
                    <h2>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.privacyAndPersonalInformation.title'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.privacyAndPersonalInformation.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.privacyAndPersonalInformation.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item intellectual-property-rights'}>
                    <h2>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualPropertyRights.title'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualPropertyRights.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualPropertyRights.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualPropertyRights.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualPropertyRights.paragraph.four'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualPropertyRights.paragraph.five'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item submitting-information'}>
                    <h2>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.submittingInformation.title'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.submittingInformation.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item accuracy-of-information-and-availability'}>
                    <h2>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accuracyOfInformationAndAvailability.title'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accuracyOfInformationAndAvailability.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accuracyOfInformationAndAvailability.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accuracyOfInformationAndAvailability.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accuracyOfInformationAndAvailability.paragraph.four'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.accuracyOfInformationAndAvailability.paragraph.five'}
                        />
                    </p>
                </div>
                <div className={'content-item third-party-sites'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.thirdPartySites.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.thirdPartySites.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.thirdPartySites.paragraph.two'}
                        />
                    </p>
                </div>
                <div className={'content-item limitation-on-our-liability'}>
                    <h2>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.limitationOnOurLiability.title'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.limitationOnOurLiability.paragraph.one'}
                        />
                    </p>
                </div>
                <div className={'content-item events-beyond-our-control'}>
                    <h2>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.eventsBeyondOurControl.title'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.eventsBeyondOurControl.paragraph.one'}
                        />
                    </p>
                </div>
                <div className={'content-item rights-of-third-parties'}>
                    <h2>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.rightsOfThirdParties.title'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.rightsOfThirdParties.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item disputes'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.disputes.title'} />
                    </h2>
                    <p>
                        <FormattedMessage id={'websiteTermsAndConditions.disputes.paragraph.one'} />
                    </p>
                    <p>
                        <FormattedMessage id={'websiteTermsAndConditions.disputes.paragraph.two'} />
                    </p>
                </div>
                <div className={'content-item changing-these-terms'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.changingTerms.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.changingTerms.paragraph.one'}
                        />
                    </p>
                </div>
                <div className={'content-item website-privacy'}>
                    <h1 id={Routes.TARGET_WEBSITE_PRIVACY}>
                        <FormattedMessage id={'websiteTermsAndConditions.websitePrivacy.title'} />
                    </h1>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.paragraph.one'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.whatDataAndWhy.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.whatDataAndWhy.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.doNotSell.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.doNotSell.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.howWeCollectData.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.howWeCollectData.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.howLongWeKeepData.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.howLongWeKeepData.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.transferringOutOfUS.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.transferringOutOfUS.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.keepingDataSecure.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.websitePrivacy.keepingDataSecure.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item cookies'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.cookies.title'} />
                    </h2>
                    <p>
                        <FormattedMessage id={'websiteTermsAndConditions.cookies.paragraph.one'} />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.cookies.ourUseOfCookies.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.cookies.ourUseOfCookies.paragraph.one'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.cookies.ourUseOfCookies.paragraph.two'}
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.cookies.ourUseOfCookies.cookieList.one'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.cookies.ourUseOfCookies.cookieList.two'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.cookies.ourUseOfCookies.cookieList.three'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.cookies.ourUseOfCookies.cookieList.four'}
                                values={rtfFormat()}
                            />
                        </li>
                    </ul>
                    <h3>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.cookies.thirdPartyAccess.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.cookies.thirdPartyAccess.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.cookies.turningOffCookies.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.cookies.turningOffCookies.paragraph.one'}
                        />
                    </p>
                </div>
            </GridContainer>
        </section>
    );
}
