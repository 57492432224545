import classNames from 'classnames';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Routes} from '../../../../Routes';

export function HelpCenterLink(props) {
    const {className, dataTestId} = props;

    const intl = useIntl();

    const linkClasses = classNames('link', className);

    return (
        <NavLink
            className={linkClasses}
            data-test-id={dataTestId ?? 'navigation-help-center-link'}
            rel={'noreferrer'}
            target={'_blank'}
            title={intl.formatMessage({id: 'navigation.link.helpCenter.title'})}
            to={Routes.HELP_CENTER}
        >
            <span>
                <FormattedMessage id={'navigation.link.helpCenter.text'} />
            </span>
        </NavLink>
    );
}
