import {
    Link,
    MoreInfo,
} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {LinkWithAttribution} from '../../components';

export function StoryContent() {
    const intl = useIntl();

    return (
        <>
            <p>
                <FormattedMessage id={'aboutUs.story.paragraphOne'} />
            </p>
            <p>
                <FormattedMessage id={'aboutUs.story.paragraphTwo'} />
            </p>
            <p className={'half-margin'}>
                <FormattedMessage
                    id={'aboutUs.story.paragraphThree'}
                    values={{
                        aha: (chunk) => (
                            <Link
                                className={'alternate'}
                                dataTestId={'about-us-link-aha'}
                                rel={'noreferrer'}
                                target={'_blank'}
                                url={'https://www.ahajournals.org/doi/full/10.1161/CIRCGEN.120.003304'}
                            >
                                {chunk}
                            </Link>
                        ),
                        ajc: (chunk) => (
                            <Link
                                className={'alternate'}
                                dataTestId={'about-us-link-ajc'}
                                rel={'noreferrer'}
                                target={'_blank'}
                                url={'https://www.ajconline.org/article/S0002-9149(21)00207-1/fulltext'}
                            >
                                {chunk}
                            </Link>
                        ),
                    }}
                />
            </p>
            <MoreInfo
                content={
                    <>
                        <LinkWithAttribution
                            attribution={intl.formatMessage({id: 'aboutUs.story.papers.attributionOne'})}
                            dataTestId={'about-us-story-paper-derivation-and-validation'}
                            isAlternate={true}
                            url={'https://insightsgenomics.com/documents/genomicsplc-HI-validation-paper-2023.pdf'}
                        >
                            <FormattedMessage id={'aboutUs.story.papers.titleOne'} />
                        </LinkWithAttribution>
                        <LinkWithAttribution
                            attribution={intl.formatMessage({id: 'aboutUs.story.papers.attributionTwo'})}
                            dataTestId={'about-us-link-uk-bio-bank'}
                            isAlternate={true}
                            url={'https://www.genomicsplc.com/news/new-and-improved-polygenic-risk-scores-for-28-common-diseases/'}
                        >
                            <FormattedMessage id={'aboutUs.story.papers.titleTwo'} />
                        </LinkWithAttribution>
                        <LinkWithAttribution
                            attribution={intl.formatMessage({id: 'aboutUs.story.papers.attributionThree'})}
                            dataTestId={'about-us-link-integrated-polygenic'}
                            isAlternate={true}
                            url={'https://www.ahajournals.org/doi/full/10.1161/CIRCGEN.120.003304'}
                        >
                            <FormattedMessage id={'aboutUs.story.papers.titleThree'} />
                        </LinkWithAttribution>
                        <LinkWithAttribution
                            attribution={intl.formatMessage({id: 'aboutUs.story.papers.attributionFour'})}
                            dataTestId={'about-us-link-validation-of-integrated-risk-tool'}
                            isAlternate={true}
                            url={'https://www.ajconline.org/article/S0002-9149(21)00207-1/fulltext'}
                        >
                            <FormattedMessage id={'aboutUs.story.papers.titleFour'} />
                        </LinkWithAttribution>
                    </>
                }
                dataTestId={'about-us-story-paper-links'}
                title={intl.formatMessage({id: 'aboutUs.story.papers.title'})}
            />
        </>
    );
}
