import {
    Button,
    Checkbox,
    ErrorSummary,
    FormGroup,
    Variant,
} from '@genomicsplc/denim-components';
import {
    useCallback,
    useEffect,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {
    ContactSupport,
    FetchError,
    Loading,
} from '../../../components';
import {useAmplitude} from '../../../context';
import {
    useMutation,
    useQuery,
    useTitle,
} from '../../../hooks';
import {useLinearFlowContext} from '../../../layouts';
import {Routes} from '../../../Routes';
import {
    action,
    Endpoint,
} from '../../../util';

const CHECKBOX_CHANGED = 'CHECKBOX_CHANGED';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const UPDATE_ALL_VALUES = 'UPDATE_ALL_VALUES';

const initialState = {
    consent: false,
    errorMessage: null,
    futureContact: false,
    futureResearch: false,
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case CHECKBOX_CHANGED:
            return {
                ...state,
                [payload.name]: payload.checked,
            };

        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: payload,
            };

        case UPDATE_ALL_VALUES:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};

export function Consent() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {nextStep} = useLinearFlowContext();
    const {
        data,
        error: queryError,
        loading: queryLoading,
    } = useQuery(Endpoint.CONSENT);
    const [updateConsent, {error: mutationError, loading: mutationLoading}] = useMutation(
        Endpoint.CONSENT,
    );
    const [{errorMessage, consent, futureContact, futureResearch}, dispatch] = useReducer(
        reducer,
        initialState,
    );
    useTitle(intl.formatMessage({id: `signUp.consent.documentTitle`}));

    useEffect(() => {
        if (data) {
            const {consent: queriedConsent} = data;
            if (queriedConsent) {
                nextStep();
            }
            else {
                dispatch(action(UPDATE_ALL_VALUES, data));
            }
        }
    }, [data]);

    const onChangeHandler = useCallback((checked, name) => {
        dispatch(
            action(CHECKBOX_CHANGED, {
                checked,
                name,
            }),
        );
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            const formValid = event.target.checkValidity();
            if (formValid) {
                dispatch(action(SET_ERROR_MESSAGE, null));

                // Send the consent values to the backend
                await updateConsent({
                    body: {
                        consent,
                        futureContact,
                        futureResearch,
                    },
                });

                amplitude.logUserConsented();
                if (futureResearch) {
                    amplitude.logResearchConsented();
                }
                if (futureContact) {
                    amplitude.logMarketingConsented();
                }
            }
            else if (!consent) {
                dispatch(
                    action(
                        SET_ERROR_MESSAGE,
                        intl.formatMessage({id: 'signUp.consent.error.consentRequired'}),
                    ),
                );
            }
        },
        [amplitude, consent, futureContact, futureResearch],
    );

    if (queryLoading) {
        return <Loading />;
    }

    if (queryError) {
        return (
            <FetchError
                dataTestId={'fetch-error'}
                reason={queryError?.status}
            />
        );
    }

    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-consent-title'}
            >
                <FormattedMessage id={'signUp.consent.title'} />
            </h2>
            <p className={'variant-dark description'}>
                <FormattedMessage
                    id={'signUp.consent.paragraph.one'}
                    values={{
                        link: (chunks) => (
                            <InternalLink
                                className={'link alternate'}
                                to={Routes.OVERVIEW}
                            >
                                <span>{chunks}</span>
                            </InternalLink>
                        ),
                    }}
                />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'signUp.consent.point.one'} />
                </li>
                <li>
                    <FormattedMessage id={'signUp.consent.point.two'} />
                </li>
                <li>
                    <FormattedMessage id={'signUp.consent.point.three'} />
                </li>
            </ul>
            <p className={'description'}>
                <FormattedMessage id={'signUp.consent.paragraph.two'} />
            </p>
            <p className={'description'}>
                <FormattedMessage id={'signUp.consent.paragraph.three'} />
            </p>
            <p className={'variant-dark description'}>
                <FormattedMessage
                    id={'signUp.consent.paragraph.four'}
                    values={{
                        pn: (chunks) => (
                            <InternalLink
                                className={'link alternate'}
                                to={Routes.PRIVACY_POLICY}
                            >
                                <span>{chunks}</span>
                            </InternalLink>
                        ),
                    }}
                />
            </p>
            {mutationError && (
                <ErrorSummary
                    dataTestId={'error-summary'}
                    errors={[
                        intl.formatMessage(
                            {id: 'fetchError.information'},
                            {cs: (chunk) => <ContactSupport text={chunk} />},
                        ),
                    ]}
                    title={intl.formatMessage({id: 'fetchError.title'})}
                />
            )}
            <form
                noValidate={true}
                onSubmit={onSubmitHandler}
            >
                <FormGroup
                    className={'consent-options'}
                    dataTestId={'consent-checkboxes'}
                    errorMessage={errorMessage}
                >
                    <Checkbox
                        checked={consent}
                        dataTestId={'consent-checkbox-consent'}
                        id={'checkbox-consent'}
                        label={intl.formatMessage({id: 'signUp.consent.checkbox.consent'})}
                        name={'consent'}
                        onChange={onChangeHandler}
                        required={true}
                    />
                    <Checkbox
                        checked={futureResearch}
                        dataTestId={'consent-checkbox-future-research'}
                        id={'checkbox-future-research'}
                        label={intl.formatMessage({id: 'signUp.consent.checkbox.futureResearch'})}
                        name={'futureResearch'}
                        onChange={onChangeHandler}
                    />
                    <Checkbox
                        checked={futureContact}
                        dataTestId={'consent-checkbox-future-contact'}
                        id={'checkbox-future-contact'}
                        label={intl.formatMessage({id: 'signUp.consent.checkbox.futureContact'})}
                        name={'futureContact'}
                        onChange={onChangeHandler}
                    />
                </FormGroup>
                <Button
                    after={true}
                    className={'consent-continue'}
                    dataTestId={'sign-up-page-continue'}
                    icon={'arrow--right'}
                    isLoading={mutationLoading}
                    submit={true}
                    title={intl.formatMessage({id: 'common.button.continue.title'})}
                    variant={Variant.PRIMARY}
                >
                    <FormattedMessage id={'common.button.continue'} />
                </Button>
            </form>
        </>
    );
}
