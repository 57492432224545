import {Trait} from '../../../../../../util';
import {AtrialFibrillationExercise} from './AtrialFibrillationExercise';
import {BreastCancerExercise} from './BreastCancerExercise';
import {CardiovascularDiseaseExercise} from './CardiovascularDiseaseExercise';
import {HighBloodPressureExercise} from './HighBloodPressureExercise';
import {HighLDLCholesterolExercise} from './HighLDLCholesterolExercise';
import {OsteopeniaExercise} from './OsteopeniaExercise';
import {ProstateCancerExercise} from './ProstateCancerExercise';
import {Type2DiabetesExercise} from './Type2DiabetesExercise';

export function Exercise(props) {
    const {
        currentStep,
        totalSteps,
        traitCode,
    } = props;

    let ExerciseComponent = null;
    switch (traitCode) {
        case Trait.AF.code:
            ExerciseComponent = AtrialFibrillationExercise;
            break;

        case Trait.BC.code:
            ExerciseComponent = BreastCancerExercise;
            break;

        case Trait.CVD_US.code:
            ExerciseComponent = CardiovascularDiseaseExercise;
            break;

        case Trait.HT.code:
            ExerciseComponent = HighBloodPressureExercise;
            break;

        case Trait.HCH.code:
            ExerciseComponent = HighLDLCholesterolExercise;
            break;

        case Trait.FNDXAT_N1P0.code:
            ExerciseComponent = OsteopeniaExercise;
            break;

        case Trait.PC.code:
            ExerciseComponent = ProstateCancerExercise;
            break;

        case Trait.T2D.code:
            ExerciseComponent = Type2DiabetesExercise;
            break;

        default:
            break;
    }

    return (
        <ExerciseComponent
            currentStep={currentStep}
            totalSteps={totalSteps}
            traitCode={traitCode}
        />
    );
}
