import {Variant} from '@genomicsplc/denim-components';
import {FormattedMessage} from 'react-intl';
import {GridContainer} from '../../../components';
import {AccountHome} from '../components';

export function HowWeCalculateRiskTitle() {
    return (
        <GridContainer
            containerClassName={'how-we-calculate-risk-title'}
            gridClassName={'decreasing-ratio'}
            variant={Variant.DARK}
        >
            <div className={'left'}>
                <AccountHome />
                <h1 data-test-id={'how-we-calculate-risk-title'}>
                    <FormattedMessage id={'reports.how-we-calculate-risk.hero.title'} />
                </h1>
                <p className={'no-margin-bottom'}>
                    <FormattedMessage id={'reports.how-we-calculate-risk.hero.description'} />
                </p>
            </div>
        </GridContainer>
    );
}
