import classNames from 'classnames';
import {useMemo} from 'react';
import {
    FetchError,
    GridContainer,
    Loading,
} from '../../components';
import {
    useName,
    useQuery,
} from '../../hooks';
import {
    Endpoint,
    extractErrorMessage,
    NetworkPolicy,
} from '../../util';
import {KitShipped} from './KitShipped';
import {ProcessingYourSample} from './ProcessingYourSample';
import {ResultsOverview} from './results-overview';
import {SendYourKitBack} from './SendYourKitBack';
import {WhatHappensNext} from './WhatHappensNext';

const CONTAINER_TYPE_DEFAULT = 'grid';
const CONTAINER_TYPE_RESULTS = 'results';

export function Home() {
    const {
        data: currentUserSummary = {},
        error: summaryError,
        loading: summaryLoading,
    } = useQuery(Endpoint.SUMMARY, NetworkPolicy.NETWORK_ONLY);
    const {
        name,
        error: nameError,
        loading: nameLoading,
    } = useName();

    const error = summaryError ?? nameError;
    const loading = summaryLoading || nameLoading;

    const {Component, containerType = CONTAINER_TYPE_DEFAULT} = useMemo(() => {
        const {kitOrderStatus = 'NONE', resultStatus = 'NONE'} = currentUserSummary;

        if (resultStatus === 'READY') {
            return {
                Component: ResultsOverview,
                containerType: CONTAINER_TYPE_RESULTS,
            };
        }
        else if (resultStatus === 'PROCESSING') {
            return {Component: ProcessingYourSample};
        }
        else if (kitOrderStatus === 'REGISTERED') {
            return {Component: SendYourKitBack};
        }
        else if (kitOrderStatus === 'ORDERED') {
            return {Component: KitShipped};
        }

        return {Component: WhatHappensNext};
    }, [currentUserSummary]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <GridContainer>
                <FetchError reason={extractErrorMessage(error)} />
            </GridContainer>
        );
    }

    const containerClasses = classNames('variant-dark', containerType, {
        'fifty-fifty': containerType === CONTAINER_TYPE_DEFAULT,
        reversed: containerType === CONTAINER_TYPE_DEFAULT,
    });

    return (
        <div
            className={containerClasses}
            data-test-id={'account-home'}
        >
            <Component name={name} summary={currentUserSummary} />
        </div>
    );
}
