import {HighLDLCholesterolRiskFactors} from './HighLDLCholesterolRiskFactors';
import {HighLDLCholesterolScreenOptions} from './HighLDLCholesterolScreenOptions';

export function HighLDLCholesterolMedicalActions(props) {
    const {riskLevel, totalSteps} = props;

    return (
        <>
            <HighLDLCholesterolRiskFactors
                currentStep={1}
                totalSteps={totalSteps}
            />
            <HighLDLCholesterolScreenOptions
                currentStep={2}
                riskLevel={riskLevel}
                totalSteps={totalSteps}
            />
        </>
    );
}
