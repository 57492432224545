import {
    useEffect,
    useState,
} from 'react';
import {
    Navigate,
    useLocation,
} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../components';
import {useUserContext} from '../../context';
import {useLazyQuery} from '../../hooks';
import {Routes} from '../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../util';

const DISPLAY_COMPONENT = 'DISPLAY_COMPONENT';
const DISPLAY_LOADING = 'DISPLAY_LOADING';
const REDIRECT_TO_AUTH = 'REDIRECT_TO_AUTH';
const REDIRECT_TO_CONSENT = 'REDIRECT_TO_CONSENT';
const REDIRECT_TO_EMAIL_VERIFIED = 'REDIRECT_TO_EMAIL_VERIFIED';

export function RequireEmailVerification(props) {
    const {children} = props;

    const {user, loginToAuth0} = useUserContext();
    const [getConsent, {data: userConsentData, error, loading}] = useLazyQuery(Endpoint.CONSENT);
    const {pathname} = useLocation();
    const [display, setDisplay] = useState(DISPLAY_LOADING);

    useEffect(() => {
        if (loading) {
            setDisplay(DISPLAY_LOADING);
        }
        else if (user) {
            if (userConsentData) {
                // Have they consented?
                const {lastUpdated} = userConsentData;
                const userHasConsented = Boolean(lastUpdated);

                if (userHasConsented && user.emailVerified) {
                    setDisplay(DISPLAY_COMPONENT);
                }
                else if (!userHasConsented) {
                    setDisplay(REDIRECT_TO_CONSENT);
                }
                else {
                    setDisplay(REDIRECT_TO_EMAIL_VERIFIED);
                }
            }
            else {
                getConsent();
                setDisplay(DISPLAY_LOADING);
            }
        }
        else {
            setDisplay(REDIRECT_TO_AUTH);
        }
    }, [loading, user, userConsentData]);

    if (error) {
        return (
            <div className={'guard'}>
                <FetchError
                    dataTestId={'authentication-context-fetch-error'}
                    reason={extractErrorMessage(error)}
                />
            </div>
        );
    }
    else if (display === DISPLAY_COMPONENT) {
        return children;
    }
    else if (display === REDIRECT_TO_CONSENT) {
        return (
            <Navigate
                replace={true}
                to={Routes.SIGN_UP_CONSENT}
            />
        );
    }
    else if (display === REDIRECT_TO_EMAIL_VERIFIED) {
        return (
            <Navigate
                replace={true}
                to={Routes.SIGN_UP_VERIFY_EMAIL}
            />
        );
    }
    else if (display === REDIRECT_TO_AUTH) {
        loginToAuth0(pathname);
        return null;
    }

    return (
        <div className={'guard'}>
            <Loading key={'loading'} />
        </div>
    );
}
