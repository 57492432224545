import {
    MoreInfo,
    RadioButton,
    RadioButtonFormGroup,
} from '@genomicsplc/denim-components';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {FetchValidationError} from '../../../fetch-validation-error';

const VALUE_FEMALE = 'FEMALE';
const VALUE_MALE = 'MALE';
const VALUE_PNTS = 'PREFER_NOT_TO_SAY';

export function ReportedSex() {
    const intl = useIntl();
    const {
        currentUserProfile: {reportedSex} = {},
        errorResponse,
        setPersistFields,
    } = useOutletContext();
    const [value, setValue] = useState(reportedSex ?? '');

    useEffect(() => {
        setPersistFields(() => () => ({reportedSex: value}));

        return () => {
            setPersistFields(null);
        };
    }, [value]);

    const onChangeHandler = useCallback((radioButtonValue) => {
        setValue(radioButtonValue);
    }, []);

    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-reported-sex-title'}
            >
                <FormattedMessage id={'signUp.reportedSex.title'} />
            </h2>
            <MoreInfo
                content={
                    <>
                        <p className={'no-margin-top'}>
                            <FormattedMessage id={'signUp.reportedSex.moreInfo.one'} />
                        </p>
                        <p className={'no-margin-top'}>
                            <FormattedMessage id={'signUp.reportedSex.moreInfo.two'} />
                        </p>
                        <p className={'no-margin'}>
                            <FormattedMessage id={'signUp.reportedSex.moreInfo.three'} />
                        </p>
                        <ul className={'no-margin-top'}>
                            <li>
                                <FormattedMessage id={'signUp.reportedSex.moreInfo.four'} />
                            </li>
                        </ul>
                        <p className={'no-margin'}>
                            <FormattedMessage id={'signUp.reportedSex.moreInfo.five'} />
                        </p>
                        <ul className={'no-margin-top'}>
                            <li>
                                <FormattedMessage id={'signUp.reportedSex.moreInfo.six'} />
                            </li>
                        </ul>
                        <p className={'no-margin'}>
                            <FormattedMessage id={'signUp.reportedSex.moreInfo.seven'} />
                        </p>
                        <ul className={'no-margin no-item-margin'}>
                            <li>
                                <FormattedMessage id={'signUp.reportedSex.moreInfo.eight'} />
                            </li>
                            <li>
                                <FormattedMessage id={'signUp.reportedSex.moreInfo.nine'} />
                            </li>
                        </ul>
                    </>
                }
                title={intl.formatMessage({id: 'common.whyWeAskThisQuestion'})}
            />
            <FetchValidationError errorResponse={errorResponse} />
            <RadioButtonFormGroup
                dataTestId={'sign-up-reported-sex-form-group'}
                errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.requiredOption'})}}
                name={'reportedSex'}
                onChange={onChangeHandler}
                required={true}
                value={value}
            >
                <RadioButton
                    dataTestId={'reported-sex-female'}
                    id={'biological-sex-female'}
                    label={intl.formatMessage({id: 'signUp.reportedSex.option.female'})}
                    value={VALUE_FEMALE}
                />
                <RadioButton
                    dataTestId={'reported-sex-male'}
                    id={'biological-sex-male'}
                    label={intl.formatMessage({id: 'signUp.reportedSex.option.male'})}
                    value={VALUE_MALE}
                />
                <p>
                    <FormattedMessage id={'common.or'} />
                </p>
                <RadioButton
                    dataTestId={'reported-sex-pnts'}
                    id={'biological-sex-pnts'}
                    label={intl.formatMessage({id: 'signUp.reportedSex.option.pnts'})}
                    value={VALUE_PNTS}
                />
            </RadioButtonFormGroup>
        </>
    );
}
