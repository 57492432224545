import {useCallback} from 'react';

export function useHubSpot() {
    const load = useCallback(() => {
        window.HubSpotConversations?.widget?.load();
    }, []);

    const open = useCallback(() => {
        // Even though load and setUserEmail have most likely been called already in teh HubspotLoader, the open call
        // is throwing a warning that the widget isn't loaded. If load has already been called, then this load should
        // be a no-op and not effect anything...
        load();
        window.HubSpotConversations?.widget?.open();
    }, []);

    const clear = useCallback(() => {
        window.HubSpotConversations?.clear({resetWidget: true});
    }, []);

    const setUserEmail = useCallback((email, token) => {
        window.hsConversationsSettings = {
            identificationEmail: email,
            identificationToken: token,
        };
    }, []);

    return {
        clear,
        load,
        open,
        setUserEmail,
    };
}
