import {
    Endpoint,
    NetworkPolicy,
} from '../util';
import {useQuery} from './useApi';

export function useTraitEligibility(networkPolicy = NetworkPolicy.NETWORK_ONLY) {
    const {
        data: currentUserTraitEligibility = [],
        error,
        loading,
    } = useQuery(Endpoint.TRAIT_ELIGIBILITY, networkPolicy);

    const eligibleTraits = currentUserTraitEligibility?.filter(({eligible}) => eligible === true);
    const ineligibleTraits = currentUserTraitEligibility?.filter(({eligible}) => eligible === false);

    return {
        eligibleTraits,
        error,
        ineligibleTraits,
        loading,
    };
}
