import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function ExampleBellCurve() {
    const intl = useIntl();

    return (
        <div className={'example-bell-curve'}>
            <h4>
                <FormattedMessage id={'common.bellCurve.illustration.title'} />
            </h4>
            <img
                alt={intl.formatMessage({id: 'common.bellCurve.illustration.alt'})}
                role={'presentation'}
                src={'/images/bell-curve-illustration.svg'}
            />
        </div>
    );
}
