import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {TestMoreInfo} from '../../../components';
import {Routes} from '../../../Routes';

export function PageContent(props) {
    const intl = useIntl();
    const {
        buttonDataTestId,
        buttonKey,
        buttonTitleKey,
        name,
        titleDataTestId,
        titleKey,
    } = props;

    return (
        <div className={'variant-dark grid layout'}>
            <img
                alt={''}
                role={'presentation'}
                src={'/images/test-kit-box-w-tube-nobg_1040x786_v1_45KB.webp'}
            />
            <div className={'content'}>
                {name && (
                    <div
                        className={'is-caption name'}
                        data-test-id={'users-shortened-name'}
                    >
                        {name}
                    </div>
                )}
                <h2 data-test-id={titleDataTestId ?? 'test-reorder-page-title'}>
                    <FormattedMessage id={titleKey} />
                </h2>
                <p>
                    <FormattedMessage id={'testReorder.order.paragraphOne'} />
                </p>
                <p>
                    <FormattedMessage id={'testReorder.order.paragraphTwo'} />
                </p>
                <InternalLink
                    className={'link button variant-primary icon after arrow--right'}
                    data-test-id={buttonDataTestId ?? 'test-reorder-button'}
                    title={intl.formatMessage({id: buttonTitleKey})}
                    to={Routes.TEST_REORDER_CHECK_YOUR_DETAILS}
                >
                    <FormattedMessage id={buttonKey} />
                </InternalLink>
                <TestMoreInfo />
            </div>
        </div>
    );
}
