import {useEffect} from 'react';
import {Loading} from '../../components';
import {useUserContext} from '../../context';

export function Logout() {
    const {logout} = useUserContext();

    useEffect(() => {
        logout();
    }, [logout]);

    return (
        <div className={'guard'}>
            <Loading key={'loading'} />
        </div>
    );
}
