import {HighBloodPressureRiskFactors} from './HighBloodPressureRiskFactors';
import {HighBloodPressureScreenOptions} from './HighBloodPressureScreenOptions';

export function HighBloodPressureMedicalActions(props) {
    const {riskLevel, totalSteps} = props;

    return (
        <>
            <HighBloodPressureRiskFactors
                currentStep={1}
                totalSteps={totalSteps}
            />
            <HighBloodPressureScreenOptions
                currentStep={2}
                riskLevel={riskLevel}
                totalSteps={totalSteps}
            />
        </>
    );
}
