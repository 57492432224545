import classNames from 'classnames';

export function SupplementalBox(props) {
    const {
        className,
        dataTestId,
        title,
        children,
    } = props;

    const supplementalBoxClasses = classNames('supplemental-box', className);

    return (
        <div
            className={supplementalBoxClasses}
            data-test-id={dataTestId}
        >
            <div className={'header is-heavy'}>{title}</div>
            <div className={'body'}>{children}</div>
        </div>
    );
}
