import classNames from 'classnames';
import {BulletPoint} from '../bullet-point';

export function NumberedTitleContainer(props) {
    const {
        className,
        dataTestId,
        number,
        large = false,
        title,
    } = props;

    const containerClasses = classNames('flex-column-to-row', className, {large});

    return (
        <div
            className={containerClasses}
            data-test-id={dataTestId}
        >
            <BulletPoint content={number} />
            {large ? <h2>{title}</h2> : <h4>{title}</h4>}
        </div>
    );
}
