/**
 * The eligibility information is made up of:
 *  - invite code
 *  - eligible regions (i.e. US States, etc)
 *  - eligible age range
 */

export const ELIGIBILITY_KEY = 'ELIGIBILITY';

export const getEligibility = () => {
    const encoded = sessionStorage.getItem(ELIGIBILITY_KEY);
    return JSON.parse(encoded);
};

export const setEligibility = (eligibility) => {
    sessionStorage.setItem(ELIGIBILITY_KEY, JSON.stringify(eligibility));
};

export const removeEligibility = () => {
    sessionStorage.removeItem(ELIGIBILITY_KEY);
};

// Ensures that an access code is present, otherwise takes the user to the landing page...
export const useEligibility = () => ({
    getEligibility,
    removeEligibility,
    setEligibility,
});
