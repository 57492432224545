import {Link} from '@genomicsplc/denim-components';
import {useMemo} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {useQuery} from '../../hooks';
import {Routes} from '../../Routes';
import {Endpoint} from '../../util';

export function KitShipped(props) {
    const {name} = props;

    const intl = useIntl();
    const {data: currentUserKitOrder} = useQuery(Endpoint.KITS);

    const trackingCode = useMemo(
        () => currentUserKitOrder?.outboundTrackingCode,
        [currentUserKitOrder],
    );

    return (
        <>
            <div className={'left'}>
                <header>
                    <div
                        className={'is-caption name'}
                        data-test-id={'users-shortened-name'}
                    >
                        {name}
                    </div>
                    <h2
                        className={'page-title'}
                        data-test-id={'home-kit-shipped-title'}
                    >
                        <FormattedMessage id={'home.kitShipped.title'} />
                    </h2>
                </header>
                <p className={'half-margin'}>
                    <FormattedMessage
                        id={'home.kitShipped.paragraphOne'}
                        values={{
                            usps: (chunk) => (trackingCode
                                ? (
                                    <Link
                                        className={'alternate'}
                                        dataTestId={'tracking-code-link'}
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingCode}`}
                                    >
                                        {chunk}
                                    </Link>
                                )
                                : chunk),
                        }}
                    />
                </p>
                <p className={'half-margin'}>
                    <FormattedMessage
                        id={'home.kitShipped.tracking'}
                        values={{
                            tracking: () => (trackingCode
                                ? (
                                    <span
                                        className={'is-heavy'}
                                        data-test-id={'tracking-code'}
                                    >
                                        {trackingCode}
                                    </span>
                                )
                                : (
                                    intl.formatMessage({id: 'home.kitShipped.tracking.awaiting'})
                                )),
                        }}
                    />
                </p>
                <p>
                    <FormattedMessage id={'home.kitShipped.paragraphTwo'} />
                </p>
                <InternalLink
                    className={'link button variant-primary'}
                    data-test-id={'activate-your-kit-button'}
                    title={intl.formatMessage({id: 'home.kitShipped.activate.title'})}
                    to={Routes.ACTIVATE}
                >
                    <FormattedMessage id={'home.kitShipped.activate.text'} />
                </InternalLink>
            </div>
            <div className={'right'}>
                <img
                    alt={intl.formatMessage({id: 'common.testKitBox.alt'})}
                    src={'/images/health-insights-test-kit-box_520x600.jpg'}
                />
            </div>
        </>
    );
}
