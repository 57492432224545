import {
    Card,
    Variant,
} from '@genomicsplc/denim-components';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

export function ActionCard(props) {
    const {
        children,
        className,
        currentStep,
        dataTestId,
        title,
        totalSteps,
    } = props;

    const cardClasses = classNames('action-card', className);

    return (
        <Card
            className={cardClasses}
            dataTestId={dataTestId}
            variant={Variant.LIGHT}
        >
            <div className={'header'}>
                {currentStep && (
                    <div className={'step is-caption is-heavy'}>
                        <FormattedMessage
                            id={'common.stepOfTotal.text'}
                            values={{
                                currentStep,
                                totalSteps,
                            }}
                        />
                    </div>
                )}
                <h4 className={'no-margin'}>{title}</h4>
            </div>
            <div className={'body'}>{children}</div>
        </Card>
    );
}
