import classNames from 'classnames';
import {LogotypeIcon} from '../../components';
import {LinearFlowProgressIndicator} from './LinearFlowProgressIndicator';

export function LinearFlowNavbarMobileContent(props) {
    const {
        className,
        dataTestId,
        farRightComponent,
    } = props;

    const containerClasses = classNames('linear-flow-navbar', 'mobile', className);

    return (
        <div
            className={containerClasses}
            data-test-id={dataTestId}
        >
            <LogotypeIcon />
            <LinearFlowProgressIndicator />
            {farRightComponent}
        </div>
    );
}
