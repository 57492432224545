import {ProstateCancerRiskFactors} from './ProstateCancerRiskFactors';
import {ProstateCancerScreenOptions} from './ProstateCancerScreenOptions';

export function ProstateCancerMedicalActions(props) {
    const {totalSteps} = props;

    return (
        <>
            <ProstateCancerRiskFactors
                currentStep={1}
                totalSteps={totalSteps}
            />
            <ProstateCancerScreenOptions
                currentStep={2}
                totalSteps={totalSteps}
            />
        </>
    );
}
