import {
    Button,
    Variant,
} from '@genomicsplc/denim-components';
import {
    useCallback,
    useEffect,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import {useAmplitude} from '../../context';
import {Routes} from '../../Routes';

export function HeroSignUpButton() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const navigate = useNavigate();
    const {setSignUpInNavbar} = useOutletContext();

    useEffect(() => {
        // We need to detect when the sign-up button disappears under the navbar
        const signUpButton = document.querySelector('.hero-signup-button');

        // We need to know how high the navbar is, so we can add that to the viewport to get the
        // correct intersection boundary
        const navbar = document.querySelector('.navbar');
        const navbarBoundingRect = navbar.getBoundingClientRect();

        // The button is intersecting when it's within the viewport, so we need to invert that in the handler. NOTE: The
        // height needs to be subtracted from the viewport margin 🤷
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(({isIntersecting}) => {
                    setSignUpInNavbar(!isIntersecting);
                });
            },
            {rootMargin: `-${navbarBoundingRect.bottom}px`},
        );
        observer.observe(signUpButton);

        return () => {
            observer.unobserve(signUpButton);
            setSignUpInNavbar(false);
        };
    }, []);

    const onClickHandler = useCallback(() => {
        amplitude.logSignUpClicked('hero');
        navigate(Routes.SIGN_UP);
    }, [amplitude, navigate]);

    return (
        <Button
            after={true}
            className={'hero-signup-button'}
            dataTestId={'hero-signup-button'}
            icon={'arrow--right'}
            onClick={onClickHandler}
            title={intl.formatMessage({id: `landing.hero.signUp.button.title`})}
            variant={Variant.PRIMARY}
        >
            <FormattedMessage id={'landing.hero.signUp.button.text'} />
        </Button>
    );
}
