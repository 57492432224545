import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

export function Overlay(props) {
    const {
        className,
        dataTestId,
        diseaseList,
        increasedCount,
        isTransitioning,
        typicalCount,
    } = props;

    const containerClassNames = classNames('risk-overlay', className);
    const increasedRiskClassNames = classNames('risk-count', 'increased-risk', 'text-clip-box', {
        transitioning: isTransitioning,
    });
    const typicalRiskClassNames = classNames('risk-count', 'typical-risk', 'text-clip-box', {
        transitioning: isTransitioning,
    });
    const diseaseListClassNames = classNames('text-clip-box', {transitioning: isTransitioning});

    return (
        <div
            className={containerClassNames}
            data-test-id={dataTestId}
        >
            <div className={'recipient'}>
                <p className={'is-caption is-heavy'}>
                    <FormattedMessage id={'landing.equalizer.label.yourResults'} />
                </p>
            </div>

            <div className={'risk-results'}>
                <div className={'risk-result'}>
                    <div className={typicalRiskClassNames}>
                        <p className={'is-caption is-heavy'}>{typicalCount}</p>
                    </div>
                    <div className={'risk-label'}>
                        <p className={'is-caption is-heavy'}>
                            <FormattedMessage id={'landing.equalizer.label.typicalRisk'} />
                        </p>
                    </div>
                </div>

                <div className={'risk-result'}>
                    <div className={increasedRiskClassNames}>
                        <p className={'is-caption is-heavy'}>{increasedCount}</p>
                    </div>
                    <div className={'risk-label'}>
                        <p className={'is-caption is-heavy'}>
                            <FormattedMessage id={'landing.equalizer.label.increasedRisk'} />
                        </p>
                        <div className={diseaseListClassNames}>
                            <p className={'is-caption truncate-text is-secondary-color'}>
                                {diseaseList}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
