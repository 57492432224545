import classNames from 'classnames';
import {
    AboutUsLink,
    ActivateKitLink,
    FooterMarketingLinks,
    HelpCenterLink,
} from '../common';

export function FooterContent() {
    const linkClasses = classNames('is-heavy', 'is-caption');

    return (
        <>
            <div className={'flex-column list-of-links'}>
                <FooterMarketingLinks />
            </div>
            <div className={'flex-column list-of-links'}>
                <AboutUsLink
                    className={linkClasses}
                    dataTestId={'footer-about-us-link'}
                />
                <ActivateKitLink
                    className={linkClasses}
                    dataTestId={'footer-activate-kit-link'}
                />
                <HelpCenterLink
                    className={linkClasses}
                    dataTestId={'footer-help-center-link'}
                />
            </div>
        </>
    );
}
