import {Outlet} from 'react-router-dom';
import {AccountSidebar} from './AccountSidebar';

export function AccountDesktopLayout() {
    return (
        <div className={'account-layout grid'}>
            <div className={'sidebar'}>
                <AccountSidebar />
            </div>
            <div className={'content'}>
                <Outlet />
            </div>
        </div>
    );
}
