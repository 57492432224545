import {Type2DiabetesRiskFactors} from './Type2DiabetesRiskFactors';
import {Type2DiabetesScreenOptions} from './Type2DiabetesScreenOptions';

export function Type2DiabetesMedicalActions(props) {
    const {riskLevel, totalSteps} = props;

    return (
        <>
            <Type2DiabetesRiskFactors
                currentStep={1}
                totalSteps={totalSteps}
            />
            <Type2DiabetesScreenOptions
                currentStep={2}
                riskLevel={riskLevel}
                totalSteps={totalSteps}
            />
        </>
    );
}
