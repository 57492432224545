import {
    useEffect,
    useMemo,
} from 'react';
import {useUserContext} from '../context';
import {Endpoint} from '../util';
import {useLazyQuery} from './useApi';

export function useName() {
    const {user: {emailVerified} = {}} = useUserContext();
    const [getProfile, {data, error, loading}] = useLazyQuery(`${Endpoint.PROFILE}`);

    useEffect(() => {
        if (emailVerified) {
            getProfile();
        }
    }, [emailVerified]);

    const name = useMemo(() => {
        if (data) {
            const {firstName} = data;
            return firstName ?? null;
        }

        return null;
    }, [data]);

    return {
        error,
        loading,
        name,
    };
}
