import {FormattedMessage} from 'react-intl';
import {
    FetchError,
    Loading,
    Test,
} from '../../../components';
import {useTraitEligibility} from '../../../hooks';
import {extractErrorMessage} from '../../../util';

export function Tests() {
    const {
        eligibleTraits,
        error,
        ineligibleTraits,
        loading,
    } = useTraitEligibility();

    return (
        <section className={'account-content my-tests'}>
            <header>
                <img
                    alt={''}
                    className={'icon'}
                    role={'presentation'}
                    src={'/images/icon-test-order.svg'}
                />
                <h2 data-test-id={'account-tests-title'}>
                    <FormattedMessage id={'account.tests.title'} />
                </h2>
            </header>
            {error ? <FetchError reason={extractErrorMessage(error)} /> : loading
                ? (
                    <div className={'center-within'}>
                        <Loading />
                    </div>
                )
                : (
                    <>
                        {Boolean(eligibleTraits?.length) && (
                            <div className={'test-block'}>
                                <div className={'is-caption is-heavy'}>
                                    <FormattedMessage id={'common.profile.tests.included'} />
                                </div>
                                {eligibleTraits.map(({trait}) => (
                                    <Test
                                        included={true}
                                        key={trait}
                                        trait={trait}
                                    />
                                ))}
                            </div>
                        )}
                        {Boolean(ineligibleTraits?.length) && (
                            <div className={'test-block'}>
                                <div className={'is-caption is-heavy'}>
                                    <FormattedMessage id={'common.profile.tests.notIncluded'} />
                                </div>
                                {ineligibleTraits.map(({trait}) => (
                                    <Test
                                        included={false}
                                        key={trait}
                                        trait={trait}
                                    />
                                ))}
                            </div>
                        )}
                    </>
                )}
        </section>
    );
}
