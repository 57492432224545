import {RegistrationGuard} from './RegistrationGuard';
import {ReportGuard} from './ReportGuard';
import {RequireEmailVerification} from './RequireEmailVerification';

export const ProtectedRoute = (props) => {
    const {
        authenticated = false,
        component: Component,
        registered = false,
        report = false,
    } = props;

    if (authenticated && report) {
        return (
            <RequireEmailVerification>
                <ReportGuard>
                    <Component />
                </ReportGuard>
            </RequireEmailVerification>
        );
    }
    else if (authenticated && registered) {
        return (
            <RequireEmailVerification>
                <RegistrationGuard>
                    <Component />
                </RegistrationGuard>
            </RequireEmailVerification>
        );
    }
    else if (authenticated) {
        return (
            <RequireEmailVerification>
                <Component />
            </RequireEmailVerification>
        );
    }
    else if (report) {
        return (
            <ReportGuard>
                <Component />
            </ReportGuard>
        );
    }
    else if (registered) {
        return (
            <RegistrationGuard>
                <Component />
            </RegistrationGuard>
        );
    }

    return <Component />;
};
