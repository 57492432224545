import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Checkmark,
    SixFiveGridContainer,
} from '../../components';

export function TakeActionContent() {
    const intl = useIntl();

    return (
        <SixFiveGridContainer className={'reversed take-action'}>
            <div className={'five'}>
                <h2>
                    <FormattedMessage id={'landing.takeAction.title'} />
                </h2>
                <ul>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'landing.takeAction.pointOne'} />
                    </li>
                    <li className={'is-heavy multiline'}>
                        <Checkmark />
                        <FormattedMessage id={'landing.takeAction.pointTwo'} />
                    </li>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'landing.takeAction.pointThree'} />
                    </li>
                </ul>
            </div>
            <img
                alt={intl.formatMessage({id: 'landing.image.actionPlan.alt'})}
                className={'six image'}
                src={'/images/action-plan-mockup_1040x1200_v1_116KB.webp'}
            />
        </SixFiveGridContainer>
    );
}
