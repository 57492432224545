import {DateTime} from 'luxon';

export const TRANSIENT_PERSIST_FIELDS_KEY = 'TRANSIENT_PERSIST_FIELDS_KEY';

export const action = (type, payload) => ({
    payload,
    type,
});

export const calculateAge = (dateOfBirth) => {
    const startDate = DateTime.fromISO(dateOfBirth);
    const endDate = DateTime.now();

    // If we ask for months as well as years, then it correctly floors years, otherwise it comes back as a fraction.
    const {years} = endDate.diff(startDate, ['years', 'months']);
    return years;
};

// Return a string message from a fetch error object
export const extractErrorMessage = (fetchError) => {
    // Fetch errors from API should in most cases either be empty or an array of error IDs. It's possible that it could
    // be string if something unexpected happens, so we allow for that as well.
    if (typeof fetchError === 'object') {
        if (typeof fetchError?.errorData === 'object') {
            return JSON.stringify(fetchError.errorData);
        }

        return JSON.stringify(fetchError);
    }

    return fetchError.toString();
};

export const defaultEventHandler = (event) => {
    event.preventDefault();
};

export const NetworkPolicy = {
    CACHE_AND_NETWORK: 'cache-and-network',
    CACHE_OR_NETWORK: 'cache-or-network',
    NETWORK_ONLY: 'network-only',
    NO_CACHE: 'no-cache',
};

export const Endpoint = {
    ACCOUNT: 'v1/me/account',
    CONFIRM_REGISTRATION: 'v1/me/profile/submit',
    CONSENT: 'v1/me/consent',
    COUNSELING_LINK: 'v1/me/genetic-counselling',
    INVITATIONS_AVAILABLE: 'invitations/available',
    KITS: 'v1/me/kits',
    LOGIN: 'v1/login',
    LOGOUT: 'v1/logout',
    PROFILE: 'v1/me/profile',
    REGISTER: 'v1/register',
    REORDER_TEST: 'v1/me/reorder-test',
    REPORT: 'v1/me/report.pdf',
    RESULTS: 'v1/me/results',
    SEND_VERIFICATION_EMAIL: 'v1/me/verify',
    SUMMARY: 'v1/me/summary',
    TRAIT_ELIGIBILITY: 'v1/me/traits',
    VALIDATE_KIT: 'v1/me/kits/validate',
};

export const RiskLevel = {
    AVERAGE: 'AVERAGE',
    HIGHER: 'HIGHER',
};

export const RiskWindow = {
    AT_LIFETIME: 'AT_LIFETIME',
    REMAINING_LIFETIME: 'REMAINING_LIFETIME',
    TEN_YEARS: 'TEN_YEARS',
};

export const Trait = {
    AF: {
        code: 'AF',
        sortKey: 'atrial',
        url: 'atrial-fibrillation',
    },
    BC: {
        code: 'BC',
        sortKey: 'breast',
        url: 'breast-cancer',
    },
    CVD_US: {
        code: 'CVD_US',
        sortKey: 'cardiovascular',
        url: 'cardiovascular-disease',
    },
    FNDXAT_N1P0: {
        code: 'FNDXAT_N1P0',
        sortKey: 'low bone',
        url: 'osteopenia',
    },
    HCH: {
        code: 'HCH',
        sortKey: 'high ldl',
        url: 'cholesterol',
    },
    HT: {
        code: 'HT',
        sortKey: 'high blood',
        url: 'hypertension',
    },
    PC: {
        code: 'PC',
        sortKey: 'prostate',
        url: 'prostate-cancer',
    },
    T2D: {
        code: 'T2D',
        sortKey: 'type',
        url: 'type-2-diabetes',
    },
};

export const traitCodeToPathname = (traitCode) => {
    if (Object.keys(Trait).includes(traitCode)) {
        return Trait[traitCode].url;
    }

    throw new Error(`Unknown trait code - ${traitCode}`);
};

export const traitPathnameToCode = (url) => {
    const trait = Object.values(Trait).find((it) => url === it.url);
    if (trait) {
        return trait.code;
    }

    throw new Error(`Unknown url - ${url}`);
};

export const devConsole = (...args) => {
    if (['development', 'test'].includes(process.env.NODE_ENV)) {
        // eslint-disable-next-line no-console
        console.log(...args);
    }
};

export class Ethnicities {
    static US_AIAN = 'US_AIAN';

    static US_BLACK = 'US_BLACK';

    static US_EAS = 'US_EAS';

    static US_EQUAL = 'US_EQUAL';

    static US_HISP = 'US_HISP';

    static US_NHPI = 'US_NHPI';

    static US_OTHER = 'US_OTHER';

    static US_PNTS = 'US_PNTS';

    static US_SAS = 'US_SAS';

    static US_SEAS = 'US_SEAS';

    static US_WHITE = 'US_WHITE';
}
