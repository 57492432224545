import {useState} from 'react';
import {
    Outlet,
    useLocation,
    useOutletContext,
} from 'react-router-dom';
import {Routes as InternalRoutes} from '../../../../Routes';
import {TRANSIENT_PERSIST_FIELDS_KEY} from '../../../../util';

const VALUE_PNTS = 'PREFER_NOT_TO_SAY';

export function ReportedSexOptionalQuestionInterceptor(props) {
    const {
        routes: {
            reportedSex: reportedSexRoute,
            pntsSex: pntsSexRoute,
        },
    } = props ?? {
        routes: {
            reportedSex: InternalRoutes.SIGN_UP_EDIT_REPORTED_SEX,
            pntsSex: InternalRoutes.SIGN_UP_EDIT_PNTS_SEX,
        },
    };

    const {pathname} = useLocation();
    const {
        currentUserProfile,
        errorResponse,
        setNavigateToOptionalQuestion,
        setPersistFields,
    } = useOutletContext();
    const [currentSelection, setCurrentSelection] = useState(
        JSON.parse(sessionStorage.getItem(TRANSIENT_PERSIST_FIELDS_KEY) ?? '{}'),
    );

    const persistFieldsInterceptor = (persistFields) => {
        // We're pretending to get a setState function, so we're expecting either a function that when called, will
        // return another function. Or, we're going to get null, which will happens when a question is unmounted.
        if (persistFields) {
            // We should now have a double function call to get that fields that require persisting...
            const fields = persistFields()();

            // We need to take care, as we null the persistFields function when the question is unmounted...
            if (fields) {
                // We need to do different thing depending on if we're on the main ethnicity question, or the optional
                // identified question.
                if (pathname === reportedSexRoute) {
                    // As we want the original question's answer to be persisted when the optional question's save and
                    // continue buttons is clicked, let's store their current answer in session storage. We can also
                    // pick it up after a hard refresh, too, which the is useful. We do this here, so the value in the
                    // sessionStorage always reflects the current answer.
                    sessionStorage.setItem(TRANSIENT_PERSIST_FIELDS_KEY, JSON.stringify(fields));

                    // Update some local state with the same value we've put into the session scope, this makes it
                    // easier to overlay the new values, over the existing profile, in the outlet below.
                    setCurrentSelection(fields);

                    if (fields.reportedSex === VALUE_PNTS) {
                        // They have selected the prefer not to say option, which means that they will have to visit
                        // the optional question. So `null` the fields to be persisted etc, etc.
                        setPersistFields(null);
                        setNavigateToOptionalQuestion(pntsSexRoute);
                    }
                    else {
                        // They have not selected the option that triggers the optional page, just pass the object
                        // along, as we don't want to do anything here...
                        setPersistFields(() => () => fields);
                        setNavigateToOptionalQuestion(null);
                    }
                }
                else {
                    // We should now be on the optional question, so just return the answer to the original question.
                    setPersistFields(
                        () => () => JSON.parse(sessionStorage.getItem(TRANSIENT_PERSIST_FIELDS_KEY)),
                    );
                    setNavigateToOptionalQuestion(null);
                }
            }
        }
        else {
            // Just pass along the null...
            setPersistFields(null);
            setNavigateToOptionalQuestion(null);
        }
    };

    return (
        <Outlet
            context={{
                currentUserProfile: {
                    ...currentUserProfile,
                    ...currentSelection,
                },
                errorResponse: errorResponse,
                setPersistFields: persistFieldsInterceptor,
            }}
        />
    );
}
