import classNames from 'classnames';

export function TwoThirdsContainer(props) {
    const {
        className,
        ContentComponent,
        dataTestId,
        TitleComponent,
    } = props;

    const containerClasses = classNames('two-thirds-container', className);

    return (
        <div
            className={containerClasses}
            data-test-id={dataTestId}
        >
            <div className={`one-third`}>{TitleComponent}</div>
            <div className={'two-thirds'}>{ContentComponent}</div>
        </div>
    );
}
