import {Navigate} from 'react-router-dom';
import {useUserContext} from '../../context';
import {Routes} from '../../Routes';

export function RequireAnonymous(props) {
    const {children} = props;

    const {isAuthenticated} = useUserContext();

    if (isAuthenticated) {
        return <Navigate to={Routes.LANDING} />;
    }

    return children;
}
