import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCardImage,
    GraphicalActionCard,
    LinkWithAttribution,
} from '../../../../../../components';

export function AtrialFibrillationWeight(props) {
    const {currentStep, totalSteps} = props;

    const intl = useIntl();

    return (
        <GraphicalActionCard
            currentStep={currentStep}
            dataTestId={'weight-AF'}
            headerImage={ActionCardImage.WEIGHT}
            title={intl.formatMessage({id: 'reports.trait.lifestyleActions.weight.title'})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.weight.description.one.AF'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.weight.description.three'} />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.CDCP'})}
                url={'https://www.cdc.gov/healthyweight/assessing/index.html'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.weight.link.assess'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.CDCP'})}
                url={'https://www.cdc.gov/healthyweight/losing_weight/getting_started.html'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.weight.link.lose'} />
            </LinkWithAttribution>
        </GraphicalActionCard>
    );
}
