import {useNavigate} from 'react-router-dom';

export function useDefaultBackClickHandler() {
    const navigate = useNavigate();

    const defaultBackClickHandler = (event) => {
        event.preventDefault();
        navigate(-1);
    };

    return defaultBackClickHandler;
}
