import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function ProcessingYourSample(props) {
    const {name} = props;

    const intl = useIntl();

    return (
        <>
            <div className={'left'}>
                <header>
                    <div
                        className={'is-caption name'}
                        data-test-id={'users-shortened-name'}
                    >
                        {name}
                    </div>
                    <h2
                        className={'page-title'}
                        data-test-id={'home-processing-your-sample-title'}
                    >
                        <FormattedMessage id={'home.processingYourSample.title'} />
                    </h2>
                </header>
                <p>
                    <FormattedMessage id={'home.processingYourSample.paragraph'} />
                </p>
            </div>
            <div className={'right'}>
                <img
                    alt={intl.formatMessage({id: 'common.testKitBox.alt'})}
                    src={'/images/health-insights-test-kit-box_520x600.jpg'}
                />
            </div>
        </>
    );
}
