import {useMemo} from 'react';
import {
    FormattedDate,
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {QuestionAndAnswer} from '../../question-and-answer';

export function ProfileInformation(props) {
    const intl = useIntl();
    const {
        allowEditing = false,
        dateOfBirth,
        editPrefix = '/sign-up',
        ethnicities,
        ethnicityIdentifyMost,
        firstName,
        homeAddress,
        lastName,
        phoneNumber,
        reportedSex,
        shippingAddress,
    } = props;

    const dateOfBirthAnswer = useMemo(
        () => (
            <FormattedDate
                day={'2-digit'}
                month={'long'}
                // https://genomicsplc.atlassian.net/browse/PH-4974
                timeZone={'UTC'}
                value={new Date(dateOfBirth)}
                year={'numeric'}
            />
        ),
        [dateOfBirth],
    );

    const ethnicityAnswer = useMemo(
        () => (ethnicities?.length === 1
            ? <FormattedMessage id={`common.profile.ethnicity.${ethnicities[0]}.text`} />
            : (
                <ul className={'ethnicities no-decoration'}>
                    {ethnicities?.map((value) => (
                        <li
                            data-test-id={`ethnicity-${value}`}
                            key={value}
                        >
                            <FormattedMessage id={`common.profile.ethnicity.${value}.text`} />
                        </li>
                    ))}
                </ul>
            )),
        [ethnicities],
    );

    const homeAddressString = useMemo(
        () =>
            `${homeAddress?.apartmentSuiteBuilding} ${homeAddress?.streetAddress}
${homeAddress?.city}, ${homeAddress?.state} ${homeAddress?.zipCode}`,
        [homeAddress],
    );

    const shippingDetailsAddressString = useMemo(
        () =>
            `${shippingAddress?.recipient}
${shippingAddress?.apartmentSuiteBuilding} ${shippingAddress?.streetAddress}
${shippingAddress?.city}, ${shippingAddress?.state} ${shippingAddress?.zipCode}`,
        [shippingAddress],
    );

    return (
        <>
            <QuestionAndAnswer
                answer={`${firstName} ${lastName}`}
                dataTestId={'summary-name'}
                editRoute={allowEditing ? `${editPrefix}/edit/name` : null}
                question={intl.formatMessage({id: 'common.profile.fullName'})}
            />
            <QuestionAndAnswer
                answer={dateOfBirthAnswer}
                dataTestId={'summary-date-of-birth'}
                editRoute={allowEditing ? `${editPrefix}/edit/date-of-birth` : null}
                question={intl.formatMessage({id: 'common.profile.dateOfBirth'})}
            />
            <QuestionAndAnswer
                answer={intl.formatMessage({id: `common.profile.biologicalSex.${reportedSex}`})}
                dataTestId={'summary-reported-sex'}
                editRoute={allowEditing ? `${editPrefix}/edit/reported-sex` : null}
                question={intl.formatMessage({id: 'common.profile.biologicalSex'})}
            />
            <QuestionAndAnswer
                answer={ethnicityAnswer}
                dataTestId={'summary-ethnicity'}
                editRoute={allowEditing ? `${editPrefix}/edit/ethnicity` : null}
                question={intl.formatMessage({id: 'common.profile.ethnicity'})}
            />
            {ethnicities?.length >= 2 && (
                <QuestionAndAnswer
                    answer={intl.formatMessage({id: `common.profile.ethnicity.${ethnicityIdentifyMost}.text`})}
                    dataTestId={'summary-identified-ethnicity'}
                    editRoute={allowEditing ? `${editPrefix}/edit/identified-ethnicity` : null}
                    question={intl.formatMessage({id: 'common.profile.identifiedEthnicity'})}
                />
            )}
            <QuestionAndAnswer
                answer={phoneNumber}
                dataTestId={'summary-phone-number'}
                editRoute={allowEditing ? `${editPrefix}/edit/phone-number` : null}
                question={intl.formatMessage({id: 'common.profile.phoneNumber'})}
            />
            <QuestionAndAnswer
                answer={homeAddressString}
                answerClassName={'address-whitespace'}
                dataTestId={'summary-home-address'}
                editRoute={allowEditing ? `${editPrefix}/edit/home-address` : null}
                question={intl.formatMessage({id: 'common.profile.homeAddress'})}
            />
            <QuestionAndAnswer
                answer={shippingDetailsAddressString}
                answerClassName={'address-whitespace'}
                dataTestId={'summary-shipping-address'}
                editRoute={allowEditing ? `${editPrefix}/edit/shipping-address` : null}
                question={intl.formatMessage({id: 'common.profile.shippingAddress'})}
            />
        </>
    );
}
