import {Auth0Provider} from '@auth0/auth0-react';
import React, {
    useMemo,
    useState,
} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Loading} from '../components';
import {
    useConfig,
    UserProvider,
} from '../context';
import {devConsole} from '../util';
import {Auth0CallbackProcessor} from './Auth0CallbackProcessor';

// hasAuthParams exists in the auth0-react library. Unfortunately we can't import it directly since it is typescript, so
// we duplicate it here.
const hasAuthParams = (searchParams) =>
    (searchParams.has('code') || searchParams.has('error')) && searchParams.has('state');

// Why does this component exist?
// This component exists to handle callbacks immediately after logging in or signing up to Auth0. When this happens, we
// want to make either a login or a signup request to our backend to exchange the Auth0 id token for a cookie based session.
//
// The auth0-react library provides an Auth0Provider component with an "onRedirectCallback" prop that already runs in
// this situation, but it is unfortunately unsuitable for our use because:
// * It runs asynchronously, so the regular react tree will immediately render beneath it while the backend request are
//   executing. We want to wait until they are finished to decide what to show the user.
// * To make the backend requests, we need access to the raw IdToken, which is not available inside the "onRedirectCallback".
//   That function only has access to the appState sent through Auth0 and basic user information.
//
// This component therefore exists to handle our own custom redirect processing, essentially replacing the use of the
// "onRedirectCallback" function with rendering a custom child element for this processing.
export const Auth0ProviderWithRedirectProcessor = ({children}) => {
    const {auth0ClientId, auth0Domain} = useConfig();
    const [searchParams] = useSearchParams();
    const [appState, setAppState] = useState(null);

    // custom handler to record appState from Auth0 callback, so we can send it to the callback element
    const onRedirectCallbackHandler = (appState) => {
        devConsole('Auth0ProviderWithHistory: Processing Auth0 callback.');
        setAppState(appState);
    };

    const conditionalChildren = useMemo(
        () => {
            if (hasAuthParams(searchParams)) {
                // This page render is processing an Auth0 callback
                if (appState === null) {
                    devConsole(
                        'Auth0ProviderWithHistory: Detected auth0 callback params and rendering loading spinner.',
                    );
                    // The appState has not yet been set, so show a loading spinner
                    return (
                        <div className={'flex-column full-height'}>
                            <Loading key={'loading'} />
                        </div>
                    );
                }
                // We have an appState so render the callback element and pass the appState
                devConsole(
                    'Auth0ProviderWithHistory: Detected auth0 callback params and rendering Auth0Callback element.',
                );
                return <Auth0CallbackProcessor appState={appState} />;
            }
            // No auth params, so render children inside the user Provider
            return (
                <UserProvider>
                    {children}
                </UserProvider>
            );
        },
        [appState, children, searchParams],
    );

    /* eslint-disable camelcase */
    return (
        <Auth0Provider
            authorizationParams={{redirect_uri: `${window.location.origin}`}}
            cacheLocation={'localstorage'}
            clientId={auth0ClientId}
            domain={auth0Domain}
            onRedirectCallback={onRedirectCallbackHandler}
        >
            {conditionalChildren}
        </Auth0Provider>
    );
    /* eslint-enable camelcase */
};
