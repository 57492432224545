import {Navbar} from '@genomicsplc/denim-components';
import classNames from 'classnames';
import {
    useMemo,
    useState,
} from 'react';
import {Outlet} from 'react-router-dom';
import {Logotype} from '../../components';
import {useSanitisedPathname} from '../../hooks';

export function MinimalLayout() {
    const sanitisedPathname = useSanitisedPathname();
    const [className, setClassName] = useState('');

    const mainClasses = useMemo(
        () => classNames(className, sanitisedPathname),
        [className, sanitisedPathname],
    );

    return (
        <div className={'minimal-layout'}>
            <Navbar
                desktopContent={<Logotype homeLink={true} />}
                mobileContent={<Logotype homeLink={true} />}
            />
            <main className={mainClasses}>
                <Outlet context={{setClassName}} />
            </main>
        </div>
    );
}
