import {FormattedMessage} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {Checkmark} from '../../components';
import {Routes} from '../../Routes';

export function ProtectDataContent() {
    return (
        <>
            <ul className={'no-decoration'}>
                <li className={'with-checkmark is-heavy'}>
                    <Checkmark />
                    <FormattedMessage id={'trust-and-privacy.protect-data.one'} />
                </li>
                <li className={'with-checkmark is-heavy'}>
                    <Checkmark />
                    <FormattedMessage id={'trust-and-privacy.protect-data.two'} />
                </li>
                <li className={'with-checkmark is-heavy'}>
                    <Checkmark />
                    <FormattedMessage id={'trust-and-privacy.protect-data.three'} />
                </li>
            </ul>
            <p>
                <FormattedMessage
                    id={'trust-and-privacy.protect-data.supplemental'}
                    values={{
                        link: (chunks) => (
                            <InternalLink
                                className={'link alternate'}
                                to={Routes.PRIVACY_POLICY}
                            >
                                <span>{chunks}</span>
                            </InternalLink>
                        ),
                    }}
                />
            </p>
        </>
    );
}
