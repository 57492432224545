import {FormattedMessage} from 'react-intl';
import {Trait} from '../../../../../util';
import {AtrialFibrillationMedicalActions} from './atrial-fibrillation';
import {BreastCancerMedicalActions} from './breast-cancer';
import {CardiovascularDiseaseMedicalActions} from './cardiovascular-disease';
import {HighBloodPressureMedicalActions} from './high-blood-pressure';
import {HighLDLCholesterolMedicalActions} from './high-ldl-cholesterol';
import {OsteopeniaMedicalActions} from './osteopenia';
import {ProstateCancerMedicalActions} from './prostate-cancer';
import {Type2DiabetesMedicalActions} from './type-2-diabetes';

export function MedicalActions(props) {
    const {
        reportedSex,
        riskLevel,
        riskWindow,
        traitCode,
    } = props;

    const totalSteps = 2;

    let MedicalActionsContents = null;
    switch (traitCode) {
        case Trait.AF.code:
            MedicalActionsContents = AtrialFibrillationMedicalActions;
            break;

        case Trait.BC.code:
            MedicalActionsContents = BreastCancerMedicalActions;
            break;

        case Trait.CVD_US.code:
            MedicalActionsContents = CardiovascularDiseaseMedicalActions;
            break;

        case Trait.HT.code:
            MedicalActionsContents = HighBloodPressureMedicalActions;
            break;

        case Trait.HCH.code:
            MedicalActionsContents = HighLDLCholesterolMedicalActions;
            break;

        case Trait.FNDXAT_N1P0.code:
            MedicalActionsContents = OsteopeniaMedicalActions;
            break;

        case Trait.PC.code:
            MedicalActionsContents = ProstateCancerMedicalActions;
            break;

        case Trait.T2D.code:
            MedicalActionsContents = Type2DiabetesMedicalActions;
            break;

        default:
            break;
    }

    return (
        <section className={'medical-actions section-divider layout two-thirds'}>
            <div className={'sidebar'}>
                <h2>
                    <FormattedMessage id={'reports.trait.medicalActions.title'} />
                </h2>
                <div
                    className={'count is-caption is-heavy'}
                    data-test-id={'medical-actions-step-count'}
                >
                    <FormattedMessage
                        id={'reports.trait.medicalActions.steps'}
                        values={{count: totalSteps}}
                    />
                </div>
            </div>
            <div className={'content'}>
                <MedicalActionsContents
                    reportedSex={reportedSex}
                    riskLevel={riskLevel}
                    riskWindow={riskWindow}
                    totalSteps={totalSteps}
                />
            </div>
        </section>
    );
}
