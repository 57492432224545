import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link} from 'react-router-dom';
import {LinkWithAttribution} from '../../components';
import {Routes} from '../../Routes';

export function PapersContent() {
    const intl = useIntl();

    return (
        <>
            <p>
                <FormattedMessage id={'science.papers.content'} />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'science.papers.attributionOne'})}
                linkClassName={'alternate'}
                url={'https://insightsgenomics.com/documents/genomicsplc-HI-validation-paper-2023.pdf'}
            >
                <FormattedMessage id={'science.papers.titleOne'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'science.papers.attributionTwo'})}
                linkClassName={'alternate'}
                url={'https://www.genomicsplc.com/news/new-and-improved-polygenic-risk-scores-for-28-common-diseases/'}
            >
                <FormattedMessage id={'science.papers.titleTwo'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'science.papers.attributionThree'})}
                linkClassName={'alternate'}
                url={'https://www.ahajournals.org/doi/full/10.1161/CIRCGEN.120.003304'}
            >
                <FormattedMessage id={'science.papers.titleThree'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'science.papers.attributionFour'})}
                linkClassName={'alternate'}
                url={'https://www.ajconline.org/article/S0002-9149(21)00207-1/fulltext'}
            >
                <FormattedMessage id={'science.papers.titleFour'} />
            </LinkWithAttribution>
            <Link
                className={'link button'}
                data-test-id={'learn-more-about-genomics'}
                to={Routes.ABOUT_US}
            >
                <FormattedMessage id={'science.papers.learnMore'} />
            </Link>
        </>
    );
}
