import {
    Breakpoint,
    useBreakpoints,
} from '@genomicsplc/denim-components';
import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import {Routes as InternalRoutes} from '../../Routes';
import {ReportGuard} from '../auth/ReportGuard';
import {NotFound} from '../not-found';
import {LabReport} from './lab-report';
import {AccountDesktopLayout} from './layout';
import {AccountMobileLayout} from './layout/AccountMobileLayout';
import {AccountSidebar} from './layout/AccountSidebar';
import {Profile} from './profile';
import {Security} from './security';
import {Tests} from './tests';

export function AccountController() {
    const breakpoint = useBreakpoints();

    const AccountLayout = [Breakpoint.PHONE, Breakpoint.TABLET_PORTRAIT].includes(breakpoint)
        ? AccountMobileLayout
        : AccountDesktopLayout;

    const IndexComponent = [Breakpoint.PHONE, Breakpoint.TABLET_PORTRAIT].includes(breakpoint)
        ? AccountSidebar
        : Navigate;

    return (
        <Routes>
            <Route path={'/'}>
                <Route element={<AccountLayout />}>
                    <Route
                        element={
                            <IndexComponent
                                replace={true}
                                to={'profile'}
                            />
                        }
                        index={true}
                    />
                    <Route
                        element={<Profile />}
                        path={'profile'}
                    />
                    <Route
                        element={<Tests />}
                        path={'tests'}
                    />
                    <Route
                        element={
                            <ReportGuard navigateTo={InternalRoutes.ACCOUNT}>
                                <LabReport />
                            </ReportGuard>
                        }
                        path={'lab-report'}
                    />
                    <Route
                        element={<Security />}
                        path={'security'}
                    />
                </Route>
                <Route
                    element={<NotFound />}
                    path={'*'}
                />
            </Route>
        </Routes>
    );
}
