import {Loading} from '../../../components';
import {useName} from '../../../hooks';
import {PageContent} from './PageContent';

export function WhileAuthenticated() {
    const {
        error,
        loading,
        name,
    } = useName();

    if (loading) {
        return (
            <div className={'center-within'}>
                <Loading />
            </div>
        );
    }

    return (
        <PageContent
            buttonDataTestId={'continue-button'}
            buttonKey={'testReorder.order.loggedIn.button.text'}
            buttonTitleKey={'testReorder.order.loggedIn.button.title'}
            name={error ? null : name}
            titleDataTestId={'test-reorder-while-authenticated'}
            titleKey={'testReorder.order.loggedIn.title'}
        />
    );
}
