import {Outlet} from 'react-router-dom';
import {LegalFooter} from '../legal-footer';

export function BaseLegalLayout() {
    return (
        <>
            <Outlet />
            <LegalFooter />
        </>
    );
}
