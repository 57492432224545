import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCardImage,
    GraphicalActionCard,
    LinkWithAttribution,
} from '../../../../../components';
import {Trait} from '../../../../../util';

export function Alcohol(props) {
    const {
        currentStep,
        totalSteps,
        traitCode,
    } = props;

    const intl = useIntl();

    const titleId = traitCode === Trait.BC.code
        ? 'reports.trait.lifestyleActions.alcohol.title.alternative'
        : 'reports.trait.lifestyleActions.alcohol.title';
    const descriptionTwoId = traitCode === Trait.BC.code
        ? 'reports.trait.lifestyleActions.alcohol.description.two.alternative'
        : 'reports.trait.lifestyleActions.alcohol.description.two';

    return (
        <GraphicalActionCard
            currentStep={currentStep}
            dataTestId={`alcohol-${traitCode}`}
            headerImage={ActionCardImage.ALCOHOL}
            title={intl.formatMessage({id: titleId})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={`reports.trait.lifestyleActions.alcohol.description.one.${traitCode}`}
                />
            </p>
            <p>
                <FormattedMessage id={descriptionTwoId} />
            </p>
            {Trait.BC.code !== traitCode && (
                <>
                    <ul>
                        <li>
                            <FormattedMessage id={'reports.trait.lifestyleActions.alcohol.item.one'} />
                        </li>
                        <li>
                            <FormattedMessage id={'reports.trait.lifestyleActions.alcohol.item.two'} />
                        </li>
                    </ul>
                    <p>
                        <FormattedMessage
                            id={'reports.trait.lifestyleActions.alcohol.description.three'}
                        />
                    </p>
                </>
            )}
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.alcohol.description.four'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.alcohol.item.three'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.alcohol.item.four'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.alcohol.item.five'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.alcohol.item.six'} />
                </li>
            </ul>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.CDCP'})}
                url={'https://www.cdc.gov/alcohol/fact-sheets/alcohol-use.htm'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.alcohol.link'} />
            </LinkWithAttribution>
        </GraphicalActionCard>
    );
}
