import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {
    DoesNotTellYou,
    GenomicsGroup,
    NumberedTitleContainer,
    PageContent,
    TitleContainer,
    TwoThirdsContainer,
} from '../../components';
import {useAmplitude} from '../../context';
import {GeneticRiskContent} from './GeneticRiskContent';
import {OtherFactorsContent} from './OtherFactorsContent';
import {OurPerformanceContent} from './OurPerformanceContent';
import {PapersContent} from './PapersContent';
import {ReadYourDNAContent} from './ReadYourDNAContent';
import {YourRiskContent} from './YourRiskContent';

export function Science() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {setMainClassName} = useOutletContext();

    useEffect(() => {
        amplitude.logScienceVisit();
        setMainClassName('wavy-line-background');

        return () => {
            setMainClassName(null);
        };
    }, [amplitude]);

    return (
        <>
            <PageContent className={'page-title variant-dark'}>
                <h1 data-test-id={'science-page-main-title'}>
                    <FormattedMessage id={'science.title.text'} />
                </h1>
                <p className={'no-margin-bottom'}>
                    <FormattedMessage id={'science.title.content'} />
                </p>
            </PageContent>
            <PageContent className={'variant-dark calculate-risk'}>
                <h2 data-test-id={'calculate-risk-title'}>
                    <FormattedMessage id={'science.calculateRisk.title'} />
                </h2>
                <TwoThirdsContainer
                    ContentComponent={<ReadYourDNAContent />}
                    TitleComponent={
                        <NumberedTitleContainer
                            number={1}
                            title={intl.formatMessage({id: 'science.calculateRisk.readYourDNA.title'})}
                        />
                    }
                />
                <TwoThirdsContainer
                    ContentComponent={<GeneticRiskContent />}
                    TitleComponent={
                        <NumberedTitleContainer
                            number={2}
                            title={intl.formatMessage({id: 'science.calculateRisk.geneticRisk.title'})}
                        />
                    }
                />
                <TwoThirdsContainer
                    ContentComponent={<OtherFactorsContent />}
                    TitleComponent={
                        <NumberedTitleContainer
                            number={3}
                            title={intl.formatMessage({id: 'science.calculateRisk.otherFactors.title'})}
                        />
                    }
                />
                <TwoThirdsContainer
                    ContentComponent={<YourRiskContent />}
                    TitleComponent={
                        <NumberedTitleContainer
                            number={4}
                            title={intl.formatMessage({id: 'science.calculateRisk.yourRisk.title'})}
                        />
                    }
                />
            </PageContent>
            <DoesNotTellYou />
            <PageContent className={'variant-dark'}>
                <TwoThirdsContainer
                    ContentComponent={<OurPerformanceContent />}
                    TitleComponent={
                        <TitleContainer
                            title={intl.formatMessage({id: 'science.ourPerformance.title'})}
                        />
                    }
                    className={'our-performance'}
                />
                <TwoThirdsContainer
                    ContentComponent={<PapersContent />}
                    TitleComponent={<TitleContainer title={intl.formatMessage({id: 'science.papers.title'})} />}
                    className={'papers'}
                />
            </PageContent>
            <PageContent className={'variant-dark genomics-group'}>
                <TwoThirdsContainer ContentComponent={<GenomicsGroup />} />
            </PageContent>
        </>
    );
}
