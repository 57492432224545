import * as amplitude from '@amplitude/analytics-browser';
import {Routes} from '../Routes';
import {Trait} from '../util';

// Config has changed with the latest version of the SDK. Amplitude marketing attribution by default by tracking UTM,
// referrers and click IDs as user properties, etc:
//     https://www.docs.developers.amplitude.com/data/sdks/browser-2/#tracking-marketing-attribution

export class AmplitudeClient {
    constructor(apiKey) {
        if (apiKey) {
            this.amplitude = amplitude.createInstance();

            // Automagic page views are turned off, as the Google Doc has specific event names which differ from what
            // the SDK docs say is generated:
            //        https://genomicsplc.atlassian.net/wiki/spaces/PE/pages/3292790785/KPI+events+for+MVP
            this.amplitude.init(apiKey, {
                defaultTracking: {
                    attribution: true,
                    fileDownloads: false,
                    formInteractions: false,
                    pageViews: false,
                    sessions: false,
                },
                logLevel: amplitude.Types.LogLevel.None,
            });
        }
    }

    static load(apiKey) {
        return new AmplitudeClient(apiKey);
    }

    #logEvent(eventName, properties = {}) {
        this.amplitude?.track(eventName, properties);
    }

    setCustomerId(customerId) {
        this.amplitude.setGroup('customer', customerId);
    }

    setUserId(userId) {
        this.amplitude?.setUserId(userId);
    }

    // ---------- Marketing pages

    logLandingVisit() {
        this.#logEvent('landing-visit');
    }

    logLandingCostModalOpenedClicked() {
        this.#logEvent('landing-cost-modal-open');
    }

    logSignUpClicked(location) {
        this.#logEvent('signup-clicked', {location});
    }

    logHowItWorksVisit() {
        this.#logEvent('hiw-visit');
    }

    logScienceVisit() {
        this.#logEvent('science-visit');
    }

    logTrustAndPrivacyVisit() {
        this.#logEvent('trustandprivacy-visit');
    }

    logAboutUsVisit() {
        this.#logEvent('aboutus-visit');
    }

    logActivateKitLinkClicked(location) {
        this.#logEvent('activate-kit-link-clicked', {location});
    }

    // ---------- Sign-up

    logValidAccessCodeEntered() {
        this.#logEvent('valid-access-code-entered');
    }

    logInvalidAccessCodeEntered(code) {
        this.#logEvent('invalid-access-code-entered', {code});
    }

    logEligibilityVisit() {
        this.#logEvent('eligibility-visit');
    }

    logThingsYouShouldKnowVisit() {
        this.#logEvent('tysk-visit');
    }

    logAccountVisit() {
        this.#logEvent('account-visit');
    }

    logAccountCreated() {
        this.#logEvent('account-created');
    }

    logUserConsented() {
        this.#logEvent('user-consented');
    }

    logResearchConsented() {
        this.#logEvent('research-consented');
    }

    logMarketingConsented() {
        this.#logEvent('marketing-consented');
    }

    logAboutYouVisited() {
        this.#logEvent('about-you-visited');
    }

    logProfileQuestionAnswered(pathname, fields) {
        switch (pathname) {
            case Routes.SIGN_UP_NAME:
                this.#logEvent('name-submitted');
                break;

            case Routes.SIGN_UP_DATE_OF_BIRTH:
                this.#logEvent('birthdate-submitted');
                break;

            case Routes.SIGN_UP_REPORTED_SEX:
                this.#logEvent('sex-submitted');
                break;

            case Routes.SIGN_UP_ETHNICITY:
                this.#logEvent(
                    fields.ethnicities.length > 1 ? 'multi-race-submitted' : 'race-submitted',
                );
                break;

            case Routes.SIGN_UP_IDENTIFIED_ETHNICITY:
                this.#logEvent('identified-race-submitted');
                break;

            case Routes.SIGN_UP_PHONE_NUMBER:
                this.#logEvent('phone-submitted');
                break;

            case Routes.SIGN_UP_HOME_ADDRESS:
                this.#logEvent('address-home-submitted');
                break;

            case Routes.SIGN_UP_SHIPPING_DETAILS:
                this.#logEvent('address-shipping-submitted');
                break;

            case Routes.SIGN_UP_SHIPPING_ADDRESS:
                this.#logEvent('address-shipping-submitted');
                break;

            default:
                this.#logEvent(`details-submitted on ${pathname}`);
        }
    }

    logAnswersConfirmed() {
        this.#logEvent('answers-confirmed');
    }

    // ---------- Kit activation

    logActivationVisit() {
        this.#logEvent('activation-visit');
    }

    logActivationUnpackVisit() {
        this.#logEvent('activation-unpack-visit');
    }

    logActivationCodeValidSubmitted() {
        this.#logEvent('activation-code-valid-submitted');
    }

    logActivationCodeInvalidSubmitted(code) {
        this.#logEvent('activation-code-invalid-submitted', {code});
    }

    logActivationSpitVisit() {
        this.#logEvent('activation-spit-visit');
    }

    logActivationShakeVisit() {
        this.#logEvent('activation-shake-visit');
    }

    logActivationPackVisit() {
        this.#logEvent('activation-pack-visit');
    }

    logActivationPeelVisit() {
        this.#logEvent('activation-peel-visit');
    }

    logActivationDateSubmitted() {
        this.#logEvent('activation-date-submitted');
    }

    logSampleActivated() {
        this.#logEvent('sample-activated');
    }

    // ---------- Kit activation

    logRequestNewTestVisit() {
        this.#logEvent('request-new-test-visit');
    }

    logRequestNewTestCheckYourDetailsVisit() {
        this.#logEvent('request-new-test-check-your-details-visit');
    }

    logRequestNewTestYourResultsVisit() {
        this.#logEvent('request-new-test-your-results-visit');
    }

    logRequestNewTestPlaced() {
        this.#logEvent('request-new-test-placed');
    }

    // ---------- Results

    logResultsHomeVisited() {
        this.#logEvent('results-home-visited');
    }

    logTraitVisited(traitCode) {
        switch (traitCode) {
            case Trait.AF.code:
                this.#logEvent('results-afib-visited');
                break;

            case Trait.BC.code:
                this.#logEvent('results-bc-visited');
                break;

            case Trait.CVD_US.code:
                this.#logEvent('results-cvd-visited');
                break;

            case Trait.FNDXAT_N1P0.code:
                this.#logEvent('results-lbd-visited');
                break;

            case Trait.HCH.code:
                this.#logEvent('results-ldl-visited');
                break;

            case Trait.HT.code:
                this.#logEvent('results-hbp-visited');
                break;

            case Trait.PC.code:
                this.#logEvent('results-pc-visited');
                break;

            case Trait.T2D.code:
                this.#logEvent('results-t2d-visited');
                break;

            default:
                this.#logEvent(`visitation for ${traitCode}`);
        }
    }

    logResultsDownloaded() {
        this.#logEvent('results-downloaded');
    }

    logResultsHowVisited() {
        this.#logEvent('results-how-visited');
    }
}
