import {Component} from 'react';
import {ErrorLayout} from '../../layouts';
import {ErrorNotice} from '../error-notice';

export class ErrorBoundary extends Component {
    state = {
        errorMessage: null,
        hasError: false,
    };

    static getDerivedStateFromError(error) {
        // Update the local state so that the fallback UI is displayed on the next render.
        return {
            errorMessage: process.env.NODE_ENV === 'development' ? error?.message : null,
            hasError: true,
        };
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'development') {
            /* eslint-disable no-console */
            console.error(error);
            console.dir(errorInfo);
            /* eslint-enable no-console */
        }
    }

    render() {
        const {children} = this.props;
        const {errorMessage, hasError} = this.state;

        if (hasError) {
            return (
                <ErrorLayout mainClassName={'error-container'}>
                    <ErrorNotice errorCode={errorMessage} />
                </ErrorLayout>
            );
        }

        return children;
    }
}
