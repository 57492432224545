import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCardImage,
    GraphicalActionCard,
    LinkWithAttribution,
} from '../../../../../../components';

export function OsteopeniaDiet(props) {
    const {currentStep, totalSteps} = props;

    const intl = useIntl();

    return (
        <GraphicalActionCard
            currentStep={currentStep}
            dataTestId={'diet-FNDXAT_N1P0'}
            headerImage={ActionCardImage.DIET}
            title={intl.formatMessage({id: 'reports.trait.lifestyleActions.diet.title'})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={'reports.trait.lifestyleActions.diet.description.FNDXAT_N1P0.one'}
                />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.one'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.one'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.two'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.three'} />
                </li>
            </ul>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.two'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.four'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.five'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.six'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.seven'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.eight'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.nine'} />
                </li>
            </ul>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.three'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.ten'} />
                </li>
            </ul>
            <p>
                <FormattedMessage
                    id={'reports.trait.lifestyleActions.diet.description.FNDXAT_N1P0.two'}
                />
            </p>
            <ul>
                <li>
                    <FormattedMessage
                        id={'reports.trait.lifestyleActions.diet.item.FNDXAT_N1P0.one'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.lifestyleActions.diet.item.FNDXAT_N1P0.two'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.lifestyleActions.diet.item.FNDXAT_N1P0.three'}
                    />
                </li>
            </ul>
            <p>
                <FormattedMessage
                    id={'reports.trait.lifestyleActions.diet.description.FNDXAT_N1P0.three'}
                />
            </p>
            <ul>
                <li>
                    <FormattedMessage
                        id={'reports.trait.lifestyleActions.diet.item.FNDXAT_N1P0.four'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.lifestyleActions.diet.item.FNDXAT_N1P0.five'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.lifestyleActions.diet.item.FNDXAT_N1P0.six'}
                    />
                </li>
            </ul>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.CDCP'})}
                url={'https://www.cdc.gov/healthyweight/healthy_eating/index.html'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.link.basics'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.BHOF'})}
                url={'https://www.bonehealthandosteoporosis.org/patients/treatment/calciumvitamin-d/'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.link.calcium'} />
            </LinkWithAttribution>
        </GraphicalActionCard>
    );
}
