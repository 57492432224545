import {AtrialFibrillationRiskFactors} from './AtrialFibrillationRiskFactors';
import {AtrialFibrillationScreenOptions} from './AtrialFibrillationScreenOptions';

export function AtrialFibrillationMedicalActions(props) {
    const {riskLevel, totalSteps} = props;

    return (
        <>
            <AtrialFibrillationRiskFactors
                currentStep={1}
                totalSteps={totalSteps}
            />
            <AtrialFibrillationScreenOptions
                currentStep={2}
                riskLevel={riskLevel}
                totalSteps={totalSteps}
            />
        </>
    );
}
