import {Link} from '@genomicsplc/denim-components';
import classNames from 'classnames';
import {
    useCallback,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
    SupplementalBox,
} from '../../../../../../components';
import {RiskWindow} from '../../../../../../util';

export function CardiovascularDiseaseRiskFactors(props) {
    const {
        currentStep,
        riskWindow,
        totalSteps,
    } = props;

    const [showAdditionalContent, setShowAdditionalContent] = useState(false);

    const onClickHandler = useCallback(
        (event) => {
            event.preventDefault();
            setShowAdditionalContent(!showAdditionalContent);
        },
        [showAdditionalContent],
    );

    const intl = useIntl();

    const additionalContentLinkClasses = classNames(
        'icon',
        'after',
        showAdditionalContent ? 'chevron--up' : 'chevron--down',
    );

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`risk-factors-CVD_US-${riskWindow}`}
            title={intl.formatMessage({id: `reports.trait.actionPlan.actionCard.discussRiskFactors.title`})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.description'}
                    values={{trait: intl.formatMessage({id: `common.trait.CVD_US.text`})}}
                />
            </p>
            <ul>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.overweight'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.diet'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.activity'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.tooMuchAlcohol'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.smoking'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.sleep'}
                    />
                </li>
            </ul>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/heartdisease/risk_factors.htm'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.link.alternative'}
                    values={{trait: intl.formatMessage({id: `reports.trait.CVD_US.text.alternative`})}}
                />
            </LinkWithAttribution>
            {riskWindow === RiskWindow.TEN_YEARS && (
                <SupplementalBox
                    title={intl.formatMessage({
                        id: 'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.heading',
                    })}
                >
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.description.one'}
                        />
                    </p>
                    <Link
                        className={additionalContentLinkClasses}
                        onClick={onClickHandler}
                    >
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.link'}
                        />
                    </Link>
                    {showAdditionalContent && (
                        <>
                            <p>
                                <FormattedMessage
                                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.description.two'}
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.description.three'}
                                />
                            </p>
                            <ul>
                                <li>
                                    <FormattedMessage
                                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.item.one'}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.item.two'}
                                    />
                                </li>
                            </ul>
                            <p>
                                <FormattedMessage
                                    id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.description.four'}
                                />
                            </p>
                            <ul>
                                <li>
                                    <FormattedMessage
                                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.item.three'}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.item.four'}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.item.five'}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id={'reports.trait.actionPlan.actionCard.discussRiskFactors.CVD_US.guidelines.item.six'}
                                    />
                                </li>
                            </ul>
                        </>
                    )}
                </SupplementalBox>
            )}
        </ActionCard>
    );
}
