import {DateTime} from 'luxon';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    Outlet,
    useNavigate,
} from 'react-router-dom';
import {useName} from '../../../../hooks';
import {Routes} from '../../../../Routes';

const KIT_ACTIVATION_KEY = 'KitActivation';

const setKitActivationCode = (kitCode) => {
    sessionStorage.setItem(KIT_ACTIVATION_KEY, JSON.stringify({kitCode}));
};

const clearKitActivationCode = () => {
    sessionStorage.removeItem(KIT_ACTIVATION_KEY);
};

const correctlyFormattedDateValueForOneDayAgo = () => {
    // We want the date for one day ago
    const {
        day,
        month,
        year,
    } = DateTime.now().minus({days: 1}).toObject();

    // Ensure that the day and month are correctly formatted with a leading 0 if required.
    return {
        day: `${day}`.padStart(2, '0'),
        month: `${month}`.padStart(2, '0'),
        year: `${year}`,
    };
};

const getOtherDate = () => {
    const details = sessionStorage.getItem(KIT_ACTIVATION_KEY);
    const {otherDate} = JSON.parse(details ?? '{}');

    // If there's no otherDate, then default to one day ago.
    return otherDate ?? correctlyFormattedDateValueForOneDayAgo();
};

const setKitActivationOtherDate = (otherDate) => {
    const details = JSON.parse(sessionStorage.getItem(KIT_ACTIVATION_KEY) ?? '{}');

    if (otherDate) {
        sessionStorage.setItem(
            KIT_ACTIVATION_KEY,
            JSON.stringify({
                ...details,
                otherDate,
            }),
        );
    }
    else {
        Reflect.deleteProperty(details, 'otherDate');
        sessionStorage.setItem(KIT_ACTIVATION_KEY, JSON.stringify(details));
    }
};

const getSampleCollected = () => {
    const details = sessionStorage.getItem(KIT_ACTIVATION_KEY);
    const {sampleCollected = ''} = JSON.parse(details ?? '{}');

    return sampleCollected;
};

const setKitActivationSampleCollected = (sampleCollected) => {
    const details = JSON.parse(sessionStorage.getItem(KIT_ACTIVATION_KEY) ?? '{}');
    sessionStorage.setItem(
        KIT_ACTIVATION_KEY,
        JSON.stringify({
            ...details,
            sampleCollected,
        }),
    );
};

const Context = createContext({});
export const useKitActivationContext = () => useContext(Context);

export const getKitActivationCode = () => {
    const details = sessionStorage.getItem(KIT_ACTIVATION_KEY);
    const {kitCode = ''} = JSON.parse(details ?? '{}');

    return kitCode;
};

// Ensures that a kit code is present, otherwise takes the user to the kit code entry page...
export const useKitCode = () => {
    const navigate = useNavigate();

    const kitCode = getKitActivationCode();

    useEffect(() => {
        if (!kitCode) {
            navigate(Routes.KIT_ACTIVATION_ENTER_THE_CODE);
        }
    }, [kitCode]);

    return {kitCode};
};

export function KitActivationContext() {
    const {name} = useName();
    const [kitCode, setKitCode] = useState(() => getKitActivationCode());
    const [otherDate, setOtherDate] = useState(() => getOtherDate());
    const [sampleCollected, setSampleCollected] = useState(() => getSampleCollected());

    const clearKitCodeInterceptor = useCallback(() => {
        // Clear tye code from local state, then from session storage...
        setKitCode('');
        clearKitActivationCode();
    }, []);

    const setKitCodeInterceptor = useCallback((value) => {
        // Persist the value into local state...
        setKitCode(value);

        // Then also set it into sessionStorage, so we can pick it up if the user refreshes the page...
        setKitActivationCode(value);
    }, []);

    const setOtherDateInterceptor = useCallback((value) => {
        // Persist the value into local state...
        setOtherDate(
            value ?? {
                day: '',
                month: '',
                year: '',
            },
        );

        // Then also set it into sessionStorage, so we can pick it up if the user refreshes the page...
        setKitActivationOtherDate(value);
    }, []);

    const setSampleCollectedInterceptor = useCallback((value) => {
        // Persist the value into local state...
        setSampleCollected(value);

        // Then also set it into sessionStorage, so we can pick it up if the user refreshes the page...
        setKitActivationSampleCollected(value);
    }, []);

    const context = {
        clearKitCode: clearKitCodeInterceptor,
        kitCode: kitCode,
        name: name,
        otherDate: otherDate,
        sampleCollected: sampleCollected,
        setKitCode: setKitCodeInterceptor,
        setOtherDate: setOtherDateInterceptor,
        setSampleCollected: setSampleCollectedInterceptor,
    };

    return (
        <Context.Provider value={context}>
            <Outlet />
        </Context.Provider>
    );
}
