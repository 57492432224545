import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {LinkWithAttribution} from '../../../../components';

export function CardiovascularDisease() {
    const intl = useIntl();

    return (
        <>
            <div className={'heading is-caption is-heavy'}>
                <FormattedMessage id={'reports.trait.learnMoreAbout.common.heading'} />
            </div>
            <h4>
                <FormattedMessage id={'common.trait.CVD_US.title'} />
            </h4>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.CVD_US.paragraph.one'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.CVD_US.paragraph.two'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.CVD_US.paragraph.three'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.CVD_US.paragraph.four'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.CVD_US.paragraph.five'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.CVD_US.paragraph.six'} />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.AHA'})}
                url={'https://www.heart.org/en/health-topics/consumer-healthcare/what-is-cardiovascular-disease'}
            >
                <FormattedMessage id={'reports.trait.learnMoreAbout.CVD_US.link.one'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.AHA'})}
                url={'https://www.heart.org/en/healthy-living/healthy-lifestyle/lifes-essential-8'}
            >
                <FormattedMessage id={'reports.trait.learnMoreAbout.CVD_US.link.two'} />
            </LinkWithAttribution>
        </>
    );
}
