import deepEqual from 'deep-equal';
import {useState} from 'react';
import {
    Outlet,
    useLocation,
    useOutletContext,
} from 'react-router-dom';
import {Routes as InternalRoutes} from '../../../../Routes';
import {
    Ethnicities,
    TRANSIENT_PERSIST_FIELDS_KEY,
} from '../../../../util';

export function EthnicityOptionalQuestionInterceptor(props) {
    const {
        routes: {
            ethnicity: ethnicityRoute,
            pntsEthnicity: pntsEthnicityRoute,
            identifiedEthnicity: identifiedEthnicityRoute,
        },
    } = props ?? {
        routes: {
            ethnicity: InternalRoutes.SIGN_UP_EDIT_ETHNICITY,
            pntsEthnicity: InternalRoutes.SIGN_UP_EDIT_PNTS_ETHNICITY,
            identifiedEthnicity: InternalRoutes.SIGN_UP_EDIT_IDENTIFIED_ETHNICITY,
        },
    };

    const {pathname} = useLocation();
    const {
        currentUserProfile,
        errorResponse,
        setNavigateToOptionalQuestion,
        setPersistFields,
    } = useOutletContext();
    const [currentSelection, setCurrentSelection] = useState(
        JSON.parse(sessionStorage.getItem(TRANSIENT_PERSIST_FIELDS_KEY) ?? '{}'),
    );

    /* Due to the way we currently cope with blatting error responses by changing the key attribute higher up the
     * component hierarchy, this gets called when it shouldn't. I.e. it gets called when transitioning between the two
     * ethnicity questions, which means the identified ethnicity options don't reflect any changes that have been made.
     * There shouldn't be any issues with commenting it out, but if we ever fix the error response issue properly,
     * we should probably uncomment this again.
     */
    /* useEffect(
        () => () => {
            // Remove any set value when this is unmounted...
            sessionStorage.removeItem(TRANSIENT_PERSIST_FIELDS_KEY);
        },
        []
    ); */

    const persistFieldsInterceptor = (persistFields) => {
        // We're pretending to get a setState function, so we're expecting either a function that when called, will
        // return another function. Or, we're going to get null, which will happens when a question is unmounted.
        if (persistFields) {
            // We should now have a double function call to get that fields that require persisting...
            const fields = persistFields()();

            // We need to take care, as we null the persistFields function when the question is unmounted...
            if (fields) {
                // We need to do different thing depending on if we're on the main ethnicity question, or the optional
                // identified question.
                if (pathname === ethnicityRoute) {
                    // Get the actual stored values from the profile...
                    const {ethnicities} = currentUserProfile;

                    // If the fields that would've been persisted are the same, then do nothing, otherwise blank the
                    // value of ethnicityIdentifyMost, as the end user will need to be shown different options.
                    const updatedFields = {...fields};
                    if (!deepEqual(ethnicities, fields.ethnicities)) {
                        updatedFields.ethnicityIdentifyMost = null;
                    }

                    // As we want the original question and the optional question persisted at the same time, let's
                    // store their current answer in session storage, so we can pick it up in the optional question.
                    // We can also pick it uip after a hard refresh, too, which the is useful. We do this here, so the
                    // value in the sessionStorage always reflects the current answer, even if they only pick one option
                    // which means this isn't actually required.
                    sessionStorage.setItem(
                        TRANSIENT_PERSIST_FIELDS_KEY,
                        JSON.stringify(updatedFields),
                    );

                    // Update some local state with the same value we've put into the session scope, this makes it
                    // easier to overlay the new values, over the existing profile, in the outlet below.
                    setCurrentSelection(updatedFields);

                    if (updatedFields.ethnicities.length >= 2) {
                        // They have selected multiple answers, which means that they will have to visit the optional
                        // question. So `null` the fields to be persisted, which means that when the form is submitted,
                        // it'll just go to the next question, without trying to save anything, which is just the
                        // behaviour that we're after.
                        setPersistFields(null);
                        setNavigateToOptionalQuestion(identifiedEthnicityRoute);
                    }
                    else if (updatedFields.ethnicities[0] === Ethnicities.US_PNTS) {
                        // They have selected the prefer not to say options, which means that they will have to visit
                        // the optional question. So `null` the fields to be persisted etc, etc.
                        setPersistFields(null);
                        setNavigateToOptionalQuestion(pntsEthnicityRoute);
                    }
                    else {
                        // They have either selected one option, or unselected all options, either way, just pass the
                        // object along, as we don't want to do anything here...
                        setPersistFields(() => () => fields);
                        setNavigateToOptionalQuestion(null);
                    }
                }
                else {
                    // We should now be on one of two the optional questions, so we want to add the previous answer to
                    // this answer and have both persisted at the same time.
                    setPersistFields(() => () => ({
                        ...JSON.parse(sessionStorage.getItem(TRANSIENT_PERSIST_FIELDS_KEY)),
                        ...fields,
                    }));
                    setNavigateToOptionalQuestion(null);
                }
            }
        }
        else {
            // Just pass along the null...
            setPersistFields(null);
            setNavigateToOptionalQuestion(null);
        }
    };

    return (
        <Outlet
            context={{
                currentUserProfile: {
                    ...currentUserProfile,
                    ...currentSelection,
                },
                errorResponse: errorResponse,
                setPersistFields: persistFieldsInterceptor,
            }}
        />
    );
}
