import classNames from 'classnames';
import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {useQuery} from '../../../hooks';
import {Endpoint} from '../../../util';
import {
    AboutUsLink,
    AccountHomeLink,
    ActivateKitLink,
    CalculateRiskLink,
    FooterMarketingLinks,
    HelpCenterLink,
    TrustAndPrivacyLink,
} from '../common';

export function FooterContent(props) {
    const {
        className,
        dataTestId,
        marketingRoutes = [],
    } = props;

    const {pathname} = useLocation();
    const {data, loading} = useQuery(Endpoint.SUMMARY);

    const displayMarketingLinks = useMemo(() => marketingRoutes.includes(pathname), [pathname]);

    const hasReport = useMemo(() => {
        const {resultStatus = 'NONE'} = data ?? {};
        return resultStatus === 'READY';
    }, [data]);

    if (loading) {
        return null;
    }

    const containerClasses = classNames('flex-column', 'list-of-links', className);
    const linkClasses = classNames('is-heavy', 'is-caption');

    return (
        <>
            <div
                className={containerClasses}
                data-test-id={dataTestId}
            >
                {displayMarketingLinks ? <FooterMarketingLinks /> : (
                    <>
                        <AccountHomeLink
                            className={linkClasses}
                            dataTestId={'footer-account-home-link'}
                        />
                        {hasReport && (
                            <CalculateRiskLink
                                className={linkClasses}
                                dataTestId={'footer-calculate-risk-link'}
                            />
                        )}
                    </>
                )}
            </div>
            <div className={'flex-column list-of-links'}>
                {!displayMarketingLinks && (
                    <TrustAndPrivacyLink
                        className={linkClasses}
                        dataTestId={'footer-trust-and-privacy-link'}
                    />
                )}
                {displayMarketingLinks && (
                    <>
                        <AboutUsLink
                            className={linkClasses}
                            dataTestId={'footer-about-us-link'}
                        />
                        <ActivateKitLink
                            className={linkClasses}
                            dataTestId={'footer-activate-kit-link'}
                        />
                    </>
                )}
                <HelpCenterLink
                    className={linkClasses}
                    dataTestId={'footer-help-center-link'}
                />
            </div>
        </>
    );
}
