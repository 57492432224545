import {FormattedMessage} from 'react-intl';
import {Checkmark} from '../../components';

export function ControlDataContent() {
    return (
        <ul className={'no-decoration'}>
            <li className={'with-checkmark is-heavy'}>
                <Checkmark />
                <FormattedMessage id={'trust-and-privacy.control-data.one'} />
            </li>
            <li className={'with-checkmark is-heavy'}>
                <Checkmark />
                <FormattedMessage id={'trust-and-privacy.control-data.two'} />
            </li>
            <li className={'with-checkmark is-heavy'}>
                <Checkmark />
                <FormattedMessage id={'trust-and-privacy.control-data.three'} />
            </li>
        </ul>
    );
}
