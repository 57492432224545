import {Navigate} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../components';
import {useQuery} from '../../hooks';
import {Routes} from '../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../util';

export function RequireTestReorderInvite(props) {
    const {children} = props;

    const {
        data: {
            physicianReviewStatus,
            registration: {confirmed = false} = {},
        } = {},
        error,
        loading,
    } = useQuery(Endpoint.SUMMARY);

    if (loading) {
        return (
            <div className={'guard'}>
                <Loading key={'loading'} />
            </div>
        );
    }

    if (error) {
        return (
            <div className={'guard'}>
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    if (confirmed && physicianReviewStatus === 'TEST_ORDER_EXPIRED') {
        return children;
    }

    // If they haven't been invited to reorder their expired test, so just send them to the /home route
    return (
        <Navigate
            replace={true}
            to={Routes.HOME}
        />
    );
}
