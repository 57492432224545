import {MoreInfo} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function ScientistsContent() {
    const intl = useIntl();

    return (
        <>
            <div className={'flex-column-to-row'}>
                <img
                    alt={intl.formatMessage({id: 'aboutUs.scientists.rachel.alt'})}
                    src={'/images/headshot-rachel-moore_800x800_v1_32KB.webp'}
                />
                <div>
                    <p>
                        <FormattedMessage
                            id={'aboutUs.scientists.rachel'}
                            values={{bold: (chunk) => <span className={'is-heavy'}>{chunk}</span>}}
                        />
                    </p>
                    <MoreInfo
                        content={intl.formatMessage({id: 'aboutUs.scientists.rachel.more.content'})}
                        dataTestId={'scientist-rachel-more-info'}
                        title={intl.formatMessage({id: 'aboutUs.scientists.rachel.more.title'})}
                    />
                </div>
            </div>
            <div className={'flex-column-to-row'}>
                <img
                    alt={intl.formatMessage({id: 'aboutUs.scientists.seamus.alt'})}
                    src={'/images/headshot-seamus-harrison_800x800_v1_39KB.webp'}
                />
                <div>
                    <p>
                        <FormattedMessage
                            id={'aboutUs.scientists.seamus'}
                            values={{bold: (chunk) => <span className={'is-heavy'}>{chunk}</span>}}
                        />
                    </p>
                    <MoreInfo
                        content={intl.formatMessage({id: 'aboutUs.scientists.seamus.more.content'})}
                        dataTestId={'scientist-seamus-more-info'}
                        title={intl.formatMessage({id: 'aboutUs.scientists.seamus.more.title'})}
                    />
                </div>
            </div>
            <div className={'flex-column-to-row'}>
                <img
                    alt={intl.formatMessage({id: 'aboutUs.scientists.vincent.alt'})}
                    src={'/images/headshot-vincent-plagnol_800x800_v1_36KB.webp'}
                />
                <div>
                    <p>
                        <FormattedMessage
                            id={'aboutUs.scientists.vincent'}
                            values={{bold: (chunk) => <span className={'is-heavy'}>{chunk}</span>}}
                        />
                    </p>
                    <MoreInfo
                        content={intl.formatMessage({id: 'aboutUs.scientists.vincent.more.content'})}
                        dataTestId={'scientist-vincent-more-info'}
                        title={intl.formatMessage({id: 'aboutUs.scientists.vincent.more.title'})}
                    />
                </div>
            </div>
        </>
    );
}
