import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {LinkWithAttribution} from '../../../../components';

export function BreastCancer() {
    const intl = useIntl();

    return (
        <>
            <div className={'heading is-caption is-heavy'}>
                <FormattedMessage id={'reports.trait.learnMoreAbout.common.heading'} />
            </div>
            <h4>
                <FormattedMessage id={'common.trait.BC.title'} />
            </h4>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.BC.paragraph.one'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.BC.paragraph.two'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.BC.paragraph.three'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.BC.paragraph.four'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.BC.paragraph.five'} />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.CDCP'})}
                url={'https://www.cdc.gov/cancer/breast/basic_info/what-is-breast-cancer.htm'}
            >
                <FormattedMessage id={'reports.trait.learnMoreAbout.BC.link.one'} />
            </LinkWithAttribution>
        </>
    );
}
