import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link} from 'react-router-dom';
import {SixFiveGridContainer} from '../../components';
import {Routes} from '../../Routes';

export function PeopleContent() {
    const intl = useIntl();

    return (
        <SixFiveGridContainer className={'about-us'}>
            <div className={'content five'}>
                <h4>
                    <FormattedMessage id={'landing.people.title'} />
                </h4>
                <p>
                    <FormattedMessage id={'landing.people.content'} />
                </p>
                <Link
                    className={'link button'}
                    data-test-id={'about-us-button'}
                    title={intl.formatMessage({id: 'landing.people.button.title'})}
                    to={Routes.ABOUT_US}
                >
                    <FormattedMessage id={'landing.people.button.text'} />
                </Link>
            </div>
            <div className={'people six'}>
                <div className={'person one'}>
                    <img
                        alt={intl.formatMessage({id: 'landing.people.seamus.alt'})}
                        src={'/images/headshot-seamus-harrison_800x800_v1_39KB.webp'}
                    />
                    <div>
                        <p className={'is-caption is-heavy'}>
                            <FormattedMessage id={'landing.people.seamus.title'} />
                        </p>
                        <p className={'is-caption is-second is-heavy is-light'}>
                            <FormattedMessage id={'landing.people.seamus.role'} />
                        </p>
                    </div>
                </div>
                <div className={'person two'}>
                    <img
                        alt={intl.formatMessage({id: 'landing.people.peter.alt'})}
                        src={'/images/headshot-peter-donnelly_800x800_v1_99KB.webp'}
                    />
                    <div>
                        <p className={'is-caption is-heavy'}>
                            <FormattedMessage id={'landing.people.peter.title'} />
                        </p>
                        <p className={'is-caption is-heavy is-second is-light'}>
                            <FormattedMessage id={'landing.people.peter.role'} />
                        </p>
                    </div>
                </div>
                <div className={'person three'}>
                    <img
                        alt={intl.formatMessage({id: 'landing.people.rachel.alt'})}
                        src={'/images/headshot-rachel-moore_800x800_v1_32KB.webp'}
                    />
                    <div>
                        <p className={'is-caption is-heavy'}>
                            <FormattedMessage id={'landing.people.rachel.title'} />
                        </p>
                        <p className={'is-caption is-second is-heavy is-light'}>
                            <FormattedMessage id={'landing.people.rachel.role'} />
                        </p>
                    </div>
                </div>
            </div>
        </SixFiveGridContainer>
    );
}
