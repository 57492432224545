import {Variant} from '@genomicsplc/denim-components';
import {useMemo} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {ExplainerStat} from './ExplainerStat';

export function UnderstandYourResult(props) {
    const {
        integratedRiskScore,
        riskLevel,
        riskWindow,
    } = props;

    const intl = useIntl();

    const willDevelopCount = useMemo(
        () => Math.round(integratedRiskScore * 100),
        [integratedRiskScore],
    );

    const willNotDevelopCount = useMemo(() => 100 - willDevelopCount, [willDevelopCount]);

    return (
        <>
            <div className={'information'}>
                <h4 className={'no-margin'}>
                    <FormattedMessage id={'reports.trait.likelihood.understanding.lead'} />
                </h4>
                <p data-test-id={`understanding-filler-${riskWindow}`}>
                    <FormattedMessage
                        id={`reports.trait.likelihood.understanding.filler.${riskWindow}`}
                        values={{
                            irs: integratedRiskScore,
                            roundedIrs: willDevelopCount,
                        }}
                    />
                </p>
            </div>
            <div className={'stats'}>
                <ExplainerStat
                    dataTestId={`explainer-stat-will-${riskLevel}`}
                    riskLevel={riskLevel}
                    stat={willDevelopCount}
                    text={intl.formatMessage({id: 'reports.trait.likelihood.understanding.stat.will'})}
                />
                <ExplainerStat
                    dataTestId={`explainer-stat-wont-${riskLevel}`}
                    riskLevel={riskLevel}
                    stat={willNotDevelopCount}
                    text={intl.formatMessage({id: 'reports.trait.likelihood.understanding.stat.wont'})}
                    variant={Variant.LIGHT}
                />
            </div>
        </>
    );
}
