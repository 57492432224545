import classNames from 'classnames';
import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {Logotype} from '../../../components';
import {
    AccountButton,
    NavbarMarketingLinks,
} from '../common';

export function DesktopNavbarContent(props) {
    const {
        className,
        dataTestId,
        homeLink = false,
        marketingRoutes = [],
    } = props;

    const {pathname} = useLocation();

    const {displayPoweredByLogotype, displayMarketingLinks} = useMemo(
        () => ({
            displayMarketingLinks: marketingRoutes.includes(pathname),
            displayPoweredByLogotype: marketingRoutes.includes(pathname),
        }),
        [pathname],
    );

    const menuClasses = classNames('main-menu', 'inline', 'no-decoration', 'no-margin', className);

    return (
        <>
            <Logotype
                dataTestId={'health-insights-logotype-navigation'}
                homeLink={homeLink}
                poweredByLogotype={displayPoweredByLogotype}
            />
            <ul
                className={menuClasses}
                data-test-id={dataTestId}
            >
                {displayMarketingLinks && <NavbarMarketingLinks compact={true} />}
                <li>
                    <AccountButton />
                </li>
            </ul>
        </>
    );
}
