import {MoreInfo} from '@genomicsplc/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function OtherFactorsContent() {
    const intl = useIntl();

    return (
        <>
            <p>
                <FormattedMessage id={'science.calculateRisk.otherFactors.contentOne'} />
            </p>
            <p>
                <FormattedMessage id={'science.calculateRisk.otherFactors.contentTwo'} />
            </p>
            <p>
                <FormattedMessage id={'science.calculateRisk.otherFactors.contentThree'} />
            </p>
            <MoreInfo
                content={
                    <>
                        <p>
                            <FormattedMessage
                                id={'science.calculateRisk.otherFactors.ethnicity.contentOne'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={'science.calculateRisk.otherFactors.ethnicity.contentTwo'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={'science.calculateRisk.otherFactors.ethnicity.contentThree'}
                            />
                        </p>
                    </>
                }
                dataTestId={'more-info-sex-or-race'}
                title={intl.formatMessage({id: 'science.calculateRisk.otherFactors.ethnicity.title'})}
            />
        </>
    );
}
