import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCard,
    LinkWithAttribution,
    SupplementalBox,
} from '../../../../../../components';
import {
    RiskLevel,
    Trait,
} from '../../../../../../util';

export function HighLDLCholesterolScreenOptions(props) {
    const {
        currentStep,
        riskLevel,
        totalSteps,
    } = props;

    const intl = useIntl();

    return (
        <ActionCard
            currentStep={currentStep}
            dataTestId={`screen-options-HCH-${riskLevel}`}
            title={intl.formatMessage({id: `reports.trait.actionPlan.actionCard.discussScreeningOptions.title`})}
            totalSteps={totalSteps}
        >
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.cholesterol.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.cholesterol.description.alternative.one'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.cholesterol.description.alternative.two'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/cholesterol/cholesterol_screening.htm'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.cholesterol.link'}
                />
            </LinkWithAttribution>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.otherTests.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.otherTests.HCH.description'}
                />
            </p>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressureCheck.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressure.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.AHA`})}
                url={'https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.bloodPressure.link'}
                />
            </LinkWithAttribution>
            <p className={'heading is-heavy'}>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.diabetes.heading'}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.diabetes.description'}
                />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: `common.link.attribution.CDCP`})}
                url={'https://www.cdc.gov/diabetes/basics/getting-tested.html'}
            >
                <FormattedMessage
                    id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.diabetes.link'}
                />
            </LinkWithAttribution>
            {riskLevel === RiskLevel.HIGHER && (
                <SupplementalBox
                    title={intl.formatMessage({
                        id: 'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.heading',
                    })}
                >
                    <p>
                        <FormattedMessage
                            id={'reports.trait.actionPlan.actionCard.discussScreeningOptions.medication.noneRecommended'}
                            values={{trait: intl.formatMessage({id: `common.trait.${Trait.HCH.code}.text`})}}
                        />
                    </p>
                </SupplementalBox>
            )}
        </ActionCard>
    );
}
