import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link} from 'react-router-dom';
import {
    ActivateKitLink,
    Checkmark,
    SixFiveGridContainer,
} from '../../components';
import {Routes} from '../../Routes';

export function SalivaTestContent() {
    const intl = useIntl();

    return (
        <SixFiveGridContainer className={'saliva-test variant-dark'}>
            <div className={'five'}>
                <h2>
                    <FormattedMessage id={'landing.salivaTest.title'} />
                </h2>
                <ul>
                    <li className={'is-heavy multiline'}>
                        <Checkmark />
                        <div>
                            <FormattedMessage id={'landing.salivaTest.pointOne'} />
                            <br />
                            <span className={'is-caption is-light'}>
                                <FormattedMessage id={'landing.salivaTest.pointOne.extra'} />
                            </span>
                        </div>
                    </li>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'landing.salivaTest.pointTwo'} />
                    </li>
                    <li className={'is-heavy'}>
                        <Checkmark />
                        <FormattedMessage id={'landing.salivaTest.pointThree'} />
                    </li>
                </ul>
                <Link
                    className={'link button'}
                    data-test-id={'see-how-the-test-works-button'}
                    title={intl.formatMessage({id: 'landing.salivaTest.button.title'})}
                    to={Routes.SEE_HOW_IT_WORKS}
                >
                    <FormattedMessage id={'landing.salivaTest.button.text'} />
                </Link>
                <p className={'is-caption'}>
                    <ActivateKitLink
                        dataTestId={'saliva-test-activate-link'}
                        location={'landing-saliva-test'}
                    />
                </p>
            </div>
            <img
                alt={intl.formatMessage({id: 'landing.image.testKit.alt'})}
                className={'six image'}
                src={'/images/test-kit-box-w-tube_1040x1200_v1_30KB.webp'}
            />
        </SixFiveGridContainer>
    );
}
