import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {LinkWithAttribution} from '../../../../components';

export function AtrialFibrillation() {
    const intl = useIntl();

    return (
        <>
            <div className={'heading is-caption is-heavy'}>
                <FormattedMessage id={'reports.trait.learnMoreAbout.common.heading'} />
            </div>
            <h4>
                <FormattedMessage id={'common.trait.AF.title'} />
            </h4>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.AF.paragraph.one'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.AF.paragraph.two'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.AF.paragraph.three'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.AF.paragraph.four'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.AF.paragraph.five'} />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.AHA'})}
                url={'https://www.heart.org/en/health-topics/atrial-fibrillation/what-is-atrial-fibrillation-afib-or-af'}
            >
                <FormattedMessage id={'reports.trait.learnMoreAbout.AF.link.one'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.AHA'})}
                url={'https://www.heart.org/en/healthy-living/healthy-lifestyle/lifes-essential-8'}
            >
                <FormattedMessage id={'reports.trait.learnMoreAbout.AF.link.two'} />
            </LinkWithAttribution>
        </>
    );
}
