import {Navigate} from 'react-router-dom';
import {Routes} from '../../Routes';

export function ResultsOverviewRedirect() {
    return (
        <Navigate
            replace={true}
            to={Routes.HOME}
        />
    );
}
