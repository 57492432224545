import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {LinkWithAttribution} from '../../../../components';

export function Osteopenia() {
    const intl = useIntl();

    return (
        <>
            <div className={'heading is-caption is-heavy'}>
                <FormattedMessage id={'reports.trait.learnMoreAbout.common.heading'} />
            </div>
            <h4>
                <FormattedMessage id={'common.trait.FNDXAT_N1P0.title'} />
            </h4>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.FNDXAT_N1P0.paragraph.one'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.FNDXAT_N1P0.paragraph.two'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.FNDXAT_N1P0.paragraph.three'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.learnMoreAbout.FNDXAT_N1P0.paragraph.four'} />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.AAFP'})}
                url={'https://familydoctor.org/condition/osteopenia/'}
            >
                <FormattedMessage id={'reports.trait.learnMoreAbout.FNDXAT_N1P0.link.one'} />
            </LinkWithAttribution>
        </>
    );
}
