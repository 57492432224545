import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCardImage,
    GraphicalActionCard,
    LinkWithAttribution,
} from '../../../../../../components';

export function HighLDLCholesterolDiet(props) {
    const {currentStep, totalSteps} = props;

    const intl = useIntl();

    return (
        <GraphicalActionCard
            currentStep={currentStep}
            dataTestId={'diet-HCH'}
            headerImage={ActionCardImage.DIET}
            title={intl.formatMessage({id: 'reports.trait.lifestyleActions.diet.title'})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.HCH.one'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.HCH.two'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.HCH.three'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.one'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.one'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.two'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.three'} />
                </li>
            </ul>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.two'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.four'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.five'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.six'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.seven'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.eight'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.nine'} />
                </li>
            </ul>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.description.three'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.diet.item.ten'} />
                </li>
            </ul>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.AHA'})}
                url={'https://www.heart.org/en/health-topics/cholesterol/prevention-and-treatment-of-high-cholesterol-hyperlipidemia/cooking-to-lower-cholesterol'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.link.cooking'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.AHA'})}
                url={'https://www.heart.org/en/health-topics/cholesterol/prevention-and-treatment-of-high-cholesterol-hyperlipidemia/the-skinny-on-fats'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.diet.link.fats'} />
            </LinkWithAttribution>
        </GraphicalActionCard>
    );
}
