import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ActionCardImage,
    GraphicalActionCard,
    LinkWithAttribution,
} from '../../../../../../components';

export function Type2DiabetesExercise(props) {
    const {currentStep, totalSteps} = props;

    const intl = useIntl();

    return (
        <GraphicalActionCard
            currentStep={currentStep}
            dataTestId={'exercise-T2D'}
            headerImage={ActionCardImage.EXERCISE}
            title={intl.formatMessage({id: 'reports.trait.lifestyleActions.exercise.title'})}
            totalSteps={totalSteps}
        >
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.exercise.description.one.T2D'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.exercise.description.two'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.exercise.description.three'} />
            </p>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.exercise.description.four'} />
            </p>
            <ul>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.exercise.item.one'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.exercise.item.two'} />
                </li>
                <li>
                    <FormattedMessage id={'reports.trait.lifestyleActions.exercise.item.three'} />
                </li>
            </ul>
            <p>
                <FormattedMessage id={'reports.trait.lifestyleActions.exercise.description.five'} />
            </p>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.CDCP'})}
                url={'https://www.cdc.gov/healthyweight/physical_activity/getting_started.html'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.exercise.link.start'} />
            </LinkWithAttribution>
            <LinkWithAttribution
                attribution={intl.formatMessage({id: 'common.link.attribution.CDCP'})}
                url={'https://www.cdc.gov/physicalactivity/basics/adding-pa/index.htm'}
            >
                <FormattedMessage id={'reports.trait.lifestyleActions.exercise.link.add'} />
            </LinkWithAttribution>
        </GraphicalActionCard>
    );
}
