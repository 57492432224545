import {
    Card,
    Variant,
} from '@genomicsplc/denim-components';
import {useMemo} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link} from 'react-router-dom';
import {Routes} from '../../../Routes';
import {Trait} from '../../../util';

export function KeepInMind(props) {
    const {
        traitCode,
        riskLevel,
        riskWindow,
    } = props;

    const intl = useIntl();

    const baselineFactorsMessageId = traitCode === Trait.PC.code ? 'PC' : 'other';
    const previousDiagnosesMessageID = [Trait.BC.code, Trait.PC.code].includes(traitCode)
        ? 'cancer'
        : Trait.CVD_US.code === traitCode
        ? 'CVD_US'
        : 'other';

    const messageIds = useMemo(() => {
        const keys = [];

        keys.push(`reports.trait.keepInMind.item.resultFactors.${riskWindow}`);

        if ([Trait.BC.code, Trait.FNDXAT_N1P0.code, Trait.PC.code].includes(traitCode)) {
            keys.push(`reports.trait.keepInMind.item.otherFactors.${traitCode}`);
        }
        else {
            keys.push(`reports.trait.keepInMind.item.otherFactors.other`);
        }

        keys.push(`reports.trait.keepInMind.item.previousDiagnoses.${previousDiagnosesMessageID}`);

        if (riskLevel !== 'HIGHER') {
            keys.push(`reports.trait.keepInMind.item.commonCondition.${traitCode}`);
        }

        if (Trait.HCH.code === traitCode) {
            keys.push('reports.trait.keepInMind.item.HCH.goodBadCholesterol');
        }

        if ([Trait.BC.code, Trait.HCH.code].includes(traitCode)) {
            keys.push(`reports.trait.keepInMind.item.rareVariants.${traitCode}`);
        }
        else {
            keys.push('reports.trait.keepInMind.item.rareVariants.other');
        }

        if ([Trait.HCH.code, Trait.HT.code, Trait.FNDXAT_N1P0.code].includes(traitCode)) {
            keys.push(`reports.trait.keepInMind.item.diagnosis.${traitCode}`);
        }
        else {
            keys.push('reports.trait.keepInMind.item.diagnosis.other');
        }

        return keys;
    }, [traitCode]);

    return (
        <Card
            className={'keep-in-mind'}
            dataTestId={`keep-in-mind-${traitCode}-${riskLevel}`}
            variant={Variant.LIGHT}
        >
            <h4 className={'more-margin'}>
                <FormattedMessage id={'reports.trait.keepInMind.lead'} />
            </h4>
            <ul>
                {messageIds.map((messageId) => (
                    <li key={messageId}>
                        <FormattedMessage
                            id={messageId}
                            values={{
                                baselineFactors: intl.formatMessage({
                                    id: `reports.trait.likelihood.baselineFactors.${baselineFactorsMessageId}`,
                                }),
                                italic: (chunks) => <em>{chunks}</em>,
                                trait: intl.formatMessage({id: `common.trait.${traitCode}.text`}),
                            }}
                        />
                    </li>
                ))}
            </ul>
            <Link
                className={'link'}
                to={Routes.REPORTS_CALCULATE_RISK}
            >
                <span>
                    <FormattedMessage id={'reports.trait.keepInMind.link'} />
                </span>
            </Link>
        </Card>
    );
}
