import {
    DATE_INCOMPLETE,
    DATE_INVALID,
    DATE_VALID,
    DateInput,
    FormGroup,
    MoreInfo,
    useDateValidation,
} from '@genomicsplc/denim-components';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    useLocation,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import {FetchValidationError} from '../../../fetch-validation-error';
import {DateNotEligible} from './DateNotEligible';

const DATE_UNPROCESSED = -2;

export function DateOfBirth() {
    const {isDateValid, parseDate} = useDateValidation();
    const intl = useIntl();
    const {pathname, state} = useLocation();
    const {
        currentUserProfile: {dateOfBirth} = {},
        errorResponse,
        setPersistFields,
    } = useOutletContext();
    const navigate = useNavigate();
    const dayRef = useRef();
    const monthRef = useRef();
    const yearRef = useRef();
    const [wholeDateValid, setWholeDateValid] = useState(DATE_UNPROCESSED);

    useEffect(() => {
        setPersistFields(() => () => {
            const year = yearRef.current?.value;
            const month = monthRef.current?.value;
            const day = dayRef.current?.value;

            const result = isDateValid({
                day,
                month,
                year,
            });

            setWholeDateValid(result);

            return result === DATE_VALID ? {dateOfBirth: `${year}-${month}-${day}`} : null;
        });

        return () => {
            setPersistFields(null);
        };
    }, []);

    const parsedDateOfBirth = useMemo(() => parseDate(dateOfBirth), [dateOfBirth]);

    useEffect(() => {
        const result = isDateValid(parsedDateOfBirth);
        if (result !== DATE_INCOMPLETE) {
            setWholeDateValid(result);
        }
    }, [parsedDateOfBirth]);

    const errorMessageToDisplay = useMemo(
        () => (wholeDateValid === DATE_INCOMPLETE
            ? intl.formatMessage({id: 'signUp.dateOfBirth.dateIncomplete'})
            : wholeDateValid === DATE_INVALID
            ? intl.formatMessage({id: 'signUp.dateOfBirth.dateInvalid'})
            : null),
        [wholeDateValid],
    );

    const rerunValidityCheckIfNecessary = useCallback(() => {
        if (wholeDateValid !== DATE_VALID) {
            const year = yearRef.current?.value;
            const month = monthRef.current?.value;
            const day = dayRef.current?.value;

            const result = isDateValid({
                day,
                month,
                year,
            });

            if (result !== DATE_INCOMPLETE) {
                setWholeDateValid(result);
            }
        }
    }, [wholeDateValid]);

    useEffect(() => {
        if (errorResponse?.errorData?.errors?.[0] === 'DOB_OUTSIDE_RANGE') {
            navigate(pathname, {state: {userIneligible: true}});
        }
    }, [errorResponse]);

    const displayIneligiblePage = useMemo(() => state?.userIneligible ?? false, [state]);
    if (displayIneligiblePage) {
        return <DateNotEligible />;
    }

    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-date-of-birth-title'}
            >
                <FormattedMessage id={'signUp.dateOfBirth.title'} />
            </h2>
            <MoreInfo
                content={intl.formatMessage({id: 'signUp.dateOfBirth.moreInfo'})}
                title={intl.formatMessage({id: 'common.whyWeAskThisQuestion'})}
            />
            <FetchValidationError errorResponse={dayRef.current?.value ? errorResponse : null} />
            <FormGroup
                dataTestId={'sign-up-date-of-birth-form-group'}
                errorMessage={errorMessageToDisplay}
                label={intl.formatMessage({id: 'signUp.dateOfBirth.label'})}
            >
                <DateInput
                    dataTestId={'date-of-birth-input'}
                    day={{
                        errorMessages: {
                            patternMismatch: intl.formatMessage({id: 'signUp.dateOfBirth.day.error.patternMismatch'}),
                            valueMissing: intl.formatMessage({id: 'signUp.dateOfBirth.day.error.valueMissing'}),
                        },
                        label: intl.formatMessage({id: 'signUp.dateOfBirth.day.label'}),
                        name: 'day',
                        ref: dayRef,
                    }}
                    id={'date-of-birth'}
                    invalid={wholeDateValid === DATE_INVALID}
                    month={{
                        errorMessages: {
                            patternMismatch: intl.formatMessage({id: 'signUp.dateOfBirth.month.error.patternMismatch'}),
                            valueMissing: intl.formatMessage({id: 'signUp.dateOfBirth.month.error.valueMissing'}),
                        },
                        label: intl.formatMessage({id: 'signUp.dateOfBirth.month.label'}),
                        name: 'month',
                        ref: monthRef,
                    }}
                    onBlur={rerunValidityCheckIfNecessary}
                    onChange={rerunValidityCheckIfNecessary}
                    required={true}
                    value={parsedDateOfBirth}
                    year={{
                        errorMessages: {
                            patternMismatch: intl.formatMessage({id: 'signUp.dateOfBirth.year.error.patternMismatch'}),
                            valueMissing: intl.formatMessage({id: 'signUp.dateOfBirth.year.error.valueMissing'}),
                        },
                        label: intl.formatMessage({id: 'signUp.dateOfBirth.year.label'}),
                        name: 'year',
                        ref: yearRef,
                    }}
                />
            </FormGroup>
        </>
    );
}
